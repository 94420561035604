<script>
import axiosService from "@/services/axios.service";
import {perspectiveStore} from "@/stores/Perspective.store";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import PlayerPicture from "@/components/common/PlayerPicture.vue";
import {playerAnalysePaneStore} from "@/components/routes/player/panes/analyse/PlayerAnalysePane.store";
import WidgetParamButton from "@/components/common/widgets/parameter/WidgetParamButton.vue";
import {playerStore} from "@/stores/Player.store";
import {gameStore} from "@/stores/Game.store";
import {teamStore} from "@/stores/Team.store";

export default {
  name: "TeamCompositionWidget",
  components: {
    WidgetParamButton,
    PlayerPicture,
    WidgetTeamParamButton,
    WidgetLoadingContent
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: () => {
    return {
      refreshTimeout: null,
      data: null,
    }
  },

  mounted() {
    window.emitter.on('players-route:widgets-need-to-refresh', () => {
      this._refresh()
    })
    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
    this._refresh()
  },

  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _parameters() {
      return perspectiveStore().getParametersByWidgetUID(this.item.uid)
    },
    _playerUID() {
      return playerAnalysePaneStore().getSelectedPlayerUID
    },
    _player() {
      if (!this._playerUID) return null
      return playerStore().get(this._playerUID)
    },
    _tab() {
      if (!this._playerUID) return null
      return playerAnalysePaneStore().getItemByPlayerUID(this._playerUID)
    },
    _gameUID() {
      if (!this._tab) return null
      return this._tab.gameUID
    },
    _game() {
      if (!this._gameUID) return null
      return gameStore().get(this._gameUID)
    },
    _targetTeamUID() {
      if (!this._player) return null
      if (!this._game) return null
      let isHomePlayer = this._game.homeTeamUID === this._player.domesticTeamUID || this._game.homeTeamUID === this._player.nationalTeamUID;

      const teamParamValue = perspectiveStore().getParameterValue(this._itemUID, 'team') || 'AGAINST'

      if (isHomePlayer) {
        switch (teamParamValue) {
          case 'PLAYER':
            return this._game.homeTeamUID
          case 'AGAINST':
            return this._game.awayTeamUID
        }
      } else {
        switch (teamParamValue) {
          case 'PLAYER':
            return this._game.awayTeamUID
          case 'AGAINST':
            return this._game.homeTeamUID
        }
      }
    },
    _targetTeam() {
      if (!this._targetTeamUID) return null
      return teamStore().get(this._targetTeamUID)
    },
    _targetTeamName() {
      if (!this._targetTeam) return "..."
      return this._targetTeam.name
    },
    _formation() {
      if (!this.data) return null
      if(this.data.formation === '') return null
      return this.data.formation
    },
    _formationRows() {
      if (!this._formation) return []
      const rows = this._formation.split('-').reverse()
      rows.push(1)
      return rows
    },
    _formationAvailable() {
      return this._formation !== null
    },
    _rowStyles() {
      return {
        height: 100 / this._formationRows.length + '%',
        minHeight: 100 / this._formationRows.length + '%'
      }
    }
  },

  methods: {

    _refresh() {
      this.data = null // => loading
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {

      if (!this._playerUID) return
      if (!this._gameUID) return
      if (!this._targetTeamUID) return

      const payload = {
        playerUID: this._playerUID,
        gameUID: this._gameUID,
        teamUID: this._targetTeamUID
      }
      axiosService.put('/wid/players/composition', payload).then(response => {
        this.data = response.data
      }).catch(error => {
        console.error(error)
      })
    },

    rowPlayers(rIndex) {
      if (this._formationRows.length === 0) return []
      const gridRowIndex = this._formationRows.length - rIndex
      const rowPlayersCount = this._formationRows[rIndex]
      const results = []
      for (let i = 0; i < rowPlayersCount; i++) {
        const lineup = this.data.lineups.find(l => l.grid && l.grid === gridRowIndex + ':' + i)
        if (lineup) {
          results.push({
            playerUID: lineup.playerUID,
            playerRole: lineup.playerRole
          })
        } else {
          results.push({
            playerUID: null,
            playerRole: "-"
          })
        }
      }
      return results
    }
  }
}
</script>

<template>
  <div class="player-team-composition-widget">
    <div class="widget-header">
      {{ _targetTeamName }}
      <div class="widget-header-params flex">
        {{ _formation }}
        <widget-param-button :widget="item"/>
      </div>
    </div>
    <div class="widget-content">
      <div v-if="data" class="composition-field">
        <div v-if="_formationAvailable" style="height: 100%">
          <div v-for="(row, rIndex) in _formationRows" class="composition-field-row" :style="_rowStyles">
            <div v-for="rowPlayer in rowPlayers(rIndex)" class="composition-field-row-item">
              <player-picture :uid="rowPlayer.playerUID" size="32px"/>
              <div class="player-role">
                {{ rowPlayer.playerRole }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="formation-unavailable">
          Formation non disponible
          <p>⚽️ Les compos débarquent généralement 1h avant le coup d’envoi… pile le temps de peaufiner tes paris !
            ⏳🔥</p>
          <p>🚫 ATTENTION 🚫</p>
          <p>Pour certaines compétitions, pas de compos dispo… ! 🤷‍♂️👀</p>
        </div>
      </div>
      <widget-loading-content v-else/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.player-team-composition-widget {
  height: 100%;
}

.widget-content {
  height: calc(100% - 28px);
}

.composition-field {
  background-image: url("@/assets/images/soccerfield.png");
  width: 100%;
  height: 100%;
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  .composition-field-row {
    text-align: center;
    display: flex;
    justify-content: space-around;
    padding-top: @padding-regular;

    .composition-field-row-item {
      max-height: 50px;
      border-radius: @radius-regular;
      border: 1px solid @stroke-color;
      overflow: hidden;
      background: rgb(255, 255, 255);
      background: linear-gradient(122deg, #5e352b 0%, rgba(94, 53, 43, 0.95) 3%, rgba(37, 37, 42, 0.85) 21%, rgba(37, 37, 42, 0.75) 100%);

      .player-role {
        line-height: 12px;
        font-size: 8pt;
        color: #ccc;
      }
    }
  }

  .formation-unavailable {
    text-align: center;
    padding: @padding-large * 3  @padding-regular @padding-regular;
    color: @text-color;

    p {
      text-align: center;
      margin-top: @padding-large;
      padding: 0 @padding-large;
      opacity: 0.75;
    }
  }
}

</style>
<script>
import {message} from "ant-design-vue";

// Stores
import {perspectiveStore} from "@/stores/Perspective.store";
import {widgetDefinitionsStore} from "@/stores/WidgetDefinitions.store";
import {contextStore} from "@/stores/Context.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "WidgetSettingsModal",

  data() {
    return {
      hasChanged: null,
    }
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _perspectiveStore() {
      return perspectiveStore();
    },
    _widgetDefinitionsStore() {
      return widgetDefinitionsStore();
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'widgetSettingsModal';
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return null;
      return visibleModal.payload
    },
    _perspectiveUID() {
      if (!this._widget) return null;
      return this._widget.perspectiveUID
    },
    _perspective() {
      if (!this._perspectiveUID) return null;
      return this._perspectiveStore.get(this._perspectiveUID)
    },
    _sport(){
      if(!this._perspective) return null
      return this._perspective.sport
    },
    _widgetUID() {
      if (!this._payload) return null;
      return this._payload.uid
    },
    _widget() {
      if (!this._widgetUID) return null
      return this._perspectiveStore.getWidgetByUID(this._widgetUID)
    },
    _parameters() {
      if (!this._widgetUID) return []
      const paramsFromDef = this._perspectiveStore.getParametersByWidgetUID(this._widgetUID)
      const paramsFromPayload = this._payload.parameters
      if (!paramsFromPayload) return paramsFromDef

      // merge parameters from definition and payload
      return paramsFromDef.map(p => {
        let payloadParam = paramsFromPayload.find(pp => pp.key === p.key)
        if (payloadParam) {
          return {
            ...p,
            values: payloadParam.values
          }
        }
        return p
      })
    },
    _parameterDefinitions() {
      if (!this._widget) return []
      let widgetDefinition = this._widgetDefinitionsStore.getByType(this._widget.type);
      if (!widgetDefinition) return []
      return widgetDefinition.parameters
    },
    _isWidgetYours() {
      if (!this._widget) return false
      return this._widget.userUID === contextStore().getLoggedUserUID()
    }
  },
  methods: {
    _onCancel() {
      if (this.hasChanged) {
        perspectiveStore().saveWidgetParameters(this._widgetUID)
      }
      modalStore().closeModal();
    },
    _setParameterValue(parameterDefinition, value) {
      if (!this._isWidgetYours) {
        message.warning("Tu ne peux pas modifier les paramètres d'un widget qui ne t'appartient pas");
        return
      }

      let changed = perspectiveStore().setWidgetParameterValue(this._widgetUID, parameterDefinition, value)
      if (this.hasChanged === null) {
        this.hasChanged = changed;
      } else {
        this.hasChanged = this.hasChanged || changed;
      }
    },
    _isSelected(parameterDefinition, value) {
      let parameter = this._parameters.find(p => p.key === parameterDefinition.key)
      if (!parameter) {
        console.warn('No parameter found for key', parameterDefinition.key)
        return false
      }
      return !!parameter.values.includes(value.key);
    },
    _deleteWidget() {
      if (!this._isWidgetYours) return
      perspectiveStore().deleteWidget(this._widgetUID, () => {
        modalStore().closeModal();
      })
    },
    _visibleValues(parameterDefinition) {
      const results = []

      if(!parameterDefinition){
        console.error('parameterDefinition is not defined')
        return []
      }

      // Check if parameterDefinition.values is an array
      if(!Array.isArray(parameterDefinition.values)){
        return []
      }

      for (let value of parameterDefinition.values) {
        if(!value.sports){
          results.push(value)
        }else if(value.sports.length === 0){
          results.push(value)
        }else if(value.sports.includes(this._sport)){
          results.push(value)
        }
      }
      return results
    }
  },
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Configuration du Widget
    </header>

    <div v-for="p in _parameterDefinitions">
    <div class="sb px-2 pt-1 flex" v-if="_visibleValues(p).length > 0">
      <label class="w-1/5">{{ p.name }}</label>
      <div class="w-4/5">
        <button v-for="v in _visibleValues(p)" :class="{'small info mr-1 mb-1':true, 'primary selected' : _isSelected(p, v)}"
                @click="_setParameterValue(p, v)">
          {{ v.name }}
        </button>
      </div>
    </div>
    </div>

    <template #footer>
      <div class="m-2 flex">
        <button class="secondary" @click="_deleteWidget" v-if="_isWidgetYours">Retirer le widget</button>
        <div class="flex-grow"></div>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>
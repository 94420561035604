<script>
import axiosService from "@/services/axios.service";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import Statistic from "@/components/common/Statistic.vue";
import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import SelectionItem from "@/components/common/SelectionItem.vue";

export default {
  name: "SelectionPaneItem.Diagnostic.Tab",
  components: {SelectionItem, OfferBookmakerValue, Statistic, TeamLogo, GameResultCircle},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      matchSelections: null,
      notMatchSelections: null,

      selectedSelectionUID: null
    }
  },

  beforeMount() {
    if (!this.gameUid) return;

    this.matchSelections = null;
    this.notMatchSelections = null;

    // On va chercher les seasons auxquelles l'équipe participe
    axiosService.get(`/dta/game/` + this.gameUid + `/selections`).then(response => {
      this.matchSelections = response.data.matchSelections;
      this.notMatchSelections = response.data.notMatchSelections;
    })
  },

  methods: {
    _isSelectedSelection(s) {
      if (!s || !this.selectedSelectionUID) return false
      return s.uid === this.selectedSelectionUID
    },
    _setSelectedSelectionUID(uid) {
      if (this.selectedSelectionUID === uid) {
        this.selectedSelectionUID = null
      } else {
        this.selectedSelectionUID = uid
      }
    }
  },
}
</script>

<template>
  <div>
    <fieldset class="m-1 text-left">
      <legend>Sélections qui match</legend>
      <a-alert v-if="!matchSelections" type="info" show-icon message="Chargement..."/>
      <a-alert v-else-if="matchSelections.length === 0" type="warning" show-icon
               message="Il n'y a pas de sélections qui match"/>
      <div v-else v-for="item in matchSelections">
        <selection-item :value="item.selection" :selected="_isSelectedSelection(item.selection)" :show-monitoring="false" :show-controls="false"
                        @click="_setSelectedSelectionUID(item.selection.uid)" class="mb-1"/>
        <div v-if="_isSelectedSelection(item.selection)">
          <hr>
          <span v-for="(statUIDs,period) in item.statsMatch">
              <statistic :uid="statUID" :period="period" v-for="statUID in statUIDs" :positive="true"/>
            </span>
          <hr>
          <span v-for="(statUIDs,period) in item.statsNotMatch">
              <statistic :uid="statUID" :period="period" v-for="statUID in statUIDs" :positive="false"/>
            </span>
          <span v-for="choice in item.choicesMatch">
              <offer-bookmaker-value :obv="choice" :positive="true"/>
            </span>
          <hr>
          <span v-for="choice in item.choicesNotMatch">
              <offer-bookmaker-value :obv="choice" :positive="false"/>
            </span>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: @text-color;
  font-size: @font-size-small;

  tr {
    border-bottom: 1px solid @stroke-color;

    &:last-child {
      border-bottom: none;
    }

    th, td {
      padding: @padding-small;
      text-align: center;
      font-family: 'SourceCodePro', serif;
      font-weight: 400;
    }

    th {
      background-color: @background-color-light;
    }

    td {
      &.label {
        text-orientation: mixed;
        writing-mode: vertical-rl;

        &.ll {
          border-left: 1px solid @stroke-color;
        }
      }
    }
  }
}
</style>
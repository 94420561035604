<script>
import SelectionPane from "@/components/routes/selection/panes/selection/SelectionPane.vue";
import AnalysePane from "@/components/routes/selection/panes/analyse/AnalysePane.vue";
import SelectionOperation from "@/components/routes/selection/panes/selection/condition/SelectionOperation.vue";
import {selectionStore} from "@/stores/Selection.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import {contextStore} from "@/stores/Context.store";
import {userStore} from "@/stores/User.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import SelectionFavIndicator from "@/components/common/SelectionFavIndicator.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import {modalStore} from "@/stores/Modal.store";
import UserDropdownMenu from "../../../common/UserDropdownMenu.vue";
import SelectionItem from "@/components/common/SelectionItem.vue";
import {message} from "ant-design-vue";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";
import axiosService from "@/services/axios.service";
import {monitoringStore} from "@/stores/Monitoring.store";
import {bankrollStore} from "@/stores/Bankroll.store";
import {playerPaneStore} from "@/components/routes/player/panes/player/PlayerPane.store";

export default {
  name: "SelectionsModal",
  components: {
    SelectionItem,
    UserDropdownMenu,
    SelectionFavIndicator,
    FontAwesomeIcon,
    SelectionOperation,
    AnalysePane,
    SelectionPane
  },

  data() {
    return {
      tab: 'user', // user or best

      bestSelections: null,
      filters: null
    }
  },

  computed: {
    _contextStore() {
      return contextStore();
    },
    _selectionStore() {
      return selectionStore();
    },
    _selectedSelectionUID() {
      return selectionPaneStore().selectionActiveUID
    },
    _isSelectionSelected() {
      return this._selectedSelectionUID !== null
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal
      if (!visibleModal) return null
      return visibleModal.payload
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false
      return visibleModal.uid === 'selectionsModal';
    },
    _selectionType() {
      const type = this._payload ? this._payload.type : null
      if (!type) {
        console.error('SelectionsModal: type is not defined in payload')
        return null
      }
      return type
    },

    _selectionTypeLabel() {
      if (!this._selectionType) return ""
      if (this._selectionType === 'PLAYER') return "Joueurs"
      if (this._selectionType === 'GAME') return "Rencontres"
      return "?"
    },

    _favoriteSelections() {
      let results = preferencesStore().get('SELECTION', 'FAVORITE_SELECTIONS_' + this._selectedSport);
      if (!results) return [];
      return results.values.map(r => parseInt(r))
    },

    _userSelections() {
      let userSelections = selectionStore().getUserSelections([this._selectionType]);
      let allUserSelections = userSelections ? [...userSelections] : [];
      if (!allUserSelections) return [];

      // filter by sport
      if (this._selectedSport) {
        allUserSelections = allUserSelections.filter(s => s.sport === this._selectedSport)
      }
      // sort by favorite status
      allUserSelections.sort((a, b) => {
        if (this._favoriteSelections.includes(a.uid) && !this._favoriteSelections.includes(b.uid)) {
          return -1
        }
        if (!this._favoriteSelections.includes(a.uid) && this._favoriteSelections.includes(b.uid)) {
          return 1
        }
        return 0
      })

      return allUserSelections
    },
    _defaultSelections() {
      let defaultSelections = this._selectionStore.getDefaultSelections([this._selectionType]);
      let allDefaultSelections = defaultSelections ? [...defaultSelections] : [];
      if (!allDefaultSelections) return [];

      // filter by sport
      if (this._selectedSport) {
        allDefaultSelections = allDefaultSelections.filter(s => s.sport === this._selectedSport)
      }
      // sort by favorite status
      allDefaultSelections.sort((a, b) => {
        if (this._favoriteSelections.includes(a.uid) && !this._favoriteSelections.includes(b.uid)) {
          return -1
        }
        if (!this._favoriteSelections.includes(a.uid) && this._favoriteSelections.includes(b.uid)) {
          return 1
        }
        return 0
      })

      return allDefaultSelections
    },
    _sortedSelections() {
      let selections = null
      if(this.tab === 'user') {
        selections = this._userSelections
      } else {
        selections = this.bestSelections
      }
      // Sort selections by ROI
      if (!selections) return []

      const monitorings = monitoringStore().getBySelectionUIDs(selections.map(s => s.uid))
      const bankrolls = bankrollStore().getByUIDs(monitorings.map(m => m.bankrollUID))

      return selections.sort((a, b) => {
        const monitoringA = monitorings.find(m => m.selectionUID === a.uid)
        const monitoringB = monitorings.find(m => m.selectionUID === b.uid)

        const bankrollA = bankrolls.find(b => b.uid === monitoringA.bankrollUID)
        const bankrollB = bankrolls.find(b => b.uid === monitoringB.bankrollUID)

        if (this.filters.orderBy === 'roi') {
          return (bankrollB.roi - bankrollA.roi) * 1000
        } else {
          return (bankrollB.winRate - bankrollA.winRate) * 1000
        }
      })
    },
    _selectedSport() {
      return this._contextStore.getSport
    },
    _isAuthenticated() {
      return this._contextStore.isAuthenticated()
    },
    _isMobile() {
      return this._contextStore.isMobileDevice()
    }
  },
  methods: {
    _onSelectSelection(s) {
      contextStore().setSport(s.sport);

      switch (s.type) {
        case 'GAME':
          this._onSelectSelectionGame(s)
          break;
        case 'PLAYER':
          this._onSelectSelectionPlayer(s)
          break;
      }
      this._onCancel()
    },

    _onSelectSelectionGame(s) {
      selectionPaneStore().setSelectionActiveUID(s.uid, s.sport);

      // Si une offre cible est définie dans la selection,
      // on remet à zéro les arguments de l'offre pour utiliser ceux de la sélection.
      if (s.offerFamilyFixedUID) {
        selectionPaneStore().setOfferFamilyFixedUID(null)
        selectionPaneStore().setOfferArgument(null)
        selectionPaneStore().setOfferFamilyFixedUID(s.offerFamilyFixedUID)
        selectionPaneStore().setOfferArgument(s.offerArgument)
      }

      if (s.leagueGroupUID) {
        const leagueGroup = leagueGroupsStore().get(s.leagueGroupUID)
        if (leagueGroup) {
          selectionPaneStore().setSelectedLeagueLabel(leagueGroup.name);
          selectionPaneStore().setSelectedLeagueUIDs(leagueGroup.values);
        }
      } else {
        selectionPaneStore().setSelectedLeagueLabel(null);
        selectionPaneStore().setSelectedLeagueUIDs([]);
      }

      selectionPaneStore().runSelection()
    },

    _onSelectSelectionPlayer(s) {
      playerPaneStore().setSelectionActiveUID(s.uid, s.sport);

      if (s.leagueGroupUID) {
        const leagueGroup = leagueGroupsStore().get(s.leagueGroupUID)
        if (leagueGroup) {
          playerPaneStore().setSelectedLeagueLabel(leagueGroup.name);
          playerPaneStore().setSelectedLeagueUIDs(leagueGroup.values);
        }
      } else {
        playerPaneStore().setSelectedLeagueLabel(null);
        playerPaneStore().setSelectedLeagueUIDs([]);
      }

      playerPaneStore().runSelection()
    },

    _openCreateSelectionModal() {

      if (!this._isAuthenticated) {
        message.warning("Connectes toi pour créer une sélection.");
        return;
      }

      modalStore().openModal({
        uid: "createSelectionModal",
        payload: {
          sport: this._selectedSport,
          type: this._selectionType
        }
      })
    },

    _onCancel() {
      modalStore().closeModal()
    },

    _isSelected(selection) {
      if (!this._selectedSelectionUID) return false;
      if (!selection) return false;
      return this._selectedSelectionUID === selection.uid
    },

    _signWithGoogle() {
      userStore().signInWithGoogle()
    },

    _initFilters() {
      if(this.filters !== null) return

      this.filters = {}
      this.filters.minROI = preferencesStore().getFloat('TOP_SELECTIONS_FILTERS', 'MIN_ROI', 0.01)
      this.filters.minWinRate = preferencesStore().getFloat('TOP_SELECTIONS_FILTERS', 'MIN_WIN_RATE', 30.0)
      this.filters.minTickets = preferencesStore().getInt('TOP_SELECTIONS_FILTERS', 'MIN_TICKETS', 300)
      this.filters.orderBy = preferencesStore().getString('TOP_SELECTIONS_FILTERS', 'ORDER_BY', 'roi')

      this._askRequestBestSelections()
    },

    _askRequestBestSelections() {
      clearTimeout(this.requestBestSelectionsTimeout)
      this.requestBestSelectionsTimeout = setTimeout(() => {
        this._requestBestSelections()
      }, 500)
    },

    async _requestBestSelections() {
      this.bestSelections = null
      // On charge les meilleures sélections
      const criteria = {
        minROI: this.filters.minROI / 100.0,
        minWinRate: this.filters.minWinRate / 100.0,
        minTickets: this.filters.minTickets,
        orderBy: this.filters.orderBy,
      }
      axiosService.put('/sel/best', criteria).then(response => {
        this.bestSelections = response.data.selections

        monitoringStore().addAll(response.data.monitorings)
        bankrollStore().addItems(response.data.bankrolls)
      }).catch(error => {
        console.error('Error while loading best selections', error)
      })
    },
    onMinROIChange(event) {

      const isNumpad = event.keyCode >= 96 && event.keyCode <= 105
      const isDigit = event.keyCode >= 48 && event.keyCode <= 57
      const isDot = event.keyCode === 190 || event.keyCode === 110
      const isDelete = event.keyCode === 8 || event.keyCode === 46

      // Check if keyCode matches a number or a dot or a comma or
      if (!isNumpad && !isDigit && !isDot && !isDelete) {
        return
      }

      // Check if the value is a number
      if (isNaN(this.filters.minROI)) {
        return
      }

      preferencesStore().save('TOP_SELECTIONS_FILTERS', 'MIN_ROI', [this.filters.minROI])
      this._askRequestBestSelections()
    },
    onMinWinRateChange() {
      preferencesStore().save('TOP_SELECTIONS_FILTERS', 'MIN_WIN_RATE', [this.filters.minWinRate])
      this._askRequestBestSelections()
    },
    onMinTicketsChange(event) {

      const isNumpad = event.keyCode >= 96 && event.keyCode <= 105
      const isDigit = event.keyCode >= 48 && event.keyCode <= 57
      const isDot = event.keyCode === 190 || event.keyCode === 110
      const isDelete = event.keyCode === 8 || event.keyCode === 46

      // Check if keyCode matches a number or a dot or a comma or
      if (!isNumpad && !isDigit && !isDot && !isDelete) {
        return
      }

      // Check if the value is a valid number
      if (isNaN(this.filters.minTickets)) {
        return
      }

      preferencesStore().save('TOP_SELECTIONS_FILTERS', 'MIN_TICKETS', [this.filters.minTickets])
      this._askRequestBestSelections()
    },
    onOrderByChange() {
      preferencesStore().save('TOP_SELECTIONS_FILTERS', 'ORDER_BY', [this.filters.orderBy])
      this._askRequestBestSelections()
    }
  },
  watch: {
    tab() {
      if (this.tab === 'best') {
        this._initFilters()
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="700" @cancel="_onCancel" :closable="this._isSelectionSelected"
          :class="{'mobile': _isMobile}">
    <header class="ant-modal-header flex">
      Sélections {{ _selectionTypeLabel }}
    </header>

    <div class="flex" v-if="_selectionType==='GAME'">
      <button class="no-radius tab" :class="{'selected': tab === 'user'}" @click="tab='user'">Tes sélections</button>
      <button class="no-radius tab" :class="{'selected': tab === 'best'}" @click="tab='best'">Top sélections</button>
    </div>
    <hr/>
    <div class="content">
      <div v-if="tab==='user'">
        <div class="selection-item-wrapper" v-if="_userSelections.length > 0">
          <selection-item :value="s" :selected="_isSelected(s)" v-for="s in _userSelections"
                          @click="_onSelectSelection(s)"/>
        </div>
        <div class="m-2" v-if="_userSelections.length === 0 && tab==='user'">
          <button class="accent w-full mb-2" @click="_openCreateSelectionModal">Crée ta 1ère sélection personnalisée
          </button>
          <a-alert class="text-center p-4"
                   message="En créant tes propres sélections, tu peux rechercher de manière précise les rencontres/joueurs succeptibles de t'intéresser."
                   type="info"/>
        </div>
        <div v-if="_defaultSelections.length > 0">
          <h2 class="p-2 mb-5">Pré-définie</h2>
          <div class="selection-item-wrapper">
            <selection-item :value="s" :selected="_isSelected(s)" v-for="s in _defaultSelections"
                            @click="_onSelectSelection(s)"/>
          </div>
        </div>
      </div>
      <div v-if="tab==='best'">

        <table>
          <tr>
            <td>ROI</td>
            <td><input v-model="filters.minROI" style="width: 40px; text-align: right" @keyup="onMinROIChange">%</td>
            <td>Bets</td>
            <td><input v-model="filters.minTickets" style="width: 40px" @keyup="onMinTicketsChange"></td>
            <td>%Win</td>
            <td>
              <select v-model="filters.minWinRate" @change="onMinWinRateChange">
                <option v-for="i in 99" :value="i">{{ i }}%</option>
              </select>
            </td>
            <td>Tri</td>
            <td>
              <select v-model="filters.orderBy" @change="onOrderByChange">
                <option value="roi">ROI</option>
                <option value="winRate">%Win</option>
              </select>
            </td>
          </tr>
        </table>

        <hr/>
        <div class="m-2" v-if="!bestSelections">
          <a-spin/>
        </div>
        <div class="selection-item-wrapper">
          <selection-item :value="s" :selected="_isSelected(s)" v-for="s in _sortedSelections"
                          @click="_onSelectSelection(s)"/>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex p-1">
        <div class="flex-grow"/>
        <button class="m-1 accent" @click="_openCreateSelectionModal" v-if="_contextStore.isAuthenticated()">Nouvelle
        </button>
        <a-tooltip placement="left" title="Connexion">
          <button class="primary m-1" v-if="!_isAuthenticated" @click="_signWithGoogle()">
            Connexion
          </button>
        </a-tooltip>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

@source-height: 28px;

#selection-modal-header-search-button {
  border: none !important;
  height: @toolbar-regular;
  padding: @padding-regular !important;
  flex-grow: 1;
  font-size: @font-size-regular !important;
  text-align: left;

  &:focus {
    outline: none;
  }
}

.ant-modal.mobile .content {
  max-height: calc(100dvh - 40px - 40px - 8px - 8px - @source-height) !important;
}

.selection-item-wrapper {
  height: calc(100vh - @toolbar-regular * 2 - @toolbar-small);
  gap: @padding-regular; /* Adjust the gap as needed */
  display: flex;
  flex-wrap: wrap;
  padding: @padding-regular;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}
</style>
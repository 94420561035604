<script>
import axiosService from "@/services/axios.service";
import TeamLogo from "@/components/common/TeamLogo.vue";
import {perspectiveStore} from "@/stores/Perspective.store";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import {widgetDataStore} from "@/stores/WidgetData.store";
import {contextStore} from "@/stores/Context.store";
import {gameStore} from "@/stores/Game.store";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import GameDate from "@/components/common/GameDate.vue";
import GameScore from "@/components/common/GameScore.vue";
import TeamName from "@/components/common/TeamName.vue";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";

export default {
  name: "NextTeamGameWidget",
  components: {
    WidgetLoadingContent,
    TeamLogo,
    TeamName, GameScore, GameDate, GameResultCircle,
    WidgetTeamParamButton
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: null
    }
  },
  mounted() {

    this._onGameUIDChange()

    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })

    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _cssStyle() {
      if (this.item) {
        return {}
      }
      return {
        width: '152px !important',
        height: '134px !important',
      }
    },
    _teamUID() {
      return perspectiveStore().getParameterTeamValue(this.item.uid)
    },
    _game() {
      if (!this.data) return null
      const results = this.data.results
      if (!results || !results.length) return null
      return results[0]
    },
    _gameUID() {
      return this._game ? this._game.uid : null
    },
    _otherTeamUID() {
      if (!this._game) return null
      if (this._game.homeTeamUID === this._teamUID) return this._game.awayTeamUID
      if (this._game.awayTeamUID === this._teamUID) return this._game.homeTeamUID
      return null
    },
    _itemUID() {
      return this.item ? this.item.uid : null
    },
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!contextStore().gameUID) return;

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) return;

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {
      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        return;
      }

      const game = gameStore().get(contextStore().gameUID)
      if (!game) {
        return
      }

      const payload = {
        whoTeamUID: perspectiveStore().getParameterTeamValue(this.item.uid),
        at: game.date,
        count: 1,
      }
      axiosService.put('/wid/next-team-games', payload).then(response => {
        this.data = response.data
        wds.put(this.item.uid, contextStore().gameUID, this.data)
      })
    },
  }
}
</script>

<template>
  <div :style="_cssStyle">
    <div class="widget-header">
      Prochain match
      <div class="widget-header-params flex">
        <widget-team-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content flex pt-1" v-else>
      <team-name :uid="_otherTeamUID" class="flex-grow text-left"/>
      <game-date :uid="_gameUID" style="min-width: 44px"/>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>

import Statistic from "@/components/common/Statistic.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameHour from "@/components/common/GameHour.vue";
import GameDate from "@/components/common/GameDate.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import {gameStore} from "@/stores/Game.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import LeagueName from "@/components/common/LeagueName.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import {leagueStore} from "@/stores/League.store";

export default {
  name: "SelectionPaneItemLineGameData",
  components: {
    LeagueLogo, LeagueName,
    FontAwesomeIcon,
    OfferChoiceButton,
    TeamLogo,
    GameDate,
    GameHour,
    AreaFlag,
    TeamName,
    Statistic,
  },
  props: {
    gameUid: {
      type: Number,
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {
    _game() {
      if (!this.gameUid) return null
      return gameStore().get(this.gameUid)
    },
    _leagueUID() {
      if (!this._game) return null
      return this._game.leagueUID
    },
    _league() {
      if (!this._leagueUID) return null
      return leagueStore().get(this._leagueUID)
    },
    _gameArea() {
      if (!this._league) return null
      return this._league.area
    },
    _isSelected() {
      return selectionPaneStore().getSelectedGameUID() === this.gameUid;
    },
  },

  methods: {}
}
</script>

<template>
  <div class="line-game-data">
    <area-flag :area="_gameArea" class="ml-1 inline-block" size="small"/>
    <league-logo :uid="_leagueUID" size="small" style="margin-top: 1px"/>
    <league-name :uid="_leagueUID" class="ml-1 inline-block" size="small" :show-area="true" :show-arjel="true"/>
    <div class="flex-grow"/>
    <game-date v-if="_game" :uid="gameUid" class="ml-1 inline-block" show-icon size="small"/>
    <game-hour v-if="_game" :uid="gameUid" class="inline-block" size="small"/>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.line-game-data {
  border-top: 1px solid @stroke-color;
  font-size: 8pt;
  color: @text-color-dark;
  display: flex;
  padding: @padding-tiny 0;

  .item-game-date {
    flex-grow: 100;
    text-align: center;
    max-width: 20%;
  }

  .item-game-venue {
    flex-grow: 100;
    max-width: 40%;
    text-align: left;
    overflow: hidden;
  }

  .item-game-weather {
    flex-grow: 100;
    max-width: 40%;
    text-align: right;
  }
}
</style>
<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import BlogAvisClient from "@/components/routes/blog/BlogAvisClient.vue";

export default {
  name: "CommentChoisirCote",
  components: {BlogAvisClient, BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif'
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Analyse les stats, maximise tes chances"
             class="w-full"/>
      </div>
    </div>
    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET s’adresse à tous ceux qui veulent passer au niveau supérieur dans leurs paris sportifs. Que tu sois un
        novice ou un pro, cet outil est là pour t’aider à parier avec plus de précision et de confiance.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>
    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Pense à la value...</h3>
      <p class="px-5">T’es là, à te demander si tu dois miser sur des petites cotes pour assurer ou tenter ta chance
        avec les
        grosses
        cotes pour le big win ? La réponse, c’est ni l’un ni l’autre ! Pour vraiment faire du blé sur le long terme,
        il
        faut trouver les cotes value. Et t’inquiète, avec DoctoBET, tu vas les dénicher comme un pro.</p>
    </div>


    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Il n'y a que ça qui compte.</h3>
      <p class="px-5">Dans le game des paris sportifs, ce qui compte, c’est pas de parier au pif. Les cotes value,
        c’est là où le
        bookmaker se plante sur la probabilité d’un résultat, et où tu peux faire un vrai coup. Mais comment savoir
        lesquelles sont vraiment value ? C’est là que DoctoBET arrive en renfort.</p>
    </div>


    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">C'est plus simple avec le bon outil.</h3>
      <p class="px-5">
        Notre outil, c’est ton meilleur pote pour scanner tous les matchs, les stats, et t’afficher direct les cotes
        qui
        valent le coup. Que t’aimes les paris safe ou que tu kiffes le risque, DoctoBET te montre où la value se
        cache,
        pour que tu mises avec confiance et sans stress.
      </p>
    </div>

    <blog-see-more/>

    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Gagne un max de temps avec DoctoBET</h3>
      <p class="px-5">Nos algos calculent les probabilités et te trouvent les cotes value en temps réel.
        Gain de temps max : Plus besoin de galérer des heures à chercher la bonne cote, DoctoBET fait tout le taf en
        quelques secondes.</p>
    </div>

    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Qu'est ce que tu attends ?</h3>
      <p class="px-5">
        Arrête de laisser tes paris au hasard. Choisir entre petites et grosses cotes, c’est dépassé. Ce qui compte,
        c’est
        de trouver les cotes value qui vont vraiment te rapporter sur le long terme. DoctoBET, c’est l’outil qui te
        file
        les meilleures opportunités sur un plateau.
      </p>
    </div>

    <blog-avis-client/>

  </div>
</template>

<style scoped lang="less">

</style>
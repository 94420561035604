<script>
import {leagueStore} from "@/stores/League.store";
import AreaFlag from "@/components/common/AreaFlag.vue";
import LeagueButton from "@/components/common/LeagueButton.vue";
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";
import {message, Modal} from "ant-design-vue";
import {preferencesStore} from "@/stores/Preferences.store";

export default {
  name: "LeagueGroupModal",
  components: {LeagueButton, AreaFlag},
  data() {
    return {
      // Filtrage des compétitions
      filterTimeout: null,
      filter: '',
      filteredLeagues: [],

      // Groupe de compétition
      group: {
        uid: null,
        name: null,
        values: []
      }
    }
  },
  mounted() {
    this.filteredLeagues = this._sportLeagues;
  },
  computed: {
    _leagueStore() {
      return leagueStore();
    },
    _sportLeagues() {
      let all = this._leagueStore.getAll
      if (!all) return []
      return all.filter(l => l.sport === this._sport)
    },
    _leaguesGrouped() {
      let leagues = this.filteredLeagues;

      console.log(leagues)
      // remove not arjel if needed
      const arjelOnly = preferencesStore().getBoolean('BETTING', 'ARJEL_ONLY', false)
      if (arjelOnly) {
        leagues = leagues.filter(l => l.isArjel);
      }
      console.log(leagues, arjelOnly)

      // filter by name
      if (this.filter && this.filter.length > 2) {
        leagues = leagues.filter(l => l.name.toLowerCase().includes(this.filter.toLowerCase()));
      }

      // group by area
      let grouped = {};
      for (let i = 0; i < leagues.length; i++) {
        let league = leagues[i];
        if (!grouped[league.area]) {
          grouped[league.area] = [];
        }
        grouped[league.area].push(league);
      }
      return grouped;
    },
    _modal() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return null;
      if (visibleModal.uid !== 'leagueGroupModal') return null
      return visibleModal;
    },
    _isVisible() {
      if (!this._modal) return false;
      return this._modal.uid === 'leagueGroupModal';
    },
    _sport() {
      return contextStore().getSport
    },
    _payload() {
      if (!this._modal || !this._modal.payload) return null;
      return this._modal.payload;
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _canConfirm() {
      return this.group && this.group.name && this.group.values && this.group.values.length > 0;
    }
  },
  methods: {
    _onSelectLeague(league) {
      if (this._isSelected(league.uid)) {
        this.group.values = this.group.values.filter(l => l !== league.uid);
      } else {
        this.group.values.push(league.uid);
      }
    },
    _isSelected(uid) {
      if(!this.group || !this.group.values) return false;
      return !!this.group.values.find(l => uid === l);
    },
    _onCancel() {
      modalStore().closeModal()
    },
    _onConfirm() {
      if (!this._canConfirm) {
        message.warn("Renseigne un nom et sélectionne au moins une compétition.")
        return
      }
      leagueGroupsStore().save(this.group.uid, this.group.name, this._sport, this.group.values)
      this._onCancel()
    },
    _deleteGroup() {
      Modal.confirm({
        title: 'Es-tu sûr de vouloir supprimer ce groupe ?',
        content: 'Cette action est irréversible.',
        okText: 'Oui, supprimer',
        okType: 'danger',
        cancelText: 'Annuler',
        onOk: () => {
          leagueGroupsStore().delete(this.group.uid)
          this._onCancel()
        },
        onCancel: () => {
          // Nothing to do
        },
      });

    }
  },

  watch: {
    filter: function (val) {
      // on filtre les ligues au bout de 300ms
      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
        this.filteredLeagues = this._sportLeagues.filter(l => l.name.toLowerCase().includes(val.toLowerCase()));
      }, 300);
    },
    _isVisible: function (val) {
      if (val) {
        // On recalcule les sportLeagues
        this.filteredLeagues = this._sportLeagues;
        // On alimente les group.values sur la bases selectedUIDs
        if (this._payload && this._payload.group) {
          this.group = this._payload.group;
        } else {
          this.group = {
            uid: null,
            name: null,
            values: []
          }
        }
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header pl-2">
      Groupe de compétition - {{ _sport }}
    </header>

    <div class="content">
      <fieldset class="m-2 no-border">
        <legend>Nom du groupe</legend>
        <div class="flex">
          <input v-model="group.name" placeholder="Nom du groupe" class=" flex-grow" maxlength="32">
        </div>
        <a-alert v-if="!group.name" type="error" show-icon class="mt-1" message="Le nom du groupe est obligatoire"/>
      </fieldset>

      <div class="flex sb">
        <legend class="p-2">Choix des compétitions</legend>
        <input v-model="filter" placeholder="Filtrer par nom" class="mr-2 my-1 ">
      </div>
      <a-alert v-if="!group || !group.values || group.values.length === 0" type="error" show-icon class="m-2"
               message="Sélectionne au moins une compétition pour créer un groupe."/>
      <div class="content">

        <div id="selection-league-modal-all" class="pb-2">

          <fieldset class="m-2 no-side-border" v-for="area in Object.keys(_leaguesGrouped)">
            <legend>{{ area }}</legend>
            <league-button class="mr-1" v-for="l in _leaguesGrouped[area]" :key="l.id"
                           :league="l"
                           :selected="_isSelected(l.uid)"
                           @click="_onSelectLeague"/>
          </fieldset>
        </div>
      </div>
    </div>


    <template #footer>
      <div class="flex m-1">
        <button class="secondary m-1" @click="_deleteGroup" v-if="group.uid">
          Supprimer ce groupe
        </button>
        <div class="flex-grow"></div>
        <button class="primary m-1" @click="_onConfirm" v-if="_canConfirm">
          Valider
        </button>
        <button class="m-1 info" @click="_onCancel" v-if="this._onCancel">Annuler</button>
      </div>
    </template>
  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#selection-league-modal-content {
  overflow: hidden;

  #selection-league-modal-selected {
    background-color: @background-color;
  }

  #selection-league-modal-all {
    background-color: @background-color-light;
  }

  fieldset {
    background-color: @background-color;
  }
}
</style>
<script>
import MainHeader from "@/components/common/MainHeader.vue";
import PlayerPane from "@/components/routes/player/panes/player/PlayerPane.vue";
import {contextStore} from "@/stores/Context.store";
import PlayerAnalysePane from "@/components/routes/player/panes/analyse/PlayerAnalysePane.vue";
import PlayerWelcomePane from "@/components/routes/player/panes/welcome/PlayerWelcomePane.vue";
import {playerAnalysePaneStore} from "@/components/routes/player/panes/analyse/PlayerAnalysePane.store";
import PlayerBadSportPane from "@/components/routes/player/panes/welcome/PlayerBadSportPane.vue";

export default {
  name: "PlayerRoute",
  components: {
    PlayerBadSportPane,
    MainHeader,
    PlayerPane,
    PlayerWelcomePane,
    PlayerAnalysePane
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _selectedPlayerUID() {
      return playerAnalysePaneStore().getSelectedPlayerUID
    },
    _isFootball() {
      return contextStore().getSport === 'FOOTBALL'
    }
  },
  beforeMount() {
    if (contextStore().shouldReload()) {
      window.location.href = window.location.origin
    }

    if (!contextStore().isInitialized()) {
      this.$router.push({name: 'init'})
    }
  },
  mounted() {
    if (!contextStore().isInitialized()) return;
    if (!contextStore().isAuthenticated()) return;

    // On met a jour le nom de la route courante
    contextStore().setRoute('player')
  }
}
</script>

<template>
  <div :class="{'mobile': _isMobile}">
    <main-header/>
    <div class="flex" v-if="_isFootball">
      <player-pane/>
      <player-analyse-pane v-if="!_isMobile && _selectedPlayerUID"/>
      <player-welcome-pane v-else-if="!_isMobile"/>
    </div>
    <div v-else>
      <player-bad-sport-pane/>
    </div>

  </div>
</template>

<style scoped lang="less">

</style>
<script>

import MonitoringTicketTemplate from "@/components/common/MonitoringTicketTemplate.vue";
import BankrollTicket from "@/components/routes/bankroll/panes/bankroll/items/BankrollTicket.vue";
import axiosService from "@/services/axios.service";
import BankrollResume from "@/components/common/BankrollResume.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";
import {bookmakerStore} from "@/stores/Bookmaker.store";
import {bankrollStore} from "@/stores/Bankroll.store";

export default {
  name: "MonitoringItem",
  components: {BankrollResume, BankrollTicket, MonitoringTicketTemplate},
  props: {
    item: {
      required: true
    },
  },

  data() {
    return {
      tickets: [],

      loading: true,
      page: 0,

      selectedDateIntervalCode: 'NEXT_3_DAYS',
      dateIntervalChoices: [
        {
          label: 'Plus tôt aujourd\'hui',
          shortLabel: '+tôt',
          code: 'EARLIER_TODAY'
        },
        {
          label: 'Aujourd\'hui',
          shortLabel: 'Auj.',
          code: 'TODAY'
        },
        {
          label: '24 prochaines heures',
          shortLabel: '24H',
          code: 'NEXT_24_HOURS'
        },
        {
          label: '3 prochains jours',
          shortLabel: '3J',
          code: 'NEXT_3_DAYS'
        }
      ],
    }

  },

  mounted() {
    this._loadTicketPage()
  },

  computed: {
    _bankrollUID() {
      return this.item.bankrollUID
    },
    _bankroll() {
      return bankrollStore().get(this._bankrollUID)
    },
    _bankrollWaitingTicketsCount() {
      if (!this._bankroll) return 0
      return this._bankroll.ticketWaitCount
    },
    _canLoadMore() {
      return this.tickets.length < this._bankrollWaitingTicketsCount
    },
    _waitingTickets() {
      let results = [...this.tickets]

      // remove undefined and null values
      results = results.filter(t => t !== undefined && t !== null)
      if(results.length === 0) return results

      // on récupère le critère de tri
      let critereTri = preferencesStore().getFirstValue('MONITORING_TICKETS', 'CRITERE_TRI');
      if (!critereTri) {
        critereTri = 'TICKET_DATETIME'
        preferencesStore().save('MONITORING_TICKETS', 'CRITERE_TRI', [critereTri])
      }

      switch (critereTri){
        case 'ODD_ASC':
          return results.sort((a, b) => {
            return parseFloat(a.odd) - parseFloat(b.odd)
          })
        case 'ODD_DESC':
          return results.sort((a, b) => {
            return parseFloat(b.odd) - parseFloat(a.odd)
          })
        case 'TICKET_DATETIME':
          return results.sort((a, b) => {
            return a.uid - b.uid
          })
        case 'GAME_DATETIME':
          return results.sort((a, b) => {
            // get most recent gameDatetime in bets
            let aGameDatetime = null

            if(!a.bets) {
              console.log("bets of a is null", a)
              return -1
            }

            a.bets.forEach(bet => {
              if(aGameDatetime == null || aGameDatetime > bet.gameDatetime){
                aGameDatetime = bet.gameDatetime
              }
            })

            let bGameDatetime = null
            b.bets.forEach(bet => {
              if(bGameDatetime == null || bGameDatetime > bet.gameDatetime){
                bGameDatetime = bet.gameDatetime
              }
            })

            if(aGameDatetime < bGameDatetime){
              return -1
            }
            if(aGameDatetime > bGameDatetime){
              return 1
            }
            return 0
          })
      }
      return results
    },

    _critereTriLabel() {
      const critereTri = preferencesStore().getFirstValue('MONITORING_TICKETS', 'CRITERE_TRI');
      if (critereTri === 'TICKET_DATETIME') return 'Date ticket'
      if (critereTri === 'GAME_DATETIME') return 'Date match'
      if (critereTri === 'ODD_ASC') return 'Côte croissante'
      if (critereTri === 'ODD_DESC') return 'Côte décroissante'

      // si le critère n'est pas défini, on utilise le paramètre par défaut
      return 'Date ticket'
    },

    _selectedDateInterval() {
      return this.dateIntervalChoices.find(c => c.code === this.selectedDateIntervalCode);
    },
    _selectedDateIntervalLabel() {
      if (!this._selectedDateInterval) return ''
      return this._selectedDateInterval.label
    },
    _bookmakerChoices() {
      const choices = [
        {
          label: 'La plus haute',
          value: null
        }
      ]
      bookmakerStore().getAll.forEach(b => {
        choices.push({
          label: b.name,
          value: b.uid
        })
      })
      return choices
    },
    _bookmakerChoiceLabel() {
      let label = 'La plus haute'
      this._bookmakerChoices.forEach(c => {
        if (c.value === this.item.bookmakerUid) {
          label = c.label
        }
      })
      return label
    },
  },

  methods: {
    _onSelectDateInterval(option) {
      this.selectedDateIntervalCode = option
      this._loadTicketPage()
    },
    _setCritereTri(option) {
      const cs = contextStore()
      if (!cs.isAuthenticated()) {
        message.warning("Il faut être connecté pour changer le tri.");
        return;
      }

      preferencesStore().save('MONITORING_TICKETS', 'CRITERE_TRI', [option])
    },
    _loadNextPage() {
      this.page += 1
      this._loadTicketPage(this.page)
    },
    _loadTicketPage(page = 0) {
      this.loading = true
      let dateInterval = {
        start: new Date(),
        end: new Date()
      };
      switch (this.selectedDateIntervalCode) {
        case 'TODAY':
          // Get end of day date time
          dateInterval.end.setHours(23, 59, 59, 999);
          break;
        case 'NEXT_24_HOURS':
          dateInterval.end.setDate(dateInterval.end.getDate() + 1);
          break;
        case 'NEXT_3_DAYS':
          dateInterval.end.setDate(dateInterval.end.getDate() + 3);
          break;
      }

      // format start and end date to YYYY-MM-DD
      dateInterval.start = dateInterval.start.toISOString().split('T')[0];
      dateInterval.end = dateInterval.end.toISOString().split('T')[0];

      const payload = {
        monitoringUID: this.item.uid,
        dateInterval: dateInterval,
        status: 'WAITING',
        page: page,
      }

      axiosService.put('/bkl/' + this.item.bankrollUID + '/ticket', payload).then(response => {
        if(page === 0){
          this.tickets = response.data.results
        } else {
          this.tickets = this.tickets.concat(response.data.results)
        }
        this.loading = false
      })
    }
  }
}
</script>

<template>
  <div :class="{'monitoring-item': true}">
    <div class="flex">
      <monitoring-ticket-template :sport="item.sport" :item="t" v-for="t in item.templates" :key="t.uid"/>

      <div class="flex-grow"/>

      <fieldset class="no-border text-right">
        <legend>Côte</legend>
        <span class="pr-1">{{_bookmakerChoiceLabel}}</span>
      </fieldset>
    </div>

    <fieldset class="mt-2 no-border">
      <legend>Bankroll associée</legend>
      <bankroll-resume :bankroll-u-i-d="item.bankrollUID" :show-balance="false"/>
    </fieldset>

    <fieldset class="mt-2 no-border">

        <legend>Tickets en cours ({{ _bankrollWaitingTicketsCount }})</legend>
        <div class="flex mx-1 mb-2">
          <a-dropdown :trigger="['click']" class="flex-grow-0">
            <button class="info small" @click.prevent>{{ _selectedDateIntervalLabel }}</button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="c in dateIntervalChoices" @click="_onSelectDateInterval(c.code)">{{
                    c.label
                  }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>

          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="small info">
              <font-awesome-icon icon="fa-solid fa-sort-amount-desc"/>
              {{ _critereTriLabel }}
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="_setCritereTri('GAME_DATETIME')">
                  Trier par date de match
                </a-menu-item>
                <a-menu-item @click="_setCritereTri('TICKET_DATETIME')">
                  Trier par date de ticket
                </a-menu-item>
                <a-menu-item @click="_setCritereTri('ODD_ASC')">
                  Trier par côte croissante
                </a-menu-item>
                <a-menu-item @click="_setCritereTri('ODD_DESC')">
                  Trier par côte décroissante
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>

      <hr/>


      <div class="bankroll-tickets p-1">
        <bankroll-ticket class="mb-2" v-for="t in _waitingTickets" :ticket="t" :editable="false"/>
        <a-alert v-if="_waitingTickets.length === 0"
                 message="Aucun bet n'a été identifié comme correspondant à ta sélection. Le monitoring est réalisé toutes les 3 heures."
                 type="warning"/>
        <div class="text-center">
          <font-awesome-icon icon="fa-solid fa-spinner" size="2xl" :spin="true" class="primary" v-if="loading"/>
          <button class="primary mb-5" v-else-if="_canLoadMore" @click="_loadNextPage">
            Afficher plus de résultats
          </button>
        </div>
      </div>

    </fieldset>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.monitoring-item {
  border-radius: @padding-small;
  padding: 0;
  color: @text-color;
  cursor: pointer;
  text-align: left;
  border: 1px solid transparent;

  &:nth-child(even) {
    background: @background-color-dark;
  }

}
</style>
<script>
import {statStore} from "@/stores/Statistic.store";
import ResultTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Stats.Tab.Result.vue";
import GoalsTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Stats.Tab.Goals.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import YoutubeButton from "@/components/common/YoutubeButton.vue";

export default {
  name: "SelectionPaneItem.Stats.Tab",
  components: {YoutubeButton, ResultTab, GoalsTab},
  props: {
    gameUid: {
      required: true
    }
  },

  beforeMount() {


    // On va chercher la préférence du type
    let type = preferencesStore().getFirstValue('SELECTION_STATS_TAB', 'TYPE');
    if (type) {
      this.filters.type = type
    } else {
      preferencesStore().save('SELECTION_STATS_TAB', 'TYPE', [this.filters.type])
    }

    // On va chercher la préférence de la league
    let league = preferencesStore().getFirstValue('SELECTION_STATS_TAB', 'LEAGUE');
    if (league) {
      this.filters.league = league
    } else {
      preferencesStore().save('SELECTION_STATS_TAB', 'LEAGUE', [this.filters.league])
    }

  },

  data() {
    return {
      selectedCategory: null,

      filters: {
        type: 'ALL',
        league: 'ANY'
      },

      data: null
    }
  },

  methods: {
    _setLeague(league) {
      this.filters.league = league
      preferencesStore().save('SELECTION_STATS_TAB', 'LEAGUE', [this.filters.league])
    },
    _setType(type) {
      this.filters.type = type
      preferencesStore().save('SELECTION_STATS_TAB', 'TYPE', [this.filters.type])
    },
    _setCategory(cat) {
      if (null == cat) {
        this.selectedCategory = null
        this.selectedSubCategory = null
        return
      }

      if (!this.selectedCategory) {
        this.selectedCategory = cat
        if (cat.subs) {
          this.selectedSubCategory = cat.subs[0]
        }
        return
      }

      if (cat.code === this.selectedCategory.code) {
        this.selectedCategory = null
        this.selectedSubCategory = null
      } else {
        this.selectedCategory = cat
        if (cat.subs) {
          this.selectedSubCategory = cat.subs[0]
        }
      }
    },
    _merge(data, targets) {
      if (!data) {
        console.warn('No data to merge')
        return null
      }
      if (!targets) {
        console.warn('No targets to merge')
        return null
      }
      const minAbsByPeriod = {
        'CURRENT_SEASON': 5,
        'ALL_TIME': 10,
        'LAST_10_GAMES': 10,
        'LAST_5_GAMES': 5
      }
      const results = []
      let points = 0
      let sumAbs = 0
      data.forEach(item => {
        const stat = item.stat
        const period = item.period
        const byFixedUID = targets.find(target => target.fixedUID.indexOf(stat.definitionFixedUID) >= 0)
        if (!byFixedUID) return
        const isMatchingWhoUID = byFixedUID.whoUID ? byFixedUID.whoUID.indexOf(stat.whoUID) >= 0 : false
        if (isMatchingWhoUID) {
          const abs = parseInt(statStore().getDataCount(stat, period))
          if (abs < minAbsByPeriod[period]) {
            return
          }
          const prc = statStore().getValue(stat, period, false)
          const statPoints = Math.pow(prc, 2) * abs
          points = points + statPoints
          sumAbs = sumAbs + abs
          results.push({
            stat: stat,
            period: period,
            percent: prc,
            positive: true
          })
        }
      })

      if (sumAbs === 0) {
        return {
          points: 0,
          stats: []
        }
      }
      return {
        points: parseInt((points / sumAbs * 100).toFixed(0)),
        stats: results
      }
    }
  },

  computed: {
    _categories() {
      return [
        {label: 'Résultat', code: 'RESULT'},
        //{label: 'Ecart de buts', code: 'GOAL_DIFFERENCE'},
        {label: 'Total de buts', code: 'GOALS'},
        //{label: 'Buts par équipe', code: 'TEAM_GOALS'}
      ]
    }
  }
}
</script>

<template>
  <div>
    <div class="flex">
      <fieldset class="m-1 p-0">
        <legend class="m-0">Type</legend>
        <button :class="{'small no-radius' : true, 'primary selected' : filters.type === 'ALL'}"
                style="height: 22.3px"
                @click="_setType('ALL')">Tous
        </button>

        <button :class="{'small no-radius' : true, 'primary selected' : filters.type === 'DOM_EXT'}"
                style="height: 22.3px"
                @click="_setType('DOM_EXT')">D/E
        </button>
      </fieldset>
      <fieldset class="m-1 p-0">
        <legend class="m-0">Compétition</legend>
        <button :class="{'small no-radius' : true, 'primary selected' : filters.league === 'ANY'}"
                style="height: 22.3px"
                @click="_setLeague('ANY')">Toutes
        </button>

        <button :class="{'small no-radius' : true, 'primary selected' : filters.league === 'CURRENT'}"
                style="height: 22.3px"
                @click="_setLeague('CURRENT')">Courante
        </button>
      </fieldset>
    </div>
    <a-alert v-if="!selectedCategory" message="Choisis une catégorie pour voir les statistiques." type="info"
             show-icon class="m-1"/>
    <div class="categories">
      <button v-if="!selectedCategory" class="tiny info" v-for="c in _categories" @click="_setCategory(c)">{{
          c.label
        }}
      </button>
    </div>

    <result-tab v-if="selectedCategory?.code === 'RESULT'" :game-uid="gameUid" :filters="filters"
                @close="_setCategory(null)" :merge-method="_merge"/>
    <goals-tab v-if="selectedCategory?.code === 'GOALS'" :game-uid="gameUid" :filters="filters"
               @close="_setCategory(null)" :merge-method="_merge"/>
    <hr/>
    <div class="flex p-2">
      <p class="pr-2 m-0 flex-grow">🔍 Avec cet onglet, explore les statistiques en lien avec différentes issues pour la rencontre ! 💡</p>
      <youtube-button video-id="-iIBghIoY3I"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.categories {
  width: 100%;
  text-align: left;
  padding: @padding-small;
  overflow-x: scroll;
  display: flex;
  white-space: nowrap;
  scrollbar-width: none;

  button {
    flex: 0 0 auto;
    font-size: 7.5pt !important;
    margin-right: @padding-tiny;
  }
}
</style>
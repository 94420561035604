<script>

import {blogRouteStore} from "@/components/routes/blog/BlogRoute.store";

export default {
  name: "BlogSeeMore",
  computed: {
    _articles() {
      return blogRouteStore().get3RandomArticles()
    },
  },
  methods: {
    _getArticleTitle(article) {
      return blogRouteStore().getTitle(article)
    },
    _goToArticle(article) {
      this.$router.push({name: 'blog', params: {article: article}})

      setTimeout(() => {
        this.$nextTick(() => {
          // smooth scroll to top of element blog-article
          const page = document.querySelector('.blog-page');
          if(!page) return
          page.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        })
      }, 100)

    }
  }
}
</script>

<template>
  <div class="blog-see-more lg:w-1/3 md:w-2/3 m-auto">
    <div class="dots">
      <div class="dot primary"></div>
      <div class="dot secondary"></div>
      <div class="dot tertiary"></div>
      <div class="dot quaternary"></div>
    </div>
    <h2>Pour aller plus loin...</h2>
    <div class="article-row flex" v-for="a in _articles">
      <div class="title flex-grow">{{ _getArticleTitle(a) }}</div>
      <button class="secondary" @click="_goToArticle(a)">Voir+</button>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.blog-see-more {
  padding: @padding-large;

  .article-row{
    background: #f4f4f4;
    border: 1px solid #eee;
    margin-top: @padding-regular;
    font-size: 1.0rem;
    padding: @padding-regular;
    border-radius: @radius-regular;

    .title {
      font-size: 13pt;
      line-height: 30px;
      text-align: right;
      margin-right: 12px;
    }
  }
}
</style>
<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import BlogAvisClient from "@/components/routes/blog/BlogAvisClient.vue";

export default {
  name: "PlusStatsMoinsPerte",
  components: {BlogAvisClient, BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  methods: {
    _scrollToBegin() {
      // scroll to end of h1-bloc
      // get .h1-bloc
      const h1Bloc = document.querySelector('.h1-bloc');
      // get h1-bloc height
      const h1BlocHeight = h1Bloc.clientHeight;
      // Scroll in blog-page element
      const blogPage = document.querySelector('.blog-page');
      blogPage.scrollTo({
        top: h1BlocHeight,
        behavior: 'smooth'
      });
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif';
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Plus de stats, moins de perte"
             class="w-full"/>
      </div>
    </div>

    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET s’adresse à tous ceux qui veulent passer au niveau supérieur dans leurs paris sportifs. Que tu sois un
        novice ou un pro, cet outil est là pour t’aider à parier avec plus de précision et de confiance.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>

    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Chaque chiffre compte</h3>
      <p class="px-5">Dans les paris sportifs, un petit détail peut tout changer. Une simple statistique peut faire la
        différence entre un pari gagnant et une perte. Les chiffres ne sont pas là pour te compliquer la vie, ils sont
        là pour te guider. Ignorer les stats, c'est comme parier les yeux fermés.</p>
    </div>

    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Les erreurs évitables</h3>
      <p class="px-5">Combien de fois as-tu perdu un pari parce que tu n'avais pas toutes les informations en main ? Une statistique clé sur la forme d'une équipe ou les performances récentes d'un joueur aurait pu t'éviter cette déception. Apprends à voir ce que d’autres ne voient pas, et transforme ces erreurs en opportunités.</p>
    </div>


    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Parier, c'est anticiper</h3>
      <p class="px-5">
        Les meilleurs parieurs savent qu'anticiper, c'est prévoir. Et pour prévoir correctement, il faut se baser sur des faits concrets. Les statistiques te donnent cette vision d’ensemble qui te permet de parier avec plus de certitude. Ne laisse plus le hasard décider pour toi, fais parler les chiffres.
      </p>
    </div>

    <blog-see-more/>

    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Un chiffre peut tout changer</h3>
      <p class="px-5">Une bonne statistique peut bouleverser ton analyse. Elle peut te faire voir un pari sous un nouvel angle, te révéler un risque caché, ou te montrer une opportunité que tu n’avais pas vue. Avant de valider ton prochain pari, assure-toi d’avoir toutes les cartes en main.</p>
    </div>


    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">DoctoBET, ton guide statistique</h3>
      <p class="px-5">
        DoctoBET est là pour t’aider à ne rien laisser au hasard. Notre outil analyse pour toi les statistiques les plus pertinentes, te donnant les insights nécessaires pour éviter les pertes et maximiser tes gains. Ne prends plus de risques inutiles : avec DoctoBET, parie avec confiance et transforme chaque pari en une opportunité de gagner.
      </p>
    </div>

    <blog-avis-client/>

  </div>
</template>

<style scoped lang="less">

</style>
<script>
import LeagueButton from "@/components/common/LeagueButton.vue";
import {contextStore} from "@/stores/Context.store";
import {modalStore} from "@/stores/Modal.store";
import {statStore} from "@/stores/Statistic.store";
import {selectionStore} from "@/stores/Selection.store";

export default {
  name: "StatSelectorModal",
  components: {LeagueButton},

  data() {
    return {

      selectedCategory: 'stat',

      filter: '',
      targetUID: null,  // statFixedUID ou selectionUID
    }
  },

  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },

    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'statSelectorModal';
    },

    _payload() {
      return modalStore().getVisibleModal.payload
    },

    _payloadCategory() {
      if (!this._payload) return null
      return this._payload.category
    },

    _type() {
      if (!this._payload) return null
      return this._payload.type
    },

    _scope() {
      if (!this._payload) return null
      return this._payload.scope
    },

    _callback() {
      if (!this._payload) return null
      return this._payload.callback
    },

    _sport() {
      if (!this._payload) return 'FOOTBALL'
      return this._payload.sport
    },
    _selections() {
      let results = selectionStore().getUserSelections(['GAME', 'PLAYER']);
      if (!results) return []

      // filter
      if (this.filter.length > 0) {
        return results.filter(o => o.name.toLowerCase().includes(this.filter.toLowerCase()))
      }

      return results
    },
    _statisticDefinitions() {
      let sdef = statStore().getDefinitions(this._sport)

      // on supprime les sdef de type compétitions
      sdef = sdef.filter(o => o.scope === 'TEAM')

      if (this._type) {
        sdef = sdef.filter(o => o.type === this._type)
      }
      if (this._scope) {
        sdef = sdef.filter(o => o.scope === this._scope)
      }

      // filter
      if (this.filter.length > 0) {
        return sdef.filter(o => o.name.toLowerCase().includes(this.filter.toLowerCase()))
      }
      return sdef
    },
    _chooseStatsMessage() {
      switch (this.selectedCategory) {
        case 'stat':
          return this._chooseStatsMessageStat
        case 'selection':
          return this._chooseStatsMessageSelection
      }
    },
    _chooseStatsMessageStat() {
      if (!this._statisticDefinitions) return 'Aucune statistique disponible'
      const count = this._statisticDefinitions.length
      return 'Choisissez une des ' + count + ' stats disponibles'
    },
    _chooseStatsMessageSelection() {
      if (!this._selections) return 'Aucune sélection disponible'
      const count = this._selections.length
      return 'Choisissez une des ' + count + ' sélections disponibles'
    },

    // On regroupe les stats par type_score
    _optionsGrouped() {
      let grouped = {}

      switch (this.selectedCategory) {
        case 'stat':
          this._statisticDefinitions.forEach(sdef => {
            let key = sdef.type + '_' + sdef.scope
            if (!grouped[key]) grouped[key] = []
            grouped[key].push(sdef)
          })
          break
        case 'selection':
          this._selections.forEach(selection => {
            let key = selection.type + '_' + selection.scope
            if (!grouped[key]) grouped[key] = []
            grouped[key].push(selection)
          })
          break
      }

      // On trie les groupes par clé
      let ordered = {}
      Object.keys(grouped).sort().forEach(function (key) {
        ordered[key] = grouped[key];
      });

      return ordered
    },

    _categories() {
      const all = [
        {label: 'Statistiques', uid: 'stat'},
        {label: 'Sélections', uid: 'selection'},
      ]

      if(this._payloadCategory) {
        this.selectedCategory = this._payloadCategory
        return all.filter(c => c.uid === this._payloadCategory)
      }
      return all
    }
  },

  methods: {
    _onCancel() {
      modalStore().closeModal();
    },

    _onSelect(item) {
      if (this._callback)
        this._callback(this.selectedCategory, item)
      modalStore().closeModal();
    },

    _groupLegend(key) {

      switch (this.selectedCategory) {
        case 'stat':
          return this._groupLegendStat(key)
        case 'selection':
          return this._groupLegendSelection(key)
      }
    },
    _groupLegendStat(key) {

      let parts = key.split('_')

      let result = ''
      switch (parts[0]) {
        case 'FREQUENCY':
          result = "Fréquence d'un évènement"
          break
        case 'RAW':
          result = 'Donnée brute'
          break
        case 'SERIE':
          result = 'Série'
          break
      }

      switch (parts[1]) {
        case 'PLAYER':
          result += ' par joueur'
          break
        case 'TEAM':
          result += ' par équipe'
          break
        case 'LEAGUE':
          result += ' par ligue'
          break
      }

      return result
    },
    _groupLegendSelection(key) {

      let parts = key.split('_')

      let result = ''
      switch (parts[0]) {
        case 'GAME':
          result = "Rencontre"
          break
        case 'PLAYER':
          result = 'Joueur'
          break
      }
      return result
    },
    _isCategorySelected(category) {
      if (!this.selectedCategory) return false;
      return this.selectedCategory === category
    }
  },

  watch: {
    _isVisible() {
      if (this._isVisible) {
        this.targetUID = this._payload.statFixedUID
      } else {
        this.targetUID = null
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="480" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header pr-2">
      <a-input v-model:value="filter" placeholder="RECHERCHER" class="transparent black"/>
    </header>

    <div class="flex" style="overflow-x: scroll; scrollbar-width: none">
      <button :class="{'no-radius': true, 'tab selected': _isCategorySelected(c.uid)}"
              @click="selectedCategory=c.uid" v-for="c in _categories">
        {{ c.label }}
      </button>
    </div>
    <hr/>

    <div class="content stat-selector-content">
      <fieldset v-for="(group, key) in _optionsGrouped" class="mx-1 mb-2 mt-1">
        <legend>{{ _groupLegend(key) }}</legend>
        <button @click="_onSelect(option)" class="text-left no-radius" v-for="option in group" style="width: 100%">
          {{ option.name }}
        </button>
      </fieldset>
    </div>

    <template #footer>
      <div class="flex p-2">
        <a-alert class="flex-grow" :message="_chooseStatsMessage" type="info" show-icon/>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

.ant-modal {
  &.mobile {
    .content {
      max-height: calc(100dvh - 40px - 40px - 8px - 8px - 28px) !important;
    }
  }
}
</style>
import {axios} from '@bundled-es-modules/axios';

export default new class {
    create() {
        return axios.create({
            baseURL: import.meta.env.VITE_API_URL,
            timeout: 20000,
            withCredentials: false
        })
    }

    getInstance() {
        let _instance = window.axios
        if (!_instance) {
            _instance = this.create()
            window.axios = _instance
        }
        return _instance
    }

    get(url) {
        return this.getInstance().get(url, this.headers())
    }

    post(url, data) {
        return this.getInstance().post(url, data, this.headers())
    }

    put(url, data) {
        return this.getInstance().put(url, data, this.headers())
    }

    delete(url) {
        return this.getInstance().delete(url, this.headers())
    }

    headers() {

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }

        let token = localStorage.getItem('token');
        if (token) {
            headers.Authorization = "Bearer " + token
        }
        return {
            headers: headers
        }
    }
}
<script>
import axiosService from "@/services/axios.service";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameDate from "@/components/common/GameDate.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import GameEvent from "@/components/common/GameEvent.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import {gameStore} from "@/stores/Game.store";

export default {
  name: "PlayerPaneItem.TempsFort.Tab",
  components: {GameEvent, GameResultCircle, TeamName, TeamLogo, LeagueLogo, GameDate},

  props: {
    playerUid: {
      required: true
    },
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      data: null,

      playerChart: {
        building: true,
        key: 0,
        type: 'bar',
        width: 380,
        options: {
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
            border: {
              show: false,
            },
          },
          colors: ['#169272', '#516dd8'],
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            labels: {
              style: {
                colors: '#FFF' // Change this to your desired color
              }
            }
          },
          yaxis: {
            labels: {
              style: {
                colors: '#FFF' // Change this to your desired color
              }
            }
          }
        },
        series: [],
      },
      teamChart: {
        building: true,
        key: 0,
        type: 'bar',
        width: 380,
        options: {
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
            border: {
              show: false,
            },
          },
          colors: ['#169272', '#516dd8'],
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            labels: {
              style: {
                colors: '#FFF' // Change this to your desired color
              }
            }
          },
          yaxis: {
            labels: {
              style: {
                colors: '#FFF' // Change this to your desired color
              }
            }
          }
        },
        series: [],
      }
    }
  },
  beforeMount() {
    this._refreshData()
  },

  mounted(){
    window.emitter.on('player-pane:critere-league:change', (league) => {
      this._refreshData(league, null)
    })
    window.emitter.on('player-pane:critere-location:change', (location) => {
      this._refreshData(null, location)
    })
  },

  beforeDestroy() {
    window.emitter.off('player-pane:critere-league:change')
    window.emitter.off('player-pane:critere-location:change')
  },

  computed: {
    _game() {
      if(!this.gameUid) return null
      return gameStore().get(this.gameUid)
    }
  },

  methods: {
    _refreshData(pCritereLeague = null, pCritereLocation = null) {

      this.data = null // => loading
      this.playerChart.building = true
      this.teamChart.building = true

      let critereLocation = pCritereLocation ? pCritereLocation : preferencesStore().getFirstValue('SELECTION_PLAYER', 'CRITERE_LOCATION') ?? 'ANY'
      let critereLeague = pCritereLeague ? pCritereLeague : preferencesStore().getFirstValue('SELECTION_PLAYER', 'CRITERE_LEAGUE') ?? 'ANY';
      const payload = {
        gameUID: this.gameUid,
        playerUID: this.playerUid,
        leagueUID: critereLeague === 'ANY' ? null : this._game.leagueUID,
        location: critereLocation
      }
      axiosService.put('/dta/player/basic-data/temps-fort', payload).then(response => {
        this.data = response.data

        this._updatePlayerChart()
        this._updateTeamChart()
      })
    },
    _getMomentLabel(moment) {
      switch (moment) {
        case 'P1_1':
          return '0-15'
        case 'P1_2':
          return '16-30'
        case 'P1_3':
          return '31-45'
        case 'P1_OT':
          return '45+'
        case 'P2_1':
          return '45-60'
        case 'P2_2':
          return '61-75'
        case 'P2_3':
          return '75-90'
        case 'P2_OT':
          return '90+'
      }
    },
    _updatePlayerChart() {

      this.playerChart.building = true

      const serie = {
        name: "Buts",
        data: []
      }
      const moments = ['P1_1', 'P1_2', 'P1_3', 'P1_OT', 'P2_1', 'P2_2', 'P2_3', 'P2_OT']
      moments.forEach((moment) => {

        const mEvents = this.data.playerEventsMapCount[moment]
        const sumGoals = mEvents.goals

        serie.data.push({
          x: this._getMomentLabel(moment),
          y: sumGoals
        })
      })
      this.playerChart.series = [serie]
      this.playerChart.key++
      this.playerChart.building = false
    },
    _updateTeamChart() {

      const serie = {
        name: "Buts",
        data: []
      }
      const moments = ['P1_1', 'P1_2', 'P1_3', 'P1_OT', 'P2_1', 'P2_2', 'P2_3', 'P2_OT']
      moments.forEach((moment) => {

        const mEvents = this.data.againstEventsMapCount[moment]
        const sumGoals = mEvents.goals

        serie.data.push({
          x: this._getMomentLabel(moment),
          y: sumGoals
        })
      })
      this.teamChart.series = [serie]
      this.teamChart.key++
    }
  },
}
</script>

<template>
  <div>
    <fieldset class="no-border text-left mt-1">
      <legend class="ml-1">Buts du joueur / période</legend>
      <apexchart
          v-if="!playerChart.building"
          :key="playerChart.key"
          ref="player-events-charts"
          :width="playerChart.width"
          :type="playerChart.type"
          :options="playerChart.options"
          :series="playerChart.series"
          class="mt-2"/>
      <a-alert
          v-else
          type="info"
          show-icon
          message="Chargement des données en cours..."
          class="m-2"/>
    </fieldset>

    <fieldset class="no-border text-left mt-1">
      <legend class="ml-1">Buts encaissé par adversaire / période</legend>
      <apexchart
          v-if="!playerChart.building"
          :key="teamChart.key"
          ref="team-events-charts"
          :width="teamChart.width"
          :type="teamChart.type"
          :options="teamChart.options"
          :series="teamChart.series"
          class="mt-2"/>
      <a-alert
          v-else
          type="info"
          show-icon
          message="Chargement des données en cours..."
          class="m-2"/>
    </fieldset>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table.last-player-game-row {
  border-bottom: 1px solid @stroke-color;

  tr, td{
    border: none;
    padding: 0;
    background: transparent;
  }

  &.over{
    background-color: @hover-color;
  }

  .date {
    width: 64px;
  }

  .league {
    width: 32px;
  }

  .team-logo {
    width: 32px;
  }

  .team-name {
    text-align: left;
  }

  .events{
    width: 64px;
    text-align: right;
  }

  .score {
    width: 32px;
  }

  .result {
    width: 36px;
    padding: @padding-regular;
  }
}
</style>
<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import BlogAvisClient from "@/components/routes/blog/BlogAvisClient.vue";

export default {
  name: "ParierBienGagnerMieux",
  components: {BlogAvisClient, BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif';
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Parier c'est bien, gagner c'est mieux"
             class="w-full"/>
      </div>
    </div>

    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET s’adresse à tous ceux qui veulent passer au niveau supérieur dans leurs paris sportifs. Que tu sois un
        novice ou un pro, cet outil est là pour t’aider à parier avec plus de précision et de confiance.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>

    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Ne te contente plus de parier</h3>
      <p class="px-5">Placer un pari, c’est excitant. Mais ce qui l’est encore plus, c’est de voir ton pari se
        transformer en gain. Pour ça, il ne suffit pas de suivre ton instinct ou de choisir au hasard. Avec DoctoBET, tu
        peux passer à l’étape supérieure : parier avec méthode pour augmenter tes chances de succès.</p>
    </div>

    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Prends l’avantage avec les bonnes sélections</h3>
      <p class="px-5">Chaque match, chaque équipe, chaque joueur cache des informations précieuses. Le module Sélection
        de DoctoBET t’apporte ces données directement, t’offrant une vision claire des paris à fort potentiel. En un
        instant, tu sais où miser pour maximiser tes chances.</p>
    </div>


    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Fais le bon choix rapidement</h3>
      <p class="px-5">
        Pourquoi passer des heures à analyser chaque détail ? Le module Sélection de DoctoBET fait le job en quelques
        secondes. Tu gagnes du temps, tu réduis les risques de paris perdus, et surtout, tu te concentres sur
        l’essentiel : gagner.
      </p>
    </div>

    <blog-see-more/>

    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Parie malin, récolte les bénéfices</h3>
      <p class="px-5">Avec DoctoBET, parier devient moins compliqué. En t’appuyant sur des sélections fiables et
        rapides, tu mises avec confiance. Plus de doute, plus de stress : juste des paris bien pensés qui t’apportent
        plus souvent des gains que des pertes.</p>
    </div>


    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">La clé du succès : DoctoBET</h3>
      <p class="px-5">
        Parier, c’est bien. Mais gagner, c’est ce qui compte vraiment. Le module Sélection de DoctoBET t’aide à faire la
        différence. Accélère tes analyses, réduis tes risques, et augmente ton taux de réussite. Avec DoctoBET, parier
        devient plus qu’un jeu : c’est une stratégie gagnante.
      </p>
    </div>

    <blog-avis-client/>

  </div>
</template>

<style scoped lang="less">

</style>
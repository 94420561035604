<script>
import LeagueButton from "@/components/common/LeagueButton.vue";
import {contextStore} from "@/stores/Context.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "WidgetParameterSelectorModal",
  components: {LeagueButton},

  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },

    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'widgetParameterSelectorModal';
    },

    _payload() {
      return modalStore().getVisibleModal.payload
    },

    _callback() {
      if(!this._payload) return null
      return this._payload.callback
    },

    _parameter() {
      if(!this._payload) return null
      return this._payload.parameter
    },

    _parameterName() {
      if(!this._parameter) return ''
      return this._parameter.name
    },

    _options() {
      if(!this._parameter) return []
      const values = this._parameter.values
      if(!values) return []
      return values.map(v => {
        return {label: v.name, value: v.key}
      })
    },
  },

  methods: {
    _onCancel() {
      modalStore().closeModal();
    },

    _onSelect(value) {
      if(this._callback)
        this._callback(value)
      modalStore().closeModal();
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      {{ _parameterName }}
    </header>

    <div class="content p-1">
      <button @click="_onSelect(o.value)" class="" v-for="o in _options" style="width: 100%">
        {{o.label}}
      </button>
    </div>

    <template #footer>
      <div class="flex p-2">
        <div class="flex-grow"/>
        <button class="info" @click="_onCancel">
          Fermer
        </button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>
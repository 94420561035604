// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";

export const leagueStore = defineStore('leagueStore', {
    state: () => {
        return {
            items: null,
        }
    },
    actions: {
        // Permet de récupérer un match par son UID
        fetchAll(callback = null) {
            if (this.items !== null) {
                if (callback) callback()
                return
            }
            this.items = []
            axiosService.get('/dta/leagues').then(response => {
                this.items = this.items.concat(response.data.results)
            }).finally(() => {
                if (callback) callback()
            });
        }
    },
    getters: {

        get: (state) => (uid) => {

            if (!uid) return null

            // convert uid to number
            uid = parseInt(uid)

            if (state.items === null) {
                console.error('leagueStore.get', 'state.items === null')
                return null
            }

            let item = state.items.find(item => item.uid === uid);
            if (item) {
                return item
            }

            return null
        },

        getAll: (state) => {
            return state.items
        },

        getByLicenceType: (state) => (licenceType) => {
            if (state.items === null) {
                console.error('leagueStore.get', 'state.items === null')
                return null
            }

            const acceptedValues = ['NONE']
            if (licenceType !== 'NONE') {
                acceptedValues.push('BASIC')
            }
            if (licenceType === 'FULL') {
                acceptedValues.push('FULL')
            }

            return state.items.filter(item => acceptedValues.indexOf(item.licenceRequired) !== -1)

        },

        getBySportAndLicenceType: (state) => (sport, licenceType) => {
            if (state.items === null) {
                console.error('leagueStore.get', 'state.items === null')
                return null
            }

            const acceptedValues = ['NONE']
            if (licenceType !== 'NONE') {
                acceptedValues.push('BASIC')
            }
            if (licenceType === 'FULL') {
                acceptedValues.push('FULL')
            }

            return state.items.filter(item => item.sport === sport && acceptedValues.indexOf(item.licenceRequired) !== -1)

        }
    }
})
<script>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {colorStore} from "@/stores/Color.store";
import {imageStore} from "@/stores/Image.store";
import {playerStore} from "@/stores/Player.store";

export default {
  name: "PlayerPicture",
  components: {FontAwesomeIcon},
  props: {
    uid: {
      required: true
    },
    size: {
      type: String,
      default: "small"
    }
  },

  beforeMount() {
    if(!this._player) return null
    if(!this._player.imageUID) return null
    imageStore().get(this._player.imageUID)
  },

  computed: {
    _player() {
      return playerStore().get(this.uid);
    },
    _playerName() {
      return this._player?.name ?? "_";
    },
    _buildCssClasses() {
      return {
        'player-picture-logo': true
      }
    },
    _canDisplayLogo() {
      return true
    },
    _altColor() {

      if (!this.uid) {
        return 'grey';
      }

      const cs = colorStore();
      return cs.get(this._playerName);
    },
    _buildCssStyle() {
      return {
        color: this._altColor,
        maxWidth: this.size ? this.size : '32px',
        maxHeight: this.size ? this.size : '32px',
        fontSize: this.size ? this.size : '32px',
      }
    },
    _buildIcons() {
      return ['fa-solid', 'fa-user'];
    },
    _base64() {
      if(!this._player) return null
      if(!this._player.imageUID) return null
      const image = imageStore().get(this._player.imageUID)
      if(!image) return null
      return 'data:image/png;base64,' + image.data
    }

  },
}
</script>

<template>
  <span>
    <font-awesome-icon :icon="_buildIcons" :class="_buildCssClasses" v-if="!_canDisplayLogo || !_base64" :style="_buildCssStyle"/>
    <img :class="_buildCssClasses" :src="_base64" v-else-if="_base64" alt="" :style="_buildCssStyle"/>
  </span>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.player-picture-logo {

  display: inline-block;
}
</style>
<script>
import axiosService from "@/services/axios.service";
import {contextStore} from "@/stores/Context.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import {perspectiveStore} from "@/stores/Perspective.store";
import {gameStore} from "@/stores/Game.store";
import {teamStore} from "@/stores/Team.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";

export default {
  name: "TeamsPerformanceChartWidget",
  components: {
    WidgetLoadingContent,
    WidgetLeagueParamButton,
    TeamLogo
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: {},
      chart: {
        key: 0,
        type: 'area',
        width: 470,
        options: {
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
            border: {
              show: false,
            },
          },
          colors: ['#169272', '#516dd8'],
          dataLabels: {
            enabled: false,
          },
        },
        series: [],
      }
    }
  },
  mounted() {
    this._onGameUIDChange()
    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })

    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!contextStore().gameUID) return;

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) return;

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {
      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        this._updateChart()
        return;
      }

      const game = gameStore().get(contextStore().gameUID)
      const payload = {
        teamUIDs: [gameStore().getHomeTeamUID(game), gameStore().getAwayTeamUID(game)],
        leagueUID: perspectiveStore().getParameterLeagueValue(this.item.uid),
        sport: perspectiveStore().getCurrentSport()
      }
      axiosService.put('/wid/teams-performance-cumulative-chart', payload).then(response => {

        // On ajoute les matchs dans le store et on met à jour le widget
        Object.keys(response.data.results).forEach(teamUID => {
          gameStore().addGames(response.data.results[teamUID], () => {
            this.data = response.data
            wds.put(this.item.uid, contextStore().gameUID, this.data)
            this._updateChart()
          })
        })

      })
    },

    /**
     * Update chart data
     * @private
     */
    _updateChart() {
      this.chart.series = []
      const ts = teamStore()
      const gs = gameStore()
      let teamUIDs = Object.keys(this.data.results);
      teamUIDs.forEach((teamUID) => {
        const team = ts.get(teamUID)
        const teamSerie = {
          name: team ? team.name : teamUID.toString(),
          data: []
        }
        let currentDataValue = 0
        let teamGames = this.data.results[teamUID].sort((b, a) => {
          if (a.date === b.date) return 0
          if (a.date < b.date) return 1
          if (a.date > b.date) return -1
        });
        teamGames.forEach(game => {
          let location = gs.getTeamLocation(game.uid, teamUID)
          let gameResultForTeam = gs.getResultFor(game, location)

          if (null !== gameResultForTeam) {
            if (gameResultForTeam === 0) {
              currentDataValue += -0.25
              teamSerie.data.push(currentDataValue.toFixed(1))
            } else if (gameResultForTeam > 0) {
              currentDataValue += 1
              teamSerie.data.push(currentDataValue.toFixed(1))
            } else if (gameResultForTeam < 0) {
              currentDataValue -= 0.5
              teamSerie.data.push(currentDataValue.toFixed(1))
            }
          }
        })

        this.chart.series.push(teamSerie)
      })

      this.chart.key++
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Performance cumulative des équipes
      <div class="widget-header-params flex">
        <widget-league-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content flex" v-else>
      <apexchart
          :key="chart.key"
          ref="teams-perfomance-chart-widget"
          :width="chart.width"
          :type="chart.type"
          :options="chart.options"
          :series="chart.series"
          class="mt-2"/>
    </div>
  </div>
</template>

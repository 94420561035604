<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import BlogAvisClient from "@/components/routes/blog/BlogAvisClient.vue";

export default {
  name: "InstinctPlusStatsRecetteGagner",
  components: {BlogAvisClient, BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif';
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Ton instinct + les stats = la recette pour gagner"
             class="w-full"/>
      </div>
    </div>

    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET s’adresse à tous ceux qui veulent passer au niveau supérieur dans leurs paris sportifs. Que tu sois un
        novice ou un pro, cet outil est là pour t’aider à parier avec plus de précision et de confiance.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>

    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Fais confiance à ton instinct</h3>
      <p class="px-5">Tu sais quand un pari "sent bon". Cet instinct de parieur, c'est ce qui te rend unique. Mais
        parfois, un petit coup de pouce supplémentaire peut transformer cette intuition en victoire. DoctoBET est là
        pour t’apporter ce petit plus qui fait toute la différence.</p>
    </div>

    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Les chiffres ne mentent pas</h3>
      <p class="px-5">Ton instinct est puissant, mais les stats le sont encore plus. Avec DoctoBET, tu accèdes à des
        données précises et actualisées qui te permettent de valider ton ressenti. Chaque pari devient plus qu'un simple
        jeu de hasard, c’est une stratégie calculée.</p>
    </div>

    <blog-see-more/>

    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Gagne du temps, réduis les risques</h3>
      <p class="px-5">
        Les paris, c’est aussi une question de timing. Pas besoin de passer des heures à analyser chaque match. DoctoBET
        fait le travail pour toi, te donnant les informations essentielles en un clin d'œil. Moins de temps passé à
        analyser, c’est plus de temps pour parier avec confiance.
      </p>
    </div>


    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Mélange de fun et de succès</h3>
      <p class="px-5">Parier doit rester un plaisir. Avec DoctoBET, tu peux t’amuser tout en augmentant tes chances de
        succès. Laisse les stats te guider sans perdre le côté fun du pari. Plus de plaisir, moins de stress.</p>
    </div>


    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">La clé de la réussite : DoctoBET</h3>
      <p class="px-5">
        Ta meilleure arme pour parier avec succès, c'est ton instinct combiné à nos stats. DoctoBET t’aide à faire les
        bons choix, rapidement et efficacement. Moins de risques, plus de gains. Laisse DoctoBET transformer ton
        instinct en victoires régulières.
      </p>
    </div>

    <blog-avis-client/>

  </div>
</template>

<style scoped lang="less">

</style>
<script>

import {widgetDefinitionsStore} from "@/stores/WidgetDefinitions.store";
import {colorStore} from "@/stores/Color.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "WidgetStoreModal",
  components: {

  },
  data() {
    return {
      filter: '',
      selectedType: null
    }
  },
  computed: {
    _widgetDefinitionsStore() {
      return widgetDefinitionsStore();
    },
    _perspective() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return null;
      return visibleModal.payload.perspective
    },
    _widgets() {
      if (!this._perspective) return []
      const sportWidgets = this._widgetDefinitionsStore.getBySportAndPerspectiveType(this._perspective.sport, this._perspective.type)

      // apply filter
      if (this.filter !== '') {
        return sportWidgets.filter(w => {
          return w.name.toLowerCase().includes(this.filter.toLowerCase()) || w.description.toLowerCase().includes(this.filter.toLowerCase())
        })
      }
      return sportWidgets
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'widgetStoreModal';
    },
  },
  methods: {
    _selectType(widget) {
      this.selectedType = widget.type;
    },
    _onCancel() {
      modalStore().closeModal();
    },
    _style(widget) {
      let bgColor = colorStore().get(JSON.stringify(widget));
      return {
        backgroundColor: bgColor,
        color: colorStore().getContrastColor(bgColor)
      }
    },
    _firstLetter(name) {
      if (!name) return '';
      return name.charAt(0).toUpperCase();
    },
    _addWidget() {
      perspectiveStore().addWidget(this._perspective.uid, this.selectedType, () => {
        modalStore().closeModal();
      })
    }
  },
  watch: {
    _isVisible: function (visible) {
      if (visible) {
        setTimeout(() => {
          let elementById = document.getElementById('search-widget-input');
          if (!elementById) return;
          elementById.focus()
        }, 100)
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel">
    <header class="ant-modal-header">
      <a-input id="search-widget-input" v-model:value="filter" placeholder="Magasin de widgets" class="transparent black"/>
    </header>
    <div id="widget-store-content">
      <div :class="{'widget-item': true, 'selected': selectedType === w.type}" @click="_selectType(w)"
           v-for="w in _widgets">
        <div class="widget-icon" :style="_style(w)">
          {{ _firstLetter(w.name) }}
        </div>
        <div class="widget-texts">
          <label>{{ w.name }}</label>
          <p>{{ w.description }}</p>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="mr-2 primary" @click="_addWidget" v-if="selectedType">Ajouter le widget</button>
        <button class="" @click="_onCancel">Annuler</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#search-widget-input {
  border: none !important;
  height: @toolbar-regular;
  padding: @padding-regular !important;
  flex-grow: 1;
  font-size: @font-size-regular !important;

  &:focus {
    outline: none;
  }
}

#widget-store-content {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;


  .widget-item {
    border-radius: @padding-small;
    padding: @padding-regular;
    margin: @padding-regular;
    color: @text-color;
    cursor: pointer;
    text-align: left;
    display: flex;

    .widget-icon {
      width: 32px;
      height: 32px;
      border-radius: @radius-small;
      background-color: @primary-color;
      display: inline-block;
      margin-right: 10px;
      text-align: center;
      line-height: 32px;
      font-size: 16px;
      font-weight: bold;
    }

    .widget-texts {

      flex-grow: 1;

      p {
        font-size: 12px !important;
        color: @text-color;
        margin: 0;
      }
    }

    &:hover {
      background-color: @hover-color;
    }

    &.selected {
      background: @selected-color !important;
    }
  }

}
</style>
<script>
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameDate from "@/components/common/GameDate.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import {playerStore} from "@/stores/Player.store";
import GameEvent from "@/components/common/GameEvent.vue";

export default {
  name: "PlayerPaneItem.Events.Tab.Row",
  components: {GameEvent, GameResultCircle, TeamName, TeamLogo, LeagueLogo, GameDate},

  props: {
    event: {
      required: true
    }
  },

  computed: {
    _sport() {
      if (!this._game) return null
      return this._game.sport
    },
    _gameUID() {
      return this.event.gameUID
    },
    _game() {
      return gameStore().get(this._gameUID)
    },
    _player() {
      return playerStore().get(this.event.playerUID)
    },
  }
}
</script>

<template>
  <table :class="{'last-player-event-row': true}">
    <tr>
      <td>
        <game-event :item="event" class="m-1" show-label="true" show-time="true"/>
      </td>
    </tr>
  </table>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table.last-player-event-row {
  border-bottom: 1px solid @stroke-color;

  tr, td{
    border: none;
    padding: 0;
    background: transparent;
  }

  &.over{
    background-color: @hover-color;
  }
}
</style>
<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import BlogAvisClient from "@/components/routes/blog/BlogAvisClient.vue";

export default {
  name: "ChoisisOnCalcule",
  components: {BlogAvisClient, BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif';
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Tu choisis, on calcule"
             class="w-full"/>
      </div>
    </div>

    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET s’adresse à tous ceux qui veulent passer au niveau supérieur dans leurs paris sportifs. Que tu sois un
        novice ou un pro, cet outil est là pour t’aider à parier avec plus de précision et de confiance.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>

    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Ton instinct, notre expertise</h3>
      <p class="px-5">Tu connais le terrain, tu as l’œil pour repérer les bons coups. Mais parfois, il manque juste ce
        petit plus pour faire pencher la balance. Avec DoctoBET, tu restes le maître de tes choix, mais nous t’apportons
        l’expertise statistique pour affiner tes paris. Ensemble, on maximise tes chances.</p>
    </div>

    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Des sélections rapides et précises</h3>
      <p class="px-5">Pas besoin de passer des heures à éplucher les statistiques. Le module Sélection de DoctoBET fait
        le travail pour toi. En une seconde, il identifie les rencontres à fort potentiel, celles qui correspondent
        parfaitement à tes critères. Plus de temps perdu, juste des choix rapides et efficaces.</p>
    </div>


    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Mise sur les bons matchs</h3>
      <p class="px-5">
        Chaque jour, des dizaines de matchs se jouent. Le défi ? Savoir où parier pour maximiser tes gains. DoctoBET
        scanne les données pour te présenter les meilleures opportunités. Tu choisis ce qui t’intéresse, et nous faisons
        le reste. Tu n’as plus qu’à parier en toute confiance.
      </p>
    </div>

    <blog-see-more/>

    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Plus de paris gagnants, moins de risques</h3>
      <p class="px-5">Parier avec DoctoBET, c’est jouer avec l’assurance d’avoir les meilleures informations à portée de
        main. Le module Sélection réduit les risques en te proposant les rencontres les plus prometteuses. Tu limites
        les paris perdants et tu augmentes ton taux de réussite, tout en gardant le contrôle.</p>
    </div>


    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">DoctoBET : ton allié pour gagner</h3>
      <p class="px-5">
        Tu restes aux commandes, mais avec DoctoBET, tu as un coéquipier de choix. Tu définis tes critères, nous
        calculons les meilleures options. Ensemble, on fait de chaque pari une opportunité de gagner. Parie avec
        DoctoBET et transforme ton instinct en succès.
      </p>
    </div>
    <blog-avis-client/>


  </div>
</template>

<style scoped lang="less">

</style>
<script>

import {statStore} from "@/stores/Statistic.store";
import {modalStore} from "../../stores/Modal.store";

export default {
  nam: "StatisticValueButton",
  props: {
    stat: {
      type: Object,
      required: false
    },
    uid: {
      required: false
    },
    period: {
      required: false
    },
    value: {
      required: false
    },
    type: {
      type: String,
      default: ''
    },
    plain: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    _statStore() {
      return statStore();
    },
    _stat() {
      if (this.stat) return this.stat
      if (!this.uid) return null
      return this._statStore.get(this.uid)
    },
    _statLabel() {
      if (!this._stat) return null
      return this._statStore.getLabel(this._stat, this.period)
    },
    _statValue() {
      if (this.value) {
        return this._statStore.formatValue(this.value)
      }
      if (!this._stat) return '...'
      return this._statStore.getValue(this._stat, this.period)
    },
    _buildCssClasses() {
      return {
        'small': true,
        'positive': this.type === 'positive',
        'negative': this.type === 'negative',
        'accent': this.type === 'accent',
        'plain': this.plain
      }
    },
  },
  methods: {
    _openStatModal() {
      if (!this._stat) return
      modalStore().openModal({
        uid: 'statisticModal', payload: {
          'uid': this._stat.uid,
          'period': this.period
        }
      })
    }
  }
}
</script>

<template>
  <a-tooltip :title="_statLabel" v-if="_statLabel">
    <button :class="_buildCssClasses" @click="_openStatModal()">{{ _statValue }}</button>
  </a-tooltip>
  <button v-else :class="_buildCssClasses" @click="_openStatModal()">{{ _statValue }}</button>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.positive {
  background: @positive-color;
  background: linear-gradient(90deg, @positive-color 0%, fade(@positive-color, 50%) 70%, rgba(0,0,0,0) 99%) !important;
  color: white !important;
}

.negative {
  background: @negative-color;
  background: linear-gradient(270deg, @negative-color 0%, fade(@negative-color, 50%) 70%, rgba(0, 0, 0, 0) 99%) !important;
  color: white !important;
}
</style>

<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import BlogAvisClient from "@/components/routes/blog/BlogAvisClient.vue";
import BlogVideo from "@/components/routes/blog/BlogVideo.vue";

export default {
  name: "IdentifierBetesNoires",
  components: {BlogVideo, BlogAvisClient, BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif';
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Identifier les bêtes noires"
             class="w-full"/>
      </div>
    </div>

    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        Avec DoctoBET, tu passes à un niveau d’analyse supérieur, que tu ne retrouveras nulle part ailleurs.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>

    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Repère les dominations cachées</h3>
      <p class="px-5">Avec DoctoBET, tu peux facilement identifier les équipes qui dominent systématiquement leurs
        adversaires. Grâce au module de sélection, tu crées des critères spécifiques comme une équipe gagnant 100 % de
        ses matchs contre un adversaire sur les 10 dernières rencontres. Cette précision est quasi impossible à obtenir
        avec d'autres outils.</p>
    </div>

    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Personnalise ta définition de "bête noire"</h3>
      <p class="px-5">Chaque parieur a sa propre définition de ce qu’est une "bête noire". Que tu cherches une équipe
        qui gagne toujours, ou simplement une qui ne perd jamais contre un adversaire, DoctoBET te permet de
        personnaliser tes critères. Contrairement aux autres plateformes, tu n’es pas contraint par des définitions
        pré-établies. C’est toi qui contrôles l’analyse.</p>
    </div>

    <blog-video :article="article"/>

    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Analyse match par match</h3>
      <p class="px-5">
        DoctoBET te donne la possibilité de filtrer les performances des équipes à domicile ou à l’extérieur, et de voir
        si une équipe domine son adversaire dans une configuration spécifique. Cette approche te permet d’affiner tes
        pronostics en tenant compte des nuances qui peuvent échapper à d'autres outils de statistiques.
      </p>
    </div>

    <blog-see-more/>

    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Combine les critères pour des résultats ultra précis</h3>
      <p class="px-5">Pourquoi se limiter à une simple domination ? DoctoBET te permet de combiner plusieurs critères.
        Par exemple, tu peux chercher une équipe qui est une bête noire à domicile, ou bien à l'extérieur, en fonction
        du contexte. Cette souplesse d’analyse fait de DoctoBET un outil incontournable pour ceux qui veulent aller plus
        loin dans leurs paris.</p>
    </div>


    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Ce que les autres ne te permettent pas</h3>
      <p class="px-5">
        Les autres outils te forcent à utiliser des filtres prédéfinis, mais avec DoctoBET, tu as une liberté totale. Si tu veux identifier des équipes qui ne perdent jamais ou qui ne concèdent que très peu de buts dans certaines conditions, tu peux le faire. DoctoBET ne te limite pas, il t’accompagne pour maximiser tes chances de succès.
      </p>
    </div>
    <blog-avis-client/>


  </div>
</template>

<style scoped lang="less">

</style>
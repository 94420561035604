<script>

import MonitoringTicketTemplateBet from "@/components/common/MonitoringTicketTemplateBet.vue";

export default {
  name: "MonitoringTicketTemplate",
  components: {MonitoringTicketTemplateBet},
  props: {
    sport: {
      required: true
    },
    item: {
      required: true
    },
  }
}
</script>

<template>
  <div :class="{'monitoring-ticket-template': true}">
    <fieldset class="p-0 no-border">
      <legend>Modèle de ticket</legend>
      <div>
        <monitoring-ticket-template-bet :sport="sport" :item="b" v-for="b in item.bets" :key="b.uid"/>
        <a-alert v-if="item.bets.length === 0"
                 message="Tu dois choisir le type de pari qui sera utilisé par ce monitoring." type="warning" show-icon class="m-2 mt-0"/>
      </div>
    </fieldset>

  </div>
</template>
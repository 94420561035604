<script>

import {modalStore} from "@/stores/Modal.store";

export default {
  name: "LeagueModal",
  data() {
    return {}
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'leagueModal';
    },
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="400" @cancel="_onCancel">
    <header class="ant-modal-header">
      League
    </header>


    <template #footer>
      <div class="my-2 mx-1">
        <button class="" @click="_onCancel">Fermer</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>
<script>

export default {
  name: "Hour",
  props: {
    value: {
      required: true
    },
    size: {
      type: String,
      default: 'medium' // small, medium accepeted
    }
  },

  computed: {
    _buildCssClasses() {
      return {
        'hour': true,
        'small': this.size === 'small'
      }
    },
    _formattedValue() {
      if(!this.value) return '..:..'

      // get hh:mm from date
      const date = new Date(this.value)
      const hours = date.getHours()
      const minutes = date.getMinutes()
      // left pad with 0
      const hoursStr = hours.toString().padStart(2, '0')
      const minutesStr = minutes.toString().padStart(2, '0')
      return `${hoursStr}:${minutesStr}`
    }
  },
}
</script>

<template>
  <span :class="_buildCssClasses">{{ _formattedValue }}</span>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.hour {
  min-width: 16px;
  min-height: 16px;
  border-radius: @radius-small;
  line-height: 24px;
  padding: 0 @padding-small;
  color: @text-color;
  font-size: 9pt;
  font-family: 'SourceCodePro', serif !important;

  &.small {
    font-size: 8pt;
    line-height: 16px;
  }
}
</style>
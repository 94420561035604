<script>
import {leagueStore} from "@/stores/League.store";
import AreaFlag from "@/components/common/AreaFlag.vue";
import LeagueButton from "@/components/common/LeagueButton.vue";
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "SelectionLeagueModal",
  components: {LeagueButton, AreaFlag},
  data() {
    return {
      // Filtrage des compétitions
      filterTimeout: null,
      filter: '',
      filteredLeagues: [],

      // Gestions de la selection
      selectedLeagues: []
    }
  },
  mounted() {
    this.filteredLeagues = this._sportLeagues;
  },
  computed: {
    _leagueStore() {
      return leagueStore();
    },
    _sportLeagues() {
      let all = this._leagueStore.getAll;
      if (!all) return [];
      return all.filter(l => l.sport === this._sport);
    },
    _leaguesGrouped() {
      let leagues = this.filteredLeagues;

      // filter by name
      if (this.filter && this.filter.length > 2) {
        leagues = leagues.filter(l => l.name.toLowerCase().includes(this.filter.toLowerCase()));
      }

      // group by area
      let grouped = {};
      for (let i = 0; i < leagues.length; i++) {
        let league = leagues[i];
        if (!grouped[league.area]) {
          grouped[league.area] = [];
        }
        grouped[league.area].push(league);
      }
      return grouped;
    },
    _modal() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return null;
      if (visibleModal.uid !== 'selectionLeagueModal') return null
      return visibleModal;
    },
    _isVisible() {
      if (!this._modal) return false;
      return this._modal.uid === 'selectionLeagueModal';
    },
    _sport() {
      // On utilise le sport passé en parametre à la modal
      if (!this._modal || !this._modal.payload || !this._modal.payload.sport) return 'FOOTBALL';
      return modalStore().getVisibleModal.payload.sport;
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    }
  },
  methods: {
    _onSelectLeague(league) {
      if (this._isSelected(league.uid)) {
        this.selectedLeagues = this.selectedLeagues.filter(l => l.uid !== league.uid);
      } else {
        this.selectedLeagues.push(league);
      }
    },
    _isSelected(uid) {
      if (this.selectedLeagues.find(l => l.uid === uid)) {
        return true;
      } else {
        return false;
      }
    },
    _onCancel() {
      modalStore().closeModal()
    },
    _onConfirm() {
      if (this._modal.payload && this._modal.payload.onConfirm) {
        this._modal.payload.onConfirm(this.selectedLeagues);
      }
      modalStore().closeModal()
    },
  },

  watch: {
    filter: function (val) {
      // on filtre les ligues au bout de 300ms
      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
        this.filteredLeagues = this._sportLeagues.filter(l => l.name.toLowerCase().includes(val.toLowerCase()));
      }, 300);
    },
    _isVisible: function (val) {
      if (val) {
        // On recalcule les sportLeagues
        this.filteredLeagues = this._sportLeagues;
        // On alimente les selectedLeagues sur la bases selectedUIDs
        if (this._modal.payload && this._modal.payload.selectedUIDs) {
          this.selectedLeagues = this._sportLeagues.filter(l => this._modal.payload.selectedUIDs.includes(l.uid));
        } else {
          this.selectedLeagues = [];
        }
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header pl-2">
      Choix des compétitions - {{ _sport }}
    </header>

    <div class="content">

      <div class="flex sb">
        <legend class="p-2">Compétitions ({{ filteredLeagues.length }})</legend>
        <input v-model="filter" placeholder="Filtrer par nom" class="mr-2 my-1 ">
      </div>
      <div class="content">
        <div id="selection-league-modal-all" class="pb-2">

          <fieldset class="m-2 no-side-border" v-for="area in Object.keys(_leaguesGrouped)">
            <legend>{{ area }}</legend>
            <league-button class="mr-1" v-for="l in _leaguesGrouped[area]" :key="l.id"
                           :league="l"
                           :selected="_isSelected(l.uid)"
                           @click="_onSelectLeague"/>
          </fieldset>
        </div>
        <div id="selection-league-modal-selected" class="st">
          <fieldset class="m-2 no-border">
            <legend>Selectionnées ({{ selectedLeagues.length }})</legend>

            <league-button class="mr-1" v-for="l in selectedLeagues" :key="l.id"
                           :league="l"
                           :selected="_isSelected(l.uid)"
                           :on-click="_onSelectLeague"/>
          </fieldset>
        </div>
      </div>
    </div>


    <template #footer>
      <div class="flex m-1">

        <div class="flex-grow"></div>
        <button class="m-1 info" @click="_onCancel" v-if="this._onCancel">Annuler</button>
        <button class="primary m-1" @click="_onConfirm">
          Valider
        </button>
      </div>
    </template>
  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#selection-league-modal-content {
  overflow: hidden;

  #selection-league-modal-selected {
    background-color: @background-color;
  }

  #selection-league-modal-all {
    background-color: @background-color-light;
  }

  fieldset {
    background-color: @background-color;
  }
}
</style>
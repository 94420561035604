<script>

export default {
  name: "ArjelLogo",

  props:{
    height: {
      type: Number,
      default: 8
    }
  },
  computed: {
    _src() {
      return '/images/arjel.png'
    },
    _height(){
      return this.height + 'px'
    }
  }
}
</script>

<template>
  <img :src="_src" alt="Arjel Logo" :style="{height: _height}">
</template>

<style scoped lang="less">

</style>
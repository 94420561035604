<script>

import {blogRouteStore} from "@/components/routes/blog/BlogRoute.store";

export default {
  name: "BlogAvisClient",

  computed: {
    _randomAvis() {
      return blogRouteStore().getAvis()
    },
    _firstLetter() {
      return this._randomAvis.name.charAt(0).toUpperCase()
    },
  }
}
</script>

<template>
  <div class="blog-avis-client lg:w-1/3 md:w-2/3 m-auto">
    <div class="dots">
      <div class="dot primary"></div>
      <div class="dot secondary"></div>
      <div class="dot tertiary"></div>
      <div class="dot quaternary"></div>
    </div>
    <h2>Ce qu'en disent mes clients</h2>
    <div class="avis">
      <div class="flex align-middle">
        <div class="avatar">{{ _firstLetter }}</div>
        <div class="name">{{ _randomAvis.name }}</div>
      </div>
      <p class="message">{{ _randomAvis.message }}</p>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

@name-height: 36px;
.blog-avis-client {
  margin-top: 64px;

  .avis {
    background: #f4f4f4;
    border: 1px solid #eee;
    border-radius: @radius-regular;
    margin: @padding-large;
    padding: @padding-large @padding-regular;

    .name {
      font-weight: bold;
      font-size: 14pt;
      line-height: @name-height;
    }

    .message {
      font-style: italic;
      margin: 0 !important;
      padding-left: @name-height + @padding-regular;
    }

    .avatar {
      font-weight: bold;
      background: @primary-color;
      border-radius: 50%;
      width: @name-height;
      height: @name-height;
      display: inline-block;
      line-height: @name-height;
      margin-right: @padding-regular;
      text-align: center;
    }
  }
}
</style>
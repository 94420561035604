<script setup>
</script>

<script>

export default {
  name: "PlayerBadSportPane",
  components: {},

  methods: {}
}
</script>

<template>

  <div id="player-bad-sport-pane">
    <p>Le module "Joueurs" n'est disponible que pour le football.</p>
  </div>

</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#player-bad-sport-pane {
  margin: @padding-small;
  border-radius: @radius-regular;
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  height: calc(100dvh - @toolbar-regular);
  width: 100% !important;

  p {
    padding-top: 200px;
    margin: 0 auto 64px auto;
    max-width: 400px;
    text-align: center;
    font-size: 2em !important;
    color: white;
  }
}

</style>
<script>
import {statStore} from "@/stores/Statistic.store";
import Statistic from "@/components/common/Statistic.vue";
import GameDate from "@/components/common/GameDate.vue";
import GameHour from "@/components/common/GameHour.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import GameScore from "@/components/common/GameScore.vue";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import TeamName from "@/components/common/TeamName.vue";
import {gameStore} from "@/stores/Game.store";
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "StatisticModal",
  components: {TeamName, GameResultCircle, GameScore, LeagueLogo, TeamLogo, GameHour, GameDate, Statistic},
  data() {
    return {
      period: 'ALL_TIME',
    }
  },

  computed: {
    _statStore() {
      return statStore();
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'statisticModal';
    },
    _payload() {
      if (!this._isVisible) return null;
      const payload = modalStore().getVisibleModal.payload
      if (!payload) return null;
      return payload;
    },
    _statUID() {
      if (!this._payload) return null;
      return this._payload.uid
    },
    _games() {
      if (!this._stat) return [];
      return statStore().getDataUIDs(this._stat, this.period)
    },
    _stat() {
      if (!this._statUID) return null;
      return this._statStore.get(this._statUID)
    },
    _gameStore() {
      return gameStore()
    },
    _isNotSerie() {
      if (!this._stat) return false;
      return this._stat.type !== 'SERIE'
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
  },
  watch: {
    _statUID() {
      if (!this._payload) return null;
      this.period = this._payload.period

      // On va chercher la stat
      statStore().get(this._statUID)
    },

  },
}
</script>

<template>
  <AModal v-if="_isVisible && _stat" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <div class="loading-div" v-if="!_stat"></div>
    <header class="ant-modal-header">
      Statistique
    </header>

    <div class="content">
      <statistic :uid="_statUID" :period="period"/>
      <hr/>
      <table style="width: 100%" class="m-1">
        <tr v-for="g in _games" class="p-2">
          <td>
            <game-date :uid="g" style="min-width: 44px"/>
          </td>
          <td class=" text-right">
            <team-name :uid="_gameStore.getHomeTeamUID(_gameStore.get(g))"
                       :highlight="_stat.whoUID === _gameStore.getHomeTeamUID(_gameStore.get(g))"/>

          </td>
          <td class="text-center">
            <team-logo :uid="_gameStore.getHomeTeamUID(_gameStore.get(g))"/>
          </td>
          <td class="text-center">
            <game-score :uid="g"/>
          </td>
          <td class="text-center">
            <team-logo :uid="_gameStore.getAwayTeamUID(_gameStore.get(g))"/>
          </td>
          <td>
            <team-name :uid="_gameStore.getAwayTeamUID(_gameStore.get(g))"
                       :highlight="_stat.whoUID === _gameStore.getAwayTeamUID(_gameStore.get(g))"
                       class="flex-grow text-left"/>
          </td>
          <td>
            <game-result-circle :team-uid="_stat.whoUID" :game-uid="g"/>
          </td>
        </tr>
      </table>
    </div>

    <template #footer>
      <div class="flex  m-2">

        <div class="flex-grow text-left">
          <button class="no-radius tab" :class="{'selected' : period === 'ALL_TIME'}"
                  :disabled="_statStore.getDataCount(_stat, 'ALL_TIME') === '-'"
                  @click="period='ALL_TIME'">
            Toujours ({{ _statStore.getDataCount(_stat, 'ALL_TIME') }})
          </button>
          <button class="no-radius tab" :class="{'selected' : period === 'CURRENT_SEASON'}"
                  :disabled="_statStore.getDataCount(_stat, 'CURRENT_SEASON') === '-'"
                  @click="period='CURRENT_SEASON'">
            Saison ({{ _statStore.getDataCount(_stat, 'CURRENT_SEASON') }})
          </button>
          <button class="no-radius tab" :class="{'selected' : period === 'LAST_10_GAMES'}" v-if="_isNotSerie"
                  :disabled="_statStore.getDataCount(_stat, 'LAST_10_GAMES') === '-'"
                  @click="period='LAST_10_GAMES'">
            10 derniers
          </button>
          <button class="no-radius tab" :class="{'selected' : period === 'LAST_5_GAMES'}" v-if="_isNotSerie"
                  :disabled="_statStore.getDataCount(_stat, 'LAST_5_GAMES') === '-'"
                  @click="period='LAST_5_GAMES'">
            5 derniers
          </button>
        </div>
      </div>

    </template>

  </AModal>
</template>

<style scoped lang="less">
</style>
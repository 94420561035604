<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";

export default {
  name: "AnalyseResultatElimineEchecs",
  components: {BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif';
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Analyse tes résultats pour éliminer les échecs"
             class="w-full"/>
      </div>
    </div>

    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET s’adresse à tous ceux qui veulent passer au niveau supérieur dans leurs paris sportifs. Que tu sois un
        novice ou un pro, cet outil est là pour t’aider à parier avec plus de précision et de confiance.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>

    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Décortique tes paris, perfectionne ta stratégie</h3>
      <p class="px-5">En tant que parieur professionnel, tu sais que chaque détail compte. DoctoBET t'offre un outil
        puissant pour analyser les résultats de tes paris passés. Grâce au monitoring de sélection, tu peux identifier
        les forces et les faiblesses de tes stratégies, et les affiner pour maximiser ton taux de réussite.</p>
    </div>

    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Élimine les erreurs, augmente tes gains</h3>
      <p class="px-5">Les erreurs coûtent cher, surtout quand tes clients comptent sur toi. Avec DoctoBET, tu peux
        réduire les risques en éliminant les paris peu rentables. En suivant de près les performances de tes sélections,
        tu minimises les échecs et optimises tes profits.</p>
    </div>


    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Ne rate aucune opportunité</h3>
      <p class="px-5">
        Dans le monde des paris, chaque seconde compte. Le module de sélection de DoctoBET te permet de ne manquer
        aucune opportunité. Tu peux identifier rapidement les rencontres à fort potentiel, en accord avec tes critères
        de recherche précis, pour toujours être à l’affût des meilleurs paris.
      </p>
    </div>

    <blog-see-more/>

    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Renforce la fidélité de tes VIP</h3>
      <p class="px-5">Tes clients VIP attendent des résultats concrets et réguliers. En utilisant DoctoBET, tu leur
        offres des pronostics basés sur une analyse approfondie et des stratégies optimisées. Augmente ton taux de
        réussite, assure leur satisfaction, et renforce leur fidélité en leur fournissant des résultats fiables.</p>
    </div>


    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Domine le marché avec des paris gagnants</h3>
      <p class="px-5">
        Ne laisse plus rien au hasard. Avec DoctoBET, tu as les outils nécessaires pour analyser tes résultats, ajuster
        tes stratégies, et ne rater aucune opportunité. Élimine les échecs et domine le marché en offrant à tes clients
        des pronostics qui font vraiment la différence.
      </p>
    </div>
  </div>
</template>

<style scoped lang="less">

</style>
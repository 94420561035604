<script>
import SelectionOperation from "@/components/routes/selection/panes/selection/condition/SelectionOperation.vue";
import SelectionConditionCriteria from "@/components/routes/selection/modals/criteria/SelectionConditionCriteria.vue";
import {conditionFormMixins} from "@/components/routes/selection/modals/condition_forms/ConditionForm.Mixins";

export default {
  name: "ConditionFormTeamResult",
  components: {SelectionConditionCriteria, SelectionOperation},
  mixins: [conditionFormMixins],
  props: {
    modelValue: {
      type: Object,
      required: true
    },
  },

  beforeMount() {
    this.form = this.modelValue;

    // On va vérifer l'état du formulaire. et appliquer des valeurs par defaut si besoin
    if (!this._matchOneChoice(this.form.conditionType, this.conditionType.choices)) {
      // On applique une valeur par defaut car la valeur actuelle n'est pas dans les choix
      this.form.conditionType = this.conditionType.default;
    }

    if (!this._matchOneChoice(this.form.who, this.who.choices)) {
      // On applique une valeur par defaut car la valeur actuelle n'est pas dans les choix
      this.form.who = this.who.default;
    }
    if (!this._matchOneChoice(this.form.against, this.against.choices)) {
      // On applique une valeur par defaut car la valeur actuelle n'est pas dans les choix
      this.form.against = this.against.default;
    }
    if (!this._matchOneChoice(this.form.what, this.what.choices)) {
      // On applique une valeur par defaut car la valeur actuelle n'est pas dans les choix
      this.form.what = this.what.default;
    }
    if (!this._matchOneChoice(this.form.period, this.period.choices)) {
      // On applique une valeur par defaut car la valeur actuelle n'est pas dans les choix
      this.form.period = this.period.default;
    }
    if (!this._matchOneChoice(this.form.locationStrict, this.locationStrict.choices)) {
      // On applique une valeur par defaut car la valeur actuelle n'est pas dans les choix
      this.form.locationStrict = this.locationStrict.default;
    }
    if (!this._matchOneChoice(this.form.leagueStrict, this.leagueStrict.choices)) {
      // On applique une valeur par defaut car la valeur actuelle n'est pas dans les choix
      this.form.leagueStrict = this.leagueStrict.default;
    }
  },

  methods: {},

  data() {
    return {
      form: {},

      conditionType: {
        label: 'Type de condition',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Fréquence", value: {choice: "FREQUENCE", min: 1}},
          {label: "Série", value: {choice: "SERIE", min: 0.2}}
        ],
        default: {
          choice: "FREQUENCE",
          min: 1
        }
      },
      who: {
        label: 'Sujet',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Une des équipes", value: {choice: "ONE_OF_TEAMS"}},
          {label: "Domicile", value: {choice: "HOME_TEAM"}},
          {label: "Extérieur", value: {choice: "AWAY_TEAM"}},
          {label: "Les 2 équipes", value: {choice: "BOTH_TEAM"}},
        ],
        default: {
          choice: "ONE_OF_TEAMS"
        }
      },
      against: {
        label: 'Adversaire',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Toute autre équipe", value: {choice: "ANY_TEAM"}},
          {label: "Adversaire du jour", value: {choice: "OTHER_TEAM"}}
        ],
        default: {
          choice: "ANY_TEAM"
        }
      },
      what: {
        label: 'Résultat',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Gagne", value: {choice: "WIN"}},
          {label: "Nul", value: {choice: "DRAW"}},
          {label: "Perd", value: {choice: "LOSE"}},
          {label: "Ne gagne pas", value: {choice: "NO_WIN"}},
          {label: "Ne fait pas nul", value: {choice: "NO_DRAW"}},
          {label: "Ne perd pas", value: {choice: "NO_LOSS"}}
        ],
        default: {
          choice: "WIN"
        }
      },
      period: {
        label: 'Période',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Cette saison", value: {choice: "LAST_SEASON", min: 1}},
          {label: "5 derniers matchs", value: {choice: "LAST_GAME", min: 5}},
          {label: "10 derniers matchs", value: {choice: "LAST_GAME", min: 10}}
        ],
        default: {
          choice: "LAST_SEASON",
          min: 1
        }
      },
      leagueStrict: {
        label: 'Compétition',
        type: "BOOLEAN",
        multiple: false,
        choices: [
          {
            label: "Peu importe",
            tooltip: "La condition ignorera la compétition des matchs",
            value: {choice: "FALSE"}
          },
          {
            label: "Celle du match",
            tooltip: "La condition prendra en compte les matchs de la compétition",
            value: {choice: "TRUE"}
          },

        ], // Oui ou Non
        default: {
          choice: "FALSE"
        }
      },
      locationStrict: {
        label: 'Domicile/Extérieur',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Peu importe", value: {choice: "FALSE"}},
          {label: "Celle du match", value: {choice: "TRUE"}}
        ],
        default: {
          choice: "FALSE"
        }
      },
    }
  }
}
</script>

<template>
  <div>

    <div class="px-1">
      <selection-condition-criteria v-model="form.conditionType" :choices="conditionType.choices"
                                    :label="conditionType.label"/>
    </div>

    <hr/>
    <div class="selection-condition-item-resume m-2" v-if="this.form.conditionType">
      <span v-if="this.form.conditionType.choice === 'FREQUENCE'">Rechercher les rencontres suivant la récurrence d'un résultat réalisée par une équipe.</span>
      <span v-if="this.form.conditionType.choice === 'SERIE'">Rechercher les rencontres suivant la série réalisée par une équipe.</span>
    </div>

    <hr/>

    <div class="condition-form-criteria">
      <selection-condition-criteria v-model="form.who" :choices="who.choices" :label="who.label"/>
      <selection-condition-criteria v-model="form.against" :choices="against.choices" :label="against.label"/>
      <selection-condition-criteria v-model="form.what" :choices="what.choices" :label="what.label" :type="what.type"/>
      <selection-condition-criteria v-model="form.period" :choices="period.choices" :label="period.label"/>
      <selection-condition-criteria v-model="form.leagueStrict" :choices="leagueStrict.choices"
                                    :label="leagueStrict.label"/>
      <selection-condition-criteria v-model="form.locationStrict" :choices="locationStrict.choices"
                                    :label="locationStrict.label"/>
    </div>

  </div>
</template>

<script>
export default {
  name: "WidgetLoadingContent",
}
</script>

<template>
  <div class="widget-content-loading">
    <font-awesome-icon icon="fa-solid fa-spinner" size="2xl" :spin="true" class="primary"/>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.widget-content-loading {
  margin-top: @padding-large;
}
</style>
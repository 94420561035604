<script>
import axiosService from "@/services/axios.service";
import TeamLogo from "@/components/common/TeamLogo.vue";
import {perspectiveStore} from "@/stores/Perspective.store";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import {contextStore} from "@/stores/Context.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import StatisticValueButton from "@/components/common/StatisticValueButton.vue";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";

export default {
  name: "BothTeamsToScoreWidget",
  components: {
    WidgetLoadingContent,
    StatisticValueButton,
    WidgetPeriodParamButton,
    WidgetTeamParamButton, WidgetLeagueParamButton, WidgetLocationParamButton, LeagueLogo, TeamLogo
  },
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      // Les données récupéré du server
      data: null,

      // La configuration du graphique
      chart: {
        key: 0,
        options: {
          tooltip: {
            enabled: false,
          },
          legend: {
            show: false,
            border: {
              show: false,
            },
          },
          colors: ['#436946', '#865762'],
        },
        series: [],
      }
    }
  },
  mounted() {
    this._onGameUIDChange()

    if (!this.item) return;  // widget store preview
    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })
    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _period() {
      if (!this.item) return null;
      return perspectiveStore().getParameterPeriodValue(this.item.uid)
    },
    _itemUID() {
      return this.item ? this.item.uid : null
    },
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!contextStore().gameUID) {
        return;
      }

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) {
        return;
      }

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {

      if (!this.item) {  // widget store preview
        this.data = {
          statUID: null,
          percent: 0.5
        }
        this._updateChart()
        return;
      }

      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        this._updateChart()
        return;
      }

      const sport = contextStore().getSport
      if (sport !== 'FOOTBALL' && sport !== 'HOCKEY') {
        this.data = {}
        console.warn('BothTeamsToScore: sport not supported', sport)
        return
      }

      let locationParamValue = perspectiveStore().getParameterValue(this.item.uid, 'location')
      let teamParamValue = perspectiveStore().getParameterValue(this.item.uid, 'team')
      const payload = {
        whoTeamUID: perspectiveStore().getParameterTeamValue(this.item.uid),
        againstTeamUID: null,
        leagueUID: perspectiveStore().getParameterLeagueValue(this.item.uid),
        location: locationParamValue === 'CURRENT' ? teamParamValue : locationParamValue,
        period: perspectiveStore().getParameterValue(this.item.uid, 'period'),
        sport: contextStore().getSport
      }
      axiosService.put('/wid/btts', payload).then(response => {
        this.data = response.data
        wds.put(this.item.uid, contextStore().gameUID, this.data)
        this._updateChart()
      })
    },
    _updateChart() {
      this.chart.series = [
        this.data.percent ? this.data.percent * 100.0 : 0,
        this.data.percent ? (1.0 - this.data.percent) * 100.0 : 0
      ]

      this.chart.key++  // force update chart component
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      BTTS
      <div class="widget-header-params flex" v-if="item">
        <widget-period-param-button :widget="item"/>
        <widget-location-param-button :widget="item"/>
        <widget-team-param-button :widget="item"/>
        <widget-league-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content flex flex-col" v-else>
      <apexchart
          :key="chart.key"
          ref="results-widget-chart"
          :width="124"
          type="pie"
          :options="chart.options"
          :series="chart.series"
          class="m-auto"/>
      <div v-if="data" class="flex">
        <statistic-value-button :uid="data.statUID" :period="_period" type="positive" class="flex-grow no-radius"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
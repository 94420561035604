<script>

// Stores
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "WidgetParamButton",
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  methods: {
    _openSettingsModal() {
      modalStore().openModal({
        uid: 'widgetSettingsModal', payload: {
          uid: this.widget.uid
        }
      })
    },
  },
  computed: {
    _tooltipTitle() {
      return 'Parametres'
    }
  }
}
</script>

<template>
  <a-tooltip placement="top" :title="_tooltipTitle">
    <button class="small no-radius" @click="_openSettingsModal">
      <font-awesome-icon icon="cog"/>
    </button>
  </a-tooltip>
</template>

<script>
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import {bankrollPaneStore} from "@/components/routes/bankroll/panes/bankroll/BankrollPane.store";
import axiosService from "@/services/axios.service";

export default {
  name: "TotalGoalsSuccessRateWidget",
  components: {
    WidgetLoadingContent
  },
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      refreshTimeout: null,
      data: {}
    }
  },
  mounted() {

    this._onBankrollUIDChange()

    if (!this.item) return;  // widget store preview
    window.emitter.on('bankroll-pane:bankroll-uid:change', (bankrollUID) => {
      this._onBankrollUIDChange()
    })
    window.emitter.on('bankroll-pane:bankroll-filters:change', (bankrollUID) => {
      this._onBankrollUIDChange()
    })

  },
  beforeUnmount() {
    window.emitter.off('bankroll-pane:bankroll-uid:change')
    window.emitter.off('bankroll-pane:bankroll-filters:change')
  },
  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _bankrollUID() {
      return bankrollPaneStore().getBankrollActiveUID()
    },
    _sortedCategories() {
      if (!this.data.categories)
        return []
      return this.data.categories.sort((a, b) => {
        // On tri par nombre de but
        if(a.nbGoals < b.nbGoals) { return -1; }
        if(a.nbGoals > b.nbGoals) { return 1; }
        return 0;
      })
    }
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onBankrollUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!this._bankrollUID) return;

      this.data = null // => loading
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {
      const payload = bankrollPaneStore().buildPayload()
      axiosService.put('/wid/bk/total-goals-success-rate', payload).then(response => {
        this.data = response.data
      })
    },

    _rate(c) {
      if (c.ticketCount === 0)
        return null

      return (c.winCount * 100.0 / c.ticketCount).toFixed(0)
    },

    _roi(c) {
      if (!c.roi)
        return '-'

      return c.roi.toFixed(1)
    },

    _styleROI(c){
      return {
        color: c.roi > 0 ? '#6DFFC2' : '#FF846E'
      }
    },

    _color(c) {
      const rate = this._rate(c)
      if (rate < 40)
        return '#FF846E'
      if (rate < 60)
        return '#FFC86D'

      return '#6DFFC2'
    },

  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Taux de réussite par nombre de but
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content" v-else>
      <table>
        <tr>
          <th class="league text-left">
            Buts
          </th>
          <th>
            Nb.
          </th>
          <th class="text-right" colspan="2">
            Réussite
          </th>
          <th class="text-right">
            ROI
          </th>
        </tr>
        <tr class="row" v-for="c in _sortedCategories" style="color:white">
          <td class="score">
            {{ c.nbGoals }}
          </td>
          <td class="ticket-count">
            {{ c.ticketCount }}
          </td>
          <td class="bar-container">
            <div class="bar" :style="{width: _rate(c) + '%', backgroundColor: _color(c)}" v-if="_rate(c)">
            </div>
          </td>
          <td class="rate">
          <span v-if="_rate(c)">
          {{ _rate(c) }}%
            </span>
          </td>
          <td class="roi" :style="_styleROI(c)">
            <span v-if="_roi(c)">{{ _roi(c) }}%</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.widget-content {
  height: calc(314px - 29px);
  overflow-y: scroll;
}

@row-height: 17px;
.row {
  padding: 0 4px;


  .rate, .score, .ticket-count {
    line-height: @row-height;
  }

  .ticket-count {
    width: 20px;
    text-align: left;
  }


  .rate {
    width: 30px;
    text-align: right;
  }

  .roi {
    width: 64px;
    text-align: right;
  }

  .score {
    width: 40px;
    text-align: left;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
  }

  .bar-container {
    margin-top: 2px;
    flex-grow: 1;
    height: calc(@row-height - 4px);

    .bar {
      height: calc(@row-height - 4px);
    }
  }
}
</style>
<script>

import {offerStore} from "@/stores/Offer.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "OfferBookmakerValue",
  props: {
    obv: {
      type: Object,
      required: true
    },
    positive: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    _buildCssClasses() {
      return {
        'offer-bookmaker-value flex': true,
        'positive': this.positive,
        'negative': !this.positive
      }
    },
    _obvValue() {
      return this.obv.value
    },
    _obvLabel() {
      return offerStore().getOfferBookmakerValueLabel(this.obv)
    },
  },

  methods: {
    _openOfferBookmakerValueModal(event) {
      event.stopPropagation()
      modalStore().openModal({
        uid: 'offerModal',
        payload: {offerUID: this.obv.offerUID, obv: this.obv}
      })
    }
  }
}
</script>

<template>
  <div :class="_buildCssClasses" @click="_openOfferBookmakerValueModal" v-if="obv">
    <div class="value pl-2">
      {{ _obvValue }}
    </div>
    <div class="label ml-2 text-left py-1" style="line-height: normal">
      {{ _obvLabel }}
    </div>
  </div>
  <div v-else>
    Loading...
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.offer-bookmaker-value {
  color: @text-color;
  font-size: @font-size-small;
  min-height: 24px;
  align-items: center;

  &:hover {
    background: @hover-color;
  }

  &.positive {
    background: -webkit-linear-gradient(0deg, @positive-color, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00);

    &:hover {
      background: -webkit-linear-gradient(0deg, @positive-color, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF00);
    }
  }

  &.negative {
    background: -webkit-linear-gradient(0deg, @negative-color, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00);

    &:hover {
      background: -webkit-linear-gradient(0deg, @negative-color, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF00);
    }
  }


  .value {
    font-weight: bold;
    text-align: right;
    min-width: 32px;
    display: flex;
    align-items: center;
  }
}
</style>
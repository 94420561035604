<script>

import {contextStore} from "@/stores/Context.store";
import {modalStore} from "@/stores/Modal.store";
import BankrollTicketBet from "@/components/routes/bankroll/panes/bankroll/items/BankrollTicketBet.vue";
import GeneratedTicket from "@/components/routes/selection/modals/ticket_generator/ticket/PaperTicket.vue";

export default {
  name: "PaperTicketModal",
  components: {GeneratedTicket, BankrollTicketBet},
  computed:{
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'paperTicketModal';
    },

    _payload() {
      if (!this._isVisible) return null;
      const payload = modalStore().getVisibleModal.payload
      if (!payload) return null;
      return payload;
    },

    _ticket() {
      if (!this._payload) return null;
      return this._payload.result;
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    }
  }
}
</script>

<template>
  <AModal class="paper-ticket" v-if="_isVisible" :open="true" :width="380" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Ticket papier
    </header>
    <div class="content">
      <generated-ticket :ticket="_ticket" ref="divToCapture"/>
    </div>
    <template #footer>
      <div class="my-2 mx-1 flex">
        <div class="flex-grow"/>
        <button class="info" @click="_onCancel">Retour</button>
      </div>
    </template>
  </AModal>
</template>

<style scoped lang="less">
  .paper-ticket{

  }
</style>
<script>
import axiosService from "@/services/axios.service";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "BankrollEvolutionGraphic",

  props: {
    bankrollUID: {
      required: true
    }
  },

  mounted() {

    window.emitter.on('bankroll-pane:bankroll-uid:change', (bankrollUID) => {
      this._refresh(bankrollUID)
    })
    window.emitter.on('bankroll-pane:bankroll-filters:change', (bankrollUID) => {
      this._refresh(bankrollUID)
    })

    if(!this.bankrollUID) {
      return
    }
    this._refresh(this.bankrollUID)

  },
  beforeUnmount() {
    window.emitter.off('bankroll-pane:bankroll-uid:change')
    window.emitter.off('bankroll-pane:bankroll-filters:change')
  },

  data() {
    return {
      values: [],
      chart: {
        key: 0,
        type: 'area',
        width: (contextStore().isMobileDevice() ? contextStore().getScreenWidth() : 400) - 16,
        options: {
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
            border: {
              show: false,
            },
          },
          colors: ['#516dd8'],
          dataLabels: {
            enabled: false
          },

          xaxis: {
            labels: {
              show: false
            }
          },

          yaxis:{
            decimalsInFloat: 0,
            labels: {
              style: {
                colors: '#EDEDED',
              },
            }
          },
        },
        series: [],
      }
    }
  },

  methods: {

    _refresh(bankrollUID) {
      axiosService.get(`/bkl/${bankrollUID}/balance-evolution`)
          .then(response => {
            this.values = response.data.results
            setTimeout(() => {
              this._updateChart()
            }, 500)
          })
    },

    _updateChart() {
      this.chart.series = [{
        name: 'Balance',
        data: this.values.map(v => v.toFixed(2))
      }]

      this.chart.key++
    }
  }
}
</script>

<template>
  <div class="bankroll-evolution-graphic">
    <apexchart
        :key="chart.key"
        ref="teams-forme-evolution-chart-widget"
        :width="chart.width"
        :type="chart.type"
        :options="chart.options"
        :series="chart.series"
        class="mt-2"/>
  </div>
</template>
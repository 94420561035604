<script>
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import ExpectedWinCalculatorItem from "@/components/common/modal/calculator/items/ExpectedWinCalculatorItem.vue";
import KellyLlouCalculatorItem from "@/components/common/modal/calculator/items/KellyLlouCalculatorItem.vue";
import DrawNoBetCalculatorItem from "@/components/common/modal/calculator/items/DrawNoBetCalculatorItem.vue";
import TauxRetourJoueurCalculatorItem
  from "@/components/common/modal/calculator/items/TauxRetourJoueurCalculatorItem.vue";
import BattreCashoutCalculatorItem from "@/components/common/modal/calculator/items/BattreCashoutCalculatorItem.vue";

export default {
  name: "CalculatorModal",
  components: {BattreCashoutCalculatorItem, KellyLlouCalculatorItem, ExpectedWinCalculatorItem, DrawNoBetCalculatorItem, TauxRetourJoueurCalculatorItem},
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal
      if (!visibleModal) return false
      return visibleModal.uid === 'calculatorModal'
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Calculatrice
    </header>
    <div class="content">
      <a-collapse>
        <a-collapse-panel key="0" header="Battre le cashout" :show-arrow="false">
          <BattreCashoutCalculatorItem/>
        </a-collapse-panel>
        <a-collapse-panel key="1" header="Critère de Kelly-Llou" :show-arrow="false">
          <KellyLlouCalculatorItem/>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="Gain souhaité" :show-arrow="false">
          <ExpectedWinCalculatorItem/>
        </a-collapse-panel>
        <a-collapse-panel key="3" header="Taux de retour joueur" :show-arrow="false">
          <TauxRetourJoueurCalculatorItem/>
        </a-collapse-panel>
        <a-collapse-panel key="4" header="Draw No Bet" :show-arrow="false">
          <DrawNoBetCalculatorItem/>
        </a-collapse-panel>
      </a-collapse>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="" @click="_onCancel">Fermer</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>
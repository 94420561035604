<script>
import axiosService from "@/services/axios.service";
import {statStore} from "@/stores/Statistic.store";
import {preferencesStore} from "@/stores/Preferences.store";
import SelectionPaneItemStatsTabGoalsUnderOver
  from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Stats.Tab.Goals.UnderOver.vue";
import {gameStore} from "@/stores/Game.store";

export default {
  name: "SelectionPaneItem.Stats.Tab.Goals",
  components: {SelectionPaneItemStatsTabGoalsUnderOver},
  props: {
    gameUid: {
      required: true
    },
    mergeMethod: {
      type: Function,
      default: () => {
        console.error('mergeMethod is required')
      }
    }
  },

  data() {
    return {
      selectedSubCategory: 'FT',
      data: null
    }
  },

  beforeMount() {
    if (!this.gameUid) return;
    this._refreshData()
  },

  methods: {
    _refreshData() {
      const payload = {
        uid: this.gameUid,
        statsFixedUID: this._statsFixedUID  // FT
      }
      this.data = null
      axiosService.put('/dta/game/basic-data/diagnostic', payload).then(response => {
        this.data = response.data
      })
    },
    _clearCategory() {
      this.$emit('close')
    },
    _setSubCategory(subCat) {
      this.selectedSubCategory = subCat
      this._refreshData()
    }
  },

  computed: {
    _filterLeague() {
      return preferencesStore().getFirstValue('SELECTION_STATS_TAB', 'LEAGUE');
    },
    _filterType() {
      return preferencesStore().getFirstValue('SELECTION_STATS_TAB', 'TYPE');
    },
    _subCategories() {
      return [
        'FT', 'P1', 'P2'
      ]
    },
    _game() {
      if (!this.gameUid) return null
      return gameStore().get(this.gameUid)
    },

    _gameHomeTeamUID() {
      if (!this._game) return null
      return this._game.homeTeamUID
    },

    _gameAwayTeamUID() {
      if (!this._game) return null
      return this._game.awayTeamUID
    },

    _05StatsFixedUID() {
      switch (this.selectedSubCategory) {
        case 'FT':
          return [301, 314]
        case 'P1':
          return [306, 319]
        case 'P2':
          return [310, 323]
      }
    },
    _15StatsFixedUID() {
      switch (this.selectedSubCategory) {
        case 'FT':
          return [302, 315]
        case 'P1':
          return [307, 320]
        case 'P2':
          return [311, 324]
      }
    },
    _25StatsFixedUID() {
      switch (this.selectedSubCategory) {
        case 'FT':
          return [303, 316]
        case 'P1':
          return [308, 321]
        case 'P2':
          return [312, 325]
      }
    },
    _35StatsFixedUID() {
      switch (this.selectedSubCategory) {
        case 'FT':
          return [304, 317]
        case 'P1':
          return [309, 322]
        case 'P2':
          return [313, 326]
      }
    },
    _overStatsFixedUID() {
      return [...this._05StatsFixedUID, ...this._15StatsFixedUID, ...this._25StatsFixedUID, ...this._35StatsFixedUID]
    },
    _statsFixedUID() {
      return [...this._overStatsFixedUID]
    },
    _filteredData() {
      if (!this.data) return null

      const results = []
      this.data.stats.forEach(stat => {

        if (this._filterLeague === 'CURRENT' && stat.leagueUID === null) return
        if (this._filterLeague === 'ANY' && stat.leagueUID !== null) return

        if (this._filterType === 'ALL' && stat.location !== 'ANY') return
        if (this._filterType === 'DOM_EXT' && stat.location === 'ANY') return
        if (this._filterType === 'DOM_EXT' && stat.location === 'HOME' && stat.whoUID !== this._gameHomeTeamUID) return
        if (this._filterType === 'DOM_EXT' && stat.location === 'AWAY' && stat.whoUID !== this._gameAwayTeamUID) return

        results.push(stat)
      })
      return results
    },

    statsToShow() {
      const results = []
      if (!this.data) return null

      const periods = ['CURRENT_SEASON', 'ALL_TIME', 'LAST_10_GAMES']
      const minAbsByPeriod = {
        'CURRENT_SEASON': 5,
        'ALL_TIME': 10,
        'LAST_10_GAMES': 10,
        'LAST_5_GAMES': 5
      }
      this._filteredData.forEach(stat => {

        switch (stat.type) {
          case 'SERIE':
            periods.forEach(period => {
              const prc = statStore().getPercent(stat, period)
              if (prc > 0.70) {
                results.push({
                  stat: stat,
                  period: period,
                  percent: prc,
                  positive: true
                })
              }
            })
            break
          case 'FREQUENCY':
            periods.forEach(period => {
              const prc = statStore().getValue(stat, period, false)
              if (prc <= 0.50) {
                return
              }
              const abs = statStore().getDataCount(stat, period)
              if (abs < minAbsByPeriod[period]) {
                return
              }
              results.push({
                stat: stat,
                period: period,
                percent: prc,
                positive: true
              })

            })
            break
        }
      })
      return results
    },

    orderedStatsToShow() {
      if (!this.statsToShow) return null
      return this.statsToShow.sort((a, b) => {
        return b.percent - a.percent
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="categories">
      <button class="tiny primary" @click="_clearCategory">Total de buts</button>
      <button :class="{'tiny' : true, 'info' : sc !== selectedSubCategory, accent : sc === selectedSubCategory}"
              v-for="sc in _subCategories" @click="_setSubCategory(sc)">{{ sc }}
      </button>
    </div>
    <a-alert v-if="!data" message="Chargement..." type="info" show-icon/>
    <selection-pane-item-stats-tab-goals-under-over :argument="0.5" :game-uid="gameUid" :data="orderedStatsToShow"
                                                    :merge-method="mergeMethod"/>
    <selection-pane-item-stats-tab-goals-under-over :argument="1.5" :game-uid="gameUid" :data="orderedStatsToShow"
                                                    :merge-method="mergeMethod"/>
    <selection-pane-item-stats-tab-goals-under-over :argument="2.5" :game-uid="gameUid" :data="orderedStatsToShow"
                                                    :merge-method="mergeMethod"/>
    <selection-pane-item-stats-tab-goals-under-over :argument="3.5" :game-uid="gameUid" :data="orderedStatsToShow"
                                                    :merge-method="mergeMethod"/>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.categories {
  width: 100%;
  text-align: left;
  padding: @padding-small;
  overflow-x: scroll;
  display: flex;
  white-space: nowrap;
  scrollbar-width: none;

  button {
    flex: 0 0 auto;
    font-size: 7.5pt !important;
    margin-right: @padding-tiny;
  }
}
</style>
<script>
import {contextStore} from "@/stores/Context.store";
import {userStore} from "@/stores/User.store";
import {modalStore} from "@/stores/Modal.store";
import {notebookStore} from "@/stores/NotebookStore";

export default {
  name: "UserDropdownMenu",

  data() {
    return {
      prices: null
    }
  },

  computed: {
    _contextStore() {
      return contextStore()
    },
    _isAuthenticated() {
      return this._contextStore.isAuthenticated()
    },
    _isNotebookFilled() {
      return notebookStore().getItems().length > 0
    }
  },
  methods: {
    _openUserPreferencesModal() {
      modalStore().openModal({uid: 'userPreferencesModal'})
    },
    _signWithGoogle() {
      userStore().signInWithGoogle()
    },
    _openCalculatorModal() {
      modalStore().openModal({uid: 'calculatorModal'})
    },
    _openNotebookModal() {
      modalStore().openModal({uid: 'notebookModal'})
    },
    _showSearchModal() {
      modalStore().openModal({
        uid: 'searchModal',
        payload: {
          filter: '',
        }
      })
    },
  }
}
</script>

<template>

  <span id="user-dropdown-menu">
    <button class="square mr-1 primary selected" v-if="_isNotebookFilled" @click="_openNotebookModal">
      <font-awesome-icon icon="fa-solid fa-book-medical"/>
    </button>

    <button class="square accent mr-1" @click="_showSearchModal">
      <font-awesome-icon icon="fa-solid fa-search"></font-awesome-icon>
    </button>

    <button class="square accent mr-1" @click="_openCalculatorModal">
      <font-awesome-icon icon="fa-solid fa-calculator"/>
    </button>

    <a-tooltip placement="left" title="Gestion de ton compte" v-if="this._isAuthenticated">
      <button class="square accent mr-1" @click="_openUserPreferencesModal">
        <font-awesome-icon icon="fa-solid fa-user"/>
      </button>
    </a-tooltip>
    <a-tooltip placement="left" title="Connexion" v-else>
      <button class="primary mr-1" @click="_signWithGoogle()">
        <font-awesome-icon icon="fa-solid fa-right-to-bracket"/>
      Connexion
      </button>
    </a-tooltip>

  </span>

</template>

<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import BlogAvisClient from "@/components/routes/blog/BlogAvisClient.vue";

export default {
  name: "PourQuiDoctobet",
  components: {BlogAvisClient, BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif'
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Analyse les stats, maximise tes chances"
             class="w-full"/>
      </div>
    </div>

    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET s’adresse à tous ceux qui veulent passer au niveau supérieur dans leurs paris sportifs. Que tu sois un
        novice ou un pro, cet outil est là pour t’aider à parier avec plus de précision et de confiance.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>

    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5"> Que tu sois débutant ou expert, DoctoBET est pour toi</h3>
      <p class="px-5">Tu te demandes si DoctoBET peut vraiment t’aider ? La réponse est simple : oui. Que tu sois un
        parieur débutant cherchant à mieux comprendre les paris sportifs ou un expert en quête d’un outil pour affiner
        tes stratégies, DoctoBET s'adapte à ton niveau. Ce n’est pas juste un site de stats, c’est un allié dans ta
        quête de réussite.</p>
    </div>


    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Pour ceux qui veulent transformer l’instinct en stratégie</h3>
      <p class="px-5">Ton instinct t’a peut-être déjà permis de gagner quelques paris, mais imagine ce que tu pourrais
        accomplir avec des données précises à ta disposition. DoctoBET te permet de combiner ton flair avec des analyses
        pointues, transformant chaque pari en une décision éclairée. Plus qu’un simple site, c’est l’outil qui te permet
        de miser avec confiance.</p>
    </div>


    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Pour ceux qui cherchent à maximiser leur rentabilité</h3>
      <p class="px-5">
        Tu veux maximiser tes gains et réduire les risques ? DoctoBET est conçu pour t’aider à faire les bons choix.
        Grâce à ses modules avancés, tu peux analyser des millions de statistiques, identifier les opportunités les plus
        rentables et affiner tes stratégies. C’est plus qu’un tableau de chiffres, c’est la clé pour booster ta
        rentabilité.
      </p>
    </div>

    <blog-see-more/>

    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Pour ceux qui veulent gagner du temps et de l’argent</h3>
      <p class="px-5">Si tu en as assez de passer des heures à chercher les bonnes infos, DoctoBET est la solution.
        Notre outil te permet d’accéder rapidement aux données les plus pertinentes, te faisant gagner un temps précieux
        tout en optimisant tes paris. DoctoBET n’est pas là pour compliquer les choses, mais pour te faciliter la vie et
        t’aider à parier plus intelligemment.</p>
    </div>

    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Pour ceux qui ne laissent rien au hasard</h3>
      <p class="px-5">
        Si tu veux tout maîtriser, DoctoBET est l’outil qu’il te faut. Il ne s’agit pas simplement de consulter des
        stats, mais d’utiliser un ensemble d’outils puissants pour prendre les meilleures décisions. Avec DoctoBET,
        chaque pari est basé sur des faits solides, te permettant de minimiser les risques et maximiser tes chances de
        succès.
      </p>
    </div>

    <blog-avis-client/>

  </div>
</template>

<style scoped lang="less">

</style>
<script>

import dateService from "@/services/date.service";

export default {
  name: "DateTime",
  props: {
    value: {
      required: true
    },
    yearIfDifferent: {
      type: Boolean,
      default: false
    },
    time: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium' // small, medium accepeted
    }
  },

  computed: {
    _buildCssClasses() {
      return {
        'date-time': true,
        'small': this.size === 'small'
      }
    },
    _isYearDifferent() {
      const currentYear = new Date().getFullYear();
      const dateYear = new Date(this.value).getFullYear();
      return currentYear !== dateYear;
    },
    _formattedValue() {

      const time = this.time ? ' HH:mm' : '';

      if (this.yearIfDifferent && this._isYearDifferent) {
        return dateService.format(this.value, 'DD/MM/YY' + time);
      }
      return dateService.format(this.value, 'DD/MM' + time);
    }
  },
}
</script>

<template>
  <span :class="_buildCssClasses">
    {{ _formattedValue }}
  </span>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.date-time {
  min-width: 16px;
  min-height: 16px;
  border-radius: @radius-small;
  line-height: 24px;
  padding: 0 @padding-small;
  color: white;
  font-size: 9pt;
  font-family: 'SourceCodePro', serif !important;

  &.small {
    font-size: 8pt;
    line-height: 16px;
  }
}
</style>
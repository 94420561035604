<script>
import axiosService from "@/services/axios.service";
import TeamLogo from "@/components/common/TeamLogo.vue";
import StatisticValueButton from "@/components/common/StatisticValueButton.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import {perspectiveStore} from "@/stores/Perspective.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import {contextStore} from "@/stores/Context.store";
import {gameStore} from "@/stores/Game.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";

export default {
  name: "TeamAllOverChartsWidget",
  components: {
    WidgetLoadingContent,
    WidgetPeriodParamButton,
    WidgetTeamParamButton,
    WidgetLeagueParamButton,
    WidgetLocationParamButton,
    StatisticValueButton,
    TeamLogo
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: null,
      chart: {
        key: 0,
        type: 'bar',
        width: 310,
        options: {
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
            border: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            labels: {
              style: {
                colors: '#FFF' // Change this to your desired color
              }
            }
          },
          yaxis: {
            labels: {
              style: {
                colors: '#FFF' // Change this to your desired color
              }
            }
          }
        },
        series: [],
      }
    }
  },
  mounted() {
    this._onGameUIDChange()
    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })

    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })

    const teamUID = perspectiveStore().getParameterTeamValue(this.item.uid)
    const game = gameStore().get(contextStore().gameUID)
    if (teamUID === game.homeTeamUID) {
      this.chart.options.colors = ['#169272']
    } else {
      this.chart.options.colors = ['#516dd8']
    }
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!contextStore().gameUID) return;

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) return;

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {
      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        this._updateChart()
        return;
      }

      const payload = {
        whoTeamUID: perspectiveStore().getParameterTeamValue(this.item.uid),
        againstTeamUID: null,
        leagueUID: perspectiveStore().getParameterLeagueValue(this.item.uid),
        location: perspectiveStore().getParameterValue(this.item.uid, 'location'),
        period: perspectiveStore().getParameterValue(this.item.uid, 'period'),
        sport: perspectiveStore().getCurrentSport()
      }
      axiosService.put('/wid/team-all-over-charts', payload).then(response => {
        this.data = response.data
        wds.put(this.item.uid, contextStore().gameUID, this.data)
        this._updateChart()
      })
    },
    _updateChart() {
      this.chart.series = []
      const series = {
        name: 'Equipe',
        data: []
      }

      // Valeur equipe
      let keys = Object.keys(this.data.team);
      keys.forEach((key, index) => {
        let teamValue = this.data.team[key];
        let leagueValue = this.data.league[key];
        if (!teamValue) teamValue = 0
        teamValue = teamValue.toFixed(1)

        series.data.push({
          'x': key,
          'y': [teamValue],
          'colors': ['#dee213'],
          goals: [
            {
              name: 'Compétition',
              value: leagueValue,
              strokeColor: '#61E9FF',
              strokeWidth: 5,
            }
          ]
        })
      })

      this.chart.series.push(series)

      this.chart.key++
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Fréquence Over Match
      <div class="widget-header-params flex">
        <widget-period-param-button :widget="item"/>
        <widget-location-param-button :widget="item"/>
        <widget-team-param-button :widget="item"/>
        <widget-league-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content flex" v-else>
      <apexchart
          :key="chart.key"
          ref="teams-perfomance-chart-widget"
          :width="chart.width"
          :type="chart.type"
          :options="chart.options"
          :series="chart.series"
          class="mt-1"/>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import BlogAvisClient from "@/components/routes/blog/BlogAvisClient.vue";

export default {
  name: "ParieAvecFaitPasChance",
  components: {BlogAvisClient, BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif';
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Parie avec les faits, pas avec la chance"
             class="w-full"/>
      </div>
    </div>

    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET s’adresse à tous ceux qui veulent passer au niveau supérieur dans leurs paris sportifs. Que tu sois un
        novice ou un pro, cet outil est là pour t’aider à parier avec plus de précision et de confiance.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>

    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Ne laisse plus le hasard décider</h3>
      <p class="px-5">Si tu penses que parier c’est simplement une question de chance, détrompe-toi. Les vrais gagnants
        sont ceux qui se basent sur des faits concrets. Les statistiques, les analyses, les données : voilà les clés
        pour faire la différence et maximiser tes gains. DoctoBET t’aide à transformer ton instinct en stratégie.</p>
    </div>

    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Les chiffres ne mentent pas</h3>
      <p class="px-5">Le football, c’est un jeu de chiffres. Chaque but, chaque passe, chaque possession raconte une
        histoire. En prenant en compte les statistiques, tu ne te contentes pas de parier, tu prends des décisions
        éclairées. Avec DoctoBET, accède à des millions de données pour affiner tes pronostics.</p>
    </div>


    <blog-see-more/>

    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Prends l'avantage sur les autres parieurs</h3>
      <p class="px-5">
        Quand tu paries avec des faits, tu prends l’avantage sur ceux qui comptent sur la chance. Les statistiques te
        permettent de voir ce que les autres ne voient pas, d’anticiper les surprises, et de faire des choix qui paient.
        DoctoBET te donne accès à un module Stats gratuit pour te démarquer.
      </p>
    </div>


    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Analyse, ajuste, gagne</h3>
      <p class="px-5">Parier sans analyser, c’est comme jouer sans but. Grâce au module Stats de DoctoBET, tu peux
        analyser en profondeur chaque match, ajuster tes paris en fonction des données et augmenter tes chances de
        succès. Ne laisse plus rien au hasard, mise sur les faits.</p>
    </div>


    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Pourquoi choisir DoctoBET ?</h3>
      <p class="px-5">
        DoctoBET te donne accès gratuitement à des statistiques détaillées pour chaque match, chaque équipe. Tout est
        là, en un seul clic, pour t’aider à faire les bons choix. Avec DoctoBET, parie intelligemment,
        maximise tes gains, et laisse les autres compter sur la chance.
      </p>
    </div>

    <blog-avis-client/>

  </div>
</template>

<style scoped lang="less">

</style>
<script>
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {userStore} from "@/stores/User.store";

export default {
  name: "UserReferringCodeFormModal",
  components: {},
  data() {
    return {
      nickname: "",
      message: ""
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'userReferringCodeFormModal';
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _messageSize() {
      return this.message.length
    },
    _nicknameValid() {
      return this.nickname.length >= 3 && this.nickname.length < 14
    },
    _messageValid() {
      return this.message.length >= 32 && this.message.length < 140
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _onValidate() {
      userStore().initCode(this.nickname, this.message, () => {
        console.log("Code initialized");
        modalStore().closeModal();
      })
    },
    _cleanNickname(){
      // On supprime les passages à la ligne
      this.nickname = this.nickname.replace(/(\r\n|\n|\r)/gm, " ");
      // On supprime les espaces
      this.nickname = this.nickname.replace(/\s+/g, " ");
      // On supprime les balises html
      this.nickname = this.nickname.replace(/<[^>]*>?/gm, '');
      // On supprime les injections de code
      this.nickname = this.nickname.replace(/<script[^>]*>.*<\/script>/gm, '');
      // On supprime les injections tentatives d'injections SQL
      this.nickname = this.nickname.replace(/(select|insert|update|delete|drop|create|alter|truncate|grant|revoke|commit|rollback|savepoint|set|show|describe|use|begin|end|declare|exec|execute|prepare"|`)/gm, '');
      // On supprime les caractères spéciaux
      this.nickname = this.nickname.replace(/[^a-zA-Z0-9]/g, '');
      // On limite la taille du pseudo
      if (this.nickname.length > 14) {
        this.nickname = this.nickname.substring(0, 14)
      }
    },
    _cleanMessage() {
      // On supprime les passages à la ligne
      this.message = this.message.replace(/(\r\n|\n|\r)/gm, " ");
      // On supprime les espaces en trop
      this.message = this.message.replace(/\s+/g, " ");
      // On supprime les balises html
      this.message = this.message.replace(/<[^>]*>?/gm, '');
      // On supprime les injections de code
      this.message = this.message.replace(/<script[^>]*>.*<\/script>/gm, '');
      // On supprime les injections tentatives d'injections SQL
      this.message = this.message.replace(/(select|insert|update|delete|drop|create|alter|truncate|grant|revoke|commit|rollback|savepoint|set|show|describe|use|begin|end|declare|exec|execute|prepare"|`)/gm, '');

      // On limite la taille du message
      if (this.message.length > 140) {
        this.message = this.message.substring(0, 140)
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="500" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Création de ton lien de parrainage
    </header>

    <div class="content">
      <div class="m-1 p-1">
        <div class="flex mb-1">
          <legend class="p-0 m-0 white" style="width: 128px; line-height: 24px">Choisis ton pseudo</legend>
          <input type="text" v-model="nickname" placeholder="" class="input flex-grow" @keyup="_cleanNickname"/>
        </div>

        <p class="p-0 m-0">Ton pseudo sera utilisé comme base pour définir ton code de parrainage. Il pourra aussi être utilisé dans
          l'application.</p>
        <a-alert type="warning" show-icon message="Tu dois choisir un pseudo" class="mt-1" v-if="!_nicknameValid"/>
      </div>
      <div class="m-1 p-1">
        <legend class="white">Que dirais-tu à quelqu'un pour le convaincre ?</legend>
        <textarea v-model="message" placeholder="Message personnalisé" class="input w-full" rows=3 @keyup="_cleanMessage"></textarea>
        <div class="flex">
          <p class="p-0 m-0 flex-grow">Ton avis sera présenté à tes futures recrues. Il pourra aussi être utilisé dans l'application et dans des campagnes de communication.</p>
          <p class="m-0">{{_messageSize}}/140</p>
        </div>

        <a-alert type="warning" show-icon message="Ton message doit contenir entre 32 et 140 caractères" class="mt-1" v-if="!_messageValid"/>
      </div>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="primary" @click="_onValidate" :disabled="!_messageValid || !_nicknameValid">Créer mon lien</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
</style>
<script>
import SelectionPaneItemLine1 from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.Line1.vue";
import SelectionPaneItemLineGameData
  from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.LineGameData.vue";

export default {
  name: "NotebookGameRow",
  components: {SelectionPaneItemLineGameData, SelectionPaneItemLine1},
  props: {
    gameUID: {
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<template>
  <div class="notebook-game-row" :class="{'selected':selected}">
    <selection-pane-item-line1 :game-uid="gameUID"/>
    <selection-pane-item-line-game-data :game-uid="gameUID"/>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.notebook-game-row{
  cursor: pointer;
  &.selected {
    .line1 {
      background: -webkit-linear-gradient(0deg, @home-color, @away-color);
    }
  }
}

</style>
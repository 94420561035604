<script>
import {gameStore} from "@/stores/Game.store";

export default {
  name: "GameScore",
  props: {
    uid: {
      required: true
    },
    moment: {
      type: String,
      default: 'FT'
    }
  },

  computed: {
    _game() {
      if(!this.uid) return null;
      return gameStore().get(this.uid);
    },
    _getHomeScore() {
      if (!this._game) return '';
      switch (this.moment) {
        case 'FT':
          return this._game.scoreFtHome
        case 'HT':
        case 'P1':
          return this._game.scoreP1Home
        case 'P2':
          return this._game.scoreP2Home
        case 'P3':
          return this._game.scoreP3Home
        case 'P4':
          return this._game.scoreP4Home
      }
      return '?'
    },
    _getAwayScore() {
      if (!this._game) return '';
      switch (this.moment) {
        case 'FT':
          return this._game.scoreFtAway
        case 'HT':
        case 'P1':
          return this._game.scoreP1Away
        case 'P2':
          return this._game.scoreP2Away
        case 'P3':
          return this._game.scoreP3Away
        case 'P4':
          return this._game.scoreP4Away
      }
      return '?'
    }
  },
}
</script>

<template>
  <div class="game-score">
    {{ _getHomeScore }}/{{ _getAwayScore }}
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.game-score {
  line-height: 20px;
  padding: 0 @padding-small;
  color: @text-color;
  font-size: 9pt;
  font-family: 'SourceCodePro', serif !important;
}
</style>

<script>

export default {
  name: "KellyLlouCalculatorItem",
  data() {
    return {
      results: null,

      kelly: {
        odd: 1.0,
        probability: 50,  //%
        incertitude: 20 //%
      }
    }
  },
  computed: {
    _kellyStake() {
      if (!this.kelly.odd || !this.kelly.probability || !this.kelly.incertitude) return 0
      const b = this.kelly.odd - 1.0
      const p = this.kelly.probability / 100.0
      const q = 1 - p
      return (b * p - q) / b;
    },
    _kellyStakeFormatted() {
      if (!this._kellyStake) return '-'
      if (this._kellyStake <= 0) return '-'
      return (this._kellyStake * 100.0).toFixed(1) + '%'
    },
    _kellyIncertitudeStakeFormatted() {
      if (!this._kellyStake) return '-'
      if (this._kellyStake <= 0) return '-'
      const coef = this.kelly.odd * (this.kelly.incertitude / 100.0)
      // on applique l'incertitude
      const stake = this._kellyStake / (1.0 + coef)

      return (stake * 100.0).toFixed(1) + '%'
    },
    _expectedValueFormatted() {
      const b = this.kelly.odd - 1.0
      const p = this.kelly.probability / 100.0

      return (p * b - (1.0 - p)).toFixed(2)
    }
  },
  methods: {}
}
</script>

<template>
  <div>
    <table>
      <tr>
        <th style="width: 100px" class="text-right">Côte</th>
        <td colspan="2" class="text-right">
          <input type="number" v-model="kelly.odd" step='0.01' placeholder="Côte" style="text-align: end; width: 64px">
        </td>
      </tr>
      <tr>
        <th style="width: 100px" class="text-right">Probabilité</th>
        <td class="pl-5">
          <a-slider
              v-model:value="kelly.probability"
              :min="0" :max="100" :step="0.5"/>
        </td>
        <td style="width: 64px" class="text-right">
          <input disabled :placeholder="kelly.probability + '%'" style="text-align: end; width: 64px">
        </td>
      </tr>
      <tr>
        <th style="width: 128px" class="text-right">Mise selon Kelly</th>
        <td colspan="2" class="text-right">
          <input disabled :placeholder="_kellyStakeFormatted" style="text-align: end; width: 64px">
        </td>
      </tr>
      <tr>
        <th style="width: 100px" class="text-right">Incertitude</th>
        <td class="pl-5">
          <a-slider
              v-model:value="kelly.incertitude"
              :min="5" :max="50" :step="5"/>
        </td>
        <td style="width: 64px" class="text-right">
          <input disabled :placeholder="kelly.incertitude + '%'" style="text-align: end; width: 64px">
        </td>
      </tr>
      <tr>
        <th style="width: 128px" class="text-right">Kelly&nbsp;+&nbsp;incertitude</th>
        <td colspan="2" class="text-right">
          <input disabled :placeholder="_kellyIncertitudeStakeFormatted" style="text-align: end; width: 64px">
        </td>
      </tr>
      <tr>
        <th style="width: 128px" class="text-right">Exp. Value/Unit</th>
        <td colspan="2" class="text-right">
          <input disabled :placeholder="_expectedValueFormatted" style="text-align: end; width: 64px">
        </td>
      </tr>
    </table>
    <a-alert class="m-1" type="info"
             message="Le critère de Kelly est un outil de gestion de bankroll qui permet de déterminer la mise optimale à placer sur un pari en fonction de la côte et de la probabilité de gain."/>
  </div>
</template>

<style scoped lang="less">

</style>
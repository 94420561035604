<script>
import Hour from "@/components/common/Hour.vue";
import {gameStore} from "@/stores/Game.store";
import TeamName from "@/components/common/TeamName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import GameHour from "@/components/common/GameHour.vue";

export default {
  name: "GameRow",
  components: {GameHour, TeamLogo, TeamName, Hour},
  props: {
    uid: {
      required: true
    }
  },

  computed: {
    _game() {
      return gameStore().get(this.uid);
    },
    _getGameHomeTeamUID() {
      return gameStore().getHomeTeamUID(this._game)
    },
    _getGameAwayTeamUID() {
      return gameStore().getAwayTeamUID(this._game)
    },
  },
}
</script>

<template>
  <div class="flex">
    <team-logo :uid="_getGameHomeTeamUID"/>
    <div class="label flex-grow">
      <team-name :uid="_getGameHomeTeamUID" class="team-home"/>
      <game-hour :uid="uid"/>
      <team-name :uid="_getGameAwayTeamUID" class="team-away"/>
    </div>
    <team-logo :uid="_getGameAwayTeamUID"/>
  </div>
</template>

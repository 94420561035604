<script>
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

// Stores
import {perspectiveStore} from "@/stores/Perspective.store";
import {leagueStore} from "@/stores/League.store";
import {contextStore} from "@/stores/Context.store";
import {gameStore} from "@/stores/Game.store";
import {modalStore} from "@/stores/Modal.store";


export default {
  name: "WidgetLeagueParamButton",
  components: {FontAwesomeIcon, LeagueLogo},
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  methods: {
    _openSettingsModal() {
      modalStore().openModal({
        uid: 'widgetSettingsModal', payload: {
          uid: this.widget.uid
        }
      })
    },
  },
  computed: {
    _key() {
      return 'league'
    },
    _value() {
      return perspectiveStore().getParameterValue(this.widget.uid, this._key)
    },
    _game() {
      return gameStore().get(contextStore().gameUID)
    },
    _leagueUID() {
      if (!this._value) return null;
      switch (this._value) {
        case 'ANY':
          return null
        case 'CURRENT':
          if (!this._game) return null;
          return this._game.leagueUID
      }
    },
    _tooltipTitle() {
      switch (this._value) {
        case 'ANY':
          return 'Toutes'
        case 'CURRENT':
          if(!this._game) return 'Compétition du match';
          let league = leagueStore().get(this._leagueUID);
          if (!league) return '?';
          return league.name
      }
    }
  }
}
</script>

<template>
  <a-tooltip placement="top" :title="_tooltipTitle">
    <button class="square small no-radius" @click="_openSettingsModal">
      <league-logo :uid="_leagueUID" size="small" v-if="_game"/>
      <font-awesome-icon v-else-if="_value === 'ANY'" icon="globe"/>
      <font-awesome-icon v-else icon="futbol"/>
    </button>
  </a-tooltip>
</template>

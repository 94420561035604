<script>
import axiosService from "@/services/axios.service";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import {statStore} from "@/stores/Statistic.store";
import TeamName from "@/components/common/TeamName.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import IndicateurCircle from "@/components/common/IndicateurCircle.vue";
import StatisticValueButton from "@/components/common/StatisticValueButton.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import YoutubeButton from "@/components/common/YoutubeButton.vue";

export default {
  name: "SelectionPaneItem.Forme.Tab",
  components: {YoutubeButton, StatisticValueButton, IndicateurCircle, LeagueLogo, TeamName, TeamLogo, GameResultCircle},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      timeoutSaveTolerance: null,
      filters: {
        tolerance: 0.15
      },

      data: null
    }
  },

  beforeMount() {

    const payload = {
      uid: this.gameUid,
    }
    axiosService.put('/dta/game/basic-data/goals', payload).then(response => {
      this.data = response.data

      let tolerance = preferencesStore().getFirstValue('SELECTION_GOALS_TAB', 'POINTS_TOLERANCE_' + this._sport);
      if (tolerance) {
        this.filters.tolerance = parseFloat(tolerance)
      } else {
        preferencesStore().save('SELECTION_GOALS_TAB', 'POINTS_TOLERANCE_' + this._sport, [this.filters.tolerance])
      }
    })
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid)
    },
    _homeTeamUID() {
      if(!this._game)
        return null

      return this._game.homeTeamUID
    },
    _awayTeamUID() {
      if(!this._game)
        return null

      return this._game.awayTeamUID
    },
    _sport() {
      if (!this._game) return null
      return this._game.sport
    },
    _homeForStat() {
      if (!this.data) return null
      return statStore().get(this.data.homeForStatUID)
    },
    _homeForAtHomeStat() {
      if (!this.data) return null
      return statStore().get(this.data.homeForAtHomeStatUID)
    },
    _awayForStat() {
      if (!this.data) return null
      return statStore().get(this.data.awayForStatUID)
    },
    _awayForAtAwayStat() {
      if (!this.data) return null
      return statStore().get(this.data.awayForAtAwayStatUID)
    },

    _homeAgainstStat() {
      if (!this.data) return null
      return statStore().get(this.data.homeAgainstStatUID)
    },
    _awayAgainstStat() {
      if (!this.data) return null
      return statStore().get(this.data.awayAgainstStatUID)
    },
    _homeAgainstAtHomeStat() {
      if (!this.data) return null
      return statStore().get(this.data.homeAgainstAtHomeStatUID)
    },
    _awayAgainstAtAwayStat() {
      if (!this.data) return null
      return statStore().get(this.data.awayAgainstAtAwayStatUID)
    },
    _scoreAbsHome() {
      let score = 0.0
      const periods = ['CURRENT_SEASON', 'LAST_5_GAMES', 'LAST_10_GAMES']

      // iterate over periods
      for (let index in periods) {
        let period = periods[index]
        let diff = this._getIndicateurValue(this._homeForStat, this._awayForStat, period)
        if (diff > 0) score++

        diff = this._getIndicateurValue(this._homeForAtHomeStat, this._awayForAtAwayStat, period)
        if (diff > 0) score++

        diff = this._getIndicateurValue(this._awayAgainstStat, this._homeAgainstStat, period)
        if (diff > 0) score++

        diff = this._getIndicateurValue(this._awayAgainstAtAwayStat, this._homeAgainstAtHomeStat, period)
        if (diff > 0) score++

      }

      return score
    },

    _scoreAbsAway() {
      let score = 0.0
      const periods = ['CURRENT_SEASON', 'LAST_5_GAMES', 'LAST_10_GAMES']

      // iterate over periods
      for (let index in periods) {
        let period = periods[index]
        let diff = this._getIndicateurValue(this._awayForStat, this._homeForStat, period)
        if (diff > 0) score++

        diff = this._getIndicateurValue(this._awayForAtAwayStat, this._homeForAtHomeStat, period)
        if (diff > 0) score++

        diff = this._getIndicateurValue(this._homeAgainstStat, this._awayAgainstStat, period)
        if (diff > 0) score++

        diff = this._getIndicateurValue(this._homeAgainstAtHomeStat, this._awayAgainstAtAwayStat, period)
        if (diff > 0) score++
      }

      return score
    },

    _scoreDiffHome() {
      let score = 0.0
      const periods = ['CURRENT_SEASON', 'LAST_5_GAMES', 'LAST_10_GAMES']

      // iterate over periods
      for (let index in periods) {
        let period = periods[index]
        let diff = this._getIndicateurValue(this._homeForStat, this._awayForStat, period)
        if (diff > 0) score += this._getAbsDiff(this._homeForStat, this._awayForStat, period)

        diff = this._getIndicateurValue(this._homeForAtHomeStat, this._awayForAtAwayStat, period)
        if (diff > 0) score += this._getAbsDiff(this._homeForAtHomeStat, this._awayForAtAwayStat, period)

        diff = this._getIndicateurValue(this._awayAgainstStat, this._homeAgainstStat, period)
        if (diff > 0) score += this._getAbsDiff(this._awayAgainstStat, this._homeAgainstStat, period)

        diff = this._getIndicateurValue(this._awayAgainstAtAwayStat, this._homeAgainstAtHomeStat, period)
        if (diff > 0) score += this._getAbsDiff(this._awayAgainstAtAwayStat, this._homeAgainstAtHomeStat, period)

      }

      return score.toFixed(1)
    },

    _scoreDiffAway() {
      let score = 0.0
      const periods = ['CURRENT_SEASON', 'LAST_5_GAMES', 'LAST_10_GAMES']

      // iterate over periods
      for (let index in periods) {
        let period = periods[index]
        let diff = this._getIndicateurValue(this._awayForStat, this._homeForStat, period)
        if (diff > 0) score += this._getAbsDiff(this._awayForStat, this._homeForStat, period)

        diff = this._getIndicateurValue(this._awayForAtAwayStat, this._homeForAtHomeStat, period)
        if (diff > 0) score += this._getAbsDiff(this._awayForAtAwayStat, this._homeForAtHomeStat, period)

        diff = this._getIndicateurValue(this._homeAgainstStat, this._awayAgainstStat, period)
        if (diff > 0) score += this._getAbsDiff(this._homeAgainstStat, this._awayAgainstStat, period)

        diff = this._getIndicateurValue(this._homeAgainstAtHomeStat, this._awayAgainstAtAwayStat, period)
        if (diff > 0) score += this._getAbsDiff(this._homeAgainstAtHomeStat, this._awayAgainstAtAwayStat, period)
      }

      return score.toFixed(1)
    },
  },

  methods: {

    _getAbsDiff(statA, statB, period) {
      const a = parseFloat(statStore().getValue(statA, period))
      const b = parseFloat(statStore().getValue(statB, period))

      return Math.abs(a - b)
    },

    _getIndicateurValue(statA, statB, period) {
      if (!statA || !statB) return 0.0

      const a = parseFloat(statStore().getValue(statA, period))
      const b = parseFloat(statStore().getValue(statB, period))

      // calcule % de différence
      const A = Math.max(a, b)
      const B = Math.min(a, b)

      const diff = (A - B) / B

      if (diff > this.filters.tolerance) {
        if (A === a) {
          return diff
        }
        return -diff
      }

      return 0
    },
    _getIndicateurType(statA, statB, period) {
      const diff = this._getIndicateurValue(statA, statB, period)

      if (diff > 0) {
        return 'positive'
      } else if (diff < 0) {
        return 'negative'
      }

      return 'neutral'
    },
    _onToleranceChanged() {
      clearTimeout(this.timeoutSaveTolerance)
      this.timeoutSaveTolerance = setTimeout(() => {
        preferencesStore().save('SELECTION_GOALS_TAB', 'POINTS_TOLERANCE_' + this._sport, [this.filters.tolerance])
      }, 250)
    }
  }
}
</script>

<template>
  <div>
    <div class="flex">
      <fieldset class="flex-grow m-1 text-left p-0">
        <table>
          <tr>
            <th colspan="2">
              Tolerance
            </th>
          </tr>
          <tr>
            <td style="width: 32px">
              {{ (filters.tolerance * 100.0).toFixed(0) }}%
            </td>
            <td style="padding: 0 8px 0 4px !important;">
              <a-slider
                  v-model:value="filters.tolerance"
                  @after-change="_onToleranceChanged"
                  :min="0.1" :max="0.5" :step="0.05"/>
            </td>
          </tr>
        </table>
      </fieldset>
      <fieldset class="my-1 mr-1 text-left p-0 legend-left">
        <table class="border-left" style="height: 100%;">
          <tr>
            <th rowspan="2">
              Bilan
            </th>
            <th colspan="2" class="border-left">
              Abs.
            </th>
            <th colspan="2" class="border-left">
              Diff.
            </th>
          </tr>
          <tr>
            <td style="width: 32px" class="text-center border-left">
              {{ _scoreAbsHome }}
            </td>
            <td style="width: 32px" class="text-center border-left">
              {{ _scoreAbsAway }}
            </td>

            <td style="width: 32px" class="text-center border-left">
              {{ _scoreDiffHome }}
            </td>
            <td style="width: 32px" class="text-center border-left">
              {{ _scoreDiffAway }}
            </td>

          </tr>
        </table>
      </fieldset>
    </div>
    <table>
      <tr>
        <th colspan="1">Att - Saison</th>
        <th colspan="1">
          <team-logo :uid="_game.homeTeamUID" size="small"/>
        </th>
        <th colspan="1">
          <team-logo :uid="_game.awayTeamUID" size="small"/>
        </th>
      </tr>
      <tr>
        <td>
          Saison
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeForStat" period="CURRENT_SEASON" class="mx-1"/>
            <indicateur-circle :type="_getIndicateurType(_homeForStat, _awayForStat, 'CURRENT_SEASON')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle :type="_getIndicateurType(_awayForStat, _homeForStat, 'CURRENT_SEASON')"/>
            <statistic-value-button :stat="_awayForStat" period="CURRENT_SEASON" class="mx-1"/>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          L5
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeForStat" period="LAST_5_GAMES" class="mx-1"/>
            <indicateur-circle :type="_getIndicateurType(_homeForStat, _awayForStat, 'LAST_5_GAMES')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle :type="_getIndicateurType(_awayForStat, _homeForStat, 'LAST_5_GAMES')"/>
            <statistic-value-button :stat="_awayForStat" period="LAST_5_GAMES" class="mx-1"/>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          L10
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeForStat" period="LAST_10_GAMES" class="mx-1"/>
            <indicateur-circle :type="_getIndicateurType(_homeForStat, _awayForStat, 'LAST_10_GAMES')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle :type="_getIndicateurType(_awayForStat, _homeForStat, 'LAST_10_GAMES')"/>
            <statistic-value-button :stat="_awayForStat" period="LAST_10_GAMES" class="mx-1"/>
          </div>
        </td>
      </tr>


      <!-- Attaque - Dependence du lieu -->
      <tr>
        <th colspan="1">Att - Dom/Ext</th>
        <th colspan="1">
          <team-logo :uid="_homeTeamUID" size="small"/>
        </th>
        <th colspan="1">
          <team-logo :uid="_awayTeamUID" size="small"/>
        </th>
      </tr>
      <tr>
        <td>
          Saison
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeForAtHomeStat" period="CURRENT_SEASON" class="mx-1"/>
            <indicateur-circle :type="_getIndicateurType(_homeForAtHomeStat, _awayForAtAwayStat, 'CURRENT_SEASON')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle :type="_getIndicateurType(_awayForAtAwayStat, _homeForAtHomeStat, 'CURRENT_SEASON')"/>
            <statistic-value-button :stat="_awayForAtAwayStat" period="CURRENT_SEASON" class="mx-1"/>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          L5
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeForAtHomeStat" period="LAST_5_GAMES" class="mx-1"/>
            <indicateur-circle :type="_getIndicateurType(_homeForAtHomeStat, _awayForAtAwayStat, 'LAST_5_GAMES')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle :type="_getIndicateurType(_awayForAtAwayStat, _homeForAtHomeStat, 'LAST_5_GAMES')"/>
            <statistic-value-button :stat="_awayForAtAwayStat" period="LAST_5_GAMES" class="mx-1"/>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          L10
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeForAtHomeStat" period="LAST_10_GAMES" class="mx-1"/>
            <indicateur-circle :type="_getIndicateurType(_homeForAtHomeStat, _awayForAtAwayStat, 'LAST_10_GAMES')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle :type="_getIndicateurType(_awayForAtAwayStat, _homeForAtHomeStat, 'LAST_10_GAMES')"/>
            <statistic-value-button :stat="_awayForAtAwayStat" period="LAST_10_GAMES" class="mx-1"/>
          </div>
        </td>
      </tr>

      <!-- Defense - Sans lien avec le lieu -->
      <tr>
        <th colspan="1">Defense - Saison</th>
        <th colspan="1">
          <team-logo :uid="_game.homeTeamUID" size="small"/>
        </th>
        <th colspan="1">
          <team-logo :uid="_game.awayTeamUID" size="small"/>
        </th>
      </tr>
      <tr>
        <td>
          Saison
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeAgainstStat" period="CURRENT_SEASON" class="mx-1"/>
            <indicateur-circle :type="_getIndicateurType( _awayAgainstStat, _homeAgainstStat,'CURRENT_SEASON')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle :type="_getIndicateurType( _homeAgainstStat, _awayAgainstStat,'CURRENT_SEASON')"/>
            <statistic-value-button :stat="_awayAgainstStat" period="CURRENT_SEASON" class="mx-1"/>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          L5
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeAgainstStat" period="LAST_5_GAMES" class="mx-1"/>
            <indicateur-circle :type="_getIndicateurType(_awayAgainstStat, _homeAgainstStat, 'LAST_5_GAMES')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle :type="_getIndicateurType( _homeAgainstStat, _awayAgainstStat,'LAST_5_GAMES')"/>
            <statistic-value-button :stat="_awayAgainstStat" period="LAST_5_GAMES" class="mx-1"/>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          L10
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeAgainstStat" period="LAST_10_GAMES" class="mx-1"/>
            <indicateur-circle :type="_getIndicateurType(_awayAgainstStat, _homeAgainstStat, 'LAST_10_GAMES')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle :type="_getIndicateurType(_homeAgainstStat, _awayAgainstStat, 'LAST_10_GAMES')"/>
            <statistic-value-button :stat="_awayAgainstStat" period="LAST_10_GAMES" class="mx-1"/>
          </div>
        </td>
      </tr>

      <!-- Defense - Avec lien avec le lieu -->
      <tr>
        <th colspan="1">Defense - Dom/Ext</th>
        <th colspan="1">
          <team-logo :uid="_game.homeTeamUID" size="small"/>
        </th>
        <th colspan="1">
          <team-logo :uid="_game.awayTeamUID" size="small"/>
        </th>
      </tr>
      <tr>
        <td>
          Saison
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeAgainstAtHomeStat" period="CURRENT_SEASON" class="mx-1"/>
            <indicateur-circle
                :type="_getIndicateurType( _awayAgainstAtAwayStat, _homeAgainstAtHomeStat,'CURRENT_SEASON')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle
                :type="_getIndicateurType( _homeAgainstAtHomeStat, _awayAgainstAtAwayStat,'CURRENT_SEASON')"/>
            <statistic-value-button :stat="_awayAgainstAtAwayStat" period="CURRENT_SEASON" class="mx-1"/>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          L5
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeAgainstAtHomeStat" period="LAST_5_GAMES" class="mx-1"/>
            <indicateur-circle
                :type="_getIndicateurType(_awayAgainstAtAwayStat, _homeAgainstAtHomeStat, 'LAST_5_GAMES')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle
                :type="_getIndicateurType( _homeAgainstAtHomeStat, _awayAgainstAtAwayStat,'LAST_5_GAMES')"/>
            <statistic-value-button :stat="_awayAgainstAtAwayStat" period="LAST_5_GAMES" class="mx-1"/>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          L10
        </td>
        <td>
          <div class="flex justify-center items-center">
            <statistic-value-button :stat="_homeAgainstAtHomeStat" period="LAST_10_GAMES" class="mx-1"/>
            <indicateur-circle
                :type="_getIndicateurType(_awayAgainstAtAwayStat, _homeAgainstAtHomeStat, 'LAST_10_GAMES')"/>
          </div>
        </td>
        <td>
          <div class="flex justify-center items-center">
            <indicateur-circle
                :type="_getIndicateurType(_homeAgainstAtHomeStat, _awayAgainstAtAwayStat, 'LAST_10_GAMES')"/>
            <statistic-value-button :stat="_awayAgainstAtAwayStat" period="LAST_10_GAMES" class="mx-1"/>
          </div>
        </td>
      </tr>

    </table>
    <hr/>
    <div class="flex p-2">
      <p class="pr-2 m-0 flex-grow">⚔️ Retrouve ici toutes les stats offensives et défensives des deux équipes ! 📊 Compare leurs forces et faiblesses pour affiner tes paris ! 🎯</p>
      <youtube-button video-id="uwdTfR8wVmM"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";
</style>
<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import BlogAvisClient from "@/components/routes/blog/BlogAvisClient.vue";

export default {
  name: "CommentChoisirCote",
  components: {BlogAvisClient, BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif';
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Analyse les stats, maximise tes chances"
             class="w-full"/>
      </div>
    </div>


    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET s’adresse à tous ceux qui veulent passer au niveau supérieur dans leurs paris sportifs. Que tu sois un
        novice ou un pro, cet outil est là pour t’aider à parier avec plus de précision et de confiance.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>


    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Comprendre pour mieux gagner</h3>
      <p class="px-5">Les paris sportifs, c’est bien plus qu’une simple intuition. Pour vraiment gagner, il faut
        comprendre ce qui se cache derrière les cotes. En analysant les statistiques, tu décryptes les performances des
        équipes, les tendances des joueurs, et les dynamiques des matchs. Ne laisse plus le hasard décider pour toi.</p>
    </div>

    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Fais parler les chiffres</h3>
      <p class="px-5">Les chiffres ne mentent pas. Ils révèlent des patterns, des forces, et des faiblesses que l'œil ne
        voit pas toujours. Apprends à lire entre les lignes des statistiques, et découvre comment utiliser ces
        informations pour parier intelligemment. Tes gains dépendent des choix que tu fais, alors fais les bons.</p>
    </div>

    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Le secret des parieurs gagnants</h3>
      <p class="px-5">
        Les parieurs les plus performants ont un point commun : ils s’appuient sur l’analyse. En intégrant les statistiques dans tes stratégies, tu passes d’un simple amateur à un parieur éclairé. C’est cette différence qui peut transformer ta passion en une source de gains réguliers.
      </p>
    </div>

    <blog-see-more/>

    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Gagne du temps, gagne en précision</h3>
      <p class="px-5">Analyser les stats manuellement, c’est long et complexe. Mais avec le bon outil, tu peux accéder en quelques clics à des analyses détaillées, prêtes à l’emploi. Ne perds plus de temps à chercher des informations, DoctoBET te les offre sur un plateau, pour que tu te concentres sur l’essentiel : gagner.</p>
    </div>


    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">DoctoBET, ton allié statistique</h3>
      <p class="px-5">
        Avec DoctoBET, tu maximises tes chances de succès. Notre outil te donne accès aux meilleures données, analysées par des algorithmes avancés, pour que tu puisses parier en toute confiance. Ne laisse plus rien au hasard, prends le contrôle de tes paris avec DoctoBET. Mets toutes les chances de ton côté et transforme tes pronostics en gains.
      </p>
    </div>

    <blog-avis-client/>


  </div>
</template>

<style scoped lang="less">

</style>
<script>
import {GridItem, GridLayout} from 'vue3-grid-layout-next'


import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

// Widgets
import OddRankSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/OddRankSuccessRate.Widget.vue";
import LeagueSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/LeagueSuccessRate.Widget.vue";
import ScoreSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/ScoreSuccessRate.Widget.vue";
import TotalGoalsSuccessRateWidget
  from "@/components/routes/bankroll/panes/analyse/widgets/TotalGoalsSuccessRate.Widget.vue";
import TeamSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/TeamSuccessRate.Widget.vue";
import AreaSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/AreaSuccessRate.Widget.vue";
import DayOfWeekSuccessRateWidget
  from "@/components/routes/bankroll/panes/analyse/widgets/DayOfWeekSuccessRate.Widget.vue";

export default {
  name: "AnalyseBankrollPerspective",
  components: {
    FontAwesomeIcon,
    GridItem, GridLayout,

    // Widgets
    OddRankSuccessRateWidget,
    LeagueSuccessRateWidget,
    ScoreSuccessRateWidget,
    TotalGoalsSuccessRateWidget,
    TeamSuccessRateWidget,
    AreaSuccessRateWidget,
    DayOfWeekSuccessRateWidget
  },
  computed: {
    _widgets() {
      return [
        {
          uid: 'odd-rank-success-rate-widget',
          type: 'OddRankSuccessRateWidget',
          x: 0, y: 0,
          dimensions: {
            width: 24, height: 18
          }
        },
        {
          uid: 'league-success-rate-widget',
          type: 'LeagueSuccessRateWidget',
          x: 24, y: 0,
          dimensions: {
            width: 32, height: 18
          }
        },
        {
          uid: 'score-success-rate-widget',
          type: 'ScoreSuccessRateWidget',
          x: 0, y: 18,
          dimensions: {
            width: 24, height: 18
          }
        },
        {
          uid: 'total-goals-success-rate-widget',
          type: 'TotalGoalsSuccessRateWidget',
          x: 24, y: 18,
          dimensions: {
            width: 24, height: 18
          }
        },
        {
          uid: 'area-success-rate-widget',
          type: 'AreaSuccessRateWidget',
          x: 0, y: 36,
          dimensions: {
            width: 24, height: 18
          }
        },
        {
          uid: 'team-success-rate-widget',
          type: 'TeamSuccessRateWidget',
          x: 24, y: 36,
          dimensions: {
            width: 32, height: 18
          }
        },
        {
          uid: 'day-of-week-success-rate-widget',
          type: 'DayOfWeekSuccessRateWidget',
          x: 0, y: 54,
          dimensions: {
            width: 24, height: 14
          }
        }
      ]
    },
    _layout() {
      const layout = []
      if (!this._widgets) return layout

      this._widgets.forEach((widget) => {
        layout.push({
          'i': widget.uid,
          'uid': widget.uid,
          'x': widget.x, 'y': widget.y,
          'w': widget.dimensions.width, 'h': widget.dimensions.height,
          'type': widget.type
        })
      })
      return layout
    }
  },
  methods: {
    _onLayoutUpdated(layout) {
      // console.log('layout updated', layout)
    }
  }
}
</script>

<template>
  <div id="analyse-bankroll-perspective-wrapper">
    <div id="analyse-bankroll-perspective">
      <grid-layout :layout.sync='_layout'
                   :col-num='128'
                   :is-draggable='false'
                   :row-height='8'
                   :is-resizable='false'
                   :vertical-compact='false'
                   :use-css-transforms='true'
                   @layout-updated="_onLayoutUpdated">
        <grid-item v-for='item in _layout' class="widget"
                   :x='item.x'
                   :y='item.y'
                   :w='item.w'
                   :h='item.h'
                   :i='item.i'>
          <component :is="item.type" :item="item"/>
        </grid-item>
      </grid-layout>
    </div>
  </div>

</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#analyse-bankroll-perspective-wrapper {
  height: calc(100dvh - @toolbar-regular);
  width: calc(100vw - 400px);
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;

  #analyse-bankroll-perspective {
    height: 2096px;
    width: 2096px;
    overflow: hidden;
  }
}

#empty-bankroll-perspective {
  margin: auto;
  width: calc(100vw - 400px);

  p {
    margin: 200px auto @padding-large auto;
    max-width: 400px;
    text-align: center;
    font-size: 2em !important;
    color: @text-color;
    opacity: 0.1;
  }
}

</style>
// stores/counter.js
import {defineStore} from 'pinia'
import {MD5} from '@/plugins/md5/md5'

export const colorStore = defineStore('colorStore', {
    state: () => {
        return {
            defaultColor: '#a6a2a2',
            colors: [
                // Red
                '#ef9a9a',
                '#e57373',
                '#ef5350',
                '#e53935',
                '#c62828',
                '#b71c1c',
                // Pink
                '#f48fb1',
                '#f06292',
                '#ec407a',
                '#d81b60',
                '#ad1457',
                '#880e4f',
                // Deep Purple
                '#b39ddb',
                '#9575cd',
                '#7e57c2',
                '#5e35b1',
                '#4527a0',
                '#311b92',
                // Indigo
                '#9fa8da',
                '#7986cb',
                '#5c6bc0',
                '#3949ab',
                '#283593',
                '#1a237e',
                // Blue
                '#90caf9',
                '#64b5f6',
                '#42a5f5',
                '#1e88e5',
                '#1565c0',
                '#0d47a1',
                // Cyan
                '#80deea',
                '#4dd0e1',
                '#26c6da',
                '#00acc1',
                '#00838f',
                '#006064',
                // Teal
                '#80cbc4',
                '#4db6ac',
                '#26a69a',
                '#00897b',
                '#00695c',
                '#004d40',
                // Green
                '#a5d6a7',
                '#81c784',
                '#66bb6a',
                '#43a047',
                '#2e7d32',
                '#1b5e20',
                // Light Green
                '#c5e1a5',
                '#aed581',
                '#9ccc65',
                '#7cb342',
                '#558b2f',
                '#33691e',
                // Lime
                '#e6ee9c',
                '#dce775',
                '#d4e157',
                '#c0ca33',
                '#9e9d24',
                '#827717',
                // Yellow
                '#fff59d',
                '#fff176',
                '#ffee58',
                '#fdd835',
                '#f9a825',
                '#f57f17',
                // Orange
                '#ffcc80',
                '#ffb74d',
                '#ffa726',
                '#fb8c00',
                '#ef6c00',
                '#e65100',
                // Deep Orange
                '#ffab91',
                '#ff8a65',
                '#ff7043',
                '#f4511e',
                '#d84315',
                '#bf360c',
                // Brown
                '#bcaaa4',
                '#a1887f',
                '#8d6e63',
                '#6d4c41',
                '#4e342e',
                '#3e2723',
                // Blue Grey
                '#b0bec5',
                '#90a4ae',
                '#78909c',
                '#546e7a',
                '#37474f',
                '#263238',
                // Grey
                '#e0e0e0',
                '#bdbdbd',
                '#757575',
                '#424242',
                '#212121',
                '#121212',
            ]
        }
    },
    actions: {},
    getters: {

        get: (state) => (key) => {
            if (!key) {
                return state.defaultColor
            }
            const _md5 = new MD5()
            // On ne prend que 10 premiers caractère pour éviter les limites des entiers en JS.
            const _md5Sum = _md5.of(key.toString()).substring(0, 10)
            return state.colors[parseInt(_md5Sum, 16) % state.colors.length]
        },

        /**
         * Retourne blanc ou noir en fonction de la couleur de fond
         * @param state
         * @returns {(function(*): (string|*))|*}
         */
        getContrastColor: (state) => (key) => {
            if (!key) {
                return '#000000'
            }
            const _md5 = new MD5()
            // On ne prend que 10 premiers caractère pour éviter les limites des entiers en JS.
            const _md5Sum = _md5.of(key.toString()).substring(0, 10)
            const _color = state.colors[parseInt(_md5Sum, 16) % state.colors.length]
            const _r = parseInt(_color.substring(1, 2), 16)
            const _g = parseInt(_color.substring(3, 2), 16)
            const _b = parseInt(_color.substring(5, 2), 16)
            const _yiq = ((_r * 299) + (_g * 587) + (_b * 114)) / 1000
            return (_yiq >= 128) ? '#000000' : '#ffffff'
        }
    }
})
<script>
import {modalStore} from "@/stores/Modal.store";
import axiosService from "@/services/axios.service";
import {preferencesStore} from "@/stores/Preferences.store";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";

export default {
  name: "TicketGeneratorModal",
  data() {
    return {
      wantToAccept: false,

      maxBets: 3,
      targetOdd: 4.1
    }
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'ticketGeneratorModal';
    },

    _payload() {
      if (!this._isVisible) return null;
      const payload = modalStore().getVisibleModal.payload
      if (!payload) return null;
      return payload;
    },

    _betCandidateUIDs() {
      if (!this._payload) return null;
      return this._payload.betCandidateUIDs;
    },

    _acceptedConditions() {
      return preferencesStore().getBoolean('TICKET_GENERATOR', 'CONDITION_ACCEPTED', false)
    },

    _paramsValid() {
      return this.maxBets >= 1 && this.targetOdd > 1.00
    },
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _acceptCondition() {
      preferencesStore().save('TICKET_GENERATOR', 'CONDITION_ACCEPTED', [true])
    },
    _cleanMaxBets() {
      // Ensure maxBets is an integer
      this.maxBets = parseInt(this.maxBets)
      // And positive
      if (this.maxBets < 1) this.maxBets = 1
      // And not too high
      if (this.maxBets > 10) this.maxBets = 10
    },
    _cleanTargetOdd() {
      // Ensure targetOdd is a float
      this.targetOdd = parseFloat(this.targetOdd)
      // And positive
      if (this.targetOdd < 1.00) this.targetOdd = 1.00
      // And not too high
      if (this.targetOdd > 100.00) this.targetOdd = 100.00
    },
    _generateTicket() {

      // On save the preferences
      preferencesStore().save('TICKET_GENERATOR', 'MAX_BETS', [this.maxBets])
      preferencesStore().save('TICKET_GENERATOR', 'TARGET_ODD', [this.targetOdd])

      const payload = {
        betCandidateUIDs: this._betCandidateUIDs,
        maxBets: this.maxBets,
        targetOdd: this.targetOdd
      }
      axiosService.post('/ticket/generate', payload).then(response => {
        if(!response.data.result){
          message.warn('DoctoBET n\'a pas réussi à générer un ticket avec les paramètres donnés')
          return
        }
        modalStore().openModal({uid: 'paperTicketModal', payload: response.data})
      }).catch(error => {
        message.error('Erreur lors de la génération du ticket')
      })
    }
  },
  watch: {
    _isVisible() {
      if (this._isVisible) {
        this.maxBets = preferencesStore().getInt('TICKET_GENERATOR', 'MAX_BETS', 1)
        this.targetOdd = preferencesStore().getFloat('TICKET_GENERATOR', 'TARGET_ODD', 1.75)
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="400" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Générateur de ticket
    </header>
    <div v-if="!_acceptedConditions" class="m-2">
      <h2 class="text-left m-1 mb-5">Conditions d'utilisation de la fonctionnalité de génération de tickets</h2>
      <fieldset class="m-1 pb-1 no-border">
        <legend class="p-0 m-0">Utilisation</legend>
        <p>La fonctionnalité de génération de tickets est une fonction fun. Les tickets générés ne sont pas
          garantis gagnants.</p>
      </fieldset>
      <fieldset class="m-1 pb-1 no-border">
        <legend class="p-0 m-0">Responsabilité</legend>
        <p>DoctoBET ne peut en aucun cas être tenu responsable des pertes financières ou autres dommages résultant de
          l'utilisation de la fonctionnalité de génération de tickets.</p>
      </fieldset>
      <fieldset class="m-1 pb-1 no-border">
        <legend class="p-0 m-0">Nature des tickets générés</legend>
        <p>Les tickets générés par cette fonctionnalité n'ont pas valeur de conseil en matière de paris sportifs. Ils
          sont fournis à titre informatif et ne constituent en aucun cas une recommandation de pari.</p>
      </fieldset>
      <fieldset class="m-1 pb-1 no-border">
        <legend class="p-0 m-0">Devoir d'analyse</legend>
        <p>Il est de votre responsabilité d'analyser et de vérifier les propositions avant de les valider chez un
          bookmaker. DoctoBET encourage les utilisateurs à faire preuve de discernement et à consulter d'autres sources
          d'information avant de prendre toute décision.</p>
      </fieldset>
      <fieldset class="m-1 pb-1 no-border">
        <legend class="p-0 m-0">Acceptation des conditions</legend>
        <p>En cochant la case ci-dessous, vous reconnaissez avoir pris connaissance des conditions d'utilisation de la
          fonctionnalité de génération de tickets et les acceptez.</p>
        <label>
          <input type="checkbox" v-model="wantToAccept" style="height: 12px;">
          J'ai compris et j'accepte les conditions
        </label>
      </fieldset>
    </div>
    <div v-else>
      <fieldset class="m-2 no-border">
        <div class="flex">
          <input type="number" v-model="maxBets" @keyup="_cleanMaxBets" style="width: 48px" class="text-center">
          <legend class="m-0 ml-2 p-0 flex-grow" style="line-height: 24px">paris maximum.</legend>
        </div>
        <p>Combien de paris souhaites-tu avoir au maximum sur ton ticket ? Rappelles-toi que plus un ticket comporte de
          paris plus il a de chance d'être perdant.</p>
      </fieldset>
      <fieldset class="m-2 no-border">
        <div class="flex">
          <input type="number" v-model="targetOdd" @keyup="_cleanTargetOdd" style="width: 48px" class="text-center">
          <legend class="m-0 ml-2 p-0 flex-grow" style="line-height: 24px">de côte totale.</legend>
        </div>
        <p>Quelle côte le ticket doit-il avoir ? Ici encore, une côte plus élevée sous-entend une probabilité plus faible et donc un risque plus grand.</p>
      </fieldset>
    </div>
    <template #footer>
      <div class="my-2 mx-1 flex">
        <button class="info" @click="_onCancel">Retour</button>
        <div class="flex-grow"/>
        <button class="primary" v-if="!_acceptedConditions" :disabled="!wantToAccept" @click="_acceptCondition">J'ai lu et compris les conditions</button>
        <button class="primary" v-if="_acceptedConditions" :disabled="!_paramsValid" @click="_generateTicket">Générer un ticket</button>
      </div>
    </template>
  </AModal>
</template>

<style scoped lang="less">

</style>
<script>
import Hour from "@/components/common/Hour.vue";

export default {
  name: "PlayerRole",
  components: {Hour},
  props: {
    role: {
      required: true
    }
  },
  computed:{
    
  }
}
</script>

<template>
  <div class="player-role-wrapper">
    <div class="player-role">
      {{ role }}
    </div>
  </div>
</template>

<style lang="less">
  @import "@/assets/styles/variables.less";

  @player-role-height: 20px;

  .player-role-wrapper{
    overflow: hidden;
    background-color: @background-color;
    border: 1px solid @stroke-color;
    border-radius: @radius-regular;
    height: @player-role-height;
    min-height: @player-role-height;
    max-height: @player-role-height;
    .player-role{
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: @player-role-height;
      overflow: hidden;
      font-size: 8pt;
      color: #ccc;
      padding: 0 @padding-small;
    }
  }
</style>

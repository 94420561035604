// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";

export const noteStore = defineStore('noteStore', {
    state: () => {
        return {
            messages: {}    // {
        }
    },
    actions: {
        // Permet de récupérer la note d'un game
        fetchByGameUID(uid) {
            if (!uid) return

            // Si la note est déjà chargée, on ne la recharge pas
            if (this.messages[uid]) {
                return
            }

            axiosService.get('/note?gameUID=' + uid).then(response => {
                this.messages[uid] = response.data.result
            });
        },

        update(gameUID, content) {
            if (!gameUID) return
            axiosService.post('/note', {
                gameUID: gameUID,
                content: content
            }).then(response => {
                this.messages[gameUID] = response.data.result
            });
        }
    },
    getters: {
        getByGameUID: (state) => (uid) => {

            // si la note n'est pas chargée, on la charge
            if (!state.messages[uid]) {
                noteStore().fetchByGameUID(uid)
                return
            }

            return state.messages[uid]
        }
    }
})
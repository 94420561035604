<script>
import {modalStore} from "@/stores/Modal.store";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import axiosService from "@/services/axios.service";
import {statStore} from "@/stores/Statistic.store";
import TeamName from "@/components/common/TeamName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";
import PlayerName from "@/components/common/PlayerName.vue";


export default {
  name: "RawStatsModal",
  components: {PlayerName, LeagueLogo, TeamLogo, TeamName},
  data() {
    return {
      data: null
    }
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'rawStatsModal';
    },

    _payload() {
      if (!this._isVisible) return null;
      const payload = modalStore().getVisibleModal.payload
      if (!payload) return null;
      return payload;
    },

    _widgetUID() {
      if (!this._payload) return null;
      return this._payload.widgetUID;
    },

    _statFixedUID() {
      if (!this._payload) return null;
      return this._payload.statFixedUID;
    },

    _stat(){
      return statStore().getDefinitionByFixedUID(this._statFixedUID)
    },

    _statScope() {
      if (!this._stat) return null;
      return this._stat.scope;
    },

    _statLabel() {
      if (!this._stat) return '...'
      return this._stat.name
    },

    _sortedData() {
      if (!this.data) return []
      return this.data.sort((a, b) => {
        if (b.absolute - a.absolute) return 1
        if (a.absolute - b.absolute) return -1

        return a.gameDate - b.gameDate
      })
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal()
    },
    _refresh() {
      const payload = statsRouteStore().buildBaseWidgetPayload()
      payload.statFixedUID = this._statFixedUID
      payload.location = perspectiveStore().getParameterValue(this._widgetUID, 'location')
      payload.period = perspectiveStore().getParameterValue(this._widgetUID, 'period')
      payload.league = perspectiveStore().getParameterValue(this._widgetUID, 'league')
      payload.limit = 100 // On demande 100 lignes

      axiosService.put('/wid/stats/raw', payload).then(response => {
        this.data = response.data.results
      }).catch(error => {
        console.error(error)
      })
    },

    _percentage(row) {
      if (!row) return '-'
      if (!row.percentage) return '-'
      return (row.percentage).toFixed(2)
    },

    _absolute(row) {
      if (!row) return '-'
      if (!row.absolute) return '-'
      return row.absolute
    },

    _locationIcon(row) {
      if (!row) return '-'
      if (row.location === 'HOME') return 'fa-solid fa-house'
      if (row.location === 'AWAY') return 'fa-solid fa-plane'
      return 'fa-solid fa-question'
    },

    _openGameDetails(row){
      if (!row.gameUID) {
        message.info("Tu dois être connecté pour accéder aux détails du match")
        return
      }
      modalStore().openModal({
        uid: "gameDetailsModal",
        payload: {
          gameUID: row.gameUID
        }
      });
    }
  },
  watch: {
    _isVisible(val) {
      if (val) {
        this._refresh()
      }
    }
  },
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      {{ _statLabel }}
    </header>

    <div class="content">
      <table v-if="data">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th>Nb.</th>
        </tr>
        <tr v-for="r in _sortedData"  @click="_openGameDetails(r)">
          <td class="logo">
            <team-logo :uid="r.teamUID" size="small"/>
          </td>
          <td class="name">
            <team-name :uid="r.teamUID" :anonymous="!r.teamUID" v-if="_statScope === 'TEAM'"/>
            <player-name :uid="r.playerUID" :anonymous="!r.playerUID" v-if="_statScope === 'PLAYER'"/>
            <span class="against-data">
              <team-name :uid="r.againstTeamUID" :anonymous="!r.againstTeamUID"/>
              <font-awesome-icon :icon="_locationIcon(r)"/>
              <league-logo :uid="r.leagueUID" size="small" class="ml-1"/>
            </span>
          </td>
          <td class="relative-value">{{ _percentage(r) }}</td>
          <td class="absolute-value">{{ _absolute(r) }}</td>

        </tr>
      </table>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="" @click="_onCancel">Fermer</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table {
  .logo {
    width: 24px;
    text-align: center;
  }

  .name {
    text-align: left;
    max-width: 150px;
    padding: 0 !important;
  }

  .relative-value {
    width: 30px;
    text-align: right;
    color: @text-color-dark;
  }

  .absolute-value {
    width: 29px;
    text-align: right;

  }

  tr {
    cursor: pointer;

    &:hover {
      background: fade(@primary-color, 40%);
    }

    &.anonymous {
      opacity: 0.25;
    }

    .against-data {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 7pt;
      color: @text-color-dark;
    }
  }
}
</style>
<script>
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";

// Stores
import {contextStore} from "@/stores/Context.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import {gameStore} from "@/stores/Game.store";
import {teamStore} from "@/stores/Team.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "WidgetTeamParamButton",
  components: {TeamLogo, LeagueLogo},
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  methods: {
    _openSettingsModal() {
      modalStore().openModal({
        uid: 'widgetSettingsModal', payload: {
          uid: this.widget.uid
        }
      })
    },
  },
  computed: {
    _key() {
      return 'team'
    },
    _value() {
      return perspectiveStore().getParameterValue(this.widget.uid, this._key)
    },
    _gameUID() {
      return contextStore().gameUID
    },
    _game() {
      return gameStore().get(this._gameUID)
    },
    _teamUID() {
      if (!this._value) return null;
      switch (this._value) {
        case 'HOME':
          return this._game.homeTeamUID
        case 'AWAY':
          return this._game.awayTeamUID
      }
    },
    _tooltipTitle() {
      let team = teamStore().get(this._teamUID);
      if (!team) return '?';
      return team.name
    }
  }
}
</script>

<template>
  <a-tooltip placement="top" :title="_tooltipTitle">
    <button class="small no-radius" @click="_openSettingsModal">
      <team-logo :uid="_teamUID" size="small"/>
    </button>
  </a-tooltip>
</template>

<script>
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "ReferringAd",
  computed: {},
  methods: {
    _openUserPreferencesModal() {
      modalStore().openModal({uid: 'userPreferencesModal', payload:{ tab: 'sponsoring' }})
    },
  }
}
</script>

<template>
  <div id="referring-ad">
    <h1 class="text-left">Parraine un pote et touche un bonus !💸</h1>

    <p class="mt-3">
      Fais profiter un pote de l’outil et encaisse un bonus de parrainage sur son abonnement ET sur celui de ses filleuls !<br/><br/>
      Chaque nouvelle inscription m’aide à améliorer DoctoBET pour que tes pronos soient toujours au top !
    </p>

    <button class="primary" @click="_openUserPreferencesModal">Parrainer un pote</button>

  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

#referring-ad {

  text-align: left;
  padding: @padding-large;

  background: rgb(255, 255, 255);
  border-radius: @radius-regular;
  cursor: pointer;
  border: 1px solid #444;
  background: linear-gradient(-229deg, #494081 0%, #494081 7%, #494081 21%, rgba(37, 37, 42, 0) 90%);

  &:hover {
    background: @title-color;
    background: linear-gradient(135deg, fade(@title-color, 20%) 0%, fade(@title-color, 15%) 10%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  }
}
</style>
// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";
import indexedDBService from "@/services/indexedDB.service";

export const imageStore = defineStore('imageStore', {
    state: () => {
        return {
            loadingUIDs: [],
            fetchByUIDTimeout: null,
            items: []
        }
    },
    actions: {
        init() {
            indexedDBService.get('images').then(response => {
                if(!response) return
                if(!response.stringify) return

                // convert string to array
                const items = JSON.parse(response.stringify)
                this.items = items || []
            })
        },
        // Permet de récupérer un match par son UID
        fetchByUID(uid) {

            if (!uid) return

            if (this.loadingUIDs.indexOf(uid) < 0) {
                this.loadingUIDs.push(uid)
            }

            // Si on a 20 équipes à charger, on le fait tout de suite
            const timeout = this.loadingUIDs.length < 50 ? 250 : 0

            if (this.fetchByUIDTimeout) clearTimeout(this.fetchByUIDTimeout)
            this.fetchByUIDTimeout = setTimeout(() => {

                // On prend 20 éléments à charger
                const uids = this.loadingUIDs.slice(0, 50)

                axiosService.get('/dta/images/' + uids.join(',')).then(response => {
                    this.items = this.items.concat(response.data.results)

                    const loadedUIDs = response.data.results.map(item => item.uid)
                    this.loadingUIDs = this.loadingUIDs.filter(uid => loadedUIDs.indexOf(uid) < 0)

                    // Save items in local storage
                    indexedDBService.set('images', this.items)
                });

            }, timeout)

        }
    },
    getters: {

        get: (state) => (uid) => {

            if (state.loadingUIDs.indexOf(uid) >= 0) {
                return null
            }

            uid = parseInt(uid)

            let item = state.items.find(item => item.uid === uid);
            if (item) {
                return item
            }

            // Si l'item' n'est pas dans le store, on le charge
            const ts = imageStore()
            ts.fetchByUID(uid)

            return null
        }
    }
})
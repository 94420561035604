<script>
import TicketBetOdd from "../../../../../common/TicketBetOdd.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import DateTime from "../../../../../common/DateTime.vue";
import {bankrollStore} from "../../../../../../stores/Bankroll.store";
import {bankrollPaneStore} from "../BankrollPane.store";
import GameRow from "@/components/common/GameRow.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameHour from "@/components/common/GameHour.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import BankrollTicketBet from "@/components/routes/bankroll/panes/bankroll/items/BankrollTicketBet.vue";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "BankrollTicket",
  components: {BankrollTicketBet, AreaFlag, GameHour, TeamName, GameRow, DateTime, FontAwesomeIcon, TicketBetOdd},

  props: {
    ticket: {
      type: Object,
      required: true
    },
    isLastBankrollTicket: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      _deleteInterval: null,
      _canDeleteTicket: false
    }
  },

  created() {
    if (!this.isLastBankrollTicket) {
      return
    }

    // On calcul la date limite pour supprimer le ticket
    let creationDate = this.ticket.creationDate;
    // convert to ms
    let creationDateMs = new Date(creationDate).getTime()
    let limit = creationDateMs + 120000
    let now = new Date();
    if (limit <= now) {
      return
    }

    const diff = limit - now
    setTimeout(() => {
      this._canDeleteTicket = false
    }, diff)

  },

  computed: {
    _cssClasses() {
      return {
        'bankroll-ticket': true
      }
    },

    _ticketStatus() {
      // Dans cet v1, un ticket = un bet donc on peut retourner le status du premier bet
      if(!this.ticket) return null
      if(!this.ticket.bets) return null
      return this.ticket.bets[0].status
    },

    _statusChoices() {
      return [
        {code: 'WAITING', label: 'En attente'},
        {code: 'WIN', label: 'Gagné'},
        {code: 'LOSE', label: 'Perdu'},
        {code: 'CANCEL', label: 'Annulé'},
        {code: 'REFUND', label: 'Remboursé'},
        {code: 'HALF_LOSE', label: 'Perdu à moitié'},
        {code: 'HALF_WIN', label: 'Gagné à moitié'},
        {code: 'CASHOUT', label: 'Cashout'}
      ]
    },

    _ticketCanBeDeleted() {

      if (!this.editable) {
        return false
      }

      // On vérifie que c'est le dernier ticket de la bankroll
      if (!this.isLastBankrollTicket) {
        this._canDeleteTicket = false
        return this._canDeleteTicket
      }

      // Un ticket peut être supprimé si il date de moins de 2 minutes
      const now = new Date()
      const creationDate = new Date(this.ticket.creationDate).getTime()
      this._canDeleteTicket = (now.getTime() - creationDate) < 120000
      return this._canDeleteTicket
    },

    _isSelected() {
      return bankrollPaneStore().getTicketActiveUID() === this.ticket.uid
    },
  },

  methods: {
    _onClickTicket() {
      bankrollPaneStore().setTicketActiveUID(this.ticket.uid)
    },
    _onDeleteClick() {
      if (!this._canDeleteTicket) {
        return
      }
      bankrollStore().deleteTicket(this.ticket.uid)
    },
    _statusLabel(status) {
      switch (status) {
        case 'WAITING':
          return 'En attente'
        case 'WIN':
          return 'Gagné'
        case 'LOSE':
          return 'Perdu'
        case 'REFUND':
          return 'Remboursé'
        case 'CANCEL':
          return 'Annulé'
        case 'HALF_LOSE':
          return 'Perdu à moitié'
        case 'HALF_WIN':
          return 'Gagné à moitié'
        case 'CASHOUT':
          return 'Cashout'
        default:
          console.warn('Unknown ticket status', this.ticket.status)
          return '?'
      }
    },

    _onSelectStatus(code) {
      bankrollStore().updateTicketStatus(this.ticket.bankrollUID, this.ticket.uid, code)
      bankrollPaneStore().setTicketActiveUID(null)
    },

    _openPaperTicketModal() {
      modalStore().openModal({
        uid: 'paperTicketModal',
        payload: {
          result: this.ticket
        }
      })
    }
  }
}
</script>

<template>
  <div :class="_cssClasses">
    <div class="flex pl-2 pt-1">
      <label class="ticket-uid">#{{ticket.uid}}</label>

      <button class="small ml-1" @click="_openPaperTicketModal">
        <font-awesome-icon icon="fa-solid fa-share-from-square"/>
      </button>
      <div class="flex-grow"/>
      <date-time :value="ticket.creationDate" :time="true" class="mr-1"/>
      <button class="edit-btn small mr-1 ml-2" @click.stop="_onClickTicket" v-if="editable">
        <font-awesome-icon icon="fa-solid fa-pen" size="lg"/>
      </button>
    </div>
    <hr class="my-1"/>

    <bankroll-ticket-bet v-for="bet in ticket.bets" :bet="bet" :ticket="ticket"/>

    <hr class="mb-1" v-if="_isSelected"/>
    <div class="flex px-1 pb-1" v-if="_isSelected">
      <button class="negative small" @click="_onDeleteClick" v-if="_ticketCanBeDeleted">Supprimer</button>
      <div class="flex-grow"/>
      <a-dropdown :trigger="['click']" class="flex-grow-0">
        <button class="info small" @click.prevent>{{ _statusLabel(_ticketStatus) }}</button>
        <template #overlay v-if="editable">
          <a-menu>
            <a-menu-item v-for="c in _statusChoices" @click="_onSelectStatus(c.code)">{{
                c.label
              }}
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.bankroll-ticket {
  cursor: pointer;
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  border: 1px solid @stroke-color;
  border-radius: @radius-regular;

  &:hover {
    background: @title-color;
    background: linear-gradient(135deg, fade(@title-color, 20%) 0%, fade(@title-color, 15%) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  }

  .ticket-uid {
    min-width: 16px;
    min-height: 16px;
    border-radius: @radius-small;
    line-height: 24px;
    padding: 0 !important;
    color: @text-color;
    opacity: 0.25;
    font-size: 9pt;
    font-family: 'SourceCodePro', serif !important;
  }

  label {
    min-width: 16px;
    min-height: 16px;
    border-radius: @radius-small;
    line-height: 18px;
    padding: 0 @padding-small;
    color: @text-color;
    font-size: 10pt;
    //white-space: nowrap;
    //text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;

    &.ticket-date {
      font-size: 8pt;
      color: @stroke-color !important;
    }
  }
}
</style>
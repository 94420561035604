// stores/counter.js
import {defineStore} from 'pinia'

import axiosService from "@/services/axios.service";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";

export const preferencesStore = defineStore('preferencesStore', {
    state: () => {
        return {
            items: null,
        }
    },
    actions: {
        /**
         * Permet l'initialisation des selections
         * @param callback
         */
        fetchAll(callback = null) {
            if (this.items !== null) {
                if (callback) callback()
                return
            }
            this.items = []

            axiosService.get('/usr/preferences').then(response => {
                this.items = response.data.results
            }).catch(error => {
                console.error('preferencesStore.fetchAll', error)
            }).finally(() => {
                if (callback) callback()
            })

        },

        save(cat, key, values, callback=null) {
            const cs = contextStore()
            if (!cs.isAuthenticated()) {
                if(callback) callback()
                return;
            }

            // remove null or undefined values
            values = values.filter(item => item !== null && item !== undefined);

            let pref = {
                cat: cat,
                key: key,
                values: values
            };
            axiosService.put('/usr/preferences', pref).then(_ => {
                // remove from items if exists
                const index = this.items.findIndex(item => {
                    return item.cat === cat && item.key === key
                })
                if (index !== -1) {
                    this.items.splice(index, 1)
                }
                // add to items
                this.items.push(pref)

                if (callback) callback()
            })
            .catch(error => {
                console.error(error)
            });
        },

        switch(cat, key, values) {

            // convert all values to string
            values = values.map(item => item.toString());

            let pref = {
                cat: cat,
                key: key,
                values: values
            };
            const cs = contextStore()
            if (!cs.isAuthenticated()) {
                message.warning("Connectes toi avant.");
                return;
            }

            axiosService.put('/usr/preferences/switch', pref).then(response => {
                // remove from items if exists
                const index = this.items.findIndex(item => {
                    return item.cat === cat && item.key === key
                })
                let exist = index >= 0;
                if (!exist) {
                    // add to items if not exists
                    this.items.push(pref)
                } else {
                    // remove values from items.values if present
                    let pref = this.items[index];
                    let toRemove = pref.values.filter(item => values.includes(item.toString()));
                    let toAdd = values.filter(item => !pref.values.includes(item.toString()));

                    toRemove.forEach(item => {
                        let index = pref.values.indexOf(item);
                        if (index >= 0) {
                            pref.values.splice(index, 1);
                        }
                    })

                    toAdd.forEach(item => {
                        pref.values.push(item.toString());
                    })

                }
                // add to items

            }).catch(error => {
                console.error(error)
            });
        }
    },
    getters: {
        get: (state) => (cat, key) => {
            if (state.items === null) return null
            return state.items.find(item => {
                return item.cat === cat && item.key === key
            })
        },
        getValues: (state) => (cat, key) => {
            if (state.items === null) return null
            const item = state.items.find(item => {
                return item.cat === cat && item.key === key
            })
            if (item === undefined) return null
            return item.values
        },
        getFirstValue: (state) => (cat, key) => {
            if (state.items === null) return null
            const item = state.items.find(item => {
                return item.cat === cat && item.key === key
            })
            if (item === undefined) return null
            return item.values[0]
        },
        getFloat: (state) => (cat, key, defaut = null) => {
            if (state.items === null) return null
            const item = state.items.find(item => {
                return item.cat === cat && item.key === key
            })
            if (item === undefined) return defaut
            return parseFloat(item.values[0])
        },
        getInt: (state) => (cat, key, defaut = null) => {
            if (state.items === null) return null
            const item = state.items.find(item => {
                return item.cat === cat && item.key === key
            })
            if (item === undefined) return defaut
            return parseInt(item.values[0])
        },
        getString: (state) => (cat, key, defaut = null) => {
            if (state.items === null) return null
            const item = state.items.find(item => {
                return item.cat === cat && item.key === key
            })
            if (item === undefined || item.values.length === 0) return defaut
            return item.values[0].toString()
        },
        getBoolean: (state) => (cat, key, defaut = null) => {
            if (state.items === null) return null
            const item = state.items.find(item => {
                return item.cat === cat && item.key === key
            })
            if (item === undefined) return defaut
            let value = item.values[0]
            value = !(!value || value === 'false' || value === false);
            return value
        },
        include: (state) => (cat, key, value) => {
            if (state.items === null) return false
            const item = state.items.find(item => {
                return item.cat === cat && item.key === key
            })
            if (item === undefined) return false
            return item.values.includes(value)
        },
    }
})
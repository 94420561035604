<script>
import Statistic from "@/components/common/Statistic.vue";
import {gameStore} from "@/stores/Game.store";

export default {
  name: "SelectionPaneItem.Stats.Tab.Goals.UnderOver",
  components: {Statistic},
  props: {
    gameUid: {
      required: true
    },
    argument: {
      required: true
    },
    data: {
      type: Array,
      default: []
    },
    mergeMethod: {
      type: Function,
      default: () => {
        console.error('mergeMethod is required')
      }
    }
  },

  data() {
    return {
      selectedChoice: null
    }
  },

  methods: {
    _setChoice(choice) {
      if (this.selectedChoice === choice) {
        this.selectedChoice = null
      } else {
        this.selectedChoice = choice
      }
    }
  },

  computed: {
    _game() {
      if (!this.gameUid) return null
      return gameStore().get(this.gameUid)
    },

    _gameHomeTeamUID() {
      if (!this._game) return null
      return this._game.homeTeamUID
    },

    _gameAwayTeamUID() {
      if (!this._game) return null
      return this._game.awayTeamUID
    },

    _overStats() {
      if (!this._game) return null
      if (!this.data) return null

      switch (this.argument) {
        case 0.5:
          return this.mergeMethod(this.data, [{
            fixedUID: [
              301, 306, 310,
              302, 307, 311,
              303, 308, 312,
              304, 309, 313
            ],
            whoUID: [this._gameHomeTeamUID, this._gameAwayTeamUID]
          }])
        case 1.5:
          return this.mergeMethod(this.data, [{
            fixedUID: [
              302, 307, 311,
              303, 308, 312,
              304, 309, 313
            ],
            whoUID: [this._gameHomeTeamUID, this._gameAwayTeamUID]
          }])
        case 2.5:
          return this.mergeMethod(this.data, [{
            fixedUID: [
              303, 308, 312,
              304, 309, 313
            ],
            whoUID: [this._gameHomeTeamUID, this._gameAwayTeamUID]
          }])
        case 3.5:
          return this.mergeMethod(this.data, [{
            fixedUID: [
              304, 309, 313
            ],
            whoUID: [this._gameHomeTeamUID, this._gameAwayTeamUID]
          }])
      }

      return null
    },

    _underStats() {
      if (!this._game) return null
      if (!this.data) return null

      switch (this.argument) {
        case 0.5:
          return this.mergeMethod(this.data, [{
            fixedUID: [
              314, 319, 323
            ],
            whoUID: [this._gameHomeTeamUID, this._gameAwayTeamUID]
          }])
        case 1.5:
          return this.mergeMethod(this.data, [{
            fixedUID: [
              314, 319, 323,
              315, 320, 324
            ],
            whoUID: [this._gameHomeTeamUID, this._gameAwayTeamUID]
          }])
        case 2.5:
          return this.mergeMethod(this.data, [{
            fixedUID: [
              314, 319, 323,
              315, 320, 324,
              316, 321, 325
            ],
            whoUID: [this._gameHomeTeamUID, this._gameAwayTeamUID]
          }])
        case 3.5:
          return this.mergeMethod(this.data, [{
            fixedUID: [
              314, 319, 323,
              315, 320, 324,
              316, 321, 325,
              317, 322, 326
            ],
            whoUID: [this._gameHomeTeamUID, this._gameAwayTeamUID]
          }])
      }

      return null
    },
    _selectedChoiceData() {
      if (!this.data) return []
      switch (this.selectedChoice) {
        case 'UNDER':
          return this._underStats.stats
        case 'OVER':
          return this._overStats.stats
      }
    }

  }
}
</script>

<template>
  <fieldset class="fieldset-bloc mx-1 mt-2 text-left" v-if="data">
    <legend>Under/Over {{ argument }} buts</legend>
    <div class="flex">
      <div class="flex-grow" v-if="_underStats">
        <button class="stats-merge-item-button" @click="_setChoice('UNDER')"
                :class="{'not-selected': selectedChoice && selectedChoice !== 'UNDER'}">
          <span class="stats-merge-item-button-label">-{{ argument }}</span>
          <span class="stats-merge-item-button-value">
                <span>{{ _underStats.points }}</span>
                <span class="stats-merge-item-button-sub-value"><sup>({{ _underStats.stats.length }})</sup></span>
              </span>
        </button>
      </div>
      <div class="flex-grow ml-1" v-if="_overStats">
        <button class="stats-merge-item-button" @click="_setChoice('OVER')"
                :class="{'not-selected': selectedChoice && selectedChoice !== 'OVER'}">
          <span class="stats-merge-item-button-label">+{{ argument }}</span>
          <span class="stats-merge-item-button-value">
                <span>{{ _overStats.points }}</span>
                <span class="stats-merge-item-button-sub-value"><sup>({{ _overStats.stats.length }})</sup></span>
              </span>
        </button>
      </div>
    </div>
    <div class="mt-1" v-if="selectedChoice !== null">
      <a-alert v-if="data.length === 0" message="Aucune statistique remarquable." type="info" show-icon/>
      <statistic :stat="item.stat" :period="item.period" v-for="item in _selectedChoiceData"/>
    </div>
  </fieldset>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";
</style>
<script>
import CountryFlag from 'vue-country-flag-next'

export default {
  name: "AreaFlag",
  components: {
    CountryFlag
  },
  props: {
    area: {
      required: false
    },
    size: {
      type: String,
      default: 'medium' // small, medium accepeted
    }
  },
  computed: {
    _area() {
      if (!this.area) return 'FR';
      if (this.area === 'SP') return 'ES';
      if (this.area === 'EN') return 'GB';
      return this.area
    }
  }
}
</script>

<template>
  <a-tooltip :title="area">
    <span v-if="!_area" class="area-flag"></span>
    <country-flag :country="_area" size="small"/>
  </a-tooltip>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.small-flag {
  height: 35px;
}
</style>
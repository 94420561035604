// stores/counter.js
import {defineStore} from 'pinia'
import {contextStore} from "./Context.store";

export const modalStore = defineStore('modalStore', {
    state: () => {
        return {
            // Contient les modals ouvertes, on affiche que la dernière à l'écran
            routes: {},
        }
    },
    actions: {
        openModal(payload) {
            if (!payload) {
                console.error('No modal payload')
                return
            }

            // get the route from url
            const cs = contextStore()
            let route = cs.getRoute()
            if (!this.routes[route]) {
                this.routes[route] = []
            }

            this.routes[route].push(payload)
        },
        closeModal() {
            const cs = contextStore()
            if (!this.routes[cs.getRoute()]) return
            this.routes[cs.getRoute()].pop()
        },
        closeAllModals() {
            const cs = contextStore()
            if (!this.routes[cs.getRoute()]) return
            this.routes[cs.getRoute()] = []
        }
    },
    getters: {
        getModals: (state) => () => {
            const cs = contextStore()
            return state.routes[cs.getRoute()]
        },

        getVisibleModal: (state) => {
            const cs = contextStore()
            const routeModals = state.routes[cs.getRoute()]
            if (!routeModals) return null
            if (routeModals.length === 0) return null
            return routeModals[routeModals.length - 1]
        },

        isVisibleModal: (state) => (uid) => {
            const visibleModal = modalStore().getVisibleModal
            if (!visibleModal) return false
            return visibleModal.uid === uid
        }
    }
})
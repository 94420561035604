// stores/counter.js
import {defineStore} from 'pinia'
import Stomp from 'webstomp-client'
import {contextStore} from "@/stores/Context.store";

export const webSocketStore = defineStore('webSocketStore', {
    state: () => {
        return {
            connected: false,
            client: null
        }
    },
    actions: {
        connect() {

            const cs = contextStore()

            const uri = import.meta.env.VITE_WS_URL
            this.client = Stomp.client(uri)
            this.client.hasDebug = false
            this.client.connect(
                {userUID: cs.getLoggedUserUID() ?? -1},
                connexionSucceed => {
                    window.addEventListener('beforeunload', this.disconnect);
                },
                connexionFailed => {
                    console.warn('Connexion WS échouée')
                }
            )
        },
        disconnect() {
            if (this.client) {
                this.client.disconnect()
            }
        },
        subscribe(topic, onMessage) {
            this.client.subscribe(topic, onMessage, null)
        },

        unsubscribe(topic) {
            this.client.unsubscribe(topic)
        }
    },
    getters: {}
})
<script setup>
import YoutubeButton from "@/components/common/YoutubeButton.vue";
</script>

<script>
import {modalStore} from "../../../../../stores/Modal.store";

export default {
  name: "WelcomePane",
  components: {},

  methods: {
    _showSearchModal() {
      modalStore().openModal({
        uid: 'searchModal',
        payload: {
          filter: '',
        }
      })
    }
  }
}
</script>

<template>

  <div id="welcome-pane">
    <p>Sélectionne une rencontre dans le panneau de gauche pour commencer à l'analyser</p>
    <button class="accent" @click="_showSearchModal">Rechercher une rencontre</button>
    <youtube-button video-id="bG2dHYSse4M" class="ml-2"/>
  </div>

</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";


#welcome-pane {
  margin: @padding-small;
  border-radius: @radius-regular;
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  height: calc(100dvh - @toolbar-regular);
  width: calc(100% - 400px - 3 * @padding-small) !important;
  p {
    margin: 200px auto 64px auto;
    max-width: 400px;
    text-align: center;
    font-size: 2em !important;
    color: white;
  }
}


.mobile {
  #welcome-pane-wrapper {
    #welcome-pane {
      display: none;
    }
  }
}

</style>
<script>
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {monitoringStore} from "@/stores/Monitoring.store";
import {message, Modal} from "ant-design-vue";
import MonitoringItem from "@/components/common/MonitoringItem.vue";

export default {
  name: "SelectionMonitoringListModal",
  components: {MonitoringItem},
  data() {
    return {

    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal
      if (!visibleModal) return false;
      return visibleModal.uid === 'selectionMonitoringListModal';
    },
    _payload() {
      if (!this._isVisible) return null;
      const payload = modalStore().getVisibleModal.payload
      if (!payload) return null;
      return payload;
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _isAuthenticated() {
      return contextStore().isAuthenticated()
    },
    _allMonitorings() {
      if(!this._payload) return []
      return monitoringStore().getBySelectionUID(this._payload.selectionUID)
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _createMonitoring() {
      if(!this._isAuthenticated) {
        message.error('Tu dois être connecté pour créer un monitoring')
        return
      }
      if(!this._payload) return

      modalStore().openModal({
        'uid': 'createMonitoringModal',
        'payload' : {selectionUID: this._payload.selectionUID}
      })
    },
    _deleteMonitoring() {
      if (!this._allMonitorings) return


      Modal.confirm({
        title: 'Es-tu sûr de vouloir supprimer les monitoring ?',
        content: 'Cette action est irréversible.',
        okText: 'Oui, supprimer',
        okType: 'danger',
        cancelText: 'Annuler',
        onOk: () => {
          this._allMonitorings.forEach(m => {
            monitoringStore().delete(m.uid)
          })
        },
        onCancel: () => {
          // Nothing to do
        },
      });

    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="500" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Monitoring de la sélection
    </header>

    <div class="content">
      <a-alert message="Monitorer une sélection te permet de tester des stratégies de betting de manière automatisée. Le système alimentera une bankroll avec les bets correspondant à tes critères." type="info" class="m-1" show-icon/>
      <a-alert v-if="_allMonitorings.length === 0" message="Tu n'as aucun monitoring sur cette sélection" type="warning" class="m-1"/>
      <monitoring-item :item="monitoring" v-for="monitoring in _allMonitorings" :key="monitoring.uid" />

    </div>

    <template #footer>
      <div class="flex m-1">
        <button class="secondary m-1" @click="_deleteMonitoring"  v-if="_allMonitorings.length > 0">
          Supprimer tous les monitorings
        </button>

        <div class="flex-grow"></div>
        <button class="primary m-1" @click="_createMonitoring" v-if="_allMonitorings.length === 0">
          Créer un monitoring
        </button>
        <button class="m-1 info" @click="_onCancel" v-if="this._onCancel">Fermer</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>
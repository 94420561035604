// stores/counter.js
import {defineStore} from 'pinia'
import {analysePaneStore} from "@/components/routes/selection/panes/analyse/AnalysePane.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";

export const selectionRouteStore = defineStore('selectionRouteStore', {
    state: () => {
        return {

        }
    },
    actions: {
        onSportChange() {
            analysePaneStore().onSportChange()
            selectionPaneStore().onSportChange()
        }
    },
    getters: {

    }
})
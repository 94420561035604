<script>
import SelectionOperation from "@/components/routes/selection/panes/selection/condition/SelectionOperation.vue";
import SelectionConditionCriteria from "@/components/routes/selection/modals/criteria/SelectionConditionCriteria.vue";
import {conditionFormMixins} from "@/components/routes/selection/modals/condition_forms/ConditionForm.Mixins";

export default {
  name: "ConditionFormTeamScore",
  components: {SelectionConditionCriteria, SelectionOperation},
  mixins: [conditionFormMixins],
  props: {
    modelValue: {
      type: Object,
      required: true
    },
  },

  beforeMount() {

    this.form = this.modelValue;

    // On applique une valeur par defaut car la valeur actuelle n'est pas dans les choix
    if (!this._matchOneChoice(this.form.who, this.who.choices)) {
      this.form.who = this.who.default;
    }
    if (!this._matchOneChoice(this.form.against, this.against.choices)) {
      this.form.against = this.against.default;
    }
    if (!this._matchOneChoice(this.form.what, this.what.choices)) {
      this.form.what = this.what.default;
    }
    if (!this._matchOneChoice(this.form.locationStrict, this.locationStrict.choices)) {
      this.form.locationStrict = this.locationStrict.default;
    }
    if (!this._matchOneChoice(this.form.leagueStrict, this.leagueStrict.choices)) {
      this.form.leagueStrict = this.leagueStrict.default;
    }
    if (!this._matchOneChoice(this.form.period, this.period.choices)) {
      this.form.period = this.period.default;
    }

  },

  mounted() {

  },

  data() {
    return {
      form: {},
      who: {
        label: 'Sujet',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Une des équipes", value: {choice: "ONE_OF_TEAMS"}},
          {label: "Domicile", value: {choice: "HOME_TEAM"}},
          {label: "Extérieur", value: {choice: "AWAY_TEAM"}},
          {label: "Les 2 équipes", value: {choice: "BOTH_TEAM"}},
        ],
        default: {
          choice: "ONE_OF_TEAMS"
        }
      },
      against: {
        label: 'Adversaire',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Toute autre équipe", value: {choice: "ANY_TEAM"}},
          {label: "Adversaire du jour", value: {choice: "OTHER_TEAM"}}
        ],
        default: {
          choice: "ANY_TEAM"
        }
      },
      what: {
        label: 'Score',
        type: "PAIR",
        multiple: false,
        choices: [
          {label: "Exactement", value: {choice: "EQUAL"}}
        ],
        minMaxChoices: [
          {label: "0", value: "0"},
          {label: "1", value: "1"},
          {label: "2", value: "2"},
          {label: "3", value: "3"},
          {label: "4", value: "4"},
          {label: "5", value: "5"},
        ],
        default: {
          choice: "EQUAL",
          min: "0",
          max: "0"
        }
      },
      locationStrict: {
        label: 'Domicile/Extérieur',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Peu importe", value: {choice: "FALSE"}},
          {label: "Celle du match", value: {choice: "TRUE"}}
        ],
        default: {
          choice: "FALSE"
        }
      },
      leagueStrict: {
        label: 'Compétition',
        type: "BOOLEAN",
        multiple: false,
        choices: [
          {
            label: "Peu importe",
            tooltip: "La condition ignorera la compétition des matchs",
            value: {choice: "FALSE"}
          },
          {
            label: "Celle du match",
            tooltip: "La condition prendra en compte les matchs de la compétition",
            value: {choice: "TRUE"}
          },

        ], // Oui ou Non
        default: {
          choice: "FALSE"
        }
      },
      period: {
        label: 'Période',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Cette saison", value: {choice: "LAST_SEASON", min: 1}},
          {label: "5 derniers matchs", value: {choice: "LAST_GAME", min: 5}},
          {label: "10 derniers matchs", value: {choice: "LAST_GAME", min: 10}}
        ],
        default: {
          choice: "LAST_SEASON",
          min: 1
        }
      }
    }
  },
  computed: {}
}
</script>

<template>
  <div>

    <div class="selection-condition-item-resume">
      Rechercher les rencontres suivant la régularité d'un score réalisé par une équipe.
    </div>

    <div class="condition-form-criteria">
      <selection-condition-criteria v-model="form.who" :choices="who.choices" :label="who.label"/>
      <selection-condition-criteria v-model="form.against" :choices="against.choices" :label="against.label"/>
      <selection-condition-criteria v-model="form.what"
                                    :choices="what.choices"
                                    :min-choices="what.minMaxChoices"
                                    :max-choices="what.minMaxChoices"
                                    :label="what.label"
                                    :type="what.type"/>
      <selection-condition-criteria v-model="form.locationStrict" :choices="locationStrict.choices"
                                    :label="locationStrict.label"/>
      <selection-condition-criteria v-model="form.leagueStrict" :choices="leagueStrict.choices"
                                    :label="leagueStrict.label"/>
      <selection-condition-criteria v-model="form.period" :choices="period.choices" :label="period.label"/>
    </div>


  </div>
</template>
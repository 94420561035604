<script>

import {leagueStore} from "@/stores/League.store";
import ArjelLogo from "@/components/common/ArjelLogo.vue";

export default {
  name: "LeagueName",
  components: {ArjelLogo},
  props: {
    uid: {
      required: true
    },
    size: {
      type: String,
      default: 'medium' // small, medium accepeted
    },
    showArea: {
      type: Boolean,
      default: false
    },
    showArjel:{
      type: Boolean,
      default: false
    }
  },

  computed: {
    _leagueStore() {
      return leagueStore();
    },
    _league() {
      return this._leagueStore.get(this.uid);
    },
    _leagueName() {
      return this._league?.name ?? "_";
    },
    _areaCode() {
      return this._league?.area ?? "_";
    },
    _isArjel(){
      return this._league?.isArjel ?? false;
    },
    _buildCssClasses() {

      if (!this._league) return {
        'league-name': true,
        'small': this.size === 'small',
        'shine': true,
      }
      return {
        'league-name': true,
        'small': this.size === 'small'
      }
    }
  },
}
</script>

<template>
  <span :class="_buildCssClasses">
    {{ _leagueName }}
    <span v-if="showArea">({{ _areaCode }})</span>
    <arjel-logo v-if="showArjel && _isArjel" style="display: inline-block" class="ml-1"/>
  </span>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.league-name {
  min-width: 16px;
  min-height: 16px;
  border-radius: @radius-small;
  line-height: 24px;
  padding: 0 @padding-small;
  color: @text-color;

  &.small {
    font-size: 8pt;
    line-height: 16px;
  }
}
</style>
<script>
import axiosService from "@/services/axios.service";
import {perspectiveStore} from "@/stores/Perspective.store";
import {contextStore} from "@/stores/Context.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import {widgetDefinitionsStore} from "@/stores/WidgetDefinitions.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import {statStore} from "@/stores/Statistic.store";
import {message} from "ant-design-vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import Statistic from "@/components/common/Statistic.vue";

export default {
  name: "FinSerieWidget",
  components: {
    Statistic,
    AreaFlag,
    WidgetPeriodParamButton, WidgetTeamParamButton, WidgetLeagueParamButton, WidgetLocationParamButton,
    FontAwesomeIcon,
    WidgetLoadingContent
  },
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      // Les données récupérés du server
      data: null,
      parameters: null
    }
  },
  mounted() {
    this._onGameUIDChange()

    if (!this.item) return;  // widget store preview
    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })
    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _gameUID() {
      return contextStore().gameUID
    },
    _widgetUID() {
      return this.item ? this.item.uid : null
    },
    _widget() {
      if (!this._widgetUID) return null
      return perspectiveStore().getWidgetByUID(this._widgetUID)
    },
    _isWidgetYours() {
      if (!this._widget) return false
      return this._widget.userUID === contextStore().getLoggedUserUID()
    },
    _serieStatDefinitions() {
      return statStore().getDefinitions(contextStore().getSport).filter(stat => stat.type === 'SERIE')
    },
    _statFixedUID() {
      const result = perspectiveStore().getParameterValue(this.item.uid, 'statFixedUID')
      if (!result) {
        return this._serieStatDefinitions[0].fixedUID
      }
      return result
    },
    _statName() {
      const sdef = this._serieStatDefinitions.find(f => f.fixedUID === parseInt(this._statFixedUID))
      if (!sdef) {
        return 'Type offre'
      }

      return sdef.name.replace('Série - ', '')
    },
    _rate() {
      if (!this.data) {
        return null
      }
      return this.data.endProba * 100.0
    },
    _formattedRate() {
      if (!this.data) {
        return null
      }
      return (this.data.endProba * 100.0).toFixed(1)
    },
    _color() {
      if (!this.data) {
        return null
      }
      // Plus la proba est grande, plus la fin de série est proche et il faut alerter le joueur avec une couleur rouge
      const rate = this.data.endProba * 100.0;
      if (rate <= 20) {
        return '#00FF00'; // Green
      } else if (rate <= 40) {
        return '#7FFF00'; // Light Green
      } else if (rate <= 60) {
        return '#FFFF00'; // Yellow
      } else if (rate <= 80) {
        return '#FF7F00'; // Orange
      } else {
        return '#FF0000'; // Red
      }
    },
    _period() {
      return perspectiveStore().getParameterValue(this.item.uid, 'period')
    },
    _statUID() {
      if (!this.data) {
        return null
      }
      return this.data.statUID
    },
    _stat() {
      if(!this._statUID) {
        return null
      }
      return statStore().get(this._statUID)
    },
    _statLabel() {
      return statStore().getLabel(this._stat, this._period)
    }
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },

    _refresh() {
      if (!contextStore().gameUID) {
        return;
      }

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) {
        return;
      }

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {

      if (!this.item) {  // widget store preview
        this.data = {}
        return;
      }

      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        return;
      }

      const payload = {
        sport: perspectiveStore().getCurrentSport(),
        gameUID: this._gameUID,
        statFixedUID: this._statFixedUID,
        team: perspectiveStore().getParameterValue(this.item.uid, 'team'),
        location: perspectiveStore().getParameterValue(this.item.uid, 'location'),
        period: this._period,
        league: perspectiveStore().getParameterValue(this.item.uid, 'league')
      }
      axiosService.put('/wid/fin-serie', payload).then(response => {
        this.data = response.data
        wds.put(this.item.uid, contextStore().gameUID, this.data)
      })
    },

    _onStatDefChange(fixedUID) {
      this._setParameterValue('statFixedUID', fixedUID)
      perspectiveStore().saveWidgetParameters(this._widgetUID)
      this._refresh()
    },

    _setParameterValue(parameterKey, value) {
      if (!this._isWidgetYours) {
        message.warning("Tu ne peux pas modifier les paramètres d'un widget qui ne t'appartient pas");
        return
      }

      const widget = widgetDefinitionsStore().getByType('FinSerieWidget')
      const parameterDefinition = widget.parameters.find(p => p.key === parameterKey)

      perspectiveStore().setWidgetParameterValue(this._widgetUID, parameterDefinition, {key: value})
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Série
      <a-dropdown :trigger="['click']" class="">
        <button class="small no-radius"
                style="padding-left: 2px !important; padding-right: 2px !important; height:27px">
          {{ _statName }}
        </button>
        <template #overlay>
          <a-menu>
              <span v-for="c in _serieStatDefinitions">
                <a-menu-item @click="_onStatDefChange(c.fixedUID)">
                  {{ c.name }}
                </a-menu-item>
              </span>
          </a-menu>
        </template>
      </a-dropdown>
      <div class="widget-header-params flex" v-if="item">
        <widget-location-param-button :widget="item"/>
        <widget-team-param-button :widget="item"/>
        <widget-league-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content" v-else-if="data.statUID">
      <table>
        <tr style="height: 46px">
          <td v-if="_statUID" class="stat-label">
            {{_statLabel}}
          </td>
        </tr>
        <tr>
          <td class="bar-container">
            <div class="bar" :style="{width: _rate + '%', backgroundColor: _color}" v-if="_rate">
            </div>
          </td>
        </tr>

      </table>
    </div>
    <div v-else class="p-2">
      <a-alert message="Oups, la stat que tu cherches n’est pas dispo dans le système. 🔍" type="warning" show-icon/>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "@/assets/styles/variables.less";

@row-height: 18px;
.bar-container {
  background: @background-color;
  width: 90%;
  height: calc(@row-height - 4px);

  .bar {
    height: calc(@row-height - 4px);
  }
}

.stat-label{
  font-size: 8pt;
  text-align: left;
}
</style>
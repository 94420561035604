<template>
  <div class="selection-operand flex">
    <selection-condition-item :sport="sport" :item="item" class="flex-grow" @click="_openEditConditionModal"/>
    <button class="square small secondary remove_condition_item no-radius" @click="_removeConditionItem"
            style="height: auto" v-if="editable">
      <font-awesome-icon icon="fa-solid fa-close"/>
    </button>
  </div>
</template>
<script>

import SelectionConditionItem from "./SelectionConditionItem.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {modalStore} from "../../../../stores/Modal.store";

export default {
  name: "SelectionOperand",
  components: {
    FontAwesomeIcon,
    SelectionConditionItem,
  },

  props: {
    selection: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    sport: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  methods: {
    _removeConditionItem() {
      if(!this.editable) return;
      this.$emit('remove', this.item);
    },
    _openEditConditionModal() {
      if(!this.editable) return;
      modalStore().openModal({
        uid: "editSelectionConditionModal",
        payload: {
          sport: this.sport,
          selectionUid: this.selection.uid,
          operand: this.item
        }
      })
    },
  }
}
</script>

<style lang="less">
@import "@/assets/styles/variables.less";

.selection-operand {
  font-size: 10pt;
  border-bottom: none;

  &:last-child {
    border-bottom: none;
  }

  > button.remove_condition_item {
    color: #1C1C1C !important;
    visibility: hidden;
    width: 20px !important;
    min-width: 20px !important;
  }

  &:hover {
    > button {
      visibility: visible;
    }
  }

  .selection-condition-item-content {
    button.add_condition_item_value {
      padding: 4px 0 !important;
      width: 12px;
      min-width: 12px;
      visibility: hidden;
    }

    &:hover {
      > button {
        visibility: visible;
      }
    }
  }

  .selection-condition-item-value {
    margin-right: 4px;
  }

  input {
    background: #fff;
    color: #1C1C1C !important;
    padding: 4px !important;
  }
}
</style>
<script>

import {playerStore} from "@/stores/Player.store";

export default {
  name: "PlayerName",
  props: {
    uid: {
      required: true
    },
    highlight: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    _player() {
      return playerStore().get(this.uid);
    },
    _playerName() {
      return this._player?.name ?? "_";
    },
    _buildCssClasses() {
      let cssClasses = null
      if (!this._player) {
        cssClasses = {
          'player-name': true,
          'shine': true,
          'highlight': this.highlight
        }
      } else {
        cssClasses = {
          'player-name': true,
          'highlight': this.highlight
        }
      }

      return cssClasses;
    }
  },
}
</script>

<template>
  <span :class="_buildCssClasses">
    {{ _playerName }}
  </span>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.player-name {
  min-width: 16px;
  min-height: 16px;
  border-radius: @radius-small;
  line-height: 24px;
  padding: 0 @padding-small;
  color: @text-color;
  font-size: 10pt;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.highlight {
    font-weight: bolder;
  }
}
</style>
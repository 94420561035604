<script>


import AreaFlag from "@/components/common/AreaFlag.vue";
import {leagueStore} from "@/stores/League.store";

export default {
  name: "LeagueButton",
  components: {AreaFlag},
  props: {
    leagueUID: {
      type: Number,
      required: false
    },
    league: {
      type: Object,
      required: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: (league) => {
        console.warn("no click handler defined for league button", league)
      }
    },
    size:{
      type: String,
      default: 'small'
    }
  },

  computed: {
    _league() {
      if(this.league) {
        return this.league;
      }
      return leagueStore().get(this.leagueUID);
    }
  },

  methods: {
    _onClick() {
      this.onClick(this._league);
    }
  },
}
</script>

<template>
  <button :class="{'small': size === 'small', 'tiny' : size === 'tiny', 'primary selected' : selected}"
          @click="_onClick()">
    <span class="flex justify-center items-center" v-if="_league">
      <area-flag :area="_league.area"></area-flag>
      <span>{{_league.area}} - {{ _league.name }}</span>
    </span>
  </button>
</template>

<script>

import BlogHeader from "@/components/routes/blog/BlogHeader.vue";
import BlogFooter from "@/components/routes/blog/BlogFooter.vue";

// Articles
import ArticleNotFound from "@/components/routes/blog/articles/ArticleNotFound.vue";
import CommentChoisirCote from "@/components/routes/blog/articles/CommentChoisirCote.vue";
import AnalyseStatsMaximiseChances from "@/components/routes/blog/articles/AnalyseStatsMaximiseChances.vue";
import PlusStatsMoinsPerte from "@/components/routes/blog/articles/PlusStatsMoinsPerte.vue";
import ParieAvecFaitPasChance from "@/components/routes/blog/articles/ParieAvecFaitPasChance.vue";
import InstinctPlusStatsRecetteGagner from "@/components/routes/blog/articles/InstinctPlusStatsRecetteGagner.vue";
import ParierBienGagnerMieux from "@/components/routes/blog/articles/ParierBienGagnerMieux.vue";
import ChoisisOnCalcule from "@/components/routes/blog/articles/ChoisisOnCalcule.vue";
import TesteStrategieDomineIncertitudes from "@/components/routes/blog/articles/TesteStrategieDomineIncertitudes.vue";
import AnalyseResultatElimineEchecs from "@/components/routes/blog/articles/AnalyseResultatElimineEchecs.vue";
import PourQuiDoctobet from "@/components/routes/blog/articles/PourQuiDoctoBET.vue";
import ModuleStats from "@/components/routes/blog/articles/ModuleStats.vue";
import IdentifierBetesNoires from "@/components/routes/blog/articles/IdentifierBetesNoires.vue";

export default {
  name: "BlogRoute",
  components: {
    BlogFooter,
    BlogHeader,

    // Article components
    ArticleNotFound,

    // Articles
    AnalyseStatsMaximiseChances,
    CommentChoisirCote,
    PlusStatsMoinsPerte,
    ParieAvecFaitPasChance,
    InstinctPlusStatsRecetteGagner,
    ParierBienGagnerMieux,
    ChoisisOnCalcule,
    TesteStrategieDomineIncertitudes,
    AnalyseResultatElimineEchecs,
    PourQuiDoctobet,
    ModuleStats,
    IdentifierBetesNoires
  },

  computed: {
    // get route article params
    _article() {
      return this.$route.params.article;
    },
    _articleComponent() {
      // convert article to component name format
      const articleComponent = this._article.split('-').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join('');

      // Check if component exists
      if (!this.$options.components[articleComponent]) {
        return 'ArticleNotFound';
      } else {
        return articleComponent;
      }

    }
  },
}
</script>

<template>
  <div class="blog-page">
    <blog-header/>
    <div class="blog-article">
      <component :is="_articleComponent" :article="_article"/>
    </div>
    <blog-footer/>
  </div>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";

.blog-page {
  position: absolute;
  top: 0;
  height: calc(100vh);
  overflow: auto;
  scrollbar-width: none;
}

@keyframes appear {
  from {
    opacity: 0;
    scale: 0.5;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.blog-article {

  background: #fff;
  padding-bottom: 100px;


  .dots {
    margin-bottom: @padding-large;
    text-align: center;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      margin: @padding-regular;


      &.primary {
        background: @primary-color;
      }

      &.secondary {
        background: @secondary-color;
      }

      &.tertiary {
        background: @tertiary-color;
      }
    }
  }

  .h1-bloc {
    padding-top: @toolbar-regular;
    background: @background-color url("@/assets/images/blog-header.png") no-repeat;
    background-size: 100%;
  }

  .body-bloc {
    animation: appear linear;
    animation-timeline: view();
    animation-range: entry 0;
    background: #fafafa;
    margin: 0 auto @padding-large auto;
    text-align: left;
    padding: @padding-large;


    h2{
      font-family: 'PoppinsRegular', serif;
      font-style: italic;
      font-size: 1.25rem !important;
      color: #606483 !important;
      margin: 24px 16px 8px 16px !important;
    }

    h3 {
      margin-bottom: 0 !important;
    }

    &.bloc-1 {
      background: transparent;
      background: linear-gradient(333deg, @primary-color 0%, fade(@primary-color, 50%) 3%, rgba(0, 0, 0, 0.02) 12%, rgba(0, 0, 0, 0.0075) 100%);
    }

    &.bloc-2 {
      background: transparent;
      background: linear-gradient(333deg, @secondary-color 0%, fade(@secondary-color, 50%) 3%, rgba(0, 0, 0, 0.02) 12%, rgba(0, 0, 0, 0.0075) 100%);
    }

    &.bloc-3 {
      background: transparent;
      background: linear-gradient(333deg, @tertiary-color 0%, fade(@tertiary-color, 50%) 3%, rgba(0, 0, 0, 0.02) 12%, rgba(0, 0, 0, 0.0075) 100%);
    }

    &.bloc-4 {
      background: transparent;
      background: linear-gradient(333deg, @quaternary-color 0%, fade(@quaternary-color, 50%) 3%, rgba(0, 0, 0, 0.02) 12%, rgba(0, 0, 0, 0.0075) 100%);
    }

    &.bloc-5 {

    }
  }

  h1 {
    font-family: 'PoppinsRegular', serif;
    font-size: 2.25rem !important;
    color: #fff !important;
    margin-bottom: 24px !important;
  }

  h2 {
    font-family: 'PoppinsRegular', serif;
    font-size: 1.25rem !important;
    font-weight: 100 !important;
    color: #606483 !important;
    margin-bottom: 16px !important;
  }

  h3 {
    font-family: 'PoppinsRegular', serif;
    color: #161E2A !important;
    margin-bottom: 16px !important;
    font-size: 28px !important;
    line-height: 42px;
    letter-spacing: 0px;
  }

  h4 {
    font-family: 'PoppinsRegular', serif;
    font-weight: 700 !important;
    color: @primary-color !important;
    margin-bottom: 16px !important;
  }

  p {
    font-size: 20px; /* Slightly larger font size */
    line-height: 30px; /* Increased line height for readability */
    color: #6B7A90; /* Dark gray text color */
    font-family: 'Roboto', serif; /* Font family */
  }
}
</style>
// stores/counter.js
import {defineStore} from 'pinia'

import axiosService from "@/services/axios.service";

export const adsStore = defineStore('adsStore', {
    state: () => {
        return {
            items: null,
        }
    },
    actions: {
        /**
         * Permet l'initialisation des selections
         * @param callback
         */
        fetchAll(callback = null) {
            if (this.items !== null) {
                if (callback) callback()
                return
            }
            this.items = []

            axiosService.get('/ad/gambling-affiliation-ads').then(response => {
                this.items = response.data.results
            }).catch(error => {
                console.error('adsStore.fetchAll', error)
            }).finally(() => {
                if (callback) callback()
            })

        },
    },
    getters: {
        getAll: (state) => {
            return state.items
        },
        getRandom: (state) => () => {
            if (state.items === null || state.items.length === 0) {
                return null
            }
            return state.items[Math.floor(Math.random() * state.items.length)]
        }
    }
})
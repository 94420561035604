<script setup>
</script>

<script>

export default {
  name: "PlayerWelcomePane",
  components: {},

  methods: {}
}
</script>

<template>

  <div id="player-welcome-pane">
    <p>Sélectionne un joueur dans le panneau de gauche pour commencer à l'analyser</p>
  </div>

</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#player-welcome-pane {
  margin: @padding-small;
  border-radius: @radius-regular;
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  height: calc(100dvh - @toolbar-regular);
  width: calc(100% - 400px - 3 * @padding-small) !important;

  p {
    margin: 200px auto 64px auto;
    max-width: 400px;
    text-align: center;
    font-size: 2em !important;
    color: white;
  }
}


.mobile {
  #player-welcome-pane {
    display: none;
  }
}

</style>
export default new class {
    constructor() {
        this.db = null;
    }

    async init() {
        if (this.db) {
            return this.db;
        }

        this.db = await this.openDB();

        return this.db;
    }

    async openDB() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open('doctobetDB', 1);

            request.onerror = (event) => {
                reject(event.target.error);
            };

            request.onsuccess = (event) => {
                resolve(event.target.result);
            };

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                const objectStore = db.createObjectStore('mystore', {keyPath: 'id'});

                objectStore.transaction.oncomplete = (event) => {
                    resolve(db);
                };
            };
        });
    }

    async get(key) {
        const db = await this.init();

        return new Promise((resolve, reject) => {
            const transaction = db.transaction(['mystore'], 'readonly');
            const objectStore = transaction.objectStore('mystore');
            const request = objectStore.get(key);

            request.onerror = (event) => {
                reject(event.target.error);
            };

            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
        });
    }

    async set(key, value) {
        const db = await this.init();

        let stringify = JSON.stringify(value);
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(['mystore'], 'readwrite');
            const objectStore = transaction.objectStore('mystore');
            const request = objectStore.put({id: key, stringify});

            request.onerror = (event) => {
                reject(event.target.error);
            };

            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
        });
    }
}
<script>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {contextStore} from "@/stores/Context.store";
import {preferencesStore} from "@/stores/Preferences.store";

export default {
  name: "GameFavIndicator",
  components: {FontAwesomeIcon},
  props: {
    uid: {
      required: true
    },
    size: {
      type: String,
      default: "small"
    }
  },

  computed: {
    _contextStore() {
      return contextStore();
    },
    _preferencesStore() {
      return preferencesStore();
    },
    _buildCssClasses() {
      return {
        'game-fav-indicator': true,
        'small': this.size === 'small',
        'tiny': this.size === 'tiny',
        'primary': this._isFav,
        'info': !this._isFav
      }
    },
    _buildBtnCssClasses() {
      return {
        'game-fav-indicator-btn': true,
        'small': this.size === 'small',
        'tiny': this.size === 'tiny',
        'no-radius': true
      }
    },
    _isFav() {
      let favorites = this._preferencesStore.getValues('GAME', 'FAVORITE_GAME');

      if (!favorites) {
        return false;
      }

      return favorites.find(fav => fav.toString() === this.uid.toString())
    },
    _tooltipMessage() {
      return this._isFav ? 'Retirer des favoris' : 'Fav le match'
    },
    _buildCssStyle() {
      return {
        height: '22px'
      }
    }
  },
  methods: {
    _switchFav() {
      this._preferencesStore.switch('GAME', 'FAVORITE_GAME', [this.uid]);
    }
  }
}
</script>

<template>
  <a-tooltip :title="_tooltipMessage">
    <button @click="_switchFav" :class="_buildBtnCssClasses">
      <font-awesome-icon
          :icon="['fa-solid', 'fa-heart']" :class="_buildCssClasses" :style="_buildCssStyle"/>
    </button>

  </a-tooltip>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";
.game-fav-indicator-btn{

  cursor: pointer;
  display: inline-block;

  &.tiny {
    max-height: 12px;

    .game-fav-indicator {
      max-height: 12px;
      max-width: 12px;
    }
  }

  &.small {

    .game-fav-indicator {
      max-height: 16px;
      max-width: 16px;
    }
  }

  &.large {
    .game-fav-indicator {
      max-height: 64px;
      max-width: 64px;
    }
  }
}
</style>
// stores/counter.js
import {defineStore} from 'pinia'

export const playerStatStore = defineStore('playerStatStore', {
    state: () => {
        return {}
    },
    actions: {
        sum(stats, location, leagueUID) {

            const result = {
                lineups: 0,
                subsIn: 0,
                subsOut: 0,
                assists: 0,
                goals: 0,
                penalties: 0,
                gamesPlayed: 0,
                redCards: 0,
                yellowCards: 0,
            }

            if(!stats) {
                return result
            }

            const playerStatsToUse = stats.filter(stat => {
                return leagueUID === 'ANY' || stat.leagueUID === leagueUID
            })



            let locationSuffix = ''
            switch (location) {
                case 'HOME':
                    locationSuffix = 'Home'
                    break
                case 'AWAY':
                    locationSuffix = 'Away'
                    break
                default:
                    locationSuffix = ''
            }
            playerStatsToUse.forEach(stat => {
                // get attr by name and location
                result.lineups += stat['lineups' + locationSuffix] || 0
                result.subsIn += stat['subsIn' + locationSuffix] || 0
                result.subsOut += stat['subsOut' + locationSuffix] || 0

                result.assists += stat['assists' + locationSuffix] || 0
                result.goals += stat['goals' + locationSuffix] || 0
                result.penalties += stat['penalties' + locationSuffix] || 0

                result.gamesPlayed += stat['gamesPlayed' + locationSuffix] || 0

                result.redCards += stat['redCards' + locationSuffix] || 0
                result.yellowCards += stat['yellowCards' + locationSuffix] || 0
            })

            result.goalsByGames = result.gamesPlayed > 0 ? result.goals / result.gamesPlayed : 0
            result.assistsByGames = result.gamesPlayed > 0 ? result.assists / result.gamesPlayed : 0
            result.penaltiesByGames = result.gamesPlayed > 0 ? result.penalties / result.gamesPlayed : 0

            return result
        }
    }
})
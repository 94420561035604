<script>

import {preferencesStore} from "@/stores/Preferences.store";
import LeagueButton from "@/components/common/LeagueButton.vue";
import {leagueStore} from "@/stores/League.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {message} from "ant-design-vue";
import {contextStore} from "@/stores/Context.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "UserLeaguePreferences",
  components: {FontAwesomeIcon, LeagueButton},
  data() {
    return {}
  },
  computed: {
    _contextStore() {
      return contextStore();
    },
    _preferencesStore() {
      return preferencesStore()
    },
    _leagueStore() {
      return leagueStore();
    },
    _allLeagues() {
      return this._leagueStore.getAll;
    },
    _displayLogo() {
      let displayLogo = this._preferencesStore.getFirstValue('LEAGUE', 'DISPLAY_LOGO');
      if (!displayLogo) {
        displayLogo = true;
      }
      return displayLogo;
    }
  },
  methods: {
    _openSelectionLeagueModal(sport) {
      const favoriteLeagueUIDs = this._favoriteLeagueUIDs(sport);
      modalStore().openModal({
        uid: 'selectionLeagueModal',
        payload: {
          sport: sport,
          selectedUIDs: favoriteLeagueUIDs,
          onConfirm: (leagues) => {
            const leaguesUIDs = leagues.map(l => l.uid)
            this._preferencesStore.save('LEAGUE', 'FAVORITE_LEAGUES_' + sport, leaguesUIDs)
          }
        }
      })
    },
    _favoriteLeagueUIDs(sport) {
      let uids = this._preferencesStore.getValues('LEAGUE', 'FAVORITE_LEAGUES_' + sport);
      if (!uids) return [];
      if (!uids.length) return [];
      return uids.map(uid => parseInt(uid));
    },
    _favoriteLeagues(sport) {
      return this._allLeagues.filter(l => l.sport === sport && this._favoriteLeagueUIDs(sport).includes(l.uid));
    },
    _switchDisplayLogo() {
      const newValue = !this._displayLogo;

      if (newValue && !this._contextStore.isLicensed()) {
        message.warning("Cette fonction n'est accessible qu'avec la formule PRO.");
        return;
      }

      this._preferencesStore.save('LEAGUE', 'DISPLAY_LOGO', [newValue]);
    },
  }
}
</script>

<template>
  <div class="pb-2">
    <fieldset class="mt-2 pb-1 no-border">
      <legend>Logo</legend>
      <div class="px-2 pt-1">
        <label class="inline-block mr-2">Afficher les logos des compétitions</label>
        <button :class="{'small': true, 'primary' : _displayLogo, 'secondary' : !_displayLogo}"
                @click="_switchDisplayLogo">
          {{ _displayLogo ? 'Oui' : 'Non' }}
        </button>
      </div>
      <div class="p-1" v-if="!_contextStore.isLicensed()">
        <a-alert type="warning" show-icon message="Cette fonction n'est accessible qu'avec un abonnement payant."/>
      </div>
    </fieldset>
    <hr/>
    <fieldset class="mt-2 no-border">
      <legend>Football</legend>
      <div class="px-2 py-1">
        <table>
          <tr>
            <td>
              <button class="small info" @click="_openSelectionLeagueModal('FOOTBALL')">
                <font-awesome-icon icon="fa-solid fa-pen"/>
              </button>
              <league-button :league="l" v-for="l in _favoriteLeagues('FOOTBALL')"/>
              <button class="small" disabled v-if="_favoriteLeagues('FOOTBALL').length === 0">
                Aucune ligue sélectionnée
              </button>
            </td>
          </tr>
        </table>
      </div>
    </fieldset>
    <fieldset class="mt-2 no-border">
      <legend>Basketball</legend>
      <div class="px-2 py-1">
        <table>
          <tr>
            <td>
              <button class="small info" @click="_openSelectionLeagueModal('BASKETBALL')">
                <font-awesome-icon icon="fa-solid fa-pen"/>
              </button>
              <league-button :league="l" v-for="l in _favoriteLeagues('BASKETBALL')"/>
              <button class="small" disabled v-if="_favoriteLeagues('BASKETBALL').length === 0">
                Aucune ligue sélectionnée
              </button>
            </td>
          </tr>
        </table>
      </div>
    </fieldset>
    <fieldset class="mt-2 no-border">
      <legend>Hockey</legend>
      <div class="px-2 py-1">
        <table>
          <tr>

            <td>
              <button class="small info" @click="_openSelectionLeagueModal('HOCKEY')">
                <font-awesome-icon icon="fa-solid fa-pen"/>
              </button>
              <league-button :league="l" v-for="l in _favoriteLeagues('HOCKEY')"/>
              <button class="small" disabled v-if="_favoriteLeagues('HOCKEY').length === 0">
                Aucune ligue sélectionnée
              </button>
            </td>
          </tr>
        </table>
      </div>
    </fieldset>
    <fieldset class="mt-2 no-border">
      <legend>Handball</legend>
      <div class="px-2 py-1">
        <table>
          <tr>
            <td>
              <button class="small info" @click="_openSelectionLeagueModal('HANDBALL')">
                <font-awesome-icon icon="fa-solid fa-pen"/>
              </button>
              <league-button :league="l" v-for="l in _favoriteLeagues('HANDBALL')"/>
              <button class="small" disabled v-if="_favoriteLeagues('HANDBALL').length === 0">
                Aucune ligue sélectionnée
              </button>
            </td>
          </tr>
        </table>
      </div>
    </fieldset>
    <fieldset class="mt-2 no-border">
      <legend>Rugby</legend>
      <div class="px-2 py-1">
        <table>
          <tr>
            <td>
              <button class="small info" @click="_openSelectionLeagueModal('RUGBY')">
                <font-awesome-icon icon="fa-solid fa-pen"/>
              </button>
              <league-button :league="l" v-for="l in _favoriteLeagues('RUGBY')"/>
              <button class="small" disabled v-if="_favoriteLeagues('RUGBY').length === 0">
                Aucune ligue sélectionnée
              </button>
            </td>
          </tr>
        </table>
      </div>
    </fieldset>
    <fieldset class="mt-2 no-border">
      <legend>Football Américain</legend>
      <div class="px-2 py-1">
        <table>
          <tr>
            <td>
              <button class="small info" @click="_openSelectionLeagueModal('AMERICAN_FOOTBALL')">
                <font-awesome-icon icon="fa-solid fa-pen"/>
              </button>
              <league-button :league="l" v-for="l in _favoriteLeagues('AMERICAN_FOOTBALL')"/>
              <button class="small" disabled v-if="_favoriteLeagues('AMERICAN_FOOTBALL').length === 0">
                Aucune ligue sélectionnée
              </button>
            </td>
          </tr>
        </table>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="less">
table {
  button {
    float: left;
  }
}
</style>
<script>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {monitoringStore} from "@/stores/Monitoring.store";
import {offerStore} from "@/stores/Offer.store";

export default {
  name: "MonitoringTicketTemplateBet",
  components: {FontAwesomeIcon},
  props: {
    sport: {
      required: true
    },
    item: {
      required: true
    },
  },

  computed: {
    _family(){
      if(!this.item) return null
      const families = offerStore().getOfferFamilyDefinitionsBySport(this.sport)
      if(!families) return null
      return families.items.find(f => f.fixedUID === this.item.offerFamilyFixedUID)
    },

    _familyLabel(){
      if(!this._family) return '?'
      return offerStore().getOfferFamilyDefinitionLabel(this._family.code)
    },

    _label(){
      if(!this.item) return '?'
      const ofc = offerStore().getOfferFamilyChoiceBySportAndFixedUID(this.sport, this.item.offerFamilyChoiceFixedUID)
      return offerStore().getOfferFamilyChoiceLabel(ofc, this.item.offerArgument, false)
    }
  },

  methods: {
    _delete() {
      monitoringStore().deleteMonitoringTicketTemplateBet(this.item.uid, null)
    }
  }

}
</script>

<template>
  <div :class="{'monitoring-ticket-template-bet px-1': true}">
    <label>{{_familyLabel}} : {{_label}}</label>
    <div class="flex-grow"/>
    <button class="square small secondary remove-ticket-template-bet no-radius" @click="_delete"
            style="height: auto">
      <font-awesome-icon icon="fa-solid fa-close"/>
    </button>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.monitoring-ticket-template-bet {
  display: flex;

  .remove-ticket-template-bet {
    color: #1C1C1C !important;
    visibility: hidden;
    width: 20px !important;
    min-width: 20px !important;
  }

  &:hover {
    .remove-ticket-template-bet {
      visibility: visible;
    }
  }
}
</style>
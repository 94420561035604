<script>

import {bankrollStore} from "../../../../../../stores/Bankroll.store";
import {bankrollPaneStore} from "../BankrollPane.store";
import {modalStore} from "../../../../../../stores/Modal.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {message, Modal} from "ant-design-vue";

export default {
  name: "BankrollPaneHeader",
  components: {FontAwesomeIcon},
  computed: {

    _bankrollActiveUID() {
      return bankrollPaneStore().getBankrollActiveUID();
    },
    _bankrollActive() {
      return bankrollStore().get(this._bankrollActiveUID);
    },
    _bankrollActiveName() {
      if (!this._bankrollActive) return "Aucune bankroll sélectionnée";
      // Keep only the first 20 characters
      if (this._bankrollActive.name.length > 25) {
        return this._bankrollActive.name.substring(0, 25) + '...'
      }

      return this._bankrollActive.name
    },
    _isBankrollYours() {
      return this._bankrollActive && bankrollStore().isYours(this._bankrollActive.uid)
    },
  },
  methods: {
    _onClickSelection() {
      modalStore().openModal({uid: "bankrollsModal"})
    },
    _resetBankroll() {
      Modal.confirm({
        title: 'Es-tu sûr de vouloir remettre à zéro cette bankroll ?',
        content: 'Cette action est irréversible. Tous les tickets seront supprimés. Les stats seront remises à zéro.',
        okText: 'Oui, supprimer',
        okType: 'danger',
        cancelText: 'Annuler',
        onOk: () => {
          bankrollStore().reset(this._bankrollActive.uid)
        },
        onCancel: () => {
          // Nothing to do
        },
      });
    },

    _copyLinkInClip() {
      const url = window.location.origin + window.location.pathname + '#/bankroll?uid=' + this._bankrollActiveUID
      navigator.clipboard.writeText(url).then(() => {
        message.success('Lien copié dans le presse-papier')
      }, () => {
        message.error('Impossible de copier le lien dans le presse-papier')
      })
    }
  },

  mounted() {
    if (!this._bankrollActive) {
      const allBk = bankrollStore().getUserBankrolls
      if (allBk && allBk.length > 0) {
        bankrollPaneStore().setBankrollActiveUID(allBk[0].uid)
      }
    }
  },
}
</script>

<template>
  <header id="bankroll-pane-header">
    <div class="flex p-1">
      <button class="mr-1 primary square" @click="_copyLinkInClip">
        <font-awesome-icon icon="fa-solid fa-link"/>
      </button>
      <button class="accent flex-grow" @click="_onClickSelection">
        {{ _bankrollActiveName }}
      </button>
      <button class="ml-1 secondary square" @click="_resetBankroll" v-if="_bankrollActive && _isBankrollYours">
        <font-awesome-icon icon="fa-solid fa-eraser"/>
      </button>
    </div>
  </header>
</template>

// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";
import {teamStore} from "@/stores/Team.store";

export const teamLoaderStore = defineStore('teamLoaderStore', {
    state: () => {
        return {
            loadingUIDs: [],
            fetchTeamByUIDTimeout: null
        }
    },
    actions: {
        // Permet de récupérer un match par son UID
        fetchTeamByUID(uid) {

            if (!uid) return

            if (this.loadingUIDs.indexOf(uid) < 0) {
                this.loadingUIDs.push(uid)
            }

            // Si on a 100 équipes à charger, on le fait tout de suite
            const timeout = 250

            if (this.fetchTeamByUIDTimeout) clearTimeout(this.fetchTeamByUIDTimeout)
            this.fetchTeamByUIDTimeout = setTimeout(() => {
                axiosService.get('/dta/teams/' + this.loadingUIDs.join(',')).then(response => {
                    teamStore().append(response.data.results)

                    const loadedUIDs = response.data.results.map(item => item.uid)
                    this.loadingUIDs = this.loadingUIDs.filter(uid => loadedUIDs.indexOf(uid) < 0)
                });

            }, timeout)

        }
    },
    getters: {
        isLoading: (state) => (uid) => {
            return state.loadingUIDs.indexOf(uid) >= 0
        }
    }
})
<script>
import Statistic from "@/components/common/Statistic.vue";
import {gameStore} from "@/stores/Game.store";

export default {
  name: "SelectionPaneItem.Stats.Tab.Result.DoubleChance",
  components: {Statistic},
  props: {
    gameUid: {
      required: true
    },
    data: {
      type: Array,
      default: []
    },
    mergeMethod: {
      type: Function,
      default: () => {
        console.error('mergeMethod is required')
      }
    },
  },

  data() {
    return {
      selectedChoice: null
    }
  },

  methods: {
    _setChoice(choice) {
      if (this.selectedChoice === choice) {
        this.selectedChoice = null
      } else {
        this.selectedChoice = choice
      }
    }
  },

  computed: {
    _game() {
      if (!this.gameUid) return null
      return gameStore().get(this.gameUid)
    },

    _gameHomeTeamUID() {
      if (!this._game) return null
      return this._game.homeTeamUID
    },

    _gameAwayTeamUID() {
      if (!this._game) return null
      return this._game.awayTeamUID
    },

    _1xHomeStats() {
      if (!this._game) return null
      if (!this.data) return null
      return this.mergeMethod(this.data, [{
        fixedUID: [4, 10, 16],
        whoUID: [this._gameHomeTeamUID]
      }, {
        fixedUID: [5, 11, 17],
        whoUID: [this._gameAwayTeamUID]
      }
      ])
    },
    _12DrawStats() {
      if (!this.data) return null
      return this.mergeMethod(this.data, [{
        fixedUID: [6, 12, 18],
        whoUID: [this._gameHomeTeamUID, this._gameAwayTeamUID]
      }])
    },
    _x2AwayStats() {
      if (!this.data) return null
      return this.mergeMethod(this.data, [{
        fixedUID: [4, 10, 16],
        whoUID: [this._gameAwayTeamUID]
      }, {
        fixedUID: [5, 11, 17],
        whoUID: [this._gameHomeTeamUID]
      }
      ])
    },
    _selectedChoiceData() {
      if (!this.data) return []
      switch (this.selectedChoice) {
        case 'HOME_DRAW':
          return this._1xHomeStats.stats
        case 'NO_DRAW':
          return this._12DrawStats.stats
        case 'DRAW_AWAY':
          return this._x2AwayStats.stats
        default:
          return []
      }
    }
  }
}
</script>

<template>
  <fieldset class="fieldset-bloc mx-1 mt-2 text-left" v-if="data">
    <legend>Double chance</legend>
    <div class="flex">
      <div class="flex-grow" v-if="_1xHomeStats">
        <button class="stats-merge-item-button" @click="_setChoice('HOME_DRAW')"
                :class="{'not-selected': selectedChoice && selectedChoice !== 'HOME_DRAW'}">
          <span class="stats-merge-item-button-label">1/X</span>
          <span class="stats-merge-item-button-value">
                <span>{{ _1xHomeStats.points }}</span>
                <span class="stats-merge-item-button-sub-value"><sup>({{ _1xHomeStats.stats.length }})</sup></span>
              </span>
        </button>
      </div>
      <div class="flex-grow mx-1" v-if="_12DrawStats">
        <button class="stats-merge-item-button" @click="_setChoice('NO_DRAW')"
                :class="{'not-selected': selectedChoice && selectedChoice !== 'NO_DRAW'}">
          <span class="stats-merge-item-button-label">1/2</span>
          <span class="stats-merge-item-button-value">
                <span>{{ _12DrawStats.points }}</span>
                <span class="stats-merge-item-button-sub-value"><sup>({{ _12DrawStats.stats.length }})</sup></span>
              </span>
        </button>
      </div>
      <div class="flex-grow" v-if="_x2AwayStats">
        <button class="stats-merge-item-button" @click="_setChoice('DRAW_AWAY')"
                :class="{'not-selected': selectedChoice && selectedChoice !== 'DRAW_AWAY'}">
          <span class="stats-merge-item-button-label">X/2</span>
          <span class="stats-merge-item-button-value">
                <span>{{ _x2AwayStats.points }}</span>
                <span class="stats-merge-item-button-sub-value"><sup>({{ _x2AwayStats.stats.length }})</sup></span>
              </span>
        </button>
      </div>
    </div>
    <div class="mt-1" v-if="selectedChoice !== null">
      <a-alert v-if="data.length === 0" message="Aucune statistique remarquable." type="info" show-icon/>
      <statistic :stat="item.stat" :period="item.period" v-for="item in _selectedChoiceData"/>
    </div>
  </fieldset>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";
</style>
<script>
import UserDropdownMenu from "@/components/common/UserDropdownMenu.vue";
import {contextStore} from "@/stores/Context.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {preferencesStore} from "@/stores/Preferences.store";
import {modalStore} from "@/stores/Modal.store";
import {sportStore} from "@/stores/Sport.store";


export default {
  name: "MainHeader",
  components: {FontAwesomeIcon, UserDropdownMenu},
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _currentRoute() {
      return this.$route.name
    },
    _currentRouteIcon() {
      if (!this._currentRoute) return ''
      switch (this._currentRoute) {
        case 'main':
          return 'fa-solid fa-bars'
        case 'stats':
          return 'fa-solid fa-chart-pie'
        case 'bankroll':
          return 'fa-solid fa-wallet'
        case 'players':
          return 'fa-solid fa-user'
        case 'games':
          return 'fa-solid fa-object-group'
        default:
          return 'fa-solid fa-bars'
      }
    },
    _currentRouteLabel() {
      if (!this._currentRoute) return ''
      switch (this._currentRoute) {
        case 'stats':
          return 'Stats'
        case 'bankrolls':
          return 'Bankrolls'
        case 'games':
          return 'Rencontres'
        case 'players':
          return 'Joueurs'
        case 'main':
        default:
          return 'DoctoBET'
      }
    },
    _canUpgrade() {
      if (!contextStore().isAuthenticated()) return false
      if (!contextStore().isLicensed()) return true
      return contextStore().getLicenseType() === 'BASIC'
    },
    _sports() {
      return sportStore().getAll
    },
    _selectedSport() {
      return sportStore().get(contextStore().getSport)
    },
    _shouldShowControls() {
      return this._currentRoute !== 'main'
    }
  },
  methods: {

    _goToMain() {
      this.$router.push({name: 'main'})
    },
    _goToStats() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['stats'],
          () => {
            this.$router.push({name: 'stats'})
          }
      )
    },
    _goToGames() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['games'],
          () => {
            this.$router.push({name: 'games'})
          }
      )
    },
    _goToPlayers() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['players'],
          () => {
            this.$router.push({name: 'players'})
          }
      )
    },
    _goToBankroll() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['bankrolls'],
          () => {
            this.$router.push({name: 'bankrolls'})
          }
      )
    },
    _openPaypalModal() {
      if (!this._canUpgrade) return
      const formule = contextStore().getLicenseType() === 'NONE' ? 'BASIC' : 'FULL'
      modalStore().openModal({
        uid: 'paypalModal',
        payload: {
          formule: formule
        }
      })
    },

    _changeSport(sport) {
      contextStore().setSport(sport.code)

      // On enregistre le sport par defaut.
      preferencesStore().save('SPORT', 'FAVORITE_SPORT', [sport.code])
    },

  },
}
</script>

<template>
  <header id="main-header">

    <div id="main-header-left">
      <span id="app-logo-gradient"></span>
      <span id="app-logo" class="float-left">
        <a-tooltip placement="right" title="DoctoBET" @click="_goToMain()">
          <img src="../../assets/images/logo-40.png" alt="DoctoBET" style="width: 40px; height: 40px; cursor: pointer"/>
        </a-tooltip>
      </span>

      <div class="flex mt-1" v-if="_shouldShowControls">
        <a-dropdown :trigger="['click']">
          <button>
            <font-awesome-icon  :icon="_currentRouteIcon"/>
            {{ _currentRouteLabel }}
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="_goToStats" class="text-left" style="font-size: 11pt !important;">
                <font-awesome-icon icon="fa-solid fa-chart-pie" style="width: 20px"/>
                <span class="ml-2">Stats</span>
              </a-menu-item>
              <a-menu-item @click="_goToGames" class="text-left" style="font-size: 11pt !important;">
                <font-awesome-icon :icon="_selectedSport.icon" style="width: 20px"/>
                <span class="ml-2">Rencontres</span>
              </a-menu-item>
              <a-menu-item @click="_goToPlayers" class="text-left" style="font-size: 11pt !important;">
                <font-awesome-icon icon="fa-solid fa-user" style="width: 20px"/>
                <span class="ml-2">Joueurs</span> <a-tag color="red" style="width:36px; margin-left:10px">BETA</a-tag>
              </a-menu-item>
              <a-menu-item @click="_goToBankroll" class="text-left" style="font-size: 11pt !important;">
                <font-awesome-icon icon="fa-solid fa-wallet" style="width: 20px"/>
                <span class="ml-2">Bankrolls</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

        <a-dropdown :trigger="['click']" class="ml-1">
          <button class="square">
            <font-awesome-icon :icon="_selectedSport.icon"/>
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="sport in _sports" :key="sport.uid" @click="_changeSport(sport)" class="text-left"
                           style="font-size: 11pt !important;">
                <font-awesome-icon :icon="sport.icon" style="width: 20px"/>
                <span class="ml-2">{{ sport.label }}</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>

    <div id="main-header-right" v-if="_shouldShowControls">
      <button class="primary mr-1" v-if="_canUpgrade" @click="_openPaypalModal">
        <font-awesome-icon icon="fa-solid fa-crown"/>
        <span v-if="!_isMobile" class="ml-2">Upgrade</span>
      </button>
      <user-dropdown-menu/>
    </div>

  </header>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#main-header {
  background-color: transparent;

  #main-header-search-input {
    width: 400px;
    height: calc(@toolbar-regular - @padding-regular);
    border-radius: @radius-regular;
    text-align: center;
    background: @background-color-light;
  }

  #main-header-left {
    float: left;

    #app-logo-gradient {
      width: 200px;
      height: @toolbar-regular;
      display: inline-block;
      position: absolute;
      left: 0;
      opacity: 0.25;
      background: rgb(97, 233, 255);
      background: linear-gradient(90deg, rgba(97, 233, 255, 1) 0%, rgba(34, 193, 195, 1) 20%, rgba(253, 187, 45, 1) 40%, rgba(255, 132, 110, 1) 60%, rgba(43, 45, 48, 1) 85%);
    }
  }

  #main-header-right {
    float: right;
  }
}

.mobile {
  #main-header {
    text-align: left;

    #main-header-search-input {
      width: 32px;
      margin-left: 10px;
    }
  }
}

</style>
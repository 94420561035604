<script>
import SelectionOperation from "@/components/routes/selection/panes/selection/condition/SelectionOperation.vue";
import SelectionConditionCriteria from "@/components/routes/selection/modals/criteria/SelectionConditionCriteria.vue";

export default {
  name: "ConditionFormLeagueResult",
  components: {SelectionConditionCriteria, SelectionOperation},

  props: {
    modelValue: {
      type: Object,
      required: true
    },
  },

  beforeMount() {
    this.form = this.modelValue;
    // On clear les valeurs dont on a pas besoin dans ce cas
    this.form.who = null;
    this.form.against = null;
    this.form.leagueStrict = null;

    // On va vérifer l'état du formulaire. et appliquer des valeurs par defaut si besoin
    if (this.what.choices.indexOf(this.form.what) === -1) {
      // On applique une valeur par defaut car la valeur actuelle n'est pas dans les choix
      this.form.what = this.what.default;
    }
    if (this.locationStrict.choices.indexOf(this.form.locationStrict) === -1) {
      // On applique une valeur par defaut car la valeur actuelle n'est pas dans les choix
      this.form.locationStrict = this.locationStrict.default;
    }
    if (this.period.choices.indexOf(this.form.period) === -1) {
      // On applique une valeur par defaut car la valeur actuelle n'est pas dans les choix
      this.form.period = this.period.default;
    }
  },

  data() {
    return {
      // Formulaire
      form: {},

      // Possibilité de saisies dans le formulaire
      what: {
        label: 'Résultat',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Victoire", value: {choice: "WIN"}},
          {label: "Nul", value: {choice: "DRAW"}},
          {label: "Défaite", value: {choice: "LOSE"}}
        ],
        default: {
          choice: "WIN"
        }
      },
      locationStrict: {
        label: 'Position',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Domicile & Extérieur", value: {choice: "FALSE"}},
          {label: "Domicile", value: {choice: "HOME_TEAM"}},
          {label: "Extérieur", value: {choice: "AWAY_TEAM"}},
        ],
        default: {
          choice: "FALSE",
        }
      },
      period: {
        label: 'Période',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {
            label: "Cette saison",
            value: {
              choice: "LAST_SEASON",
              min: 1
            }
          },
        ],
        default: {
          choice: "LAST_SEASON",
          min: 1
        }
      }
    }
  },
  computed: {}
}
</script>

<template>
  <div>

    <div class="selection-condition-item-resume">
      Rechercher les rencontres suivant la récurrence d'un résultat dans la compétition dans laquelle elles se jouent.
    </div>
    <div class="condition-form-criteria">
      <selection-condition-criteria v-model="form.what" :choices="what.choices" :label="what.label" :type="what.type"/>
      <selection-condition-criteria v-model="form.locationStrict" :choices="locationStrict.choices"
                                    :label="locationStrict.label"/>
      <selection-condition-criteria v-model="form.period" :choices="period.choices" :label="period.label"/>

    </div>

  </div>
</template>

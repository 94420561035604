<script>

export default {
  name: "ItemMatchingIndicator",
  components: {

  },
  props: {
    matchingScore: {
      type: Number,
      default: 0
    },
    minScore: {
      type: Number
    },
    maxScore: {
      type: Number
    }
  },
  computed: {
    _matchingScore() {
      return parseFloat(this.matchingScore.toFixed(1))
    },
    _relativeMatchingScore() {
      if (this.matchingScore < this.minScore) {
        return 0
      }
      if (this.matchingScore >= this.maxScore) {
        return 1
      }

      return parseFloat(this.matchingScore / this.maxScore).toFixed(2)
    },
    _matchingScoreTooltip() {
      return 'Score : ' + this._matchingScore
    },
  }
}
</script>

<template>
  <div class="item-matching-indicator" v-if="_matchingScore > 0">
    <a-tooltip placement="top" :title="_matchingScoreTooltip">
      <font-awesome-icon icon="fa-regular fa-star" v-if="_relativeMatchingScore < 1/6.0"
                         :style="{color: _relativeMatchingScore > ((0/6.0 + 1/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                         class="mr-1"/>
      <font-awesome-icon icon="fa-solid fa-star" v-else
                         :style="{color: _relativeMatchingScore >= ((1/6.0 + 2/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                         class="mr-1"/>

      <font-awesome-icon icon="fa-regular fa-star" v-if="_relativeMatchingScore <= 3/6.0"
                         :style="{color: _relativeMatchingScore >= ((2/6.0 + 3/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                         class="mr-1"/>
      <font-awesome-icon icon="fa-solid fa-star" v-else
                         :style="{color: _relativeMatchingScore >= ((3/6.0 + 4/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                         class="mr-1"/>

      <font-awesome-icon icon="fa-regular fa-star" v-if="_relativeMatchingScore <= 5/6.0"
                         :style="{color: _relativeMatchingScore >= ((4/6.0 + 5/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                         class="mr-1"/>
      <font-awesome-icon icon="fa-solid fa-star" v-else
                         :style="{color: _relativeMatchingScore >= ((5/6.0 + 6/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                         class="mr-1"/>
    </a-tooltip>
    <button class="small square p-0 mr-1">
      {{ _matchingScore }}
    </button>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.small-flag {
  height: 35px;
}
</style>
export const conditionFormMixins = {
    // generic funcion
    methods: {
        _matchOneChoice(choice, choices) {

            if (!choice) return false

            // check if choice is in choices ignored label
            let matches = choices.filter(c => {
                let value = c.value;
                let sameChoice = value.choice === choice.choice;
                let sameMin = value.min === choice.min;
                let sameMax = value.max === choice.max;
                return sameChoice && sameMin && sameMax;
            });
            return matches.length > 0;
        }
    }
}
// stores/counter.js
import {defineStore} from 'pinia'

export const widgetDataStore = defineStore('widgetDataStore', {
    state: () => {
        return {
            // Map dont la clé est WIDGET_UID/GAME_UID
            map: null,
        }
    },
    actions: {
        put(widgetUID, gameUID, data) {
            if (!this.map) {
                this.map = {}
            }
            if (!this.map[gameUID]) {
                this.map[gameUID] = {}
            }
            if (!this.map[gameUID][widgetUID]) {
                this.map[gameUID][widgetUID] = {}
            }
            this.map[gameUID][widgetUID] = data
        },
        remove(gameUID) {
            if (!this.map) return
            this.map[gameUID] = null
        },
        clearWidgetData(widgetUID) {
            if (!this.map) return
            for (let gameUID in this.map) {
                if (this.map[gameUID] && this.map[gameUID][widgetUID]) {
                    this.map[gameUID][widgetUID] = null
                }
            }
        }
    },
    getters: {
        get: (state) => (widgetUID, gameUID) => {
            if (state.map === null) return null
            let gameData = state.map[gameUID];
            if (!gameData) return null
            let gameWidgetData = gameData[widgetUID]
            if (!gameWidgetData) return null
            return gameWidgetData
        },
    }
})
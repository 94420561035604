<script>
import {perspectiveStore} from "@/stores/Perspective.store";
import {contextStore} from "@/stores/Context.store";
import {analysePaneStore} from "@/components/routes/selection/panes/analyse/AnalysePane.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {message} from "ant-design-vue";
import {modalStore} from "@/stores/Modal.store";
import {preferencesStore} from "@/stores/Preferences.store";

export default {
  name: "AnalysePerspectivesBottomBar",
  components: {FontAwesomeIcon},
  computed: {
    _currentGameSport() {
      return contextStore().getSport
    },
    _perspectives() {
      return perspectiveStore().getBySportAndType(this._currentGameSport, 'GAME')
    },
    _selectedPerspective() {
      return analysePaneStore().getPerspectiveBySport(this._currentGameSport)
    },
    _selectedPerspectiveUID() {
      const perspective = this._selectedPerspective
      if (!perspective) return null
      return perspective.uid
    },
    _isPerspectiveYours() {
      if (!contextStore().isAuthenticated()) return false
      const perspective = this._selectedPerspective
      if (!perspective) return false
      return perspective.userUID === contextStore().getLoggedUserUID()
    }
  },
  methods: {
    _isSelected(perspective) {
      return perspective.uid === this._selectedPerspectiveUID
    },
    _switchEditMode(perspective) {
      perspectiveStore().switchEditMode(perspective.uid)
    },
    _openWidgetStore(perspective) {
      if (!perspective) return
      modalStore().openModal({
        uid: 'widgetStoreModal', payload: {
          perspective: perspective
        }
      })
    },
    _onClick(perspective) {
      if (this._isSelected(perspective)) {
        this._showPerspectiveSettingsModal(perspective)
      } else {
        const gameUID = contextStore().gameUID
        analysePaneStore().setPerspective(gameUID, perspective.uid)

        // Save the perspective as default for the user/sport
        preferencesStore().save('DEFAULT_PERSPECTIVE', this._currentGameSport + '_PERSPECTIVE', [perspective.uid])
      }
    },
    _showPerspectiveSettingsModal(perspective, creation = false) {

      if (perspective.userUID !== contextStore().getLoggedUserUID()) {
        return
      }

      modalStore().openModal({
        uid: 'perspectiveSettingsModal', payload: {
          uid: perspective.uid,
          creation: creation,
          onCreate : () => {
            const gameUID = contextStore().gameUID
            analysePaneStore().setPerspective(gameUID, this._perspectiveUID)
          }
        }
      })
    },
    _createPerspective() {
      if (!contextStore().isAuthenticated()) {
        message.error('Tu dois être connecté pour créer une perspective')
        return
      }

      if (!this._selectedPerspective) {
        message.error('Tu dois sélectionner une perspective')
        return
      }

      const sport = this._selectedPerspective.sport
      perspectiveStore().create('GAME', sport, (perspective) => {
        this._showPerspectiveSettingsModal(perspective, true)
      })
    }
  }
}
</script>

<template>
  <div id="analyse-perspectives-bottom-bar">
    <div class="flex">
      <div class="flex-grow">
        <span v-for="p in _perspectives">
          <a-tooltip placement="top" :title="p.description">
            <button :class="{'no-radius small tab': true, 'selected': _isSelected(p)}"
                    @click="_onClick(p)">
              {{ p.name }}
            </button>
          </a-tooltip>

        </span>
      </div>
      <div>
        <a-tooltip placement="top" title="Ajouter un widget"
                   v-if="_isPerspectiveYours && _selectedPerspective && _selectedPerspective.editMode === false">
          <button class="small info"
                  @click="_switchEditMode(_selectedPerspective)">
            Modifier
          </button>
        </a-tooltip>
        <a-tooltip placement="top" title="Ajouter un widget"
                   v-if="_isPerspectiveYours && _selectedPerspective && _selectedPerspective.editMode === true">
          <button class="small info perspective-tab mr-1"
                  @click="_openWidgetStore(_selectedPerspective)">
            Ajouter un widget
          </button>
        </a-tooltip>
        <a-tooltip placement="top" title="Ajouter un widget"
                   v-if="_isPerspectiveYours && _selectedPerspective && _selectedPerspective.editMode === true">
          <button class="small primary selected info perspective-tab"
                  @click="_switchEditMode(_selectedPerspective)">
            Valider
          </button>
        </a-tooltip>
        <a-tooltip placement="top" title="Créer une perspective" v-if="_selectedPerspective && _selectedPerspective.editMode === false">
          <button class="square small info ml-1"
                  @click="_createPerspective()">
            <font-awesome-icon icon="fa-solid fa-plus"/>
          </button>
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#analyse-perspectives-bottom-bar {
  height: @toolbar-small;
  width: calc(100% - 400px - 3 * @padding-small);
  overflow-y: hidden;
  overflow-x: hidden;
  position: fixed;
  bottom: 0;
  background: @background-color-light;
  margin: 0;
  padding: 0;
  text-align: left;
  border-top: 1px solid @background-color;

  .tab {
    height: calc(@toolbar-small - 2px);
  }
}
</style>
<script>

import {preferencesStore} from "@/stores/Preferences.store";
import {bookmakerStore} from "@/stores/Bookmaker.store";

export default {
  name: "UserBettingPreferences",
  components: {},
  data() {
    return {
      arjelOnly: null,
      favoriteBookmakerUID: null
    }
  },
  mounted() {
    this.arjelOnly = this._preferencesStore.getBoolean('BETTING', 'ARJEL_ONLY', false)

    let bookmaker = this._preferencesStore.getFirstValue('BETTING', 'FAVORITE_BOOKMAKER');
    if (bookmaker) {
      this.favoriteBookmakerUID = bookmaker
    }
  },
  computed: {
    _preferencesStore() {
      return preferencesStore()
    },
    _bookmakerStore() {
      return bookmakerStore()
    }
  },
  methods: {
    _onChangeBookmaker() {
      this._preferencesStore.save('BETTING', 'FAVORITE_BOOKMAKER', [this.favoriteBookmakerUID])
    },
    _savePref(cat, cle, values) {
      this._preferencesStore.save(cat, cle, values)
    }
  }
}
</script>

<template>
  <div class="ml-2 pb-2">
    <fieldset class="mt-2">
      <legend>ARJEL</legend>
      <div class="px-2 py-1">
        <label class="inline-block mr-2">Utiliser uniquement les ligues ARJEL ?</label>
        <select v-model="arjelOnly" @change="_savePref('BETTING', 'ARJEL_ONLY', [arjelOnly])">
          <option :value="false">Non</option>
          <option :value="true">Oui</option>
        </select>
      </div>
    </fieldset>
    <fieldset class="mt-2">
      <legend>Bookmaker</legend>
      <div class="px-2 py-1">
        <label class="inline-block mr-2">Par default</label>
        <select v-model="favoriteBookmakerUID" @change="_onChangeBookmaker">
          <option v-for="b in _bookmakerStore.getAll" :value="b.uid">{{ b.name }}</option>
        </select>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="less">

</style>
<script>
import TeamLogo from "@/components/common/TeamLogo.vue";
import GameDate from "@/components/common/GameDate.vue";
import GameScore from "@/components/common/GameScore.vue";
import IndicateurCircle from "@/components/common/IndicateurCircle.vue";
import {gameStore} from "@/stores/Game.store";
import {leagueStore} from "@/stores/League.store";
import AreaFlag from "@/components/common/AreaFlag.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";

export default {
  name: "SimpleGameRow",
  components: {LeagueLogo, AreaFlag, IndicateurCircle, GameScore, GameDate, TeamLogo},
  props: {
    gameUid: {
      required: true
    }
  },
  computed: {
    game() {
      return gameStore().get(this.gameUid)
    },
    homeTeamUID() {
      if (!this.game) return null
      return this.game.homeTeamUID
    },
    awayTeamUID() {
      if (!this.game) return null
      return this.game.awayTeamUID
    },
    leagueUID() {
      if (!this.game) return null
      return this.game.leagueUID
    },
    league() {
      if (!this.leagueUID) return null
      return leagueStore().get(this.leagueUID)
    },
    leagueArea() {
      if (!this.league) return null
      return this.league.area
    }
  },
  methods: {
    _indicateurCircleColor(location) {
      if (!this.game) return 'neutral'
      const result = gameStore().getResultFor(this.game, location)
      if (result > 0) return 'positive'
      return 'neutral'
    },
  }
}
</script>

<template>
  <tr>
    <td>
      <area-flag :area="leagueArea"/>
    </td>
    <td>
      <league-logo :uid="leagueUID" size="small"/>
    </td>
    <td>
      <game-date :uid="gameUid"/>
    </td>
    <td>
      <team-logo :uid="homeTeamUID" size="small"/>
    </td>
    <td>
      <span style="display: inline-block" class="mr-1">
        <indicateur-circle :type="_indicateurCircleColor('HOME')"/>
      </span>
      <game-score :uid="gameUid" style="display: inline-block"/>
      <span style="display: inline-block" class="ml-1">
        <indicateur-circle :type="_indicateurCircleColor('AWAY')"/>
      </span>
    </td>
    <td>
      <team-logo :uid="awayTeamUID" size="small"/>
    </td>
  </tr>
</template>

<style scoped lang="less">

</style>
<script>
import {bookmakerStore} from "@/stores/Bookmaker.store";

export default {
  name: "OfferModalRow",
  props: {
    bookmakerUid: {},
    values: {}
  },
  computed: {
    _bookmaker() {
      if (!this.bookmakerUid) return null
      return bookmakerStore().get(this.bookmakerUid)
    },
    _bookmakerName() {
      if (!this._bookmaker) return null
      return this._bookmaker.name
    }
  },
  methods: {

  },

}
</script>

<template>
  <tr>
    <td>{{ _bookmakerName }}</td>
    <td>{{ values.trj }}</td>
    <td>{{ values.opening }}</td>
    <td>{{ values.min }}</td>
    <td>{{ values.max }}</td>
    <td>{{ values.current }}</td>
  </tr>
</template>

<style scoped lang="less">

</style>
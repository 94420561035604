<script>

import PlayerAnalysePaneHeaderTab
  from "@/components/routes/player/panes/analyse/header/PlayerAnalysePane.Header.Tab.vue";
import {playerAnalysePaneStore} from "@/components/routes/player/panes/analyse/PlayerAnalysePane.store";

export default {
  name: "PlayerAnalysePaneHeader",
  components: {PlayerAnalysePaneHeaderTab},
  computed: {
    _playerUIDs() {
      return playerAnalysePaneStore().getItems.map(item => item.playerUID)
    },
  },
  methods: {}
}
</script>

<template>
  <header id="player-analyse-pane-header">
      <player-analyse-pane-header-tab v-for="playerUID in _playerUIDs" :player-u-i-d="playerUID"/>
  </header>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

#player-analyse-pane-header {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  scrollbar-width: none; /* For Firefox */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
}

</style>
<script>
import Hour from "@/components/common/Hour.vue";

export default {
  name: "GameEvent",
  components: {Hour},
  props: {
    item: {
      required: true
    },
    showLabel: {
      default: false
    },
    showTime: {
      default: false
    }
  },
  computed: {
    _label() {
      switch (this.item.type) {
        case 'CARD':
          switch (this.item.subtype) {
            case 'YELLOW_CARD':
              return 'Carton jaune'
            case 'RED':
              return 'Carton rouge'
          }
          break
        case 'GOAL':
          return 'But'
        case 'ASSIST':
          return 'Passe décisive'
        case 'SUBSTITUTION':
          switch (this.item.subtype) {
            case 'SUBSTITUTION_IN':
              return 'Entrée'
            case 'SUBSTITUTION_OUT':
              return 'Sortie'
          }
      }
      return 'Inconnu'
    },
    _icon() {
      switch (this.item.type) {
        case 'CARD':
          return 'fa-square'
        case 'GOAL':
          return 'fa-futbol'
        case 'ASSIST':
          return 'fa-gift'
        case 'SUBSTITUTION':
          switch (this.item.subtype) {
            case 'SUBSTITUTION_IN':
              return 'fa-arrow-right-to-bracket'
            case 'SUBSTITUTION_OUT':
              return 'fa-arrow-right-from-bracket'
          }
      }
      return 'fa-question'
    },
    _color() {
      switch (this.item.type) {
        case 'CARD':
          switch (this.item.subtype) {
            case 'YELLOW_CARD':
              return 'yellow'
            case 'RED':
              return 'red'
          }
          break
        case 'GOAL':
        case 'ASSIST':
        case 'SUBSTITUTION':
          return 'white'
      }
      return 'violet'
    },
    _style() {
      return {
        color: this._color
      }
    }
  }
}
</script>

<template>
  <div class="game-event flex">
    <div class="game-event-icon-wrapper">
      <div class="game-event-icon">
        <font-awesome-icon :icon="_icon" :style="_style" class="mx-1"/>
      </div>
    </div>
    <span class="ml-1" v-if="showLabel">
      {{ _label }}
    </span>
    <span class="ml-1" v-if="showTime">
      à la {{item.time}}'
    </span>
  </div>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";

@event-size: 20px;
.game-event {
  line-height: @event-size;

  .game-event-icon-wrapper {
    overflow: hidden;
    background-color: @background-color;
    border: 1px solid @stroke-color;
    border-radius: @radius-regular;
    height: @event-size;
    width: @event-size;
    min-height: @event-size;
    min-width: @event-size;
    max-width: @event-size;
    max-height: @event-size;
    line-height: @event-size;
    font-size: 8pt;

    .game-event-icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: @event-size;
      overflow: hidden;
    }
  }
}
</style>

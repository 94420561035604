<script>

import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";

export default {
  name: "DrawNotBetCalculatorItem",
  components: {OfferChoiceButton, OfferBookmakerValue},
  data() {
    return {
      form: {
        eventOdd: 2.00,
        coverOdd: 4.00,
        totalStake: 100.0
      }
    }
  },
  computed: {
    _stakeToCover() {
      if (!this.form.coverOdd) return 0
      return parseFloat((this.form.totalStake / (this.form.coverOdd - 1.0)).toFixed(2))
    },
    _intermediateStake() {
      return parseFloat(this.form.totalStake) + parseFloat(this._stakeToCover)
    },
    _ratio() {
      return this.form.totalStake / this._intermediateStake
    },
    _coverStake() {
      if(!this.form.totalStake) return '-'
      return parseFloat((this._stakeToCover * this._ratio).toFixed(2))
    },
    _eventStake() {
      if(!this.form.totalStake) return '-'
      return parseFloat((this.form.totalStake - this._coverStake).toFixed(2))
    },
    _profitIfWin() {
      if(!this.form.totalStake) return '-'
      return parseFloat(((this._eventStake * this.form.eventOdd) - this._eventStake - this._coverStake).toFixed(2))
    },
    _equivalentOdd() {
      if(!this.form.totalStake) return '-'
      return (parseFloat(this._profitIfWin / this.form.totalStake)).toFixed(2)
    },
    _losePercent(){
      return ((this.form.eventOdd - this._equivalentOdd) / this.form.eventOdd * 100).toFixed(2)
    }

  },
  methods: {

  }
}
</script>

<template>
  <table>
    <tr>
      <th></th>
      <th>
        Côtes
      </th>
      <th>
        Mises
      </th>
    </tr>
    <tr>
      <th class="text-right">Evenement cible</th>
      <td class="text-center">
        <input type="number" v-model="form.eventOdd" step='0.01' placeholder="Côte" style="text-align: end; width: 64px">
      </td>
      <td>
        <input type="number" disabled :placeholder="_eventStake" placeholder="Mise" style="text-align: end; width: 64px">
      </td>
    </tr>
    <tr>
      <th class="text-right">Evenement de couverture</th>
      <td class="text-center">
        <input type="number" v-model="form.coverOdd" placeholder="Côte" style="text-align: end; width: 64px">
      </td>
      <td>
        <input disabled :placeholder="_coverStake" style="text-align: end; width: 64px">
      </td>
    </tr>
    <tr>
      <th class="text-right">Mise totale à engager</th>
      <td></td>
      <td style="width: 64px" class="text-right">
        <input type="number" v-model="this.form.totalStake" step='0.01' placeholder="Mise totale" style="text-align: end; width: 64px">
      </td>
    </tr>
  </table>

  <hr class="my-2"/>

  <table>
    <tr>
      <td>Profit si évenement</td>
      <td class="text-right"></td>
      <td class="text-right">
        <button class="info">{{_profitIfWin}}</button>
      </td>
    </tr>
    <tr>
      <td>Cote équivalente</td>
      <td class="text-right">
        <label>-{{_losePercent}}%</label>
      </td>
      <td class="text-right">
        <button class="info">{{_equivalentOdd}}</button>
      </td>
    </tr>
  </table>
</template>

<style scoped lang="less">

</style>
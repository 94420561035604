// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";
import {message} from "ant-design-vue";
import {contextStore} from "@/stores/Context.store";
import {gameStore} from "@/stores/Game.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import {analysePaneStore} from "@/components/routes/selection/panes/analyse/AnalysePane.store";

export const perspectiveStore = defineStore('perspectiveStore', {
    state: () => {
        return {
            items: null,
            widgets: null,
            parameters: null
        }
    },
    actions: {

        create(type, sport, callback = null) {
            const params = {
                type: type,
                sport: sport,
                name: "Nouvelle perspective",
                description: "Description de la perspective"
            }
            axiosService.post('/per', params).then(res => {
                if(!res.data.perspective) {
                    console.error('No perspective in response', res)
                    return
                }
                res.data.perspective.editMode = false
                this.items.push(res.data.perspective)
                if (callback) callback(res.data.perspective)
            })
        },

        delete(perspectiveUID, callback = null) {
            axiosService.delete('/per/' + perspectiveUID).then(res => {
                this.items = this.items.filter(p => p.uid !== perspectiveUID)
            }).finally(() => {
                if (callback) callback()
            })
        },

        /**
         *
         * @param perspectiveUID
         * @param form Object {name: string, description: string}
         * @param callback
         */
        update(perspectiveUID, form, callback = null) {
            if (!perspectiveUID) return
            if (!form) return
            if (!form.name) return
            if (!form.description) return
            axiosService.put('/per/' + perspectiveUID, form).then(res => {
                this.items.find(p => p.uid === perspectiveUID).name = form.name
                this.items.find(p => p.uid === perspectiveUID).description = form.description
            }).finally(() => {
                if (callback) callback()
            })
        },

        updateLayout(perspectiveUID, layout, callback = null) {
            if (!perspectiveUID) return

            const updatedWidgets = []
            layout.forEach((w, index) => {
                const widget = this.widgets.find(item => parseInt(item.uid) === parseInt(w.i))
                if (!widget) return

                if (widget.x === w.x && widget.y === w.y) {
                    return
                }

                widget.x = w.x
                widget.y = w.y
                updatedWidgets.push(w)
            })

            const payload = {
                widgets: updatedWidgets
            }
            axiosService.put('/per/' + perspectiveUID + '/layout', payload).then(res => {
                // Nothing to do
            })
        },

        // Permet de récupérer un match par son UID
        fetchAll(callback = null) {
            if (this.items !== null) {
                if (callback) callback()
                return
            }
            this.items = []
            axiosService.get('/per').then(response => {

                // On ajoute un flag editMode à chaque perspective
                response.data.perspectives.forEach(p => {
                    p.editMode = false
                })

                this.items = response.data.perspectives
                this.widgets = response.data.widgets
                this.parameters = response.data.parameters
            }).finally(() => {
                if (callback) callback()
            });
        },

        addWidget(perspectiveUID, widgetType, callback = null) {
            axiosService.post('/per/widget', {
                widgetType: widgetType,
                perspectiveUID: perspectiveUID
            }).then(res => {
                this.parameters.push(...res.data.parameters)
                this.widgets.push(res.data.widget)
            }).finally(() => {
                if (callback) callback()
            })
        },

        deleteWidget(widgetUID, callback = null) {
            axiosService.delete('/per/widget/' + widgetUID).then(res => {
                // On supprime les paramètres
                this.parameters = this.parameters.filter(p => p.widgetUID !== widgetUID)
                // On supprime le widget
                this.widgets = this.widgets.filter(w => w.uid !== widgetUID)
            }).finally(() => {
                if (callback) callback()
            })
        },

        switchEditMode(perspectiveUID, callback = null) {
            const perspective = this.items.find(p => p.uid === perspectiveUID)
            if(!perspective) {
                console.warn('Perspective not found', perspectiveUID)
                return
            }
            perspective.editMode = !perspective.editMode
            if (callback) callback()
        },

        resetAllEditMode() {
            if (!this.items) return
            this.items.forEach(p => p.editMode = false)
        }
    },
    getters: {
        get: (state) => (uid) => {
            return state.items.find(item => item.uid === parseInt(uid));
        },
        getCurrent: (state) => () => {
            const sport = contextStore().getSport
            if (!sport) return null
            return analysePaneStore().getPerspectiveBySport(sport)
        },
        getCurrentSport: (state) => () => {
            const perspective = perspectiveStore().getCurrent()
            if (!perspective) return null
            return perspective.sport
        },
        getBySportAndType: (state) => (sport, type) => {
            if (!state.items) {
                return []
            }
            return state.items.filter(item => item.sport === sport && item.type === type)
        },
        getWidgetsByPerspectiveUID: (state) => (perspectiveUID) => {
            if (!state.widgets) {
                return []
            }
            return state.widgets.filter(item => item.perspectiveUID === perspectiveUID)
        },
        getParametersByWidgetUID: (state) => (widgetUID) => {
            if (!state.parameters) {
                return []
            }
            return state.parameters.filter(item => item.widgetUID === widgetUID)
        },
        getParameterValue: (state) => (widgetUID, key) => {
            const parameters = perspectiveStore().getParametersByWidgetUID(widgetUID)
            if (!parameters) {
                return null
            }
            const parameter = parameters.find(p => p.key === key);
            if (!parameter) {
                return null
            }

            const values = parameter.values
            if (!values) {
                return null
            }
            if (values.length === 0) {
                return null
            }
            return values[0]
        },
        getParameterDoubleValue: (state) => (widgetUID, key) => {
            const rawVal = perspectiveStore().getParameterValue(widgetUID, key)
            if (!rawVal) return null
            return parseFloat(rawVal)
        },
        getParameterBooleanValue: (state) => (widgetUID, key) => {
            const rawVal = perspectiveStore().getParameterValue(widgetUID, key)
            if (!rawVal) return null
            return rawVal === 'true'
        },
        getParameterLeagueValue: (state) => (widgetUID) => {

            let paramValue = perspectiveStore().getParameterValue(widgetUID, 'league')
            if (!paramValue) return null
            switch (paramValue) {
                case 'ANY':
                    return null
                case 'CURRENT':
                    if (!contextStore().gameUID) return null
                    const game = gameStore().get(contextStore().gameUID)
                    if (!game) return null;
                    return game.leagueUID
            }
        },
        getParameterPeriodValue: (state) => (widgetUID) => {
            let paramValue = perspectiveStore().getParameterValue(widgetUID, 'period')
            if (!paramValue) return null
            return paramValue
        },
        getParameterTeamValue: (state) => (widgetUID) => {
            if (!contextStore().gameUID) return null
            let paramValue = perspectiveStore().getParameterValue(widgetUID, 'team')
            if (!paramValue) return null
            const game = gameStore().get(contextStore().gameUID)
            if(!game) {
                console.warn('Game not found', contextStore().gameUID)
                return null
            }
            switch (paramValue) {
                case 'HOME':
                    return game.homeTeamUID
                case 'AWAY':
                    return game.awayTeamUID
            }
        },
        getWidgetByUID: (state) => (widgetUID) => {
            if (!state.widgets) {
                return null
            }
            return state.widgets.find(item => item.uid === widgetUID)
        },
        saveWidgetParameters: (state) => (widgetUID) => {
            if (!state.parameters) {
                return null
            }
            const widgetParams = state.parameters.filter(item => item.widgetUID === widgetUID && item.key)

            axiosService.put('/per/widget-parameter', {
                widgetUID: widgetUID,
                parameters: widgetParams
            }).then(response => {
                message.success("Paramètrage sauvegardé")
            });
        },
        setWidgetParameterValue: (state) => (widgetUID, parameterDefinition, value) => {

            if(!widgetUID || !parameterDefinition) {
                console.error('Missing parameters', widgetUID, parameterDefinition)
                return false
            }

            const cs = contextStore()
            if (!cs.isAuthenticated()) {
                message.warning("Tu dois être connecté pour modifier les paramètres d'un widget.");
                return false;
            }

            let widget = state.widgets.find(item => item.uid === widgetUID)
            if (widget.userUID !== cs.loggedUser.uid) {
                message.warning("Ce widget n'est pas à toi.");
                return false;
            }

            let parameter = state.parameters.find(p => p.widgetUID === widgetUID && p.key === parameterDefinition.key)
            if (!parameter) {
                parameter = {
                    widgetUID: widgetUID,
                    key: parameterDefinition.key,
                    values: []
                }
                state.parameters.push(parameter)
            }

            if (parameterDefinition.multiple) {
                if (parameter.values.includes(value.key)) {
                    if (parameter.values.length === 1 && parameterDefinition.required) {
                        // On ne fait rien, pour ne pas permettre la suppression de la derniere valeur
                        return false
                    } else {
                        parameter.values = parameter.values.filter(v => v !== value.key)
                    }
                } else {
                    parameter.values.push(value.key)
                }
            } else {
                parameter.values = [value.key]
            }

            // clear les data du widget
            widgetDataStore().clearWidgetData(widgetUID)

            // On envoie un event pour dire que le widget a changé de paramètre
            window.emitter.emit('perspective-store:widget-setting:change', widget.uid)

            return true
        },
        isWidgetInCurrentPerspective: (state) => (widgetUID) => {
            const perspective = perspectiveStore().getCurrent()
            if (!perspective) return false
            const widget = state.widgets.find(item => item.uid === widgetUID)
            if (!widget) return false
            return widget.perspectiveUID === perspective.uid
        }
    }
})
<template>
  <div class="selection-condition flex-col">
    <div class="flex flex-grow" v-if="operation">
      <div class="flex-col">
        <div class="selection-condition-operator pt-1" @click="_switchOperator">{{ operation.operator }}</div>
      </div>

      <div class="selection-condition-items flex-grow flex-col">
        <selection-operation :selection="selection" :operation="op" v-for="op in operations" :parent="operation"
                             @remove="_removeOperation"
                             :sport="sport" :editable="editable"/>
        <selection-operand :selection="selection" :item="operand" v-for="operand in operands" @remove="_removeOperand" :sport="sport"
                           class="sb" :editable="editable"/>
        <button class="small" @click.prevent="_addConditionItem()" v-if="!_hasChildOperations && editable">
          <font-awesome-icon icon="fa-solid fa-plus"/>
          AJOUTER CONDITION
        </button>
        <button class="small" @click.prevent="_addOperationItem()" v-if="_hasChildOperations && editable">
          <font-awesome-icon icon="fa-solid fa-plus"/>
          AJOUTER OPERATION
        </button>
      </div>
    </div>
    <div class="selection-condition-add-item text-right px-1">
      <button class="small" @click.prevent="_removeOperation(operation)" v-if="_hasParent && editable">
        <font-awesome-icon icon="fa-solid fa-trash-can"/>
        SIMPLIFIER
      </button>
      <button class="small" @click.prevent="_wrapCondition" v-if="editable">
        <font-awesome-icon icon="fa-solid fa-times"/>
        COMBINER
      </button>
    </div>

  </div>
</template>
<script>
import SelectionOperand from "@/components/routes/selection/modals/SelectionOperand.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "SelectionOperation",
  components: {FontAwesomeIcon, SelectionOperand},

  props: {
    sport: {
      type: String,
      required: true,
    },
    selection: {
      type: Object,
      required: true,
    },
    operation: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    _switchOperator() {
      if(!this.editable) return;
      this.operation.operator = this.operation.operator === 'AND' ? 'OR' : 'AND';
    },
    _wrapCondition() {
      if(!this.editable) return;
      // deep copy this.condition
      const copyCondition = JSON.parse(JSON.stringify(this.operation));
      copyCondition.operator = 'AND';

      this.operation.operator = 'OR';
      this.operation.operands = null;
      this.operation.operations = [copyCondition, {
        operator: 'AND'
      }];
    },

    _addOperationItem() {
      if(!this.editable) return;

      if (!this.operation.operations) {
        this.operation.operations = [];
      }

      let newOperation = {
        tempUID: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
        operator: 'AND',
        operations: [],
        operands: []
      };
      this.operation.operations.push(newOperation);
    },

    _addConditionItem() {
      if(!this.editable) return;
      if (!this.operation.operands) {
        this.operation.operands = [];
      }

      let newOperand = {
        tempUID: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
        targetType: 'STATISTIC_DEFINITION',
        targetFixedUID: null,
        player: null,
        who: null,
        against: null,
        period: null,
        location: null,
        league: null,
        matchingScoreNegativeImpact: null,
        condition: {},
        rootOperationUID: this.selection.operation.uid || this.selection.operation.tempUID,
        parentOperationUID: this.operation.uid || this.operation.tempUID,
      };
      this.operation.operands.push(newOperand);

      modalStore().openModal({
        uid: "editSelectionConditionModal",
        payload: {
          sport: this.sport,
          selectionUid: this.selection.uid,
          operand: newOperand
        }
      })
    },
    _removeOperand(operand) {
      if(!this.editable) return;
      this.operation.operands = this.operation.operands.filter(child => child !== operand);
      let isChildrenEmpty = this.operation.operands.length === 0;
      if (isChildrenEmpty) {
        this._removeOperation(this.operation)
      }
    },
    _removeOperation(operation) {
      if(!this.editable) return;
      this.$emit('remove', operation);
    }
  },

  computed: {

    operations() {
      return this.operation.operations;
    },
    operands() {
      return this.operation.operands;
    },

    _hasParent() {
      if (!this.parent) return false;
      return true
    },

    _hasChildOperations() {
      if (!this.operation.operations) return false;
      return this.operation.operations.length > 0;
    },

  }
}
</script>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.selection-condition {
  background: transparent;
  border: 1px solid @stroke-color;
  border-radius: @padding-small;
  color: @text-color;

  &&:only-child &.selection-condition {
    border-top: 0;
    border-right: 0;
  }

  &&:last-child {
    border-bottom: 1px solid @stroke-color;
  }

  .selection-condition-operator {
    font-size: 9pt;
    cursor: pointer;
    width: 21px;
    writing-mode: vertical-lr;
  }

  .selection-condition-items {
    border-left: 1px solid @stroke-color;
    border-bottom: 1px solid @stroke-color;
  }
}
</style>
<script>

import {refereeStore} from "@/stores/Referee.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "RefereeName",
  components: {FontAwesomeIcon},
  props: {
    uid: {
      required: true
    }
  },

  computed: {
    _referee() {
      return refereeStore().get(this.uid);
    },
    _refereeName() {
      return this._referee?.name ?? "_";
    },
    _buildCssClasses() {
      let cssClasses = null
      if (!this._referee) {
        cssClasses = {
          'referee-name': true
        }
      } else {
        cssClasses = {
          'referee-name': true
        }
      }

      return cssClasses;
    }
  },
}
</script>

<template>
  <span :class="_buildCssClasses">
    {{ _refereeName }}
    <font-awesome-icon icon="fa-person-running" />
  </span>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.referee-name {
  min-width: 16px;
  min-height: 16px;
  border-radius: @radius-small;
  line-height: 24px;
  padding: 0 @padding-small;
  color: @text-color;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 9pt;
  font-family: 'SourceCodePro', serif !important;
}
</style>
export default new class {

    create() {

    }

    format(d, format = 'DD/MM') {

        switch (format) {
            case 'DD/MM/YY':
                return this.formatDDMMYY(d)
            case 'DD/MM/YYYY':
                return this.formatDDMMYYYY(d)
            case 'DD/MM HH:mm':
                return this.formatDDMM(d) + ' ' + this.formatHHMM(d)
            case 'DD/MM':
            default:
                return this.formatDDMM(d)
        }

    }

    formatDDMM(d) {
        // get hh:mm from date
        const date = new Date(d)
        const day = date.getDate()
        const month = date.getMonth() + 1
        // left pad with 0
        const dayStr = day.toString().padStart(2, '0')
        const monthStr = month.toString().padStart(2, '0')
        return `${dayStr}/${monthStr}`
    }

    formatHHMM(d) {
        // get hh:mm from date
        const date = new Date(d)
        const hours = date.getHours()
        const minutes = date.getMinutes()
        // left pad with 0
        const hoursStr = hours.toString().padStart(2, '0')
        const minutesStr = minutes.toString().padStart(2, '0')
        return `${hoursStr}:${minutesStr}`
    }

    formatDDMMYY(d) {

        // format Date to DD/MM/YY
        if (!d) return ''
        if (typeof d === 'string') {
            d = new Date(d)
        }
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        let year = d.getFullYear()
        // get last 2 digits of year
        year = year.toString().substr(2, 2)

        if (month.length < 2)
            month = '0' + month
        if (day.length < 2)
            day = '0' + day

        return [day, month, year].join('/')
    }

    formatDDMMYYYY(d) {

        // format Date to DD/MM/YYYY
        if (!d) return ''
        if (typeof d === 'string') {
            d = new Date(d)
        }
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        const year = d.getFullYear()

        if (month.length < 2)
            month = '0' + month
        if (day.length < 2)
            day = '0' + day

        return [day, month, year].join('/')
    }

    addDays(date, days) {
        const result = new Date(date)
        result.setDate(result.getDate() + days)
        return result
    }

    getEndOf(date) {
        const result = new Date(date)
        result.setHours(23, 59, 59, 999)
        return result
    }

    fromString(dateString) {
        return new Date(dateString)
    }

    isBefore(date1, date2) {
        if (typeof date1 === 'string') {
            date1 = this.fromString(date1)
        }
        if (typeof date2 === 'string') {
            date2 = this.fromString(date2)
        }
        return date1 < date2
    }

    isAfter(date1, date2) {
        return date1 > date2
    }

    compare(date1, date2) {
        if (typeof date1 === 'string') {
            date1 = this.fromString(date1)
        }
        if (typeof date2 === 'string') {
            date2 = this.fromString(date2)
        }

        if (this.isBefore(date1, date2)) {
            return -1
        }
        if (this.isAfter(date1, date2)) {
            return 1
        }
        return 0
    }

    buildDateInterval(selectedDateInterval) {
        let dateInterval = {
            start: new Date(),
            end: new Date()
        };
        switch (selectedDateInterval) {
            case 'EARLIER_TODAY':
                // Get end of day date time
                dateInterval.start.setHours(0, 0, 0, 0);
                dateInterval.end = new Date();
                break;
            case 'TODAY':
                // Get end of day date time
                dateInterval.end.setHours(23, 59, 59, 999);
                break;
            case 'NEXT_24_HOURS':
                dateInterval.end.setDate(dateInterval.end.getDate() + 1);
                dateInterval.end.setHours(23, 59, 59, 999);
                break;
            case 'NEXT_3_DAYS':
                dateInterval.end.setDate(dateInterval.end.getDate() + 3);
                dateInterval.end.setHours(23, 59, 59, 999);
                break;
            case 'NEXT_7_DAYS':
                dateInterval.end.setDate(dateInterval.end.getDate() + 7);
                dateInterval.end.setHours(23, 59, 59, 999);
                break;
        }

        // format start and end date to YYYY-MM-DD
        dateInterval.start = dateInterval.start.toISOString()//.split('T')[0];
        dateInterval.end = dateInterval.end.toISOString()//.split('T')[0];

        return dateInterval
    }
}
<script>
import MainHeader from "@/components/common/MainHeader.vue";
import {contextStore} from "@/stores/Context.store";
import {userStore} from "@/stores/User.store";
import UserPreferencesModal from "@/components/common/modal/user_preferences/UserPreferencesModal.vue";
import CalculatorModal from "@/components/common/modal/calculator/CalculatorModal.vue";
import LicenceLeagueModal from "@/components/common/modal/LicenceLeagueModal.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import {blogRouteStore} from "@/components/routes/blog/BlogRoute.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import YoutubeButton from "@/components/common/YoutubeButton.vue";

export default {
  name: "MainRoute",
  components: {
    YoutubeButton,
    FontAwesomeIcon,
    BlogSeeMore,
    LeagueLogo,
    LicenceLeagueModal,
    CalculatorModal,
    UserPreferencesModal,
    MainHeader
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _randomAvis() {
      return blogRouteStore().getAvis(3)
    }
  },
  methods: {
    _signWithGoogle() {
      userStore().signInWithGoogle()
    },
    _openYoutube(videoId) {
      window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank')
    },
    _goToStats() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['stats'],
          () => {
            this.$router.push({name: 'stats'})
          }
      )
    },
    _goToGames() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['games'],
          () => {
            this.$router.push({name: 'games'})
          }
      )
    },
    _goToPlayers() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['players'],
          () => {
            this.$router.push({name: 'players'})
          }
      )
    },
    _continue() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['stats'],
          () => {
            if (contextStore().isAuthenticated()) {
              this._goToStats()
            } else {
              this._signWithGoogle()
            }
          }
      )
    },
    _scrollToOffers() {
      document.getElementById('offers-wrapper').scrollIntoView({behavior: 'smooth'})
    },
    _firstLetter(avis) {
      return avis.name.charAt(0).toUpperCase()
    },
    _avisColor(index) {
      switch (index % 4) {
        case 0:
          return {background: '#6DFFC2'}
        case 1:
          return {background: '#FFA500'}
        case 2:
          return {background: '#FF6347'}
      }
    }
  },
  beforeMount() {
    if (contextStore().shouldReload()) {
      window.location.href = window.location.origin
    }

    if (!contextStore().isInitialized()) {
      this.$router.push({name: 'init', query: {redirect: 'main', query: this._urlUID}})
    }
  },
}
</script>

<template>
  <div :class="{'mobile': _isMobile}">
    <main-header/>

    <div class="main-welcome-pane">

      <div class="w-100 space-x-8 h-16">
        <a href="https://www.twitter.com/OctobetTeam" target="_blank">
          <svg class="svg-inline--fa fa-twitter text-4xl p-2 white hover:text-5xl hover:p-0 duration-200"
               aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path class="" fill="currentColor"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
          </svg>
        </a><a href="https://www.instagram.com/octobetteam" target="_blank">
        <svg class="svg-inline--fa fa-instagram text-4xl p-2 white hover:text-5xl hover:p-0 duration-200"
             aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path class="" fill="currentColor"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
        </svg>
      </a><a href="https://discord.gg/t2yYqYxaNr" target="_blank">
        <svg class="svg-inline--fa fa-discord text-4xl p-2 white hover:text-5xl hover:p-0 duration-200"
             aria-hidden="true" focusable="false" data-prefix="fab" data-icon="discord" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <path class="" fill="currentColor"
                d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
        </svg>
      </a><a href="mailto:contact@Docto.BET?subject=Contact" target="_blank">
        <svg class="svg-inline--fa fa-envelope text-4xl p-2 white hover:text-5xl hover:p-0 duration-200"
             aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path class="" fill="currentColor"
                d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"></path>
        </svg>
      </a></div>
      <div class="bloc" style="text-align: center; height: 100vh; padding-top: 8vh">
        <h1 class="lg:w-1/2 md:w-2/3 m-auto">
          <span class="highlighted">SOIGNE</span> TA BK
        </h1>
        <h4 class="lg:w-1/2 md:w-2/3 m-auto my-5">Avec nos stats, deviens un pro des paris sportifs</h4>
        <h3 class="lg:w-1/2 md:w-2/3 m-auto">
          DoctoBET te donne accès à des millions de données sur toutes les compétitions. Prédis l'imprévisible grâce à
          des statistiques détaillées sur les joueurs, les équipes et les matchs.
        </h3>
        <h3 class="lg:w-1/2 md:w-2/3 m-auto">
          Maximise tes gains et réduis tes risques.
        </h3>
        <div>
          <button class="large primary m-auto" @click="_continue">
            DEMARRE MAINTENANT
          </button>
          <button class="large accent ml-1" @click="_scrollToOffers">
            OFFRES
          </button>
        </div>
      </div>

      <div class="bloc" id="points-fort-wrapper">
        <h2 class="lg:w-2/3 md:w-2/3 m-auto">
          <span class="highlighted">L'OUTIL</span> DU PARIEUR EXIGEANT
        </h2>
        <h3 class="lg:w-1/2 md:w-2/3 m-auto">
          Docto.BET, un outil ultra personnalisable regroupant tout ce dont un parieur a besoin. Avec Docto.BET, il
          devient facile de structurer tes analyses pour améliorer tes résultats et viser des gains sur le long terme.
        </h3>
        <div style="margin-top: 64px;">
          <div class="flex m-auto points-fort-flex">
            <div class="point-fort">
              <h3 class="uppercase">Analyse personnalisée</h3>
              <p class="message">Crée tes propres perspectives pour affiner tes pronostics.</p>
            </div>
            <div class="point-fort">
              <h3 class="uppercase">Optimisation du temps</h3>
              <p class="message">Gagne du temps en automatisant les recherches de stats.</p>
            </div>
            <div class="point-fort">
              <h3 class="uppercase">Monitoring automatisé</h3>
              <p class="message">Suis l'évolution de tes stratégies et ajuste-les en fonction des résultats.</p>
            </div>

          </div>
        </div>
      </div>

      <div class="bloc lg:w-2/3 md:w-2/3 w-full m-auto">
        <div class="flex">
          <div class="w-full lg:w-2/3">
            <h2 style="text-align: left">
              <span class="highlighted">UNE BONNE STAT</span><br/> PEUT TOUT CHANGER
            </h2>
            <p>Accède à plus de 100 types de statistiques puissantes et leurs multiples variantes pour
              décortiquer chaque rencontre
              décortiquer chaque rencontre
              à venir. Que tu sois novice ou expert, notre module stats te permet de créer des perspectives
              personnalisées, te fournissant les insights précis et pertinents dont tu as besoin pour prendre des
              décisions éclairées. </p>
            <p>Ne te contente plus de parier à l'instinct : avec Docto.BET, chaque pari devient une
              stratégie gagnante.</p>
            <div>
              <button class="large youtube m-auto" @click="_openYoutube('MDMyT0NFZ8A')">
                <font-awesome-icon :icon="['fab', 'youtube']" class="mx-2"/>
              </button>
              <button class="large uppercase accent ml-1" @click="_goToStats">
                Accéder aux stats
              </button>
            </div>

          </div>
          <div class="lg:w-1/3 w-1/5 text-right">
            <font-awesome-icon icon="fa-solid fa-chart-pie" class="bloc-icon"/>
          </div>
        </div>
      </div>

      <div class="bloc lg:w-2/3 md:w-2/3 m-auto">
        <div class="flex">
          <div class="w-1/3">
            <font-awesome-icon icon="fa-solid fa-object-group" class="bloc-icon"/>
          </div>
          <div class="w-full lg:w-2/3">
            <h2 style="text-align: left">
              <span class="highlighted">Sélection</span><br/> précise
            </h2>
            <p>Avec nos sélections personnalisées, identifie en quelques secondes les rencontres qui répondent
              à des
              critères statistiques ultra-précis, parmi des centaines de matchs. Ne perds plus de temps à tout analyser
              manuellement : laisse Docto.BET faire le travail pour toi. Grâce à des possibilités infinies de filtrage
              et à la note de correspondance, tu repères instantanément les matchs les plus intéressants.
            </p>
            <p>Gagner devient une
              question de méthode, pas de chance.</p>
            <div>
              <button class="large youtube m-auto" @click="_openYoutube('bG2dHYSse4M')">
                <font-awesome-icon :icon="['fab', 'youtube']" class="mx-2"/>
              </button>
              <button class="large accent uppercase ml-1" @click="_goToGames">
                Accéder aux sélections
              </button>
            </div>

          </div>
        </div>
      </div>

      <div class="bloc lg:w-2/3 md:w-2/3 m-auto">
        <div class="flex">
          <div class="w-full lg:w-2/3">
            <h2 style="text-align: left">
              <span class="highlighted">Monitoring</span><br/> automatique
            </h2>
            <p>Avant de miser ton argent, teste tes stratégies sur le long terme en toute sécurité. Confie au
              système la
              tâche d’identifier et de placer automatiquement des paris virtuels selon tes critères ultra précis, et
              laisse-le gérer une bankroll virtuelle pour toi. Suis les performances de tes stratégies sans lever le
              petit
              doigt et ajuste-les en toute sérénité.</p>
            <p>Avec Docto.BET, tu peux explorer et affiner des dizaines de
              stratégies sans effort, maximisant ainsi tes chances de succès lorsque tu décideras de passer à
              l’action.</p>
            <button class="large youtube m-auto" @click="_openYoutube('mFS9JDsPvBM')">
              <font-awesome-icon :icon="['fab', 'youtube']" class="mx-2"/>
            </button>
          </div>
          <div class="w-1/3 text-right">
            <font-awesome-icon icon="fa-solid fa-stethoscope" class="bloc-icon"/>
          </div>
        </div>
      </div>

      <div class="bloc" id="avis-client-wrapper">
        <div class="lg:w-2/3 md:w-2/3 m-auto mb-5" style="text-align: center">
          <div class="mb-5">
            <font-awesome-icon icon="fa-solid fa-star" style="font-size: 32pt; color:#6DFFC2;" class="mx-5"/>
            <font-awesome-icon icon="fa-solid fa-star" style="font-size: 32pt; color:#6DFFC2;" class="mx-5"/>
            <font-awesome-icon icon="fa-solid fa-star" style="font-size: 32pt; color:#6DFFC2;" class="mx-5"/>
          </div>
          <h1 style="text-align: center; margin-bottom: 32px; margin-top: 32px">
            AVIS <span class="highlighted">CLIENT</span>
          </h1>
        </div>
        <div style="margin-top: 64px">
          <div class="flex m-auto avis-client-flex">
            <div class="avis-client" v-for="(avis, index) in _randomAvis">
              <div class="flex align-middle">
                <div class="avatar" :style="_avisColor(index)">{{ _firstLetter(avis) }}</div>
                <div class="name">{{ avis.name }}</div>
              </div>
              <p class="message">{{ avis.message }}</p>
            </div>

          </div>
        </div>
      </div>
      <div class="bloc" id="offers-wrapper">
        <div class="lg:w-2/3 md:w-2/3 m-auto mb-5" style="text-align: center">
          <h1 style="text-align: center; margin-bottom: 32px;">
            OFFRES <span class="highlighted">IMBATTABLES</span>
          </h1>

          <h3 style="text-align: center; margin-top: 24px">
            Docto.BET propose le meilleur rapport qualité-prix du marché. Découvre nos offres et choisis celle qui
            te convient le mieux.
          </h3>
        </div>
        <div style="margin-top: 64px">
          <div class="flex lg:w-2/3 m-auto offers-flex">
            <div class="offer NONE ">
              <h2>DEBUTANT</h2>
              <h4 class="highlighted">Gratuit</h4>
              <p>Crée un compte et profite de toutes les fonctionnalités de l'outil. Aucune limite de
                temps.</p>
              <ul>
                <li>Accès complet au module Stats</li>
                <li>13 ligues analysables</li>
                <li>Bonus de parrainage à 5%</li>
              </ul>
            </div>

            <div class="offer PRO">
              <h2>PRO</h2>
              <h4 class="highlighted">5€/an</h4>
              <ul>
                <li>Accès illimités aux données d'une trentaine de compétition dont les plus grands
                  championnats
                  Européens.
                </li>
                <li>Bonus de parrainage à 10%</li>
                <li>Bonus sur les parrainages de vos filleuls de 5%</li>
                <li>Paiement en une fois. Pas de renouvellement automatique.</li>
              </ul>
            </div>
          </div>
          <div class="flex lg:w-1/2 m-auto mt-5">
            <div class="offer ULTIMATE w-full">
              <h2>ULTIMATE</h2>
              <h4 class="highlighted">20€/an</h4>
              <ul>
                <li>Accès illimités à plus de 85 compétitions.</li>
                <li>Bonus de parrainage à 15%</li>
                <li>Bonus sur les parrainages de vos filleuls de 10%</li>
                <li>Paiement en une fois. Pas de renouvellement automatique.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="sticky-bar" @click="_signWithGoogle">
        COMMENCE A GAGNER MAINTENANT
      </div>

    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

@keyframes appear {
  from {
    opacity: 0;
    scale: 0.5;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.bloc {
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0;
}

@name-height: 36px;
.main-welcome-pane {
  margin: auto;
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - @toolbar-regular);
  scrollbar-width: none;
  padding-bottom: 100px;

  .highlighted {
    background: linear-gradient(90deg, #FF501E 0%, #FFA528 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-weight: bolder;
  }

  h1 {
    font-family: 'PoppinsBold', serif;
    font-size: 4.6875rem !important;
    color: #fff !important;
    margin-bottom: 24px !important;
    font-weight: bolder;
    text-transform: uppercase;
  }

  h2 {
    font-family: 'PoppinsBold', serif;
    font-size: 44px !important;
    color: #fff !important;
    margin-bottom: 24px !important;
    font-weight: bolder;
    text-transform: uppercase;
  }

  h3 {
    font-family: 'PoppinsRegular', serif;
    font-size: 1.25rem !important;
    font-weight: 100 !important;
    color: #fff !important;
    margin-bottom: 16px !important;
  }

  h4 {
    font-family: 'PoppinsBold', serif;
    font-weight: bolder;
    text-transform: uppercase;
  }

  .bloc {
    max-width: 1024px;
    margin: auto;
    text-align: left;
    padding: 64px 10px;
  }

  p, ul, li {
    font-size: 1.125rem; /* Slightly larger font size */
    line-height: 1.14;
    color: #fff; /* Dark gray text color */
    font-family: 'Arial', serif; /* Font family */
    text-align: left;
  }

  ul {
    list-style-type: circle;
    padding: 0;

    li {
      margin-left: 16px;
    }
  }

  .bloc-icon {
    margin-top: 100px;
    color: #fff;
    font-size: 128px;
  }

  #points-fort-wrapper {
    .points-fort-flex {
      gap: @padding-regular; /* Adjust the gap as needed */
      display: flex;
      flex-wrap: wrap;
      padding: @padding-regular;
    }

    .point-fort {
      text-align: center;
      flex: 1 1 300px; /* permet aux div de croître et se contracter, avec une base de 350px */
      box-sizing: border-box;
      width: 300px;
      max-width: calc(100%);
      padding: 16px;
      border-radius: @radius-regular;
      background: rgb(255, 255, 255);
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);

      p {
        text-align: center;
        font-size: 11pt;
      }
    }
  }

  #avis-client-wrapper {
    .avis-client-flex {
      gap: @padding-regular; /* Adjust the gap as needed */
      display: flex;
      flex-wrap: wrap;
      padding: @padding-regular;
    }

    .avis-client {
      flex: 1 1 300px; /* permet aux div de croître et se contracter, avec une base de 350px */
      box-sizing: border-box;
      width: 300px;
      max-width: calc(100%);
      padding: 16px;
      border-radius: @radius-regular;
      background: rgb(255, 255, 255);
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);

      .name {
        color: white;
        font-weight: bold;
        font-size: 14pt;
        line-height: @name-height;
      }

      .message {
        font-style: italic;
        margin: 0 !important;
        padding-left: @name-height + @padding-regular;
      }

      .avatar {
        font-weight: bold;
        background: @primary-color;
        border-radius: 50%;
        width: @name-height;
        height: @name-height;
        display: inline-block;
        line-height: @name-height;
        margin-right: @padding-regular;
        text-align: center;
      }
    }

  }

  #offers-wrapper {

    .offers-flex {
      gap: @padding-regular; /* Adjust the gap as needed */
      display: flex;
      flex-wrap: wrap;
      padding: @padding-regular;
    }

    .offer {
      flex: 1 1 300px; /* permet aux div de croître et se contracter, avec une base de 350px */
      box-sizing: border-box;
      width: 300px;
      max-width: calc(100%);
      padding: 16px;
      border-radius: @radius-regular;

      &.NONE {
        background: rgb(255, 255, 255);
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
      }

      &.PRO {
        background: @accent-color;
        background: linear-gradient(135deg, fade(@accent-color, 30%) 0%, fade(@accent-color, 20%) 20%, fade(@accent-color, 15%) 33%);
      }

      &.ULTIMATE {
        background: rgb(236, 156, 44);
        background: linear-gradient(135deg, #f97f2866 0%, #f97f2866 33%, #fea32866 66%, #fea32866 100%);
      }
    }
  }


  .sticky-bar {
    justify-content: center;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 200ms, opacity 200ms;
    height: 2.8125rem;
    padding: 0 0.9375rem 0 0;
    z-index: 20;
    background: rgba(0, 0, 0, 0.7);
    font-family: "gt_americaextended_black", "Arial Black", "Arial Bold", sans-serif;
    font-size: 0.875rem;
    text-transform: uppercase;
    line-height: 1.1;
    color: white;
    overflow: hidden;
    text-decoration: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 599px) {
  .main-welcome-pane {
    h1 {
      font-size: 3.125rem !important;
    }

    h2 {
      font-size: 2.5rem !important;
    }

    h3 {
      font-size: 1rem !important;
    }

    .bloc {
      padding: 32px 10px;
    }

    p, ul, li {
      font-size: 1rem; /* Slightly larger font size */
    }

    .bloc-icon {
      font-size: 64px;
    }

    .offers {
      .offer {
        padding: 16px;
        border-radius: @radius-regular;

        &.NONE {
          margin-right: 0;
        }

        &.PRO {
          margin-left: 0;
        }
      }
    }
  }
}

</style>
// stores/counter.js
import {defineStore} from 'pinia'

export const blogRouteStore = defineStore('blogRouteStore', {
    state: () => {
        return {
            articles: {
                "module-stats":{
                    title: "Module Stats",
                    video: "https://www.youtube.com/embed/MDMyT0NFZ8A"
                },
                "identifier-betes-noires":{
                    title: "Identifier ses bêtes noires",
                    video: "https://www.youtube.com/embed/hEXIq4oFiG0"
                },
                "analyse-resultat-elimine-echecs": {
                    title: "Analyse tes résultats pour éliminer les échecs",
                },
                "analyse-stats-maximise-chances": {
                    title: "Analyse les stats : maximise tes chances",
                },
                "choisis-on-calcule": {
                    title: "Tu choisis, on calcule"
                },
                "comment-choisir-cote": {
                    title: "Comment choisir une cote ?"
                },
                "instinct-plus-stats-recette-gagner": {
                    title: "Instinct + stats : la recette pour gagner"
                },
                "parie-avec-fait-pas-chance": {
                    title: "Parie avec les faits, pas avec la chance"
                },
                "parier-bien-gagner-mieux": {
                    title: "Parier c'est bien, gagner c'est mieux"
                },
                "plus-stats-moins-perte": {
                    title: "Plus de stats, moins de pertes"
                },
                "teste-strategie-domine-incertitudes": {
                    title: "Teste ta stratégie et domine les incertitudes"
                },
                "pour-qui-doctobet": {
                    title: "Pour qui est DoctoBet ?"
                }
            },
            avis: [
                {
                    name: 'Llou',
                    message: "Outil de statistiques très poussé. Il est modulable et personnalisable afin de répondre à des besoins personnels très spécifiques"
                },
                {
                    name: 'lavisduloup',
                    message: "L'outil indispensable pour pouvoir préparer ses bets et tester ses stratégies"
                },
                {
                    name: 'Vlad',
                    message: "Boostez vos paris avec les analyses précises de DoctoBet. Rejoignez-nous et maximisez vos gains dès aujourdhui !"
                },
                {
                    name: 'DataPronos',
                    message: "L'intuition c'est bien, mais les stats c'est mieux !"
                },
                {
                    name: "Ultrasmons",
                    message: "Site parfait, bien rempli, rien à dire."
                },
                {
                    name: "Stefragui ",
                    message: "Cet outil est une mine d’or pour celles et ceux qui voudraient tester plusieurs stratégies "
                },
                {
                    name: "Gavieur",
                    message: "Outil personnalisable, Complet et Pratique !"
                }
            ],
        }
    },
    actions: {

    },
    getters: {
        getAvis: (state) => (count) => {
            if(!count || count ===1) {
                return state.avis[Math.floor(Math.random() * state.avis.length)];
            }
            let avis = [];
            for (let i = 0; i < count; i++) {
                let item = state.avis[Math.floor(Math.random() * state.avis.length)];
                if(avis.includes(item)) {
                    i--;
                    continue;
                }
                avis.push(item);
            }
            return avis;
        },
        getTitle: (state) => (key) => {
            let articleElement = state.articles[key];
            if(!articleElement) {
                return "Article non trouvé";
            }
            return articleElement.title;
        },
        get3RandomArticles: (state) => () => {
            let articles = Object.keys(state.articles);
            let randomArticles = [];
            for (let i = 0; i < 3; i++) {
                let randomIndex = Math.floor(Math.random() * articles.length);
                randomArticles.push(articles[randomIndex]);
                articles.splice(randomIndex, 1);
            }
            return randomArticles
        },
        getVideo: (state) => (key) => {
            let articleElement = state.articles[key];
            if(!articleElement) {
                return "Article non trouvé";
            }
            return articleElement.video;
        }
    }
})
<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import StatisticValueButton from "@/components/common/StatisticValueButton.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import {gameStore} from "@/stores/Game.store";
import TeamName from "@/components/common/TeamName.vue";
import axiosService from "@/services/axios.service";
import {contextStore} from "@/stores/Context.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import GameDate from "@/components/common/GameDate.vue";
import GameScore from "@/components/common/GameScore.vue";
import {widgetDataStore} from "@/stores/WidgetData.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";

export default {
  name: "LastTeamGamesWidget",
  components: {
    WidgetLoadingContent,
    GameScore,
    GameDate,
    WidgetLeagueParamButton,
    WidgetLocationParamButton,
    WidgetTeamParamButton,
    WidgetPeriodParamButton,
    TeamName, GameResultCircle, TeamLogo, StatisticValueButton, FontAwesomeIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: [],
    }
  },
  mounted() {
    this._onGameUIDChange()
    window.emitter.on('context-store:game-uid:change', () => {
      this._onGameUIDChange()
    })

    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _gameStore() {
      return gameStore();
    },
    _contextStore() {
      return contextStore()
    },
    _gameUID() {
      return this._contextStore.gameUID
    },
    _game() {
      return this._gameStore.get(this._gameUID)
    },
    _teamUID() {
      return perspectiveStore().getParameterTeamValue(this.item.uid)
    },
    _games() {
      if (!this.data) return []
      let games = this.data.map(g => gameStore().get(g));
      // remove null games
      return games.filter(g => g)
    },
    _itemUID() {
      return this.item ? this.item.uid : null
    },
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!contextStore().gameUID) return;

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) return;

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {

      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        return;
      }

      if (!this._game) {
        console.info("Cannot refresh LastTeamGamesWidget because game is not available")
        return;
      }

      const payload = {
        teamUID: this._teamUID,
        location: perspectiveStore().getParameterValue(this.item.uid, 'location'),
        leagueUID: perspectiveStore().getParameterLeagueValue(this.item.uid),
        at: this._game.date,
        sport: perspectiveStore().getCurrentSport()
      }
      axiosService.put('/wid/last-team-games', payload).then(response => {
        this.data = response.data.gameUIDs
        wds.put(this.item.uid, contextStore().gameUID, this.data)
      }).catch(error => {
        console.error(error)
      })
    },
    _otherTeamUID(game) {
      if (!game) return null
      if (game.homeTeamUID === this._teamUID) return game.awayTeamUID
      if (game.awayTeamUID === this._teamUID) return game.homeTeamUID
      return null
    },
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Derniers matchs
      <div class="widget-header-params flex">
        <widget-location-param-button :widget="item"/>
        <widget-team-param-button :widget="item"/>
        <widget-league-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content py-1" v-else>
      <div v-for="g in _games" class="flex px-1">
        <game-date :uid="g.uid" style="min-width: 44px"/>
        <team-name :uid="_otherTeamUID(g)" class="flex-grow text-left" :short="true"/>
        <game-score :uid="g.uid" style="min-width: 58px;"/>
        <game-result-circle :team-uid="_teamUID" :game-uid="g.uid"/>
      </div>
    </div>
  </div>
</template>

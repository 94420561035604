<script>
import TicketBetOdd from "../../../../../common/TicketBetOdd.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import DateTime from "../../../../../common/DateTime.vue";
import {sportStore} from "../../../../../../stores/Sport.store";
import {bankrollPaneStore} from "../BankrollPane.store";
import {offerStore} from "@/stores/Offer.store";
import {gameStore} from "@/stores/Game.store";
import GameRow from "@/components/common/GameRow.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameHour from "@/components/common/GameHour.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import GameDate from "@/components/common/GameDate.vue";
import GameScore from "@/components/common/GameScore.vue";
import LeagueName from "@/components/common/LeagueName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";

export default {
  name: "BankrollTicketBet",
  components: {
    TeamLogo,
    LeagueName,
    GameScore, GameDate, AreaFlag, GameHour, TeamName, GameRow, DateTime, FontAwesomeIcon, TicketBetOdd},

  props: {
    ticket: {
      type: Object,
      required: true
    },
    bet: {
      type: Object,
      required: true
    }
  },

  computed: {

    _ticketStatus() {
      // Dans cet v1, un ticket = un bet donc on peut retourner le status du premier bet
      if(!this.ticket.bets || this.ticket.bets.length === 0) return null
      return this.ticket.bets[0].status
    },

    _isSelected() {
      return bankrollPaneStore().getTicketActiveUID() === this.ticket.uid
    },

    _stakeAbsolute() {
      if(!this.ticket.stakeAbsolute) return 0
      return this.ticket.stakeAbsolute.toFixed(2)
    },

    _stakeRelative() {
      if(!this.ticket.stakeRelative) return 0
      return this.ticket.stakeRelative.toFixed(3)
    },

    _sportIcon() {
      const sport = sportStore().get(this.bet.sport)
      if (!sport) {
        console.warn('Unknown sport', this.bet.sport)
        return 'fa-solid fa-question'
      }
      return sport.icon
    },

    _betGameUID() {
      if(!this._betGame) return null
      return this._betGame.uid
    },

    _betGame() {
      if (!this.bet.gameUID) return null
      return gameStore().get(this.bet.gameUID)
    },

    _betGameLeagueUID() {
      if(!this._betGame) return null
      return this._betGame.leagueUID
    },

    _getGameHomeTeamUID() {
      return gameStore().getHomeTeamUID(this._betGame)
    },
    _getGameAreaUID() {
      if(!this._betGame) return null
      return this._betGame.area
    },
    _getGameAwayTeamUID() {
      return gameStore().getAwayTeamUID(this._betGame)
    },
    _betFamilyLabel() {
      const offerFamily = offerStore().getOfferFamilyBySportAndFixedUID(this.bet.sport, this.bet.offerFamilyFixedUID)
      if (!offerFamily) {
        return null
      }
      return offerFamily.label
    },
    _betLabel() {
      if(!this.bet){
        return '...'
      }
      if (this.bet.label) {
        return this.bet.label
      }
      const offerFamilyChoice = offerStore().getOfferFamilyChoiceBySportAndFixedUID(this.bet.sport, this.bet.offerFamilyChoiceFixedUID)
      return offerStore().getOfferFamilyChoiceLabel(offerFamilyChoice, this.bet.offerArgument, false)
    },
    _type() {
      switch (this.bet.status) {
        case 'WAITING':
        case 'REFUND':
        case 'CANCEL':
          return 'info'
        case 'WIN':
        case 'HALF_WIN':
        case 'CASHOUT':
          return 'positive'
        case 'LOSE':
        case 'HALF_LOSE':
          return 'negative'
        default:
          console.warn('Unknown ticket status', this.ticket.status)
          return '?'
      }
    },

    _plain() {
      switch (this.bet.status) {
        case 'WAITING':
        case 'WIN':
        case 'LOSE':
          return false
        case 'CANCEL':
        case 'HALF_WIN':
        case 'REFUND':
        case 'CASHOUT':
        case 'HALF_LOSE':
          return true
        default:
          console.warn('Unknown ticket status', this.ticket.status)
      }
    },

    _tooltip() {
      return this._statusLabel(this.bet.status)
    },

    _isLock() {
      return this.bet.lock
    }
  },

  methods: {
    _statusLabel(status) {
      switch (status) {
        case 'WAITING':
          return 'En attente'
        case 'WIN':
          return 'Gagné'
        case 'LOSE':
          return 'Perdu'
        case 'REFUND':
          return 'Remboursé'
        case 'CANCEL':
          return 'Annulé'
        case 'HALF_LOSE':
          return 'Perdu à moitié'
        case 'HALF_WIN':
          return 'Gagné à moitié'
        case 'CASHOUT':
          return 'Cashout'
        default:
          console.warn('Unknown ticket status', this.ticket.status)
          return '?'
      }
    },
  }
}
</script>

<template>
    <div class="px-2 pb-1 text-center" v-if="_isLock">
      <font-awesome-icon icon="fa-lock" size="2xl" class="m-3" style="color: #865762"/>
      <a-alert message="Tu ne peux pas consulter ce bet, car tu n'as pas la licence nécessaire." type="warning" show-icon/>
    </div>
    <div class="px-2 pb-1" v-else>
      <div class="text-left">
        <font-awesome-icon :icon="_sportIcon" size="xl" class="mr-2 info"/>
        <team-logo :uid="_getGameHomeTeamUID" size="small"/>
        <team-name :uid="_getGameHomeTeamUID" class="team-home"/>
        <span style="color:white"> - </span>
        <team-name :uid="_getGameAwayTeamUID" class="team-away"/>
        <team-logo :uid="_getGameAwayTeamUID" size="small"/>
        <game-score :uid="_betGameUID" style="display: inline-block" class="float-right"/>
      </div>

      <div style="margin-left: 30px" class="text-left">
        <area-flag :area="_getGameAreaUID" class="mr-2"/>
        <league-name :uid="_betGameLeagueUID" style="font-size: 10pt" show-arjel show-area/>
      </div>

      <div class="flex" style="margin-left: 30px">
        <game-date :uid="_betGameUID" :show-icon="true"/> <game-hour :uid="_betGameUID"/>

        <div class="flex-grow"/>
        <button class="small">{{ _statusLabel(_ticketStatus) }}</button>
        <a-tooltip :title="_tooltip">
          <ticket-bet-odd :value="bet.odd" :type="_type" :plain="_plain" class="ml-1"/>
        </a-tooltip>
      </div>

      <div class="flex mt-1" style="margin-left: 30px">

        <label class="flex-grow" style="line-height: 24px">
          <font-awesome-icon icon="fa-solid fa-bullseye" size="xs" class="mr-1"/>
          <span v-if="_betFamilyLabel">{{ _betFamilyLabel }} </span>
          {{ _betLabel }}
        </label>
        <label class=" mr-1 text-right" style="line-height: 24px">
        {{ _stakeRelative }}%
        </label>
        <button style="line-height: 24px" class="small info" disabled>€ {{ _stakeAbsolute }}</button>
      </div>

      <div class="mt-1" style="margin-left: 30px" v-if="false === bet.compliantWithSelection">
        <a-alert message="Ce bet est sortie des critères de sélection." type="warning" show-icon/>
      </div>

    </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.bankroll-ticket {
  background-color: @background-color;
  border-radius: @radius-regular;
  cursor: pointer;
  border: 1px solid @background-color;

  label {
    min-width: 16px;
    min-height: 16px;
    border-radius: @radius-small;
    line-height: 18px;
    color: @text-color;
    font-size: 10pt;
    //white-space: nowrap;
    //text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;

    &.ticket-date {
      font-size: 8pt;
      color: @stroke-color !important;
    }
  }
}
</style>
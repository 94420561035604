// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";

export const venueStore = defineStore('venueStore', {
    state: () => {
        return {
            loadingUIDs: [],
            fetchVenueByUIDTimeout: null,
            items: []
        }
    },
    actions: {
        // Permet de récupérer un match par son UID
        fetchVenueByUID(uid) {

            if (!uid) return

            if (this.loadingUIDs.indexOf(uid) < 0) {
                this.loadingUIDs.push(uid)
            }

            // Si on a 100 équipes à charger, on le fait tout de suite
            const timeout = this.loadingUIDs.length < 100 ? 250 : 0

            if (this.fetchVenueByUIDTimeout) clearTimeout(this.fetchVenueByUIDTimeout)
            this.fetchVenueByUIDTimeout = setTimeout(() => {
                axiosService.get('/dta/venues/' + this.loadingUIDs.join(',')).then(response => {
                    this.items = this.items.concat(response.data.results)

                    const loadedUIDs = response.data.results.map(item => item.uid)
                    this.loadingUIDs = this.loadingUIDs.filter(uid => loadedUIDs.indexOf(uid) < 0)
                });

            }, timeout)

        }
    },
    getters: {

        get: (state) => (uid) => {

            if (state.loadingUIDs.indexOf(uid) >= 0) {
                return null
            }

            uid = parseInt(uid)

            let venue = state.items.find(item => item.uid === uid);
            if (venue) {
                return venue
            }

            // Si la venue n'est pas dans le store, on le charge
            const ts = venueStore()
            ts.fetchVenueByUID(uid)

            return null
        },

        getAll: (state) => {
            return state.items
        }
    }
})
<script>
import axiosService from "@/services/axios.service";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameDate from "@/components/common/GameDate.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import GameEvent from "@/components/common/GameEvent.vue";
import PlayerPaneItemEventsTabGameGroup
  from "@/components/routes/player/panes/player/tabs/PlayerPaneItem.Events.Tab.GameGroup.vue";
import GameRow from "@/components/common/GameRow.vue";

export default {
  name: "PlayerPaneItem.Events.Tab",
  components: {
    GameRow,
    PlayerPaneItemEventsTabGameGroup, GameEvent, GameResultCircle, TeamName, TeamLogo, LeagueLogo, GameDate},

  props: {
    playerUid: {
      required: true
    },
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      data: null
    }
  },
  beforeMount() {
    this._refreshData()
  },

  computed: {
    _sport() {
      if (!this._game) return null
      return this._game.sport
    },
    _game() {
      return gameStore().get(this.gameUid)
    },
    _events() {
      if (!this.data) return []
      const results = [...this.data.events]
      return results.sort((a, b) => {
        return new Date(a.date) - new Date(b.date)
      })
    },
    _eventsGroupByGameUID() {
      if (!this._events) return []
      return this._events.reduce((acc, e) => {
        if (!acc[e.gameUID]) {
          acc[e.gameUID] = []
        }
        acc[e.gameUID].push(e)
        return acc
      }, {})
    },
    _sortedGameUIDs() {
      if(!this._events) return []
      // On construit un map gameUID : dateDate
      const gameUIDs = this._events.reduce((acc, e) => {
        acc[e.gameUID] = e.date
        return acc
      }, {})
      // On trie les gameUIDs par date
      return Object.keys(gameUIDs).sort((a, b) => {
        return new Date(gameUIDs[a]) - new Date(gameUIDs[b])
      }).reverse()
    }
  },

  methods: {
    _refreshData() {
      const payload = {
        gameUID: this.gameUid,
        playerUID: this.playerUid
      }
      axiosService.put('/dta/player/basic-data/events', payload).then(response => {
        this.data = response.data
      })
    },
  },
}
</script>

<template>
  <div>
    <fieldset class="no-border text-left mt-2">
      <legend class="ml-1">Derniers évènements</legend>
      <a-alert v-if="!_events || _events.length === 0" type="info" show-icon message="Aucun match trouvé"
               class="mx-1 mb-2"/>
      <player-pane-item-events-tab-game-group v-for="gameUID in _sortedGameUIDs" :key="gameUID" class="mb-2" :game-uid="parseInt(gameUID)" :events="_eventsGroupByGameUID[gameUID]"/>
    </fieldset>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table.last-player-event-row {
  border-bottom: 1px solid @stroke-color;

  tr, td {
    border: none;
    padding: 0;
    background: transparent;
  }

  &.over {
    background-color: @hover-color;
  }
}
</style>
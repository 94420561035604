<script>

export default {
  name: "SelectionConditionCriteria",
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    choices: {
      required: true
    },
    minChoices: {
      required: false
    },
    maxChoices: {
      required: false
    },
    type: {
      type: String,
      default: 'ELEMENTARY'
    }
  },
  data() {
    return {
      form: {}
    }
  },
  watch: {
    modelValue: function (newVal, oldVal) { // watch props change
      this.form = newVal;
    }
  },

  beforeMount() {
    this.form = this.modelValue;
  },

  methods: {
    _isSelected(value) {
      if (!this.modelValue || !value) {
        return false;
      }

      if (this.minChoices || this.maxChoices) {
        // S'il existe des choix min max, on compare uniquement la valeur choice et pas min/max
        return this.modelValue.choice === value.choice;
      }

      return JSON.stringify(this.modelValue) === JSON.stringify(value);
    },
    _setChoice(value) {

      if (this.minChoices || this.maxChoices) {
        // S'il existe des choix min max, on compare uniquement la valeur choice et pas min/max
        this.form.choice = value.choice
      } else {
        this.form = value;
      }

      this._handleChoiceChange();
    },

    _handleChoiceChange() {
      this._handleChange()
    },

    _handleChange() {
      this.$emit('update:modelValue', this.form);
    },

    _onSelectMin(option) {
      if (!option.value) return
      this.form.min = option.value;
      this._handleChange();
    },

    _onSelectMax(option) {
      if (!option.value) return
      this.form.max = option.value;
      this._handleChange();
    }
  },
  computed: {
  }
}
</script>

<template>
  <div class="selection-condition-criteria flex m-1">
    <div class="flex-grow text-left">
      <span v-if="choices">
        <button :class="{'mr-1': true, 'primary selected' : _isSelected(item.value)}" :title="item.tooltip"
                v-for="item in choices" @click="_setChoice(item.value)">
          {{ item.label }}
        </button>
      </span>
      <span v-if="minChoices">
        <a-dropdown :trigger="['click']">
          <button class="primary selected">
             {{ modelValue.min }}
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="option in minChoices" @click="_onSelectMin(option)" style="text-align: left">
                <span class="mr-2" v-if="!option.children">{{ option.label }}</span>
                <a-sub-menu :key="option.uid" :title="option.label" v-if="option.children">
                <a-menu-item v-for="sub in option.children" @click="_onSelectMin(sub)">{{ sub.label }}</a-menu-item>
              </a-sub-menu>
              </a-menu-item>

            </a-menu>
          </template>
        </a-dropdown>
      </span>
      <span v-if="maxChoices">
        <label class="small mx-1"> : </label>
        <a-dropdown :trigger="['click']">
          <button class="small primary selected">
             {{ modelValue.max }}
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="option in maxChoices" @click="_onSelectMax(option)" style="text-align: left">
                <span class="mr-2" v-if="!option.children">{{ option.label }}</span>
                <a-sub-menu :key="option.uid" :title="option.label" v-if="option.children">
                <a-menu-item v-for="sub in option.children" @click="_onSelectMax(sub)">{{ sub.label }}</a-menu-item>
              </a-sub-menu>
              </a-menu-item>

            </a-menu>
          </template>
        </a-dropdown>
      </span>
    </div>
    <label class="pr-2">
      {{ label }}
    </label>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.selection-condition-criteria {
  height: @toolbar-regular;
  line-height: @toolbar-regular !important;
  color: @text-color !important;
  margin: 0 !important;
  padding-left: @padding-small !important;

  button {
    height: calc(@toolbar-regular - @padding-regular);
  }
}
</style>
<script>
import SelectionOperation from "@/components/routes/selection/panes/selection/condition/SelectionOperation.vue";
import SelectionConditionCriteria from "@/components/routes/selection/modals/criteria/SelectionConditionCriteria.vue";
import {conditionFormMixins} from "@/components/routes/selection/modals/condition_forms/ConditionForm.Mixins";

export default {
  name: "ConditionFormLeagueScore",
  components: {SelectionConditionCriteria, SelectionOperation},
  mixins: [conditionFormMixins],

  props: {
    modelValue: {
      type: Object,
      required: true
    },
  },

  beforeMount() {
    this.form = this.modelValue;
    // On clear les valeurs dont on a pas besoin dans ce cas
    this.form.who = null;
    this.form.against = null;
    this.form.leagueStrict = null;
    this.form.locationStrict = null;

    // On init les valeurs dont on a besoin si elles sont null
    if (!this._matchOneChoice(this.form.what, this.what.choices)) {
      this.form.what = this.what.default;
    }
    if (!this._matchOneChoice(this.form.period, this.period.choices)) {
      this.form.period = this.period.default;
    }
  },

  mounted() {

  },

  data() {
    return {
      form: {},
      what: {
        label: 'Score',
        type: "PAIR",
        multiple: false,
        choices: [
          {label: "Exactement", value: {choice: "EQUAL"}}
        ],
        minMaxChoices: [
          {label: "0", value: "0"},
          {label: "1", value: "1"},
          {label: "2", value: "2"},
          {label: "3", value: "3"},
          {label: "4", value: "4"},
          {label: "5", value: "5"},
          {
            label: "Autres", children: [
              {label: "6", value: "6"},
              {label: "7", value: "7"},
              {label: "8", value: "8"},
              {label: "9", value: "9"},
              {label: "10", value: "10"},
              {label: "20", value: "20"},
              {label: "30", value: "30"},
              {label: "40", value: "40"},
              {label: "50", value: "50"},
              {label: "60", value: "60"},
              {label: "70", value: "70"},
              {label: "80", value: "80"},
              {label: "90", value: "90"},
              {label: "100", value: "100"},
              {label: "110", value: "110"},
              {label: "120", value: "120"},

            ]
          },
        ],
        default: {
          choice: "EQUAL",
          min: "0",
          max: "0"
        }
      },
      period: {
        label: 'Période',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Cette saison", value: {choice: "LAST_SEASON", min: 1}},
        ],
        default: {
          choice: "LAST_SEASON",
          min: 1
        }
      }
    }
  },
  computed: {}
}
</script>

<template>
  <div>


    <div class="selection-condition-item-resume">
      Rechercher les rencontres suivant la régularité d'un score dans la compétition dans laquelle elles se jouent.
    </div>

    <div class="condition-form-criteria">
      <selection-condition-criteria v-model="form.what"
                                    :choices="what.choices"
                                    :min-choices="what.minMaxChoices"
                                    :max-choices="what.minMaxChoices"
                                    :label="what.label"
                                    :type="what.type"/>
      <selection-condition-criteria v-model="form.period" :choices="period.choices" :label="period.label"/>
    </div>

  </div>
</template>

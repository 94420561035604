<script>
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import PlayerPicture from "@/components/common/PlayerPicture.vue";
import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import {bookmakerStore} from "@/stores/Bookmaker.store";
import {offerStore} from "@/stores/Offer.store";

export default {
  name: "SelectionPaneItemOfferMatchRow",
  components: {OfferBookmakerValue, PlayerPicture, TeamLogo, LeagueLogo},
  props: {
    item: {
      type: Object,
      required: true
    },
    obv: {
      type: Object,
      required: true
    },
    positive: {
      type: Boolean,
      required:true
    }
  },

  computed:{
    _buildCssClasses() {
      return {
        'offer-criteria': true,
        'positive': this.positive,
        'negative': !this.positive
      }
    },
    _obvLabel() {
      let os = offerStore();
      let base = os.getOfferFamilyChoiceLabelByUID(this.item.targetFixedUID, this.item.argument, this.item.condition.bookmakerUid)
      switch (this.item.condition.operator){
        case 'UPPER':
          base += ' > '
          break
        case 'LOWER':
          base += ' < '
          break
      }
      base += (this.item.condition.value).toFixed(2);

      if(this.item.condition.comparedToFixedUID){
        base += ' et '
        switch (this.item.condition.comparedToOperator){
          case 'UPPER':
            base += 'supérieure à '
            break
          case 'LOWER':
            base += 'inférieure à '
            break
        }
        base += os.getOfferFamilyChoiceLabelByUID(this.item.condition.comparedToFixedUID, this.item.condition.comparedToArgument, null)
      }

      if(this.item.condition.comparedToEcart && this.item.condition.comparedToEcart !== 0){
        base += ' de '
        base += (this.item.condition.comparedToEcart * 100.0).toFixed(0)
        base += ' % ou plus'
      }

      return base
    },
    _obvValue() {
      if(!this.obv?.value) return '-.--'
      return (this.obv.value).toFixed(2)
    },
    _bookmakerLogoSrc() {
      if(!this.obv) return null
      const bookmakerUID = this.obv.bookmakerUID
      if(!bookmakerUID) return null
      const bookmaker = bookmakerStore().get(bookmakerUID)
      if (!bookmaker) return null
      const lowerCaseBookName = bookmaker.name.toLowerCase().replaceAll(' ', '')
      return '/images/bookmakers/' + lowerCaseBookName + '.png'
    }
  }
}
</script>

<template>
  <div class="flex">
    <div class="value pl-2">
      {{ _obvValue }}
    </div>
    <div class="bookmaker">
      <img :src="_bookmakerLogoSrc" style="height: 24px; width: 24px;" alt="" v-if="_bookmakerLogoSrc"/>
    </div>
    <div class="label ml-2 text-left py-1" style="line-height: normal">
      {{ _obvLabel}}
    </div>
  </div>
</template>


<style scoped lang="less">
@import "@/assets/styles/base.less";

.offer-criteria {
  color: @text-color;
  font-size: @font-size-small;
  min-height: 24px;
  align-items: center;

  &:hover {
    background: @hover-color;
  }

  &.positive {
    background: -webkit-linear-gradient(0deg, @positive-color, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00);

    &:hover {
      background: -webkit-linear-gradient(0deg, @positive-color, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF00);
    }
  }

  &.negative {
    background: -webkit-linear-gradient(0deg, @negative-color, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00);

    &:hover {
      background: -webkit-linear-gradient(0deg, @negative-color, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF00);
    }
  }

  .value {
    font-weight: bold;
    text-align: right;
    min-width: 40px;
    display: flex;
    align-items: center;
  }

  .bookmaker{
    display: flex;
    align-items: center;
    min-width: 24px;
  }
}
</style>
<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import BlogAvisClient from "@/components/routes/blog/BlogAvisClient.vue";

export default {
  name: "TesteStrategieDomineIncertitudes",
  components: {BlogAvisClient, BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif';
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Teste ta stratégie, domine les incertitudes"
             class="w-full"/>
      </div>
    </div>

    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET s’adresse à tous ceux qui veulent passer au niveau supérieur dans leurs paris sportifs. Que tu sois un
        novice ou un pro, cet outil est là pour t’aider à parier avec plus de précision et de confiance.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>

    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Affine ta stratégie, maximise tes gains</h3>
      <p class="px-5">En tant que parieur pro, tu sais que chaque décision est cruciale. DoctoBET est là pour t'aider à
        peaufiner tes stratégies de betting et maximiser ton taux de réussite et ton ROI. Avec nos outils de monitoring,
        tu peux
        analyser en détail tes paris passés et ajuster tes méthodes pour des résultats durables.</p>
    </div>

    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Élimine les incertitudes</h3>
      <p class="px-5">Les paris impliquent toujours une part de risque, mais avec DoctoBET, tu peux réduire cette
        incertitude au minimum. Notre module de monitoring de sélection te permet de tester différentes stratégies sur
        le long terme, d'identifier celles qui fonctionnent et d'éliminer celles qui ne rapportent pas. Fini le hasard,
        place aux décisions éclairées.</p>
    </div>


    <blog-see-more/>

    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Contrôle total de ta bankroll</h3>
      <p class="px-5">
        Gérer ta bankroll avec précision est essentiel pour durer dans ce milieu. Grâce à l'analyseur de bankroll de
        DoctoBET, tu peux suivre l'évolution de tes gains et pertes et ajuster tes mises en conséquence. Tu maîtrises
        ton budget tout en optimisant tes profits.
      </p>
    </div>

    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Satisfaction client garantie</h3>
      <p class="px-5">Tes clients VIP attendent des résultats concrets. Avec DoctoBET, tu peux leur offrir des
        pronostics plus fiables, basés sur des stratégies testées et des données solides. En optimisant tes paris, tu
        augmentes ton taux de réussite, fidélises ta clientèle et renforces ta réputation.</p>
    </div>

    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">DoctoBET : ton allié pour dominer le marché</h3>
      <p class="px-5">
        Ne laisse plus rien au hasard. Avec DoctoBET, tu as les outils pour tester, ajuster et perfectionner tes
        stratégies de betting. Teste tes idées, élimine les incertitudes, et domine le marché avec des pronostics qui
        font la différence.
      </p>
    </div>

    <blog-avis-client/>

  </div>
</template>

<style scoped lang="less">

</style>
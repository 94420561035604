// stores/counter.js
import {defineStore} from 'pinia'
import {contextStore} from "@/stores/Context.store";

export const notebookStore = defineStore('notebookStore', {
    state: () => {
        return {
            items: {
                // By Sport
            }
        }
    },
    actions: {
        init(){
            // init items form local storage
            this.items = JSON.parse(localStorage.getItem('notebookStore.items')) || []
        },
        save(){
            // save items to local storage
            localStorage.setItem('notebookStore.items', JSON.stringify(this.items))
        },
        remove(gameUID){
            const sport = contextStore().getSport
            if(!this.items[sport]){
                this.items[sport] = []
                return
            }
            this.items[sport] = this.items[sport].filter(item => item !== gameUID)
            this.save()
        },
        switch(gameUID){

            const sport = contextStore().getSport
            if(!this.items[sport]){
                this.items[sport] = []
            }

            // add in items or remove it if already in
            if(this.items[sport].includes(gameUID)){
                this.items[sport] = this.items[sport].filter(item => item !== gameUID)
            } else {
                this.items[sport].push(gameUID)
            }
            this.save()
        }
    },
    getters: {
        getItems: (state) => () => {
            const sport = contextStore().getSport
            if(!state.items[sport]){
                state.items[sport] = []
            }
            return state.items[sport]
        },
        isIn: (state) => (gameUID) => {
            return notebookStore().getItems().includes(gameUID)
        }
    }
})
// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";

export const settingStore = defineStore('settingStore', {
    state: () => {
        return {
            data: null
        }
    },
    actions: {
        fetchActive(callback = null) {
            if(this.data !== null) {
                if (callback) callback()
                return
            }

            axiosService.get('/set/settings').then(response => {
                this.data = response.data.results
                this.initialized = true
            }).finally(() => {
                if (callback) callback()
            });
        }
    },
    getters: {
        get: (state) => (nature, cle) => {
            return state.data.find(setting => setting.nature === nature && setting.cle === cle)
        }
    },
    getString: (state) => (nature, cle, defaut = null) => {
        const setting = settingStore().get(nature, cle)
        return setting ? setting.valeur : defaut
    },
    getDouble: (state) => (nature, key, defaut = null) => {
        const setting = settingStore().get(nature, key)
        return setting ? parseFloat(setting.valeur) : defaut
    }
})
<script setup>
</script>

<script>
export default {
  name: "WelcomeBankrollPane",
  components: {}
}
</script>

<template>

  <div id="welcome-bankroll-pane">
    <p>Sélectionne une bankroll dans le panneau de gauche pour commencer à l'analyser</p>
  </div>

</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#welcome-bankroll-pane {
  margin: auto;

  p {
    margin: 200px auto @padding-large auto;
    max-width: 400px;
    text-align: center;
    font-size: 2em !important;
    color: @text-color;
    opacity: 0.1;
  }
}

.mobile {
  #welcome-pane {
    display: none;
  }
}

</style>
<script>

import {analysePaneStore} from "@/components/routes/selection/panes/analyse/AnalysePane.store";
import {gameStore} from "@/stores/Game.store";
import TeamName from "@/components/common/TeamName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import {contextStore} from "@/stores/Context.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "AnalysePaneHeaderTab",
  components: {
    TeamLogo,
    TeamName,
    AreaFlag
  },
  props: {
    gameUID: {
      type: Number,
      required: true
    }
  },
  computed: {
    _analysePaneStore() {
      return analysePaneStore();
    },
    _gameStore() {
      return gameStore();
    },
    _contextStore() {
      return contextStore();
    },
    _game() {
      return this._gameStore.get(this.gameUID)
    },
    _homeTeamUID() {
      return this._gameStore.getHomeTeamUID(this._game)
    },
    _awayTeamUID() {
      return this._gameStore.getAwayTeamUID(this._game)
    },
    _isSelected() {
      return this._contextStore.getSelectedGameUID() === this.gameUID;
    }
  },
  methods: {
    _onClick() {
      if (this._isSelected) {
        this._openGameModal()
        return
      }
      this._selectGame()
    },
    _selectGame() {
      contextStore().setGameUID(this.gameUID)
    },
    _openGameModal() {
      modalStore().openModal({uid: 'gameModal', gameUID: this.gameUID})
    },
    _closeGame() {
      this._analysePaneStore.removeGame(this.gameUID);
      widgetDataStore().remove(this.gameUID);
    }
  }
}
</script>

<template>
  <div :class="{'analyse-pane-tab': true, 'selected' : _isSelected}">
    <div class="flex">
      <div @click="_onClick" class="px-2">
        <team-logo :uid="_homeTeamUID" size="small"/>
        <team-name :uid="_homeTeamUID" :short="true"/>
        <span> - </span>
        <team-name :uid="_awayTeamUID" :short="true"/>
        <team-logo :uid="_awayTeamUID" size="small"/>
      </div>
      <div class="analyse-pane-tab-close ml-2 mr-2" @click.prevent="_closeGame()">
        <font-awesome-icon icon="fa-solid fa-close"/>
      </div>
      <div :class="{'active-border' : true, 'selected' : _isSelected}"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.analyse-pane-tab {
  display: inline-block;
  height: @toolbar-regular;
  line-height: @toolbar-regular;
  cursor: pointer;
  opacity: 0.7;
  color: @text-color;


  .analyse-pane-tab-close {
    visibility: hidden;
  }

  &:hover, &.selected {
    opacity: 1;

    .analyse-pane-tab-close {
      visibility: visible;
    }

    .active-border {
      opacity: 0.2;
    }
  }

  &.selected {
    //border-bottom: 3px solid @highlight-color;
  }

  .active-border {
    width: 100%;
    height: 4px;
    background: -webkit-linear-gradient(0deg, @home-color, @away-color);
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.0;
    border-radius: @radius-regular;

    &.selected {
      opacity: 1;
    }
  }
}
</style>
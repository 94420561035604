<script>
import Hour from "@/components/common/Hour.vue";
import {gameStore} from "@/stores/Game.store";

export default {
  name: "GameHour",
  components: {Hour},
  props: {
    uid: {
      required: true
    },
    size: {
      type: String,
      default: 'medium' // small, medium accepeted
    }
  },

  computed: {
    _game() {
      if(!this.uid) return null
      return gameStore().get(this.uid)
    },
    _getValue() {
      if(!this._game) return null
      return gameStore().getGameDate(this._game)
    }
  },
}
</script>

<template>
  <hour :value="_getValue" :size="size"/>
</template>

<script>
import PlayerAnalysePaneHeader from "@/components/routes/player/panes/analyse/header/PlayerAnalysePane.Header.vue";
import PlayerAnalysePerspectivesBottomBar
  from "@/components/routes/player/panes/analyse/perspective/PlayerAnalysePerspectivesBottomBar.vue";
import PlayerAnalysePerspective
  from "@/components/routes/player/panes/analyse/perspective/PlayerAnalysePerspective.vue";

export default {
  name: "PlayerAnalysePane",
  components: {
    PlayerAnalysePerspectivesBottomBar,
    PlayerAnalysePaneHeader,
    PlayerAnalysePerspective
  },
}
</script>

<template>
  <div id="player-analyse-pane-wrapper">
    <div id="player-analyse-pane">
      <player-analyse-pane-header/>
      <player-analyse-perspective/>
      <player-analyse-perspectives-bottom-bar/>
    </div>
  </div>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";

#player-analyse-pane {
  margin: @padding-small;
  border-radius: @radius-regular;
  background: rgb(34, 34, 34);
  background: linear-gradient(215deg, rgba(255, 255, 255, 0.05) 0%, rgba(44, 44, 46, 0.80) 30%, rgba(44, 44, 46, 0.80) 40%, rgba(34, 34, 34, 0.90) 100%);
  height: calc(100dvh - @toolbar-regular);
}

.mobile {
  #player-analyse-pane {
    display: none;
  }
}

</style>
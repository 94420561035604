<template>
  <div class="offer mb-2">
    <div class="offer-game">
      <div class="offer-game-teams">{{ offer.game.homeTeam.name }} - {{ offer.game.awayTeam.name }}</div>
      <div class="offer-game-league">{{ offer.league.code }}</div>
      <div class="offer-game-date ml-1">{{ offer.game.date }}</div>
    </div>
    <div class="offer-type">
      <div class="offer-type-label">{{ offer.type }}</div>
      <div class="offer-type-probability">55-62%</div>
    </div>
    <div class="offer-values flex">
      <div class="offer-value flex-1" v-for="value in offer.values">
        {{ value.bookmaker.name }} : {{ value.value }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Offer",
  props: {
    offer: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style scoped lang="less">
.offer {
  background: #C8C8C8;
  font-size: 8pt;

  .offer-game {
    padding: 4px;
    background: #E8E8E8;
    display: flex;
    font-size: 9pt;

    .offer-game-teams {
      // grow
      flex-grow: 4;
      text-align: left;
    }
  }

  .offer-type {
    display: flex;
    padding: 2px 4px;
    background: #DDD;

    .offer-type-label {
      flex-grow: 1;
      text-align: left;
    }

    .offer-type-probability {
      flex-grow: 1;
      text-align: right;
    }
  }

  .offer-values {
    padding: 2px 4px;
    background: #EEE;
  }
}
</style>
<script>
import SelectionOperation from "@/components/routes/selection/panes/selection/condition/SelectionOperation.vue";
import SelectionConditionCriteria from "@/components/routes/selection/modals/criteria/SelectionConditionCriteria.vue";
import {conditionFormMixins} from "@/components/routes/selection/modals/condition_forms/ConditionForm.Mixins";

export default {
  name: "ConditionFormTeamPoints",
  components: {SelectionConditionCriteria, SelectionOperation},

  mixins: [conditionFormMixins],

  props: {
    modelValue: {
      type: Object,
      required: true
    },
  },

  beforeMount() {
    this.form = this.modelValue;
    // On clear les valeurs dont on a pas besoin dans ce cas
    this.form.who = null;
    this.form.against = null;
    this.form.leagueStrict = null;

    // On init les valeurs dont on a besoin si elles sont null
    if (!this._matchOneChoice(this.form.what, this.what.choices)) {
      this.form.what = this.what.default;
    }
    if (!this._matchOneChoice(this.form.locationStrict, this.locationStrict.choices)) {
      this.form.locationStrict = this.locationStrict.default;
    }
    if (!this._matchOneChoice(this.form.period, this.period.choices)) {
      this.form.period = this.period.default;
    }
  },

  mounted() {

  },

  data() {
    return {
      form: {},
      what: {
        label: 'Points',
        type: "PAIR",
        multiple: false,
        choices: [
          {label: "Plus de", value: {choice: "MORE"}},
          {label: "Moins de", value: {choice: "LESS"}},
          {label: "Exactement", value: {choice: "EQUAL"}}
        ],
        minMaxChoices: [
          {label: "0.5", value: {choice: "0.5"}},
          {label: "1.5", value: {choice: "1.5"}},
          {label: "2.5", value: {choice: "2.5"}},
          {label: "3", value: {choice: "3"}},
          {label: "4", value: {choice: "4"}},
          {label: "5", value: {choice: "5"}},
          {
            label: "Autres", children: [
              {label: "6", value: {choice: "6"}},
              {label: "7", value: {choice: "7"}},
              {label: "8", value: {choice: "8"}},
              {label: "9", value: {choice: "9"}},
              {label: "10", value: {choice: "10"}},
              {label: "20", value: {choice: "20"}},
              {label: "30", value: {choice: "30"}},
              {label: "40", value: {choice: "40"}},
              {label: "50", value: {choice: "50"}},
              {label: "60", value: {choice: "60"}},
              {label: "70", value: {choice: "70"}},
              {label: "80", value: {choice: "80"}},
              {label: "90", value: {choice: "90"}},
              {label: "100", value: {choice: "100"}},
              {label: "110", value: {choice: "110"}},
              {label: "120", value: {choice: "120"}},

            ]
          },
        ],
        default: {
          choice: "MORE",
          min: "2"
        }
      },
      locationStrict: {
        label: 'Position',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Domicile & Extérieur", value: {choice: "FALSE"}},
          {label: "Domicile", value: {choice: "HOME_TEAM"}},
          {label: "Extérieur", value: {choice: "AWAY_TEAM"}},
        ],
        default: {
          choice: "FALSE"
        }
      },
      period: {
        label: 'Période',
        type: "ELEMENTARY",
        multiple: false,
        choices: [
          {label: "Cette saison", value: {choice: "LAST_SEASON", min: 1}},
          {label: "5 derniers matchs", value: {choice: "LAST_GAME", min: 5}},
          {label: "10 derniers matchs", value: {choice: "LAST_GAME", min: 10}}
        ],
        default: {
          choice: "LAST_SEASON",
          min: 1
        }
      }
    }
  },
  computed: {}
}
</script>

<template>
  <div>
    <div class="selection-condition-item-resume">
      Rechercher les rencontres suivant le nombre de points marqués par une équipe.
    </div>
    <hr/>
    <div class="condition-form-criteria">
      <selection-condition-criteria v-model="form.what"
                                    :choices="what.choices"
                                    :min-choices="what.minMaxChoices"
                                    :label="what.label"
                                    :type="what.type"/>
      <selection-condition-criteria v-model="form.locationStrict" :choices="locationStrict.choices"
                                    :label="locationStrict.label"/>
      <selection-condition-criteria v-model="form.period" :choices="period.choices" :label="period.label"/>
    </div>


  </div>
</template>

<script>

import {preferencesStore} from "@/stores/Preferences.store";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";

export default {
  name: "UserTeamPreferences",
  components: {},
  data() {
    return {
    }
  },
  computed: {
    _contextStore() {
      return contextStore();
    },
    _preferencesStore() {
      return preferencesStore();
    },
    _displayTeamLogo() {
      let displayLogo = this._preferencesStore.getFirstValue('TEAM', 'DISPLAY_LOGO');
      if (!displayLogo) {
        displayLogo = true;
      }
      return displayLogo;
    }
  },
  methods: {
    _switchDisplayTeamLogo() {
      const newValue = !this._displayTeamLogo;

      if (newValue && !this._contextStore.isLicensed()) {
        message.warning("Cette fonction n'est accessible qu'avec la formule PRO.");
        return;
      }

      this._preferencesStore.save('TEAM', 'DISPLAY_LOGO', [newValue]);
    },
  }
}
</script>

<template>
  <div class="ml-2 pb-2">
    <fieldset class="mt-2 pb-1">
      <legend>Logo</legend>
      <div class="px-2 pt-1">
        <label class="inline-block mr-2">Afficher les logos des équipes</label>
        <button :class="{'small': true, 'primary' : _displayTeamLogo, 'secondary' : !_displayTeamLogo}"
                @click="_switchDisplayTeamLogo">
          {{ _displayTeamLogo ? 'Oui' : 'Non' }}
        </button>
      </div>
      <div class="p-1" v-if="!_contextStore.isLicensed()">
        <a-alert type="warning" show-icon message="Cette fonction n'est accessible qu'avec un abonnement payant."/>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="less">

</style>
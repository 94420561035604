<script>

import Statistic from "@/components/common/Statistic.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameHour from "@/components/common/GameHour.vue";
import GameDate from "@/components/common/GameDate.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import {gameStore} from "@/stores/Game.store";
import {contextStore} from "@/stores/Context.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import {analysePaneStore} from "@/components/routes/selection/panes/analyse/AnalysePane.store";

// Tabs
import FormTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Forme.Tab.vue";
import GoalsTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Goals.Tab.vue";
import UnderOverTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.UnderOver.Tab.vue";
import ScoreTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Score.Tab.vue";
import H2HTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.H2H.Tab.vue";
import DiagnosticTab
  from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Diagnostic.Tab.vue";
import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import {teamStore} from "@/stores/Team.store";
import {leagueStore} from "@/stores/League.store";
import {selectionStore} from "@/stores/Selection.store";
import axiosService from "@/services/axios.service";
import {bookmakerStore} from "@/stores/Bookmaker.store";
import GamblingAffiliationAd from "@/components/common/GamblingAffiliationAd.vue";
import VenueName from "@/components/common/VenueName.vue";
import RefereeName from "@/components/common/RefereeName.vue";
import LeagueButton from "@/components/common/LeagueButton.vue";
import LeagueName from "@/components/common/LeagueName.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import SelectionPaneItemOfferMatch
  from "@/components/routes/selection/panes/selection/items/SelectionPaneItemOfferMatch.vue";
import SelectionPaneItemLine1 from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.Line1.vue";
import SelectionPaneItemLineGameData
  from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.LineGameData.vue";
import SelectionPaneItemTabs from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.Tabs.vue";
import {notebookStore} from "@/stores/NotebookStore";
import {noteStore} from "@/stores/Note.store";
import SelectionMatch from "@/components/common/SelectionMatch.vue";
import ReferringAd from "@/components/common/ReferringAd.vue";


export default {
  name: "SelectionPaneItem",
  components: {
    ReferringAd,
    SelectionMatch,
    SelectionPaneItemTabs,
    SelectionPaneItemLineGameData,
    SelectionPaneItemLine1,
    SelectionPaneItemOfferMatch,
    LeagueLogo,
    LeagueName,
    LeagueButton,
    RefereeName,
    VenueName,
    GamblingAffiliationAd,
    OfferBookmakerValue,
    FontAwesomeIcon,
    OfferChoiceButton,
    TeamLogo,
    GameDate,
    GameHour,
    AreaFlag,
    TeamName,
    Statistic,

    // Tabs
    FormTab,
    GoalsTab,
    UnderOverTab,
    ScoreTab,
    H2HTab,
    DiagnosticTab
  },
  props: {
    item: {
      required: true
    },
    showAd: {
      required: true
    }
  },

  data() {
    return {
      odds: null
    }
  },

  computed: {
    _gameStore() {
      return gameStore();
    },
    _offers() {
      if (!this.item.offers) return null
      return this.item.offers
    },
    _selectedSelectionUID() {
      return selectionPaneStore().getSelectionActiveUIDBySport(contextStore().getSport)
    },
    _selectedSelection() {
      if (!this._selectedSelectionUID) return null
      return selectionStore().get(this._selectedSelectionUID)
    },
    _selectedSelectionOFCUID() {
      if (!this._selectedSelection) return null
      return this._selectedSelection.offerFamilyChoiceFixedUID
    },
    _contextStore() {
      return contextStore();
    },
    _game() {
      if (!this.item) return null
      return this._gameStore.get(this.item.gameUID)
    },
    _getGameHomeTeamUID() {
      if (!this._game) return null
      return this._gameStore.getHomeTeamUID(this._game)
    },
    _getGameAwayTeamUID() {
      if (!this._game) return null
      return this._gameStore.getAwayTeamUID(this._game)
    },
    _tooFewGamesHome() {
      if (!this._game) return false
      const team = teamStore().get(this._getGameHomeTeamUID)
      if (!team) {
        //console.warn('No home team found for game', this._game)
        return false
      }
      const playedAtHome = team.currentSeasonGamesHomeCount
      const playedAway = team.currentSeasonGamesAwayCount

      if (playedAtHome < 6) return true
      if (playedAtHome + playedAway < 10) return true

      return false
    },
    _tooFewGamesAway() {
      if (!this._game) return false
      const team = teamStore().get(this._getGameAwayTeamUID)
      if (!team) {
        //console.warn('No away team found for game', this._game)
        return false
      }

      const playedAtHome = team.currentSeasonGamesHomeCount
      const playedAway = team.currentSeasonGamesAwayCount

      if (playedAway < 6) return true
      if (playedAtHome + playedAway < 10) return true

      return false
    },
    _tooFewStats() {
      return this._tooFewGamesHome && this._tooFewGamesAway
    },
    _isHomeTeamNextGameInDifferentLeague() {
      if (!this._game) return false
      if (!this._game.nextHomeTeamGameLeagueUID) return false
      return this._game.leagueUID !== this._game.nextHomeTeamGameLeagueUID
    },
    _isAwayTeamNextGameInDifferentLeague() {
      if (!this._game) return false
      if (!this._game.nextAwayTeamGameLeagueUID) return false
      return this._game.leagueUID !== this._game.nextAwayTeamGameLeagueUID
    },
    _homeTeamNextGameInDifferentLeagueMessage() {
      const nextLeague = leagueStore().get(this._game.nextHomeTeamGameLeagueUID)
      const nextLeagueName = nextLeague ? nextLeague.name : 'dans une autre ligue'
      const team = teamStore().get(this._game.homeTeamUID)
      if (!team) return 'Prochain match de l\'équipe extérieure ' + nextLeagueName
      return 'Prochain match ' + team.name + ' en ' + nextLeagueName
    },
    _awayTeamNextGameInDifferentLeagueMessage() {
      const nextLeague = leagueStore().get(this._game.nextAwayTeamGameLeagueUID)
      const nextLeagueName = nextLeague ? nextLeague.name : 'dans une autre ligue'
      const team = teamStore().get(this._game.awayTeamUID)
      if (!team) return 'Prochain match de l\'équipe extérieure ' + nextLeagueName
      return 'Prochain match ' + team.name + ' en ' + nextLeagueName
    },
    _cssClasses() {
      return {
        'selection-pane-item': true,
        'selected': this._isSelected
      };
    },
    _matchingScore() {
      return parseFloat(this.item.matchingScore.toFixed(1))
    },
    _relativeMatchingScore() {
      return selectionPaneStore().getRelativeMatchingScore(this._matchingScore)
    },
    _matchingScoreTooltip() {
      return 'Score : ' + this._matchingScore
    },
    _selectedGameUID() {
      return selectionPaneStore().selectedGameUID
    },
    _isSelected() {
      return this._selectedGameUID === this.item.gameUID;
    },
    _isMobileDevice() {
      return this._contextStore.isMobileDevice()
    },
    _odds() {
      if (!this._isSelected) return []
      if (!this.odds) {
        if (!this._selectedSelectionOFCUID) return;
        const argumentParam = (this._selectedSelection.offerArgument) ? '&argument=' + this._selectedSelection.offerArgument : ''
        axiosService.get('/dta/offer/choice/' + this._selectedSelectionOFCUID + '/values?gameUID=' + this.item.gameUID + argumentParam).then(response => {
          this.odds = response.data.results
        })
        return []
      }
      return this.odds
    },
    _isInNotebook() {
      return notebookStore().isIn(this.item.gameUID)
    },
    _personalNote() {
      return noteStore().getByGameUID(this.item.gameUID)
    },
    _personalNoteContent() {
      if (!this._personalNote) return ''
      return this._personalNote.content
    }
  },

  methods: {

    _openGameDetails() {
      selectionPaneStore().setSelectedGameUID(this.item.gameUID)
    },

    _quickDiagnostic() {
      selectionPaneStore().setSelectedItemTab('DIAGNOSTIC')
      selectionPaneStore().setSelectedGameUID(this.item.gameUID)
    },
    _analyse() {
      // un onglet d'analyse sur desktop
      analysePaneStore().addGame(this.item.gameUID, contextStore().getSport)
    },
    _addToBookMedical() {
      notebookStore().switch(this.item.gameUID)
    },

    _bookmakerLogoSrc(bookmakerUID) {
      const bookmaker = bookmakerStore().get(bookmakerUID)
      if (!bookmaker) return null
      const lowerCaseBookName = bookmaker.name.toLowerCase().replaceAll(' ', '')
      return '/images/bookmakers/' + lowerCaseBookName + '.png'
    }
  },

  watch: {
    _offers(value) {
      // force redessin du composant
      // pour une raison inconnue, le composant ne se redessine pas quand on provient d'une redirection automatique au chargement du site.
      this.$forceUpdate()
    }
  }
}
</script>

<template>
  <div>
    <div :class="_cssClasses">
      <selection-pane-item-line1 :game-uid="item.gameUID" @click="_openGameDetails"/>
      <selection-pane-item-line-game-data :game-uid="item.gameUID"/>
      <hr/>

      <div class="line2">

        <span class="flex-grow"/>
        <div class="item-matching-indicator" v-if="_matchingScore > 0">
          <a-tooltip placement="top" :title="_matchingScoreTooltip">
            <font-awesome-icon icon="fa-regular fa-star" v-if="_relativeMatchingScore < 1/6.0"
                               :style="{color: _relativeMatchingScore > ((0/6.0 + 1/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                               class="mr-1"/>
            <font-awesome-icon icon="fa-solid fa-star" v-else
                               :style="{color: _relativeMatchingScore >= ((1/6.0 + 2/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                               class="mr-1"/>

            <font-awesome-icon icon="fa-regular fa-star" v-if="_relativeMatchingScore <= 3/6.0"
                               :style="{color: _relativeMatchingScore >= ((2/6.0 + 3/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                               class="mr-1"/>
            <font-awesome-icon icon="fa-solid fa-star" v-else
                               :style="{color: _relativeMatchingScore >= ((3/6.0 + 4/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                               class="mr-1"/>

            <font-awesome-icon icon="fa-regular fa-star" v-if="_relativeMatchingScore <= 5/6.0"
                               :style="{color: _relativeMatchingScore >= ((4/6.0 + 5/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                               class="mr-1"/>
            <font-awesome-icon icon="fa-solid fa-star" v-else
                               :style="{color: _relativeMatchingScore >= ((5/6.0 + 6/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                               class="mr-1"/>
          </a-tooltip>
          <button class="small square p-0 mr-1">
            {{ _matchingScore }}
          </button>
        </div>
        <div style="position: absolute; width: 100%; padding-top: 4px">
          <div v-for="offer in _offers" v-if="_offers">
            <offer-choice-button :offer-family="offer" :choice="c" v-for="c in offer.choices" v-if="offer"
                                 :selected="c.fixedUID === _selectedSelectionOFCUID"/>
          </div>
          <a-tooltip placement="top" title="Aucune offre disponible">
            <button class="small" v-if="!item.offers" disabled>-</button>
          </a-tooltip>
        </div>
      </div>
      <div class="personal-note" v-if="(_isInNotebook || _isSelected) && _personalNote">
        <p>{{ _personalNoteContent }}</p>
      </div>

      <div v-if="_isSelected && _selectedSelectionOFCUID">
        <hr/>
        <table>
          <tr>
            <th rowspan="2">Toutes<br/>les côtes</th>
            <th v-for="o in odds">
              <img :src="_bookmakerLogoSrc(o.bookmakerUid)" style="height: 24px; width: 24px;" alt=""/>
            </th>
          </tr>
          <tr>
            <td v-for="o in _odds" class="text-left">
              {{ o.value }}
            </td>
          </tr>
        </table>
      </div>

      <a-alert v-if="_isHomeTeamNextGameInDifferentLeague" class="m-1"
               :message="_homeTeamNextGameInDifferentLeagueMessage" type="warning" show-icon/>
      <a-alert v-if="_isAwayTeamNextGameInDifferentLeague" class="m-1"
               :message="_awayTeamNextGameInDifferentLeagueMessage" type="warning" show-icon/>

      <div>
        <span v-for="(statUIDs,period) in item.statsMatch">
          <statistic :uid="statUID" :period="period" v-for="statUID in statUIDs" :positive="true"/>
        </span>
        <span v-for="(statUIDs,period) in item.statsNotMatch">
          <statistic :uid="statUID" :period="period" v-for="statUID in statUIDs" :positive="false"/>
        </span>
        <span v-for="(note, selectionUID) in item.selectionsMatch">
          <selection-match :uid="selectionUID" :note="note" :positive="true"/>
        </span>
        <selection-pane-item-offer-match :items="item.offerBookmakerValueCriteria[true]" :positive="true"/>
      </div>

      <!-- Onglets -->
      <selection-pane-item-tabs :game-uid="item.gameUID" v-if="_isSelected"/>
      <hr/>
      <div class="flex">
        <a-alert v-if="_tooFewStats" message="Peu de match joués" style="height: 28px; border-radius: 0" class="flex-grow"
                 type="warning" show-icon/>
        <div class="flex-grow"/>

        <button class="no-radius" v-if="this._isMobileDevice" @click.stop="_quickDiagnostic" style="width: 110px">
          Diagnostic
        </button>
        <button class="no-radius" @click="_analyse" v-if="!this._isMobileDevice" style="width: 110px">Analyser</button>
        <button class="no-radius" :class="{'primary selected' : _isInNotebook}" @click="_addToBookMedical">
          <font-awesome-icon icon="fa-solid fa-eyedropper"/>
        </button>
      </div>
    </div>

    <div v-if="showAd" class="inline-block text-center my-5">
      <referring-ad/>
      <!--<gambling-affiliation-ad/>-->
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.selection-pane-item {
  background: rgb(255,255,255);
  background: linear-gradient(135deg, rgba(255,255,255,0.10) 0%, rgba(255,255,255,0.08) 20%, rgba(115,115,115,0.05) 33%, rgba(115,115,115,0.10) 100%);
  border-radius: @radius-regular;
  cursor: pointer;
  border: 1px solid #444;

  &:hover {
    background: @title-color;
    background: linear-gradient(135deg, fade(@title-color, 20%) 0%, fade(@title-color, 15%) 10%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  }

  &.selected {
    .line1 {
      background: -webkit-linear-gradient(0deg, @home-color, @away-color);
    }
  }

  .line0 {
    height: 20px;
  }


  .line2 {
    display: flex;
    height: 44px !important;
    text-align: center;

    .item-matching-indicator {
      font-size: @font-size-small;
      line-height: 44px;
    }

  }

  .personal-note {
    border-top: 1px solid @stroke-color;
    font-style: italic;
    background: @background-color-light;

    p {
      padding: @padding-regular;
      margin: 0;
    }

  }

}
</style>
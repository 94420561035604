<script>

import {userStore} from "@/stores/User.store";

export default {
  name: "UserName",
  props: {
    uid: {
      required: true
    }
  },

  computed: {
    _store() {
      return userStore();
    },
    _item() {
      return this._store.get(this.uid);
    },
    _name() {
      return this._item?.name ?? "_";
    },
    _buildCssClasses() {

      if (!this._item) return {
        'user-name': true,
        'shine': true,
      }
      return {
        'user-name': true
      }
    }
  },
}
</script>

<template>
  <span :class="_buildCssClasses">
    {{ _name }}
  </span>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.league-name {
  min-width: 16px;
  min-height: 16px;
  border-radius: @radius-small;
  line-height: 24px;
  padding: 0 @padding-small;
  color: @text-color;
}
</style>
<script>

import TeamName from "@/components/common/TeamName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import {playerAnalysePaneStore} from "@/components/routes/player/panes/analyse/PlayerAnalysePane.store";
import PlayerName from "@/components/common/PlayerName.vue";

export default {
  name: "PlayerAnalysePaneHeaderTab",
  components: {
    PlayerName,
    TeamLogo,
    TeamName,
    AreaFlag
  },
  props: {
    playerUID: {
      type: Number,
      required: true
    }
  },
  computed: {
    _isSelected() {
      return playerAnalysePaneStore().getSelectedPlayerUID === this.playerUID;
    }
  },
  methods: {
    _onClick() {
      this._selectPlayer()
    },
    _selectPlayer() {
      playerAnalysePaneStore().setSelectedPlayerUID(this.playerUID)
    },
    _closePlayer() {
      playerAnalysePaneStore().removePlayer(this.playerUID)
    }
  }
}
</script>

<template>
  <div :class="{'player-analyse-pane-tab': true, 'selected' : _isSelected}">
    <div class="flex">
      <div @click="_onClick" class="px-2">
        <player-name :uid="playerUID"/>
      </div>
      <div class="player-analyse-pane-tab-close ml-2 mr-2" @click.prevent="_closePlayer()">
        <font-awesome-icon icon="fa-solid fa-close"/>
      </div>
      <div :class="{'active-border' : true, 'selected' : _isSelected}"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.player-analyse-pane-tab {
  display: inline-block;
  height: @toolbar-regular;
  line-height: @toolbar-regular;
  cursor: pointer;
  opacity: 0.7;
  color: @text-color;


  .player-analyse-pane-tab-close {
    visibility: hidden;
  }

  &:hover, &.selected {
    opacity: 1;

    .player-analyse-pane-tab-close {
      visibility: visible;
    }

    .active-border {
      opacity: 0.2;
    }
  }

  .active-border {
    width: 100%;
    height: 4px;
    background: -webkit-linear-gradient(0deg, @home-color, @away-color);
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.0;
    border-radius: @radius-regular;

    &.selected {
      opacity: 1;
    }
  }
}
</style>
<script>

export default {
  name: "YoutubeButton",
  props: {
    videoId: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'medium'
    }
  },

  computed: {
    _classes() {
      return {
        'youtube': true,
        'small': this.size === 'small'
      }
    }
  },

  methods: {
    _onClick() {
      window.open(`https://www.youtube.com/watch?v=${this.videoId}`, '_blank')
    }
  },
}
</script>

<template>
  <button :class="_classes" @click="_onClick()">
    <font-awesome-icon :icon="['fab', 'youtube']" />
  </button>
</template>

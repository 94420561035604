<script>
import UserName from "@/components/common/UserName.vue";
import {contextStore} from "@/stores/Context.store";
import DateTime from "@/components/common/DateTime.vue";

export default {
  name: "ChatMessage",
  components: {DateTime, UserName},

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    _isYours() {
      if (!this.item) return false
      return this.item.userUID === contextStore().getLoggedUserUID()
    },
    _cssClasses() {
      return {
        'chat-message': true,
        'yours': this._isYours,
        'not-yours': !this._isYours,
      }
    }
  },
}
</script>

<template>
  <div :class="_cssClasses">
    <div class="bubble" v-if="item">
      {{ item.content }}
      <br/>
      <div class="text-right">
        <user-name :uid="item.userUID" class="mt-1" v-if="!_isYours"/>
        <date-time :value="item.date" :time="true" class="mt-1"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.chat-message {
  display: flex;

  .bubble {
    max-width: 80%;
    padding: @padding-small @padding-regular;
    margin: @padding-small @padding-regular;
    border-radius: @radius-regular;
    font-size: @font-size-small;
  }

  &.not-yours {
    justify-content: flex-start;

    .bubble {
      background-color: @background-color;
      color: @text-color;
    }
  }

  &.yours {
    justify-content: flex-end;

    .bubble {
      background-color: @selected-color;
      color: @text-color;
    }
  }
}
</style>
<script>
import axiosService from "@/services/axios.service";
import {bookmakerStore} from "@/stores/Bookmaker.store";
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import OfferModalRow from "@/components/common/modal/offer/OfferModal.Row.vue";

export default {
  name: "OfferModal",
  components: {OfferModalRow},
  data() {
    return {
      results: null,

      kelly: {
        odd: 1.0,
        probability: 50,  //%
        incertitude: 20 //%
      }
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal
      if (!visibleModal) return false
      return visibleModal.uid === 'offerModal'
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal
      if (!visibleModal) return null
      return visibleModal.payload
    },
    _offerUID() {
      if (!this._payload) return null
      return this._payload.offerUID
    },
    _bookmakerUIDs() {
      if (!this.results) return []
      let uids = this.results.map(result => result.bookmakerUID);
      // remove duplicates
      return uids.filter((v, i, a) => a.indexOf(v) === i);
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _kellyStake() {
      if (!this.kelly.odd || !this.kelly.probability || !this.kelly.incertitude) return 0
      const b = this.kelly.odd - 1.0
      const p = this.kelly.probability / 100.0
      const q = 1 - p
      return (b * p - q) / b;
    },
    _kellyStakeFormatted() {
      if(!this._kellyStake) return '-'
      if(this._kellyStake <= 0) return '-'
      return (this._kellyStake * 100.0).toFixed(1) + '%'
    },
    _kellyIncertitudeStakeFormatted() {
      if(!this._kellyStake) return '-'
      if(this._kellyStake <= 0) return '-'
      const coef = this.kelly.odd * (this.kelly.incertitude / 100.0)
      // on applique l'incertitude
      const stake = this._kellyStake / (1.0 + coef)

      return (stake * 100.0).toFixed(1) + '%'
    },
    _expectedValueFormatted(){
      const b = this.kelly.odd - 1.0
      const p = this.kelly.probability / 100.0

      return (p * b - (1.0 - p)).toFixed(2)
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _bookmaker(uid) {
      if (!uid) return null
      return bookmakerStore().get(uid)
    },
    _valuesByBookmakerUID(uid) {
      const result = {
        trj: null,
        opening: null,
        min: null,
        max: null,
        current: null
      }

      if (!this.results) return result

      this.results.forEach(r => {
        if (r.bookmakerUID === uid) {
          result.trj = r.valueTRJ
          result.min = r.valueMin
          result.max = r.valueMax
          result.opening = r.valueFirst
          result.current = r.value

          if(result.trj){
            result.trj = (result.trj * 100.0).toFixed(1) + '%'
          }else{
            result.trj = '-'
          }
        }
      })

      return result
    },
  },
  watch: {
    _offerUID(uid) {
      if (!uid) return;
      axiosService.get('/dta/offer/' + uid + '/values').then(response => {
        this.results = response.data.results
      })

      // On tente de récupérer le parametre obv dans le payload
      if (this._payload.obv) {
        this.kelly.odd = this._payload.obv.value

        if(this._payload.obv.valueProbability){
          let probability = (this._payload.obv.valueProbability * 100.0).toFixed(1);
          this.kelly.probability = probability * 1.0
        }else{
          this.kelly.probability = 0.0
        }
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Offre
    </header>
    <div class="content">
      <table>
        <tr>
          <th>Bookmaker</th>
          <th>TRJ</th>
          <th>Open</th>
          <th>Min</th>
          <th>Max</th>
          <th>Act.</th>
        </tr>
        <offer-modal-row :bookmaker-uid="bookmakerUID" :values=_valuesByBookmakerUID(bookmakerUID) v-for="bookmakerUID in _bookmakerUIDs" :key="bookmakerUID"/>
      </table>
    </div>

    <hr/>

    <fieldset class="m-1 mt-3">
      <legend>Critère de <a href="https://www.bet2invest.com/fr/blog/The-Kelly-Criterion:-What-punters-need-to-know-!">Kelly</a>-<a href="https://twitter.com/Llou1007">Llou</a></legend>

      <table>
        <tr>
          <th style="width: 100px" class="text-right">Côte</th>
          <td colspan="2" class="text-right">
            <input type="number" v-model="kelly.odd" step='0.01' placeholder="Côte" style="text-align: end; width: 64px">
          </td>
        </tr>
        <tr>
          <th style="width: 100px" class="text-right">Probabilité</th>
          <td class="pl-5">
            <a-slider
                v-model:value="kelly.probability"
                :min="0" :max="100" :step="0.5"/>
          </td>
          <td style="width: 64px" class="text-right">
            <input disabled :placeholder="kelly.probability + '%'" style="text-align: end; width: 64px">
          </td>
        </tr>
        <tr>
          <th style="width: 128px" class="text-right">Mise selon Kelly</th>
          <td colspan="2" class="text-right">
            <input disabled :placeholder="_kellyStakeFormatted" style="text-align: end; width: 64px">
          </td>
        </tr>
        <tr>
          <th style="width: 100px" class="text-right">Incertitude</th>
          <td class="pl-5">
            <a-slider
                v-model:value="kelly.incertitude"
                :min="5" :max="50" :step="5"/>
          </td>
          <td style="width: 64px" class="text-right">
            <input disabled :placeholder="kelly.incertitude + '%'" style="text-align: end; width: 64px">
          </td>
        </tr>
        <tr>
          <th style="width: 128px" class="text-right">Kelly&nbsp;+&nbsp;incertitude</th>
          <td colspan="2" class="text-right">
            <input disabled :placeholder="_kellyIncertitudeStakeFormatted" style="text-align: end; width: 64px">
          </td>
        </tr>
        <tr>
          <th style="width: 128px" class="text-right">Exp. Value/Unit</th>
          <td colspan="2" class="text-right">
            <input disabled :placeholder="_expectedValueFormatted" style="text-align: end; width: 64px">
          </td>
        </tr>
      </table>
      <a-alert class="m-1" type="info" message="Le critère de Kelly est un outil de gestion de bankroll qui permet de déterminer la mise optimale à placer sur un pari en fonction de la côte et de la probabilité de gain."/>
    </fieldset>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="" @click="_onCancel">Fermer</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>
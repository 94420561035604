<script>

import {selectionStore} from "@/stores/Selection.store";
import {modalStore} from "@/stores/Modal.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {contextStore} from "@/stores/Context.store";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import {message} from "ant-design-vue";
import {playerPaneStore} from "@/components/routes/player/panes/player/PlayerPane.store";
import {preferencesStore} from "@/stores/Preferences.store";
import YoutubeButton from "@/components/common/YoutubeButton.vue";

export default {
  name: "PlayerPaneHeader",
  components: {YoutubeButton, ArjelLogo, FontAwesomeIcon},

  data() {
    return {
      displayedPage: 1,

      filter: '',
      filterTimeout: null
    }
  },
  computed: {
    _dateIntervalChoices() {
      return [
        {
          label: 'Plus tôt aujourd\'hui',
          shortLabel: '+tôt',
          code: 'EARLIER_TODAY'
        },
        {
          label: 'Aujourd\'hui',
          shortLabel: 'Auj.',
          code: 'TODAY'
        },
        {
          label: '24 prochaines heures',
          shortLabel: '24H',
          code: 'NEXT_24_HOURS'
        },
        {
          label: '3 prochains jours',
          shortLabel: '3J',
          code: 'NEXT_3_DAYS'
        }
      ]
    },
    _locationPrefValue() {
      return preferencesStore().getFirstValue('SELECTION_PLAYER', 'CRITERE_LOCATION') ?? 'ANY'
    },
    _locationLabel(){
      switch (this._locationPrefValue){
        case 'ANY':
          return 'D&E'
        case 'CURRENT':
          return 'Courante'
      }
    },
    _critereTri(){
      return preferencesStore().getFirstValue('SELECTION_PLAYER', 'CRITERE_TRI');
    },
    _critereTriLabel() {
      if (this._critereTri === 'NOTE') return 'Note'
      if (this._critereTri === 'DATETIME') return 'Date'
      if (this._critereTri === 'GOALS') return 'Buts'
      if (this._critereTri === 'ASSISTS') return 'Passe D.'
      if (this._critereTri === 'PENALTIES') return 'Pen.'

      // si le critère n'est pas défini, on utilise le paramètre par défaut
      return 'Note'
    },
    _critereLeagueLabel(){
      let critereLeague = preferencesStore().getFirstValue('SELECTION_PLAYER', 'CRITERE_LEAGUE') ?? 'ANY';
      if (critereLeague === 'ANY') return 'Toutes'
      if (critereLeague === 'CURRENT') return 'Du jour'
    },
    _selectionActive() {
      let selectedSelectionUID = playerPaneStore().getSelectionActiveUIDBySport(contextStore().getSport);
      return selectionStore().get(selectedSelectionUID);
    },
    _selectionActiveName() {
      let selectionActive = this._selectionActive;
      if (!selectionActive) return "Choisir une sélection";

      // Keep only the first 20 characters
      if (selectionActive.name.length > 25) {
        return selectionActive.name.substring(0, 25) + '...'
      }

      return selectionActive.name
    },
    _selectedDateInterval() {
      return this._dateIntervalChoices.find(c => c.code === playerPaneStore().selectedDateInterval);
    },
    _selectedDateIntervalLabel() {
      if (!this._selectedDateInterval) return ''
      return this._selectedDateInterval.shortLabel
    }
  },
  methods: {
    _onClickSelection() {
      modalStore().openModal({uid: "selectionsModal", payload: {type: "PLAYER"}})
    },
    _clearSelection() {
      playerPaneStore().clearSelection()
      playerPaneStore().runSelection()
    },
    _onSelectDateInterval(option) {
      if (option === 'NEXT_3_DAYS' || option === 'NEXT_7_DAYS') {
        if (!contextStore().isAuthenticated()) {
          message.warn("Connectes toi pour avoir accès à plus de jours d'analyse")
          return
        }
      }
      playerPaneStore().selectedDateInterval = option;

      // On relance la recherche
      this.displayedPage = 1
      playerPaneStore().runSelection();
    },
    _switchArjelOnly() {
      playerPaneStore().switchArjelOnly()

      // On relance la recherche
      this.displayedPage = 1
      playerPaneStore().runSelection();
    },
    _onLocationChange(location) {
      const cs = contextStore()
      if (!cs.isAuthenticated()) {
        message.warning("Il faut être connecté pour changer la localisation.");
        return;
      }

      preferencesStore().save('SELECTION_PLAYER', 'CRITERE_LOCATION', [location])
      window.emitter.emit('player-pane:critere-location:change', location)
    },
    _setCritereTri(option) {
      const cs = contextStore()
      if (!cs.isAuthenticated()) {
        message.warning("Il faut être connecté pour changer le tri.");
        return;
      }

      preferencesStore().save('SELECTION_PLAYER', 'CRITERE_TRI', [option])
      this.displayedPage = 1
    },
    _setCritereLeague(option) {
      const cs = contextStore()
      if (!cs.isAuthenticated()) {
        message.warning("Il faut être connecté pour changer le critère de compétition.");
        return;
      }

      preferencesStore().save('SELECTION_PLAYER', 'CRITERE_LEAGUE', [option])
      this.displayedPage = 1

      window.emitter.emit('player-pane:critere-league:change', option)
    }
  },
  watch: {
    filter: function (val) {
      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
        playerPaneStore().filter = val
        playerPaneStore().runSelection()
      }, 300);
    }
  }
}
</script>

<template>
  <div id="player-pane-header">
    <header>
      <div class="flex pt-1 mx-1">
        <button class="accent" @click="_onClickSelection">
          {{ _selectionActiveName }}
        </button>
        <div class="flex-grow"/>
        <button class="info" v-if="_selectionActive" @click="_clearSelection">
          <font-awesome-icon icon="fa-solid fa-close"/>
        </button>
      </div>
    </header>
    <header class="px-1 py-1 flex">
      <a-dropdown :trigger="['click']" class="flex-grow-0">
        <button class="info" @click.prevent>{{ _selectedDateIntervalLabel }}</button>
        <template #overlay>
          <a-menu>
            <a-menu-item v-for="c in _dateIntervalChoices" @click="_onSelectDateInterval(c.code)">{{
                c.label
              }}
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>

      <input v-model="filter" placeholder="Cherche un joueur..." class="ml-1 flex-grow">

    </header>
    <header>
      <div class="flex mx-1">
        <a-dropdown :trigger="['click']" class="flex-grow-0">
          <button class="small info mr-1">
            <font-awesome-icon icon="fa-solid fa-trophy"/>
            {{ _critereLeagueLabel }}
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="_setCritereLeague('ANY')">
                Toutes compétitions
              </a-menu-item>
              <a-menu-item @click="_setCritereLeague('CURRENT')">
                Uniquement compétition du jour
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

        <a-dropdown :trigger="['click']" class="flex-grow-0">
          <button class="small info mr-1">
            <font-awesome-icon icon="fa-solid fa-location-pin"/>
            {{ _locationLabel }}
          </button>
          <template #overlay>
            <a-menu>
              <span>
                <a-menu-item @click="_onLocationChange('ANY')">
                  D&E
                </a-menu-item>
                <a-menu-item @click="_onLocationChange('CURRENT')">
                  Courant
                </a-menu-item>
              </span>
            </a-menu>
          </template>
        </a-dropdown>

        <a-dropdown :trigger="['click']" class="flex-grow-0">
          <button class="small info">
            <font-awesome-icon icon="fa-solid fa-sort-amount-desc"/>
            {{ _critereTriLabel }}
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="_setCritereTri('NOTE')">
                Trier par note de correspondance
              </a-menu-item>
              <a-menu-item @click="_setCritereTri('DATETIME')">
                Trier par date/heure
              </a-menu-item>
              <a-menu-item @click="_setCritereTri('GOALS')">
                Trier par buts
              </a-menu-item>
              <a-menu-item @click="_setCritereTri('ASSISTS')">
                Trier par passes décisives
              </a-menu-item>
              <a-menu-item @click="_setCritereTri('PENALTIES')">
                Trier par penalties
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

        <div class="flex-grow"/>
        <youtube-button video-id="b9OA5zF9j5o" size="small"/>

      </div>
    </header>
  </div>
</template>
<script>
import UserLeaguePreferences from "@/components/common/modal/user_preferences/category/UserLeaguePreferences.vue";
import UserTeamPreferences from "@/components/common/modal/user_preferences/category/UserTeamPreferences.vue";
import UserBettingPreferences from "@/components/common/modal/user_preferences/category/UserBettingPreferences.vue";
import UserAccountPreferences from "@/components/common/modal/user_preferences/category/UserAccountPreferences.vue";
import {modalStore} from "@/stores/Modal.store";
import UserReferringPreferences from "@/components/common/modal/user_preferences/category/UserReferringPreferences.vue";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "UserPreferencesModal",
  components: {
    UserReferringPreferences,
    UserAccountPreferences,
    UserBettingPreferences,
    UserTeamPreferences, UserLeaguePreferences
  },
  data() {
    return {
      selectedCategory: 'account',
      categories: [
        {label: 'Compte', uid: 'account'},
        {label: 'Betting', uid: 'betting'},
        {label: 'Compétition', uid: 'league'},
        {label: 'Equipe', uid: 'team'},
        {label: 'Parrainage', uid: 'sponsoring'}
      ],
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'userPreferencesModal';
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return {};
      return visibleModal.payload;
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _isCategorySelected(category) {
      if (!this.selectedCategory) return false;
      return this.selectedCategory === category
    },
  },
  watch: {
    _isVisible(val) {
      if (val) {
        if(this._payload && this._payload.tab) {
          this.selectedCategory = this._payload.tab
        }
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="500" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Préférences
    </header>

    <div class="content">
      <div class="flex" style="overflow-x: scroll; scrollbar-width: none">
        <button :class="{'no-radius': true, 'tab selected': _isCategorySelected(c.uid)}"
                @click="selectedCategory=c.uid" v-for="c in categories">
          {{ c.label }}
        </button>
      </div>
      <hr/>

      <user-account-preferences v-if="selectedCategory === 'account'"/>
      <user-betting-preferences v-if="selectedCategory === 'betting'"/>
      <user-league-preferences v-if="selectedCategory === 'league'"/>
      <user-team-preferences v-if="selectedCategory === 'team'"/>
      <user-referring-preferences v-if="selectedCategory === 'sponsoring'"/>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="" @click="_onCancel">Fermer</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
</style>
<script>
import {adsStore} from "@/stores/ads.store";

export default {
  name: "GamblingAffiliationAd",
  computed: {
    _ad() {
      return adsStore().getRandom()
    }
  },
  methods: {
    _goToX() {
      window.open('https://x.com/OctobetTeam', '_blank');
    }
  }
}
</script>

<template>
  <div>
    <a rel="sponsored noreferrer noopener" v-if="_ad" :href="_ad.href" :title="_ad.title" target="_blank">
      <img width="380" height="50" border="0" :alt="_ad.title" :src="_ad.src">
    </a>
    <div class="flex">
      <button class="small info flex-grow mt-2" @click="_goToX">Tu veux ta pub ici ? Contactes-moi</button>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";
</style>
<script>
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import PlayerPicture from "@/components/common/PlayerPicture.vue";
import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import SelectionPaneItemOfferMatchRow
  from "@/components/routes/selection/panes/selection/items/SelectionPaneItemOfferMatch.Row.vue";

export default {
  name: "SelectionPaneItemOfferMatch",
  components: {SelectionPaneItemOfferMatchRow, OfferBookmakerValue, PlayerPicture, TeamLogo, LeagueLogo},
  props: {
    items: {
      type: Object,
      required: true
    },
    positive: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    _buildCssClasses() {
      return {
        'offer-criteria': true,
        'positive': this.positive,
        'negative': !this.positive
      }
    },
    _isEmpty() {
      return !this.items || !this.items.length || this.items.length === 0
    }
  },
}
</script>

<template>
  <div :class="_buildCssClasses" v-if="!_isEmpty">
    <div v-for="item in items" :key="item.id">
      <selection-pane-item-offer-match-row :obv="obv" :item="item" :positive="positive"
                                           v-for="obv in item.offerBookmakerValues" :key="obv.id" class="flex"/>
    </div>
  </div>
</template>


<style scoped lang="less">
@import "@/assets/styles/base.less";

.offer-criteria {
  color: @text-color;
  font-size: @font-size-small;
  min-height: 24px;
  align-items: center;

  &:hover {
    background: @hover-color;
  }

  &.positive {
    background: -webkit-linear-gradient(0deg, @positive-color, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00);

    &:hover {
      background: -webkit-linear-gradient(0deg, @positive-color, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF00);
    }
  }

  &.negative {
    background: -webkit-linear-gradient(0deg, @negative-color, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00);

    &:hover {
      background: -webkit-linear-gradient(0deg, @negative-color, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF00);
    }
  }

  .value {
    font-weight: bold;
    text-align: right;
    min-width: 40px;
    display: flex;
    align-items: center;
  }

  .bookmaker {
    display: flex;
    align-items: center;
    min-width: 24px;
  }
}
</style>
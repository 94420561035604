<script>
import axiosService from "@/services/axios.service";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import TeamName from "@/components/common/TeamName.vue";
import YoutubeButton from "@/components/common/YoutubeButton.vue";

export default {
  name: "SelectionPaneItem.Standing.Tab",
  components: {YoutubeButton, TeamName, TeamLogo, GameResultCircle},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      data: null,

      sortByOptions: [
        {value: 'PTS', label: 'Pts / Diff.', shortLabel: 'Pts/Diff.'},
        {value: 'ATT', label: 'Meilleure attaque', shortLabel: 'Att.'},
        {value: 'DEF', label: 'Meilleure défense', shortLabel: 'Def.'},
        {value: 'GD', label: 'Diff. buts / Pts', shortLabel: 'Diff.'},
        {value: "NO_LOSS", label: 'Moins de défaites', shortLabel: '-Def.'},
        {value: "NO_WIN", label: 'Moins de victoires', shortLabel: '-Vic.'},
        {value: "NO_DRAW", label: 'Moins de nuls' , shortLabel: '-Nul'},
      ],

      filters: {
        full: false,
        location: 'ANY',
        sortBy: 'PTS',
      },
    }
  },
  beforeMount() {

    // On va chercher la préférence du type
    let type = preferencesStore().getFirstValue('SELECTION_STANDING_TAB', 'LOCATION');
    if (type) {
      this.filters.type = type
    } else {
      preferencesStore().save('SELECTION_STANDING_TAB', 'LOCATION', [this.filters.location])
    }

    this._refreshData()
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid)
    },
    _groups() {
      if (!this.data) return []
      let groups = this.data.standings.map(s => s.group).filter((value, index, self) => self.indexOf(value) === index);
      // sort array by alphabetical order
      groups.sort((a, b) => {
        return a.localeCompare(b)
      })
      return groups
    },
    _groupStandings() {
      if (!this.data) return []
      return this.data.standings.filter(s => s.group === this.filters.group)
    },
    _sortedRanking() {
      if (!this.data) return []
      // create a copy of this.data.standings
      const standings = [...this._groupStandings]

      switch (this.filters.sortBy){
        case 'PTS':
          // sort by won * 3 + drawn and then by goal difference
          standings.sort((a, b) => {
            const aPoints = a.won * 3 + a.drawn
            const bPoints = b.won * 3 + b.drawn
            if (aPoints !== bPoints) {
              return bPoints - aPoints
            }
            return (b.pointsFor - b.pointsAgainst) - (a.pointsFor - a.pointsAgainst)
          })
          break
        case 'ATT':
          // sort by points for and then by goal difference
          standings.sort((a, b) => {
            if (a.pointsFor !== b.pointsFor) {
              return b.pointsFor - a.pointsFor
            }
            return (b.pointsFor - b.pointsAgainst) - (a.pointsFor - a.pointsAgainst)
          })
          break
        case 'DEF':
          // sort by points against and then by goal difference
          standings.sort((a, b) => {
            if (a.pointsAgainst !== b.pointsAgainst) {
              return a.pointsAgainst - b.pointsAgainst
            }
            return (b.pointsFor - b.pointsAgainst) - (a.pointsFor - a.pointsAgainst)
          })
          break
        case 'GD':
          // sort by goal difference and then by points
          standings.sort((a, b) => {
            if ((a.pointsFor - a.pointsAgainst) !== (b.pointsFor - b.pointsAgainst)) {
              return (b.pointsFor - b.pointsAgainst) - (a.pointsFor - a.pointsAgainst)
            }
            return b.won * 3 + b.drawn - a.won * 3 + a.drawn
          })
          break
        case 'NO_LOSS':
          // sort by lost and then by goal difference
          standings.sort((a, b) => {
            if (a.lost !== b.lost) {
              return a.lost - b.lost
            }
            return (b.pointsFor - b.pointsAgainst) - (a.pointsFor - a.pointsAgainst)
          })
          break
        case 'NO_WIN':
          // sort by won and then by goal difference
          standings.sort((a, b) => {
            if (a.won !== b.won) {
              return a.won - b.won
            }
            return (b.pointsFor - b.pointsAgainst) - (a.pointsFor - a.pointsAgainst)
          })
          break
        case 'NO_DRAW':
          // sort by drawn and then by goal difference
          standings.sort((a, b) => {
            if (a.drawn !== b.drawn) {
              return a.drawn - b.drawn
            }
            return (b.pointsFor - b.pointsAgainst) - (a.pointsFor - a.pointsAgainst)
          })
          break
      }


      return standings
    },
    _sortByLabel() {
      const option = this.sortByOptions.find(o => o.value === this.filters.sortBy)
      return option ? option.shortLabel : ''
    },
    _typeLabel() {
      switch (this.filters.location) {
        case 'HOME':
          return 'Dom.'
        case 'AWAY':
          return 'Ext.'
        default:
          return 'Gen.'
      }
    },
    _groupLabel() {
      return this.filters.group ? this.filters.group : 'Tous'
    }
  },

  methods: {

    _switchFullMode() {
      this.filters.full = !this.filters.full
    },

    _setGroup(group) {
      this.filters.group = group
    },

    _setLocation(location) {
      this.filters.location = location
      preferencesStore().save('SELECTION_STANDING_TAB', 'LOCATION', [this.filters.location])

      this._refreshData()
    },

    _setSortBy(option) {
      this.filters.sortBy = option.value
    },

    _refreshData() {
      const payload = {
        uid: this.gameUid,
        location: this.filters.location
      }
      axiosService.put('/dta/game/basic-data/standing', payload).then(response => {
        this.data = response.data

        // On sélectionne le premier groupe
        if (this.data.standings.length > 0) {
          this.filters.group = this.data.standings[0].group
        }
      })
    },

    _shouldShowLine(row) {
      if (!this._game) {
        return false
      }

      if (!row) {
        return false
      }

      if (!row.teamUID) {
        return false
      }

      if (!this.filters.full) {
        return row.teamUID === this._game.homeTeamUID || row.teamUID === this._game.awayTeamUID
      }
      return true
    },

    _rowClass(row) {
      if (!this._game) {
        return ''
      }

      if (!row) {
        return ''
      }

      if (!row.teamUID) {
        return ''
      }

      if (row.teamUID === this._game.homeTeamUID) {
        return 'home-row'
      }

      if (row.teamUID === this._game.awayTeamUID) {
        return 'away-row'
      }
    },
  }
}
</script>

<template>
  <div>
    <div class="flex">
      <fieldset class="m-1 p-0 text-left" v-if="_groups.length > 1">
        <legend class="m-0">Groupe</legend>
        <a-dropdown :trigger="['click']">
          <button :class="{'small no-radius' : true}" style="height: 22.3px">{{ _groupLabel }}
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="g in _groups" @click="_setGroup(g)" class="text-left">
                {{ g }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </fieldset>

      <fieldset class="m-1 p-0 text-left">
        <legend class="m-0">Complet</legend>
        <button :class="{'small no-radius' : true, 'primary' : filters.full === true}"
                style="height: 22.3px; width: 52px"
                @click="_switchFullMode">{{ filters.full ? 'Oui' : 'Non' }}
        </button>
      </fieldset>
      <fieldset class="m-1 p-0 text-left">
        <legend class="m-0">Type</legend>
        <a-dropdown :trigger="['click']">
          <button :class="{'small no-radius' : true}"
                  style="height: 22.3px">{{ _typeLabel }}
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="_setLocation('ANY')" class="text-left">
                General.
              </a-menu-item>
              <a-menu-item @click="_setLocation('HOME')" class="text-left">
                Domicile
              </a-menu-item>
              <a-menu-item @click="_setLocation('AWAY')" class="text-left">
                Exterieur
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </fieldset>
      <fieldset class="m-1 p-0 text-left">
        <legend class="m-0">Trier</legend>
        <a-dropdown :trigger="['click']">
          <button :class="{'small no-radius' : true}"
                  style="height: 22.3px">{{ _sortByLabel }}
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="so in sortByOptions" @click="_setSortBy(so)" class="text-left">
                {{ so.label }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

      </fieldset>
    </div>
    <table v-if="data">
      <tr>
        <th>#</th>
        <th colspan="2"></th>
        <th>J</th>
        <th>G</th>
        <th>N</th>
        <th>P</th>
        <th>BP</th>
        <th>BC</th>
        <th>-/+</th>
      </tr>
      <tr v-for="(r, index) in _sortedRanking">
        <td :class="_rowClass(r)" v-if="_shouldShowLine(r)">{{ index + 1 }}</td>
        <td v-if="_shouldShowLine(r)">
          <team-logo :uid="r.teamUID"/>
        </td>
        <td v-if="_shouldShowLine(r)" class="text-left">
          <team-name :uid="r.teamUID"/>
        </td>
        <td v-if="_shouldShowLine(r)">{{ r.played }}</td>
        <td v-if="_shouldShowLine(r)">{{ r.won }}</td>
        <td v-if="_shouldShowLine(r)">{{ r.drawn }}</td>
        <td v-if="_shouldShowLine(r)">{{ r.lost }}</td>
        <td v-if="_shouldShowLine(r)">{{ r.pointsFor }}</td>
        <td v-if="_shouldShowLine(r)">{{ r.pointsAgainst }}</td>
        <td v-if="_shouldShowLine(r)">{{ r.pointsFor - r.pointsAgainst }}</td>
      </tr>
    </table>
    <hr/>
    <div class="flex p-2">
      <p class="pr-2 m-0 flex-grow">🎥 Utiliser un classement ? C’est un jeu d’enfant, pas besoin de te faire un dessin... Je te fais une vidéo ! 🚀</p>
      <youtube-button video-id="od3MwP3VS_k"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.home-row{
  background: linear-gradient(90deg, @home-color, fade(@home-color, 0.80));
}

.away-row{
  background: linear-gradient(90deg, @away-color, fade(@away-color, 0.80));
}
</style>
<script>

export default {
  nam: "TicketBetValueButton",
  props: {
    value: {
      required: false
    },
    type: {
      type: String,
      default: ''
    },
    plain: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    _buildCssClasses() {
      return {
        'small': true,
        'info': this.type === 'info',
        'positive': this.type === 'positive',
        'negative': this.type === 'negative',
        'plain': this.plain
      }
    },
  },
}
</script>

<template>
  <button :class="_buildCssClasses">{{ value }}</button>
</template>

<script>

import {blogRouteStore} from "@/components/routes/blog/BlogRoute.store";

export default {
  name: "BlogVideo",
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _videoURL() {
      return blogRouteStore().getVideo(this.article)
    },
  }
}
</script>

<template>
  <div class="blog-video lg:w-1/3 md:w-2/3 m-auto" v-if="_videoURL">
    <div class="dots">
      <div class="dot primary"></div>
      <div class="dot secondary"></div>
      <div class="dot tertiary"></div>
      <div class="dot quaternary"></div>
    </div>
    <iframe width="100%" height="300" :src="_videoURL"
            title="Stats"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
    </iframe>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.blog-video {
  padding: @padding-large;
}
</style>
<script>

import {venueStore} from "@/stores/Venue.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import AreaFlag from "@/components/common/AreaFlag.vue";

export default {
  name: "VenueName",
  components: {AreaFlag, FontAwesomeIcon},
  props: {
    uid: {
      required: true
    },
    area: {
      required: false
    }
  },

  computed: {
    _venue() {
      return venueStore().get(this.uid);
    },
    _venueName() {
      return this._venue?.name ?? "_";
    },
    _buildCssClasses() {
      let cssClasses = null
      if (!this._venue) {
        cssClasses = {
          'venue-name': true
        }
      } else {
        cssClasses = {
          'venue-name': true
        }
      }

      return cssClasses;
    }
  },
}
</script>

<template>
  <div :class="_buildCssClasses">
    <area-flag :area="area" v-if="area"/>
    <font-awesome-icon icon="fa-location-pin" v-else/>
    <span class="name">
      {{ _venueName }}
    </span>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.venue-name {
  min-width: 16px;
  min-height: 16px;
  border-radius: @radius-small;
  color: @text-color;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 9pt;
  font-family: 'SourceCodePro', serif !important;

  .name{
    line-height: 24px;
  }
}
</style>
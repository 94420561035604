<script>
import axiosService from "@/services/axios.service";
import {contextStore} from "@/stores/Context.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import StatisticValueButton from "@/components/common/StatisticValueButton.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import {perspectiveStore} from "@/stores/Perspective.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "GoalsWidget",
  components: {
    WidgetLoadingContent,
    WidgetPeriodParamButton,
    WidgetTeamParamButton,
    WidgetLeagueParamButton,
    WidgetLocationParamButton,
    StatisticValueButton,
    TeamLogo
  },
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      data: {
        forStatUID: null,
        forGoals: null,
        forDataCount: null,

        againstStatUID: null,
        againstGoals: null,
        againstDataCount: null,
      }
    }
  },
  mounted() {

    this._onGameUIDChange()

    if (!this.item) return;  // widget store preview
    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })

    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _contextStore() {
      return contextStore();
    },
    _itemUID() {
      return this.item ? this.item.uid : null
    },
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!contextStore().gameUID) return;

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) return;

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {

      if (!this.item) {  // widget store preview
        this.data = {
          forGoals: 1.2,
          againstGoals: 1.5,
          forStatUID: null,
          againstStatUID: null
        }
        return;
      }

      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        return;
      }

      const payload = {
        whoTeamUID: perspectiveStore().getParameterTeamValue(this.item.uid),
        againstTeamUID: null,
        leagueUID: perspectiveStore().getParameterLeagueValue(this.item.uid),
        location: perspectiveStore().getParameterValue(this.item.uid, 'location'),
        period: perspectiveStore().getParameterValue(this.item.uid, 'period'),
        sport: perspectiveStore().getCurrentSport()
      }
      axiosService.put('/wid/goals', payload).then(response => {
        this.data = response.data
        wds.put(this.item.uid, contextStore().gameUID, this.data)
      })
    },
    _showStatModal(statUID) {
      if (!statUID) return
      modalStore().openModal({
        uid: 'statisticModal', payload: {
          'uid': statUID,
          'period': perspectiveStore().getParameterValue(this.item.uid, 'period')
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Buts
      <div class="widget-header-params flex" v-if="item">
        <widget-period-param-button :widget="item"/>
        <widget-location-param-button :widget="item"/>
        <widget-team-param-button :widget="item"/>
        <widget-league-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content flex" v-else>
      <button class="flex-1 positive no-radius" style="height: 32px" @click="_showStatModal(data.forStatUID)">
        {{ data.forGoals ? data.forGoals.toFixed(1) : '-' }}
      </button>
      <button class="flex-1 negative no-radius" style="height: 32px" @click="_showStatModal(data.againstStatUID)">
        {{ data.againstGoals ? data.againstGoals.toFixed(1) : '-' }}
      </button>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";
.positive {
  background: @positive-color;
  background: linear-gradient(90deg, @positive-color 0%, fade(@positive-color, 50%) 70%, rgba(0,0,0,0) 99%) !important;
  color: white !important;
}

.negative {
  background: @negative-color;
  background: linear-gradient(270deg, @negative-color 0%, fade(@negative-color, 50%) 70%, rgba(0,0,0,0) 99%) !important;
  color: white !important;
}
</style>
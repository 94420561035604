// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";

export const widgetDefinitionsStore = defineStore('widgetDefinitionsStore', {
    state: () => {
        return {
            items: null
        }
    },
    actions: {
        // Permet de récupérer un match par son UID
        fetchAll(callback = null) {
            if (this.items !== null) {
                if (callback) callback()
                return
            }
            this.items = []
            axiosService.get('/per/widget-definitions').then(response => {
                this.items = response.data.items
            }).finally(() => {
                if (callback) callback()
            });
        }
    },
    getters: {
        get: (state) => (uid) => {
            if (!state.items) {
                return null
            }

            let item = state.items.find(item => item.uid === uid);
            if (item) {
                return item
            }
        },
        getBySport: (state) => (sport) => {
            if (!state.items) {
                return []
            }
            return state.items.filter(item => item.sports.length === 0 || item.sports.indexOf(sport) >= 0)
        },
        getByType: (state) => (type) => {
            if (!state.items) {
                return null
            }
            return state.items.find(item => item.type === type)
        },
        getBySportAndPerspectiveType: (state) => (sport, perspectiveType) => {
            if (!state.items) {
                return []
            }
            return state.items.filter(item => (item.perspectiveType === perspectiveType) && (item.sports.length === 0 || item.sports.indexOf(sport) >= 0))
        },
    }
})
<script>
import {modalStore} from "@/stores/Modal.store";
import {bankrollStore} from "@/stores/Bankroll.store";
import {contextStore} from "@/stores/Context.store";
import BankrollResume from "@/components/common/BankrollResume.vue";
import {message, Modal} from "ant-design-vue";
import {monitoringStore} from "@/stores/Monitoring.store";

export default {
  name: "EditBankrollModal",
  components: {BankrollResume},

  data() {
    return {
      bankrollName: ''
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'editBankrollModal';
    },
    _payload() {
      if (!this._isVisible) return null;
      return modalStore().getVisibleModal.payload
    },
    _bankrollUID() {
      if (!this._payload) return null;
      return this._payload.bankrollUID
    },
    _initialBankrollName() {
      if (!this._bankrollUID) return null;
      let bankroll = bankrollStore().get(this._bankrollUID);
      if (!bankroll) return null;
      return bankroll.name
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _canConfirm() {
      return this.bankrollName.length > 0 && this.bankrollName !== this._initialBankrollName
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _deleteBankroll() {
      if (!contextStore().isAuthenticated()) {
        return // On ne peut pas delete si on est pas authentifié
      }

      // Il faut vérifier que la BK n'est pas associée a un monitoring
      let monitoring = monitoringStore().getByBankrollUID(this._bankrollUID)
      if(monitoring && monitoring.length > 0) {
        message.error('Impossible de supprimer cette bankroll car elle est associée à un monitoring.')
        return
      }

      Modal.confirm({
        title: 'Es-tu sûr de vouloir supprimer cette bankroll ?',
        content: 'Cette action est irréversible.',
        okText: 'Oui, supprimer la bankroll',
        okType: 'danger',
        cancelText: 'Annuler',
        onOk: () => {
          bankrollStore().delete(this._bankrollUID)
          this._onCancel()
        },
        onCancel: () => {
          // Nothing to do
        },
      });
    },
    _onConfirm() {
      if (!this._canConfirm) return;
      bankrollStore().update(this._bankrollUID, this.bankrollName)
      this._onCancel()
    }
  },
  watch: {
    _bankrollUID: function (newVal) {
      if (!newVal) return;
      let bankroll = bankrollStore().get(newVal);
      if (!bankroll) return;
      this.bankrollName = bankroll.name
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" @cancel="_onCancel"
          :class="{'mobile': _isMobile}">
    <header class="ant-modal-header flex">
      Editer une bankroll
    </header>

    <div class="content">
      <fieldset class="m-2">
        <legend>Nom de la bankroll</legend>
        <div class="flex p-2">
            <input placeholder="Nom de la bankroll" v-model="bankrollName" class="w-100 flex-grow"/>
        </div>
      </fieldset>
    </div>

    <template #footer>
      <div class="flex p-1">
        <button class="m-1 secondary" @click="_deleteBankroll">Supprimer</button>
        <div class="flex-grow"/>
        <button class="m-1 primary" @click="_onConfirm" v-if="_canConfirm">Valider</button>
        <button class="m-1 info" @click="_onCancel">Fermer</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.bankroll-item {
  border-radius: @padding-small;
  padding: @padding-regular;
  margin: @padding-regular;
  color: @text-color;
  cursor: pointer;
  text-align: left;
  display: flex;
  border: 1px solid transparent;

  .bankroll-icon {
    width: 24px;
    height: 24px;
    border-radius: @radius-small;
    background-color: @primary-color;
    display: inline-block;
    margin-right: 8px;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
  }

  .bankroll-texts {
    flex-grow: 1;

    p {
      font-size: 12px !important;
      color: @text-color;
      margin: 0;
    }
  }

  &:hover {
    border: 1px solid @stroke-color;
  }

  &.selected {
    background: @selected-color !important;
  }
}
</style>
<script>

import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";

export default {
  name: "TauxRetourJoueurCalculatorItem",
  components: {OfferChoiceButton, OfferBookmakerValue},
  data() {
    return {
      form: {
        coteA: 1.65,
        coteB: 3.1,
        coteC: 4.8
      }
    }
  },
  computed: {
    _probaARaw() {
      return (1 / this.form.coteA * 100).toFixed(2)
    },
    _probaBRaw() {
      return (1 / this.form.coteB * 100).toFixed(2)
    },
    _probaCRaw() {
      if(!this.form.coteC) return '-'
      return (1 / this.form.coteC * 100).toFixed(2)
    },
    _probaA() {
      return (1 / this.form.coteA * this._tauxRetourJoueur * 100).toFixed(2)
    },
    _probaB() {
      return (1 / this.form.coteB * this._tauxRetourJoueur * 100).toFixed(2)
    },
    _probaC() {
      if(!this.form.coteC) return '-'
      return (1 / this.form.coteC * this._tauxRetourJoueur * 100).toFixed(2)
    },
    _sumProba(){
      return (parseFloat(this._probaA) + parseFloat(this._probaB) + parseFloat(this._probaC)).toFixed(0) + '%'
    },
    _sumProbaRaw(){
      return (parseFloat(this._probaARaw) + parseFloat(this._probaBRaw) + parseFloat(this._probaCRaw)).toFixed(0) + '%'
    },
    _tauxRetourJoueur() {
      if (!this.form.coteA || !this.form.coteB) return null
      if (!this.form.coteC) {
        return 1 / ((1 / this.form.coteA) + (1 / this.form.coteB))
      }
      return 1 / ((1 / this.form.coteA) + (1 / this.form.coteB) + (1 / this.form.coteC))
    },
    _formattedTRJ() {
      if (!this._tauxRetourJoueur) return '-'
      return (this._tauxRetourJoueur * 100.0).toFixed(1) + '%'
    }
  },
  methods: {}
}
</script>

<template>
  <table>
    <tr>
      <th class="text-right">Côtes</th>
      <td class="text-center">
        <input type="number" v-model="form.coteA" step='0.01' placeholder="Côte" style="text-align: end; width: 64px">
      </td>
      <td class="text-center">
        <input type="number" v-model="form.coteB" step='0.01' placeholder="Côte" style="text-align: end; width: 64px">
      </td>
      <td class="text-center">
        <input type="number" v-model="form.coteC" step='0.01' placeholder="Côte" style="text-align: end; width: 64px">
      </td>
    </tr>
    <tr>
      <th class="text-right">({{_sumProbaRaw}}) Probabilités biaisées</th>
      <td class="text-center">
        <input type="number" :placeholder="_probaARaw" disabled placeholder="%" style="text-align: end; width: 64px">
      </td>
      <td class="text-center">
        <input type="number" :placeholder="_probaBRaw" disabled placeholder="%" style="text-align: end; width: 64px">
      </td>
      <td class="text-center">
        <input type="number" :placeholder="_probaCRaw" disabled placeholder="%" style="text-align: end; width: 64px">
      </td>
    </tr>
    <tr>
      <th class="text-right">Taux de retour au joueur</th>
      <td class="text-center" colspan="3">
        <input type="number" disabled :placeholder="_formattedTRJ" class="text-center">
      </td>
    </tr>
    <tr>
      <th class="text-right">({{ _sumProba }}) Probabilités réelles</th>
      <td class="text-center">
        <input type="number" :placeholder="_probaA" disabled placeholder="%" style="text-align: end; width: 64px">
      </td>
      <td class="text-center">
        <input type="number" :placeholder="_probaB" disabled placeholder="%" style="text-align: end; width: 64px">
      </td>
      <td class="text-center">
        <input type="number" :placeholder="_probaC" disabled placeholder="%" style="text-align: end; width: 64px">
      </td>
    </tr>
  </table>
</template>

<style scoped lang="less">

</style>
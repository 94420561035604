<script>

import {userStore} from "@/stores/User.store";

export default {
  name: "BlogHeader",
  methods: {
    _signWithGoogle() {
      userStore().signInWithGoogle()
    },
    _goToMain() {
      this.$router.push({name: 'main'})
    },
  }
}
</script>

<template>
  <header id="blog-header">
    <div id="blog-header-left">
      <span id="app-logo-gradient"></span>
      <span id="app-logo" class="float-left" @click="_goToMain()">
        <a-tooltip placement="right" title="DoctoBET">
          <img src="../../../assets/images/logo-40.png" alt="DoctoBET"
               style="width: 40px; height: 40px; cursor: pointer"/>
        </a-tooltip>
      </span>
      <button>
        DoctoBET
      </button>
    </div>

    <div id="blog-header-right">
      <a-tooltip placement="left" title="Connexion">
        <button class="primary mr-1" @click="_signWithGoogle()">
          <font-awesome-icon icon="fa-solid fa-right-to-bracket"/>
          Commencer
        </button>
      </a-tooltip>
    </div>
  </header>
</template>


<style scoped lang="less">
@import "@/assets/styles/variables.less";

#blog-header {
  background-color: @background-color-light;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  #blog-header-left {
    float: left;

    #app-logo-gradient {
      width: 200px;
      height: @toolbar-regular;
      display: inline-block;
      position: absolute;
      left: 0;
      opacity: 0.25;
      background: rgb(97, 233, 255);
      background: linear-gradient(90deg, rgba(97, 233, 255, 1) 0%, rgba(34, 193, 195, 1) 20%, rgba(253, 187, 45, 1) 40%, rgba(255, 132, 110, 1) 60%, rgba(43, 45, 48, 1) 85%);
    }
  }

  #blog-header-right {
    float: right;
  }
}

.mobile {
  #blog-header {
    text-align: left;
  }
}

</style>
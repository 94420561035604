<script>

import {gameStore} from "@/stores/Game.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import PlayerFormeTab from "@/components/routes/player/panes/player/tabs/PlayerPaneItem.Forme.Tab.vue";
import PlayerEventsTab from "@/components/routes/player/panes/player/tabs/PlayerPaneItem.Events.Tab.vue";
import PlayerH2HTab from "@/components/routes/player/panes/player/tabs/PlayerPaneItem.H2H.Tab.vue";
import playerPaneItemTempsFortTab from "@/components/routes/player/panes/player/tabs/PlayerPaneItem.TempsFort.Tab.vue";

export default {
  name: "PlayerPaneItemTabs",
  components: {playerPaneItemTempsFortTab, PlayerFormeTab, PlayerEventsTab, PlayerH2HTab},
  props: {
    playerUid: {
      type: Number,
      required: true
    },
    gameUid: {
      type: Number,
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {
    _game() {
      if (!this.gameUid) return null
      return gameStore().get(this.gameUid)
    }
  },

  methods: {
    _isTabVisible(tab) {
      if (!this._game) {
        return false  // Pas de game, pas de tab
      }
      const sport = this._game.sport
      switch (sport) {
        case 'FOOTBALL':
          return true
        default:
          return false
      }
    },

    _isSelectedTab(tab) {
      if (!this._isTabVisible(tab)) {
        return false
      }
      return selectionPaneStore().selectedItemTab === tab
    },

    _setSelectedTab(tab) {
      selectionPaneStore().selectedItemTab = tab
    },
  }
}
</script>

<template>
  <div>
    <div class="flex">
      <button
          v-if="_isTabVisible('FORME')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('FORME')}"
          @click="_setSelectedTab('FORME')">Forme
      </button>
      <button
          v-if="_isTabVisible('EVENTS')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('EVENTS')}"
          @click="_setSelectedTab('EVENTS')">Events
      </button>
      <button
          v-if="_isTabVisible('H2H')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('H2H')}"
          @click="_setSelectedTab('H2H')">H2H
      </button>
      <button
          v-if="_isTabVisible('TEMPS_FORT')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('TEMPS_FORT')}"
          @click="_setSelectedTab('TEMPS_FORT')">Tps. Fort
      </button>
    </div>
    <hr>
    <div>
      <player-forme-tab :player-uid="playerUid" :game-uid="gameUid" v-if="_isSelectedTab('FORME')"/>
      <player-events-tab :player-uid="playerUid" :game-uid="gameUid" v-if="_isSelectedTab('EVENTS')"/>
      <player-h2-h-tab :player-uid="playerUid" :game-uid="gameUid" v-if="_isSelectedTab('H2H')"/>
      <player-pane-item-temps-fort-tab :player-uid="playerUid" :game-uid="gameUid"  v-if="_isSelectedTab('TEMPS_FORT')"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";
</style>
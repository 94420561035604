<script>
import {perspectiveStore} from "@/stores/Perspective.store";
import {contextStore} from "@/stores/Context.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {message} from "ant-design-vue";
import {modalStore} from "@/stores/Modal.store";
import {preferencesStore} from "@/stores/Preferences.store";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";

export default {
  name: "TeamRouteBottomBar",
  components: {FontAwesomeIcon},
  computed: {
    _selectedSport(){
      return contextStore().getSport
    },
    _perspectives() {
      return perspectiveStore().getBySportAndType(this._selectedSport, 'TEAM')
    },
    _selectedPerspectiveUID() {
      return statsRouteStore().getSelectedPerspectiveUID()
    }
  },
  methods: {
    _isSelected(perspective) {
      return perspective.uid === this._selectedPerspectiveUID
    },
    _onClick(perspective) {
      if (this._isSelected(perspective)) {
        this._showPerspectiveSettingsModal(perspective)
      } else {
        statsRouteStore().setSelectedPerspectiveUID(perspective.uid)

        // Save the perspective as default for the user/sport
        preferencesStore().save('DEFAULT_PERSPECTIVE', 'TEAM_PERSPECTIVE', [perspective.uid])
      }
    },
    _showPerspectiveSettingsModal(perspective, creation = false) {
      if (perspective.userUID !== contextStore().getLoggedUserUID()) {
        return
      }

      modalStore().openModal({
        uid: 'perspectiveSettingsModal', payload: {
          uid: perspective.uid,
          creation: creation
        }
      })
    },
    _createPerspective() {
      if (!contextStore().isAuthenticated()) {
        message.error('Tu dois être connecté pour créer une perspective')
        return
      }

      // Limite lié a ta licence
      const allPerspectives = perspectiveStore().getBySportAndType(this._selectedSport, 'TEAM')
      switch (contextStore().getLicenseType()) {
        case 'NONE':  // si pas de licence, impossible de créer une perspective
          message.warn("Sans licence, tu ne peux pas créer de perspective.")
          return
        case 'BASIC':  // si pas de licence ULTIMATE, limiter le nombre de perspectives à 2
          if (allPerspectives.length >= 2) {
            message.warn("Sans licence ULTIMATE, tu ne peux pas avoir plus de 2 perspectives.")
            return
          }
          break
        case 'FULL': // si licence ULTIMATE, on limite a 8
          if (allPerspectives.length >= 8) {
            message.warn("Il n'est pas possible d'avoir plus de 8 perspectives.")
            return
          }
          break
      }

      const sport = this._selectedSport
      perspectiveStore().create('TEAM', sport, (perspective) => {
        this._showPerspectiveSettingsModal(perspective, true)
      })
    }
  }
}
</script>

<template>
  <div id="team-route-bottom-bar">
    <div class="flex">
      <div class="flex-grow">
        <span v-for="p in _perspectives">
          <a-tooltip placement="top" :title="p.description">
            <button :class="{'small tab perspective-tab': true, 'selected': _isSelected(p)}"
                    @click="_onClick(p)">
              {{ p.name }}
            </button>
          </a-tooltip>
        </span>
      </div>
      <div>
        <a-tooltip placement="top" title="Créer une perspective">
          <button class="square small info perspective-tab ml-1"
                  @click="_createPerspective()">
            <font-awesome-icon icon="fa-solid fa-plus"/>
          </button>
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#team-route-bottom-bar {
  height: calc(@toolbar-small + 2px);
  width: calc(100%);
  overflow-y: hidden;
  overflow-x: hidden;
  position: fixed;
  bottom: 0;
  background: @background-color-light;
  margin: 0;
  padding: 0;
  text-align: left;
  border-top: 1px solid @background-color;

  .perspective-tab {
    height: @toolbar-small;
    line-height: @toolbar-small;
    display: inline-block;
    border-radius: 0;
  }
}
</style>
<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";
import BlogAvisClient from "@/components/routes/blog/BlogAvisClient.vue";
import BlogVideo from "@/components/routes/blog/BlogVideo.vue";

export default {
  name: "ModuleStats",
  components: {BlogVideo, BlogAvisClient, BlogSeeMore},
  props: {
    article: {
      type: String,
      required: true
    }
  },
  computed: {
    _imgSrc() {
      return 'https://docto.bet/cdn/blog/' + this.article + '.gif';
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/3 md:w-2/3 m-auto">
        <img :src="_imgSrc" alt="Module Stats"
             class="w-full"/>
      </div>
    </div>

    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET, c’est plus qu’un simple outil, c’est ton allié stratégique pour des paris mieux calculés.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>

    <div class="body-bloc bloc-1 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Personnalise ton tableau de bord</h3>
      <p class="px-5">Avec DoctoBET, tu peux créer ton propre tableau de bord d'analyse en quelques clics. Chaque
        rencontre est unique, et avec notre module statistiques, tu personnalises l'affichage des données selon les
        critères qui t'intéressent le plus : buts marqués, matchs à domicile ou à l'extérieur, performances récentes...
        Bref, tout ce dont tu as besoin pour affiner tes pronostics.</p>
    </div>

    <div class="body-bloc bloc-2 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Analyse chaque détail</h3>
      <p class="px-5">Ne laisse plus rien au hasard. Le module te permet d'analyser chaque équipe en profondeur. Par
        exemple, visualise rapidement si une équipe surperforme en over 0.5 buts ou over 1.5 buts, que ce soit à
        domicile ou sur l'ensemble de la saison. Chaque statistique est ajustable pour te donner une vue complète et
        adaptée à ta stratégie.</p>
    </div>

    <blog-video :article="article"/>

    <div class="body-bloc bloc-3 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Vision instantanée des liens</h3>
      <p class="px-5">
        Un survol rapide de ton tableau te permet de voir immédiatement où se situe chaque équipe dans différents
        critères. Les lignes colorées différencient les équipes en compétition, l'adversaire, et celles de la même
        compétition. C'est un gain de temps énorme pour repérer les opportunités les plus intéressantes sans avoir à
        creuser des heures dans les données.
      </p>
    </div>

    <blog-see-more/>

    <div class="body-bloc bloc-4 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Affiche jusqu'à 100 équipes simultanément</h3>
      <p class="px-5">Besoin d'élargir ton analyse ? Avec le module statistiques de DoctoBET, tu peux passer de
        l'analyse de 15 équipes à celle de 100 équipes en un clic. Idéal pour
        surveiller les tendances sur une large sélection de matchs.</p>
    </div>


    <div class="body-bloc bloc-5 lg:w-1/3 md:w-2/3">
      <h3 class="p-5">Ton compte, ton espace, tes perspectives</h3>
      <p class="px-5">
        En te créant un compte gratuit, tu auras accès à un outil totalement personnalisé et sécurisé. Crée autant de
        perspectives que tu le souhaites, sauvegarde tes paramètres, et reviens quand tu veux pour affiner tes
        pronostics. Avec DoctoBET, tu gagnes du temps et de l'efficacité, en éliminant les incertitudes grâce à une
        analyse précise et rapide.
      </p>
    </div>
    <blog-avis-client/>


  </div>
</template>

<style scoped lang="less">

</style>
<script>
import {Modal} from "ant-design-vue";
import SelectionPane from "@/components/routes/selection/panes/selection/SelectionPane.vue";
import AnalysePane from "@/components/routes/selection/panes/analyse/AnalysePane.vue";
import SelectionOperation from "@/components/routes/selection/panes/selection/condition/SelectionOperation.vue";

// Stores
import {selectionStore} from "@/stores/Selection.store";
import {contextStore} from "@/stores/Context.store";
import {modalStore} from "@/stores/Modal.store";
import {offerStore} from "@/stores/Offer.store";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";
import {monitoringStore} from "@/stores/Monitoring.store";


export default {
  name: "EditSelectionModal",
  components: {SelectionOperation, AnalysePane, SelectionPane},
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _selectionStore() {
      return selectionStore();
    },
    _contextStore() {
      return contextStore();
    },
    _selectedSelectionUID() {
      if (!this._selectedSelection) return null
      return this._selectedSelection.uid
    },
    _selectedSelection() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal || !visibleModal.payload) return null;
      let selection = visibleModal.payload.selection;
      if (!selection) return null;
      if (!selection.operation) {
        // Si la selection n'a pas d'operation, on lui en crée une
        selection.operation = {
          operator: "AND",
          operations: [],
          operands: []
        }
      }
      return selection
    },
    _isSelectedSelectionYours() {
      if (!this._selectedSelection) return false
      if(!selectionStore().isYours(this._selectedSelectionUID)){
        return false
      }
      return true
    },
    _selectedSelectionType() {
      if (!this._selectedSelection) return null
      return this._selectedSelection.type
    },
    _selectedSelectionTypeLabel() {
      if (!this._selectedSelectionType) return ""
      if (this._selectedSelectionType === "PLAYER") return "Joueur"
      if (this._selectedSelectionType === "GAME") return "Rencontre"
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'editSelectionModal';
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.payload;
    },
    _sport() {
      if (!this._payload) return null
      return this._payload.sport
    },
    _selections() {
      return this._selectionStore.getAll
    },
    _hasConditions() {
      if (!this._selectedSelection) return false
      if (!this._selectedSelection.operation) return false

      const hasOperands = this._selectedSelection.operation.operands && this._selectedSelection.operation.operands.length > 0
      const hasOperations = this._selectedSelection.operation.operations && this._selectedSelection.operation.operations.length > 0

      return hasOperands || hasOperations
    },
    _leagueGroups() {
      return leagueGroupsStore().getBySport(contextStore().getSport);
    },
    _sportOfferFamilies() {
      let offerFamilyDefinitionsBySport = offerStore().getOfferFamilyDefinitionsBySport(contextStore().getSport);
      if (!offerFamilyDefinitionsBySport) return []
      return offerFamilyDefinitionsBySport.items
    },
    _sportOfferFamilyChoices() {
      const results = []
      if (!this._sportOfferFamilies) {
        return results
      }

      this._sportOfferFamilies.forEach(f => {

        // On liste les arguments possibles
        let args = []
        f.choices.forEach(c => {
          c.arguments.forEach(a => {
            args.push(a)
          })
        })
        // remove duplicates
        args = args.filter((v, i, a) => a.indexOf(v) === i);
        // remove null
        args = args.filter(a => a !== null)
        if (args.length === 0) {
          args = null
        }

        results.push({
          label: offerStore().getOfferFamilyDefinitionLabel(f.code),
          value: f.fixedUID,
          args: args
        })
      })
      return results
    },
    _offerTypeLabel() {
      let familyFixedUID = this._selectedSelection.offerFamilyFixedUID;
      if (!familyFixedUID) {
        return 'Choisir un type d\'offre'
      }
      if (!this._sportOfferFamilies) {
        return 'Choisir un type d\'offre'
      }

      const family = this._sportOfferFamilies.find(f => f.fixedUID === parseInt(familyFixedUID))
      if (!family) {
        return 'Type offre'
      }

      const arg = this._selectedSelection.offerArgument
      return offerStore().getOfferFamilyDefinitionLabel(family.code) + (arg ? ' ' + arg : '')
    },
    _selectedFamily() {
      let familyFixedUID = this._selectedSelection.offerFamilyFixedUID;
      if (!familyFixedUID) {
        return null
      }
      if (!this._sportOfferFamilies) {
        return null
      }

      const family = this._sportOfferFamilies.find(f => f.fixedUID === parseInt(familyFixedUID))
      if (!family) {
        return null
      }
      return family
    },
    _selectedLeagueGroupName() {
      if (!this._selectedSelection.leagueGroupUID) {
        return 'Toutes les compétitions'
      }
      const lg = this._leagueGroups.find(lg => lg.uid === this._selectedSelection.leagueGroupUID)
      if (!lg) {
        return 'Toutes les compétitions'
      }
      return lg.name
    }
  },
  methods: {
    _onApply() {
      if (!this._contextStore.isAuthenticated()) {
        return // On ne peut pas sauvegarder si on est pas authentifié
      }
      if(this._isSelectedSelectionYours){
        this._selectionStore.saveSelection(this._selectedSelection)
      }
      this._onCancel()
    },

    _openingMonitoringList() {
      if (!this._contextStore.isAuthenticated()) {
        return // On ne peut pas monitorer si on est pas authentifié
      }

      modalStore().openModal({
        uid: 'selectionMonitoringListModal',
        payload: {selectionUID: this._selectedSelection.uid}
      })
    },

    _onDelete() {
      if (!this._contextStore.isAuthenticated()) {
        return // On ne peut pas delete si on est pas authentifié
      }

      // Il faut vérifier que la séléction n'est pas attachée à un monitoring
      const monitoring = monitoringStore().getBySelectionUID(this._selectedSelection.uid)

      if (monitoring && monitoring.length > 0) {
        Modal.error({
          title: 'Suppression impossible',
          content: 'Cette sélection est utilisée dans un monitoring. Il faut d\'abord supprimer le monitoring pour pouvoir supprimer la sélection.'
        });
        return
      }

      Modal.confirm({
        title: 'Es-tu sûr de vouloir supprimer cette sélection ?',
        content: 'Cette action est irréversible.',
        okText: 'Oui, supprimer la sélection',
        okType: 'danger',
        cancelText: 'Annuler',
        onOk: () => {
          selectionStore().deleteSelection(this._selectedSelection)
          this._onCancel()
        },
        onCancel: () => {
          // Nothing to do
        },
      });
    },

    _onDuplicate() {
      if (!this._contextStore.isAuthenticated()) {
        return // On ne peut pas delete si on est pas authentifié
      }

      Modal.confirm({
        title: 'Duplication de sélection',
        content: 'Souhaites tu faire une copie de cette sélection ?',
        okText: 'Oui, dupliquer la sélection',
        okType: 'danger',
        cancelText: 'Non',
        onOk: () => {
          selectionStore().duplicateSelection(this._selectedSelection)
          this._onCancel()
        },
        onCancel: () => {
          // Nothing to do
        },
      });
    },

    _onCancel() {
      modalStore().closeModal()
    },
    _removeOperation(operationToRemove) {
      if (!this._selectedSelection) return;

      let s = null  // represente la selection dans laquelle se trouve l'operation a supprimer
      for (let selection of this._selections) {
        if (selection === this._selectedSelection) {
          s = selection
        }
      }
      if (!s) {
        console.warn("cannot find selection", this._selectedSelection)
        return;
      }

      s.operation.operations = this._removeOperationIn(s.operation.operations, operationToRemove);
      if (s.operation.operations.length === 1) {
        let firstChild = s.operation.operations[0]
        s.operation.operator = firstChild.operator;
        s.operation.operations = firstChild.operations;
        s.operation.operands = firstChild.operands;
      }
    },

    _removeOperationIn(operations, operationToRemove) {
      if (!operations || operations.length <= 0) return operations;
      for (let operation of operations) {
        const sameOp = operation === operationToRemove;
        if (sameOp) {
          operations = operations.filter(child => child !== operation);

          if (operations.length === 1) {
            let firstChild = operations[0]
            operation.operator = firstChild.operator;
            operation.operations = firstChild.operations;
            operation.operands = firstChild.operands;
          }

        }
        if (operation.operations) {
          operation.operations = this._removeOperationIn(operation.operations, operationToRemove)

          if (operation.operations.length === 1) {
            let firstChild = operation.operations[0]
            operation.operator = firstChild.operator;
            operation.operations = firstChild.operations;
            operation.operands = firstChild.operands;
          }
        }
      }

      return operations;
    },
    _onFamilyChange(uid, arg) {
      this._selectedSelection.offerFamilyFixedUID = uid
      this._selectedSelection.offerArgument = arg
    },
    _onLeagueGroupChange(uid) {
      if(!this._isSelectedSelectionYours) return
      this._selectedSelection.leagueGroupUID = uid
    },
    _openLeagueGroupsModal() {
      if(!this._isSelectedSelectionYours) return
      modalStore().openModal({
        uid: "leagueGroupsModal",
        payload: {
          sport: this._sport
        }
      })
    },
    _isSelectedChoice(choice) {
      return this._selectedSelection.offerFamilyChoiceFixedUID === choice.fixedUID
    },
    _setOfferFamilyChoice(choice) {
      if(!this._isSelectedSelectionYours) return
      if (this._selectedSelection.offerFamilyChoiceFixedUID === choice.fixedUID) {
        this._selectedSelection.offerFamilyChoiceFixedUID = null
        return
      }
      this._selectedSelection.offerFamilyChoiceFixedUID = choice.fixedUID
    },
    _choiceLabel(choice) {
      return offerStore().getOfferFamilyChoiceLabel(choice, this._selectedSelection.offerArgument, true)
    },
    _clearOfferFamily() {
      if(!this._isSelectedSelectionYours) return
      this._selectedSelection.offerFamilyFixedUID = null
      this._selectedSelection.offerFamilyChoiceFixedUID = null
    }
  },
  watch: {
    _isVisible(v) {
      selectionStore().removeUselessOperands(this._selectedSelection)
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Editeur de sélection {{ _selectedSelectionTypeLabel }}
    </header>
    <div>
      <fieldset class="m-1 pb-1 no-border">
        <legend>Nom de la sélection</legend>
        <input type="text" v-model="_selectedSelection.name" class="w-full" :disabled="!_isSelectedSelectionYours">
      </fieldset>
      <fieldset class="m-1 pb-1 no-border">
        <legend>Description</legend>
        <input class="w-full" type="text" v-model="_selectedSelection.description" :disabled="!_isSelectedSelectionYours">
        <a-alert class="mt-2"
                 message="La description est optionnelle mais elle permet de mieux comprendre l'objectif de la sélection."
                 type="warning" v-if="_selectedSelection.description.length === 0" show-icon/>
      </fieldset>
      <hr/>
      <div class="selection">
        <div class="selection-conditions px-2 py-2">
          <selection-operation :selection="_selectedSelection" :operation="_selectedSelection.operation"
                               @remove="_removeOperation" :sport="_sport" :editable="_isSelectedSelectionYours"/>
          <a-alert class="mt-2" message="Ta sélection doit contenir au moins une condition." type="error"
                   v-if="!_hasConditions" show-icon/>
        </div>

      </div>
      <hr class="my-2"/>
      <div class="flex">
        <fieldset class="mx-1 pb-1 no-border flex-grow">
          <legend>Compétitions ciblés</legend>
          <div class="flex">
            <a-dropdown :trigger="['click']" class="flex-grow-0">
              <button class="small info mr-1">
                {{ _selectedLeagueGroupName }}
              </button>
              <template #overlay v-if="_isSelectedSelectionYours">
                <a-menu>
                  <a-menu-item @click="_onLeagueGroupChange(null)">
                    Toutes les compétitions
                  </a-menu-item>
                  <span v-for="lg in _leagueGroups">
                  <a-menu-item @click="_onLeagueGroupChange(lg.uid)">
                    {{ lg.name }}
                  </a-menu-item>
                </span>
                  <hr/>
                  <a-menu-item @click="_openLeagueGroupsModal">
                    Gestion des groupes
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </fieldset>
        <fieldset class="mx-1 pb-1 no-border text-right" v-if="_isSelectedSelectionYours">
          <legend>Autoriser consultation</legend>
          <div>
            <button :class="{'small mr-1' : true, 'info': !_selectedSelection.private, 'primary selected': _selectedSelection.private}"
                    @click="_selectedSelection.private = true">
              Non
            </button>
            <button :class="{'small mr-1' : true, 'info': _selectedSelection.private, 'primary selected': !_selectedSelection.private}"
                    @click="_selectedSelection.private = false">
              Oui
            </button>
          </div>
        </fieldset>
      </div>
      <div v-if="_selectedSelectionType === 'GAME'">
        <hr class="my-2"/>
        <fieldset class="mx-1 mb-3 no-border">
          <legend>Type de bet visé</legend>
          <div class="flex">
            <a-dropdown :trigger="['click']" class="flex-grow-0">
              <button class="small info mr-1">
                {{ _offerTypeLabel }}
              </button>
              <template #overlay v-if="_isSelectedSelectionYours">
                <a-menu>
              <span v-for="c in _sportOfferFamilyChoices">
                <a-menu-item @click="_onFamilyChange(c.value, null)" v-if="c.args === null">
                  {{ c.label }}
                </a-menu-item>
                <a-sub-menu :key="c.value" :title="c.label" v-else>
                  <a-menu-item v-for="arg in c.args" @click="_onFamilyChange(c.value, arg)">
                    {{ arg }}
                  </a-menu-item>
                </a-sub-menu>
              </span>
                </a-menu>
              </template>
            </a-dropdown>
            <div v-if="_selectedFamily">
              <button :class="{'small mr-1':true, 'primary selected' : _isSelectedChoice(c)}"
                      v-for="c in _selectedFamily.choices" @click="_setOfferFamilyChoice(c)">
                {{ _choiceLabel(c) }}
              </button>
            </div>
            <div class="flex-grow"/>
            <button class="secondary small" @click="_clearOfferFamily" v-if="_selectedFamily && _isSelectedSelectionYours">
              Effacer
            </button>
          </div>
          <a-alert class="mt-2" v-if="!_selectedSelection.offerFamilyFixedUID"
                   message="Choisir ce type permet d'afficher les côtes associées dans les résultats."
                   type="info" show-icon/>
        </fieldset>
      </div>
    </div>
    <template #footer>
      <div class="my-2 mx-1 flex">
        <button class="secondary" @click="_onDelete" v-if="_isSelectedSelectionYours">
          <font-awesome-icon :icon="['fas', 'trash-can']"/>
        </button>
        <a-tooltip title="Dupliquer la sélection" v-if="_isSelectedSelectionYours">
          <button class="info ml-1" @click="_onDuplicate">
            <font-awesome-icon :icon="['fas', 'copy']"/>
          </button>
        </a-tooltip>

        <div class="flex-grow"></div>
        <button class="accent ml-2 mr-1" @click="_openingMonitoringList" v-if="_selectedSelectionType==='GAME' && _isSelectedSelectionYours">
          <font-awesome-icon :icon="['fas', 'heart-pulse']"/>
          Monitorer
        </button>
        <button class="primary mr-1" @click="_onApply" v-if="_contextStore.isAuthenticated() && _hasConditions">
          {{ _isSelectedSelectionYours ? 'Sauvegarder' : 'Fermer' }}
        </button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.selection-item {
  border-radius: @padding-small;
  background-color: @background-color-light;
  padding: 0 @padding-small;
  color: @text-color;
  cursor: pointer;

  &:hover {
    background-color: @hover-color;
  }

  &.selected {
    background: @selected-color !important;
  }
}
</style>
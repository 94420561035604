<script>
import {colorStore} from "@/stores/Color.store";
import {modalStore} from "@/stores/Modal.store";
import {bankrollPaneStore} from "../panes/bankroll/BankrollPane.store";
import {bankrollStore} from "@/stores/Bankroll.store";
import {contextStore} from "@/stores/Context.store";
import BankrollResume from "@/components/common/BankrollResume.vue";
import {message} from "ant-design-vue";

export default {
  name: "BankrollsModal",
  components: {BankrollResume},
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'bankrollsModal';
    },
    _selectedBankrollUID() {
      return bankrollPaneStore().bankrollActiveUID
    },
    _isBankrollSelected() {
      return this._selectedBankrollUID !== null
    },
    _userBankrolls() {
      return bankrollStore().getUserBankrolls
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _openCreateBankrollModal() {

      if (!contextStore().isAuthenticated()) {
        message.error('Tu dois être connecté pour créer une bankroll')
        return
      }

      modalStore().openModal({uid: "createBankrollModal"})
    },
    _isSelected(bankroll) {
      if (!this._selectedBankrollUID) return false;
      if (!bankroll) return false;
      return this._selectedBankrollUID === bankroll.uid
    },
    _onSelectBankroll(bankroll) {
      bankrollPaneStore().setBankrollActiveUID(bankroll.uid)
      this._onCancel()
    },
    _bankrollName(bankroll) {
      if(!bankroll) return '?'
      let rawName = bankroll.name
      // remove 'Monitoring -'
      rawName = rawName.replace('Monitoring - ', '')
      // Keep only 20 characters
      if (rawName.length > 24) {
        return rawName.substring(0, 24) + '...'
      }
      return rawName
    },
    _firstLetter(name) {
      return name.charAt(0).toUpperCase()
    },
    _bankrollStyle(bankroll) {
      let bgColor = colorStore().get(JSON.stringify(bankroll));
      return {
        backgroundColor: bgColor,
        color: colorStore().getContrastColor(bgColor)
      }
    },
    _editBankroll(bankrollUID) {
      modalStore().openModal({uid: 'editBankrollModal', payload: {bankrollUID: bankrollUID}})
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="700" @cancel="_onCancel" :closable="this._isBankrollSelected"
          :class="{'mobile': _isMobile}">
    <header class="ant-modal-header flex">
      Bankrolls
    </header>

    <div class="content">
      <a-alert v-if="_userBankrolls.length === 0" message="Tu n'as pas encore de bankroll" class="m-2"
               description="Clique sur 'Nouvelle bankroll' pour en créer une." type="info" show-icon/>
      <div class="bankroll-item-wrapper">
        <div :class="{'bankroll-item': true, 'selected': _isSelected(bk)}" v-for="bk in _userBankrolls"
             @click="_onSelectBankroll(bk)">

          <div class="bankroll-texts flex">
            <div class="bankroll-icon" :style="_bankrollStyle(bk)">
              {{ _firstLetter(_bankrollName(bk)) }}
            </div>

              <label>{{ _bankrollName(bk) }}</label>
              <div class="flex-grow"/>
              <button class="small info" @click.stop="_editBankroll(bk.uid)">Editer</button>
          </div>
          <div>
            <p>{{ bk.description }}</p>
            <bankroll-resume :bankroll-u-i-d="bk.uid" class="mt-1"/>
          </div>
        </div>
      </div>
      <div>

      </div>
    </div>

    <template #footer>
      <div class="flex p-1">
        <div class="flex-grow"/>
        <button class="m-1 primary" @click="_openCreateBankrollModal">Nouvelle bankroll</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.bankroll-item-wrapper {
  height: calc(100vh - @toolbar-regular * 2 - @toolbar-small);
  gap: @padding-regular; /* Adjust the gap as needed */
  display: flex;
  flex-wrap: wrap;
  padding: @padding-regular;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}

.bankroll-item {
  padding: @padding-regular;
  cursor: pointer;
  text-align: left;
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  border: 1px solid @stroke-color;
  border-radius: @radius-regular;
  flex: 1 1 320px; /* permet aux div de croître et se contracter, avec une base de 350px */
  box-sizing: border-box;
  max-width: calc(100%);

  &:hover {
    background: @title-color;
    background: linear-gradient(135deg, fade(@title-color, 20%) 0%, fade(@title-color, 15%) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  }

  .bankroll-icon {
    width: 24px;
    height: 24px;
    border-radius: @radius-small;
    background-color: @primary-color;
    display: inline-block;
    margin-right: 8px;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
  }

  .bankroll-texts {
    flex-grow: 1;

    p {
      font-size: 12px !important;
      color: @text-color;
      margin: 0;
    }
  }

  &:hover {
    border: 1px solid @stroke-color;
  }
}
</style>
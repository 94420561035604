<script>

// Stores
import {perspectiveStore} from "@/stores/Perspective.store";
import {widgetDefinitionsStore} from "@/stores/WidgetDefinitions.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "WidgetPeriodParamButton",
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  methods: {
    _openSettingsModal() {
      modalStore().openModal({
        uid: 'widgetSettingsModal', payload: {
          uid: this.widget.uid
        }
      })
    },
  },
  computed: {
    _key() {
      return 'period'
    },
    _value() {
      return perspectiveStore().getParameterValue(this.widget.uid, this._key)
    },
    _label() {
      if (!this._value) return '?';

      const wd = widgetDefinitionsStore().getByType(this.widget.type)
      if (!wd) return '?';

      return wd.parameters.find(p => p.key === this._key).values.find(v => v.key === this._value).short
    },
    _tooltipTitle() {
      if (!this._value) return '?';

      const wd = widgetDefinitionsStore().getByType(this.widget.type)
      if (!wd) return '?';

      return wd.parameters.find(p => p.key === this._key).values.find(v => v.key === this._value).name
    }
  }
}
</script>

<template>
  <a-tooltip placement="top" :title="_tooltipTitle">
    <button class="small no-radius" @click="_openSettingsModal">
      {{ _label }}
    </button>
  </a-tooltip>
</template>

// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "../../../../../services/axios.service";

export const bankrollPaneStore = defineStore('bankrollPaneStore', {
    state: () => {
        return {
            // Les des matchs qui matchent avec la selection active
            bankrollActiveUID: null,
            ticketActiveUID: null,

            // Active bankroll data
            results: null,
            lastTicketUID: null,

            // Filters
            orderBy: "TICKET_DATETIME",
            orderDirection: "DESC",

            leagueUIDs: null,
            minOdd: null,
            maxOdd: null,
            status: null,

            page: 0,    // Par defaut on charge la première page
            canLoadMore: true,
            loadBankrollTimeout: null
        }
    },
    actions: {
        loadBankrollNextPage() {
            if(!this.canLoadMore) {
                return
            }
            this.page++
            this.loadBankroll()
        },

        reloadBankroll() {
            this.page = 0
            this.canLoadMore = true
            this.loadBankroll()
        },

        loadBankroll() {
            clearTimeout(this.loadBankrollTimeout)

            this.loadBankrollTimeout = setTimeout(() => {
                const payload = bankrollPaneStore().buildPayload()
                axiosService.put('/bkl/' + this.bankrollActiveUID + '/ticket', payload).then(response => {
                    if (this.page === 0) {
                        this.results = []
                        this.lastTicketUID = response.data.lastTicketUID
                    }
                    if(response.data.results.length === 0) {
                        this.canLoadMore = false
                    }
                    this.results = this.results.concat(response.data.results)
                })
            }, 100)
        },
        setBankrollActiveUID(uid) {
            if (this.bankrollActiveUID === uid) {
                return  // Si on clique sur le même on ne fait rien
            }

            this.bankrollActiveUID = parseInt(uid)
            bankrollPaneStore().reloadBankroll()

            window.emitter.emit('bankroll-pane:bankroll-uid:change', this.bankrollActiveUID)
        },
        setTicketActiveUID(uid) {
            if (this.ticketActiveUID === uid) {
                this.setTicketActiveUID(null)
                return
            }
            this.ticketActiveUID = uid
        },
        setSortBy(option) {
            if (this.orderBy === option) {
                return
            }
            this.orderBy = option
            bankrollPaneStore().reloadBankroll()
        },
        setSortDirection(option) {
            if (this.orderDirection === option) {
                return
            }
            this.orderDirection = option
            bankrollPaneStore().reloadBankroll()
        },
        setMinOdd(odd) {
            let newValue = odd
            if (!newValue) {
                newValue = null
            } else {
                newValue = parseFloat(newValue)
            }
            if (this.minOdd === newValue) {
                return
            }
            this.minOdd = newValue

            bankrollPaneStore().reloadBankroll()
            window.emitter.emit('bankroll-pane:bankroll-filters:change')
        },
        setMaxOdd(odd) {
            let newValue = odd
            if (!newValue) {
                newValue = null
            } else {
                newValue = parseFloat(newValue)
            }
            if (this.maxOdd === newValue) {
                return
            }
            this.maxOdd = newValue
            bankrollPaneStore().reloadBankroll()
            window.emitter.emit('bankroll-pane:bankroll-filters:change')
        }
    },
    getters: {
        getBankrollActiveUID: (state) => () => {
            return state.bankrollActiveUID
        },
        getResults: (state) => () => {
            return state.results
        },
        getTicketActiveUID: (state) => () => {
            return state.ticketActiveUID
        },
        buildPayload: (state) => () => {
            return  {
                bankrollUID: state.bankrollActiveUID,
                leagueUIDs: state.leagueUIDs,
                minOdd: state.minOdd,
                maxOdd: state.maxOdd,
                status: state.status,
                page: state.page,
                orderBy: state.orderBy,
                orderDirection: state.orderDirection
            }
        }
    }
})
<script>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {contextStore} from "@/stores/Context.store";
import {preferencesStore} from "@/stores/Preferences.store";
import {leagueStore} from "@/stores/League.store";

export default {
  name: "LeagueFavIndicator",
  components: {FontAwesomeIcon},
  props: {
    uid: {
      required: true
    },
    size: {
      type: String,
      default: "small"
    }
  },

  computed: {
    _contextStore() {
      return contextStore();
    },
    _leagueStore() {
      return leagueStore();
    },
    _league() {
      return this._leagueStore.get(this.uid);
    },
    _buildCssClasses() {
      return {
        'league-fav-indicator': true,
        'small': this.size === 'small',
        'primary': this._isFav,
        'info': !this._isFav
      }
    },
    _isFav() {
      if (!this._league) {
        return false;
      }
      const sport = this._league.sport
      let favorites = preferencesStore().getValues('LEAGUE', 'FAVORITE_LEAGUES_' + sport);

      if (!favorites) {
        return false;
      }

      return favorites.find(fav => fav.toString() === this.uid.toString())
    },
    _tooltipMessage() {
      return this._isFav ? 'Retirer des favoris' : 'Ajouter aux favoris'
    },
    _buildCssStyle() {
      return {
        height: '22px'
      }
    }
  },
  methods: {
    _switchFav() {
      if (!this._league) {
        return false;
      }
      const sport = this._league.sport
      preferencesStore().switch('LEAGUE', 'FAVORITE_LEAGUES_' + sport, [this.uid]);
    }
  }
}
</script>

<template>
  <a-tooltip :title="_tooltipMessage">
    <font-awesome-icon
        @click="_switchFav"
        :icon="['fa-solid', 'fa-heart']" :class="_buildCssClasses" :style="_buildCssStyle"/>
  </a-tooltip>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.league-fav-indicator {

  cursor: pointer;
  display: inline-block;
  max-height: 24px;
  max-width: 24px;

  &.small {
    max-height: 16px;
    max-width: 16px;
  }

  &.large {
    max-height: 64px;
    max-width: 64px;
  }
}
</style>
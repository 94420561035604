<script>
import BlogSeeMore from "@/components/routes/blog/BlogSeeMore.vue";

export default {
  name: "CommentChoisirCote",
  components: {BlogSeeMore},
  methods: {
    _goHome() {
      this.$router.push('/');
    }
  }
}
</script>

<template>
  <div>
    <div class="h1-bloc">
      <div class="lg:w-1/2 m-auto p-5">
        <h1 class="px-2">Article non trouvé</h1>
      </div>
    </div>

    <div class="body-bloc lg:w-1/3 md:w-2/3">
      <h2>
        DoctoBET s’adresse à tous ceux qui veulent passer au niveau supérieur dans leurs paris sportifs. Que tu sois un
        novice ou un pro, cet outil est là pour t’aider à parier avec plus de précision et de confiance.
      </h2>
      <div class="dots">
        <div class="dot primary"></div>
        <div class="dot secondary"></div>
        <div class="dot tertiary"></div>
        <div class="dot quaternary"></div>
      </div>
    </div>


    <blog-see-more/>

  </div>
</template>

<style scoped lang="less">

</style>
// stores/counter.js
import {defineStore} from 'pinia'
import {perspectiveStore} from "@/stores/Perspective.store";
import {contextStore} from "@/stores/Context.store";

export const playerAnalysePaneStore = defineStore('playerAnalysePaneStore', {
    state: () => {
        return {
            // Represente la liste des joueurs ouvert en analyse
            // Forme : {playerUID: string, gameUID: string}
            items: [],

            // Represente le joueur selectionné dans la liste des joueurs
            selectedPlayerUID: null,
            // Represente la perspective selectionnée pour analyser le joueur
            selectedPerspective: null
        }
    },
    actions: {
        setSelectedPlayerUID(playerUID) {
            if(this.selectedPlayerUID === playerUID){
                return
            }
            this.selectedPlayerUID = playerUID
            window.emitter.emit('players-route:widgets-need-to-refresh', playerUID)
        },
        addPlayer(playerUID, gameUID) {

            // check if player is already in the list
            const item = this.items.find(item => item.playerUID === playerUID)
            if(item){
                return
            }

            const newItem = {
                playerUID: playerUID,
                gameUID: gameUID
            }
            const newItems = [...this.items, newItem]
            this.items = [...new Set(newItems)]

            this.setSelectedPlayerUID(playerUID)

            // Si aucune perspective n'est selectionnée, on selectionne la première perspective du joueur
            if(playerAnalysePaneStore().getSelectedPerspective) {
                return
            }

            const perspectives = perspectiveStore().getBySportAndType(contextStore().getSport, 'PLAYER')
            if(perspectives.length > 0){
                this.setSelectedPerspective(perspectives[0])
            }

        },
        removePlayer(playerUID) {
            const newItems = this.items.filter(item => item.playerUID !== playerUID)
            this.items = [...new Set(newItems)]

            if(this.items.length === 0){
                this.setSelectedPlayerUID(null)
                this.setSelectedPerspective(null)
            }else{
                this.setSelectedPlayerUID(this.items[0].playerUID)
            }
        },
        setSelectedPerspective(perspective) {
            this.selectedPerspective = perspective
        }
    },
    getters: {
        getItems: (state) => {
            return state.items
        },
        getItemByPlayerUID: (state) => (playerUID) => {
            if(!playerUID){
                return null
            }
            if(!state.items){
                return null
            }
            return state.items.find(item => item.playerUID === playerUID)
        },
        getSelectedPlayerUID: (state) => {
            return state.selectedPlayerUID
        },
        getSelectedPerspective: (state) => {
            return state.selectedPerspective
        }
    }
})
<script>
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameDate from "@/components/common/GameDate.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import GameEvent from "@/components/common/GameEvent.vue";
import PlayerPaneItemEventsTabRow from "@/components/routes/player/panes/player/tabs/PlayerPaneItem.Events.Tab.Row.vue";

export default {
  name: "PlayerPaneItem.Events.Tab.GameGroup",
  components: {PlayerPaneItemEventsTabRow, GameEvent, GameResultCircle, TeamName, TeamLogo, LeagueLogo, GameDate},

  props: {
    gameUid: {
      required: true
    },
    events: {
      required: true
    }
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid)
    },
    _eventGameTeamUID() {
      if (!this._game) return null

      const teamUID = this.events[0].teamUID

      if (teamUID === this._game.homeTeamUID) {
        return this._game.homeTeamUID
      }
      return this._game.awayTeamUID
    },
    _eventGameAgainstTeamUID() {
      if (!this._game) return null

      const teamUID = this.events[0].teamUID

      if (teamUID === this._game.homeTeamUID) {
        return this._game.awayTeamUID
      }
      return this._game.homeTeamUID
    },
  }
}
</script>

<template>
  <div>
    <div class="mx-1 flex" style="font-size: 9pt">
      <team-logo :uid="_eventGameAgainstTeamUID" class=""/>
      <team-name :uid="_eventGameAgainstTeamUID"/>
      <div class="flex-grow"/>
      <game-date :uid="gameUid"/>
      <game-result-circle :team-uid="_eventGameTeamUID" :game-uid="gameUid"/>
    </div>
    <div style="padding-left: 24px">
      <player-pane-item-events-tab-row :event="e" v-for="e in events" :key="e.uid"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table.last-player-event-row {
  border-bottom: 1px solid @stroke-color;

  tr, td{
    border: none;
    padding: 0;
    background: transparent;
  }

  &.over{
    background-color: @hover-color;
  }
}
</style>
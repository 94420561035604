// stores/counter.js
import {defineStore} from 'pinia'

import axiosService from "@/services/axios.service";
import {contextStore} from "@/stores/Context.store";

export const leagueGroupsStore = defineStore('leagueGroupsStore', {
    state: () => {
        return {
            items: null,
        }
    },
    actions: {
        /**
         * Permet l'initialisation des selections
         * @param callback
         */
        fetchAll(callback = null) {
            if (this.items !== null) {
                if (callback) callback()
                return
            }
            this.items = []

            axiosService.get('/usr/league-groups').then(response => {
                this.items = response.data.results
            }).catch(error => {
                console.error('leagueGroupsStore.fetchAll', error)
            }).finally(() => {
                if (callback) callback()
            })

        },

        save(uid, name, sport, values) {
            const cs = contextStore()
            if (!cs.isAuthenticated()) {
                return;
            }

            // remove null or undefined values
            values = values.filter(item => item !== null && item !== undefined);

            let payload = {
                uid: uid,
                name: name,
                sport: sport,
                values: values
            };
            axiosService.post('/usr/league-group', payload).then(response => {

                if(!response.data.result) {
                    console.error('leagueGroupsStore.save', 'response.data.result is null')
                    return
                }

                // on recupere l'uid du nouvel element
                let group = response.data.result
                if(!group) {
                    console.error('leagueGroupsStore.save', 'group is null')
                    return
                }

                let groupUID = group.uid

                // remove from items if exists
                const index = this.items.findIndex(item => {
                    return item.uid === groupUID
                })
                if (index !== -1) {
                    this.items.splice(index, 1)
                }
                // add to items
                this.items.push(payload)
            }).catch(error => {
                console.error(error)
            });
        },
        delete(uid) {
            if(!uid) return
            const cs = contextStore()
            if (!cs.isAuthenticated()) {
                return;
            }

            axiosService.delete('/usr/league-group/' + uid).then(_ => {
                // remove from items if exists
                const index = this.items.findIndex(item => {
                    return item.uid === uid
                })
                if (index !== -1) {
                    this.items.splice(index, 1)
                }
            }).catch(error => {
                console.error(error)
            });
        }
    },
    getters: {
        getAll: (state) => {
            return state.items
        },
        getBySport: (state) => (sport) => {
            if (state.items === null) return null
            return state.items.filter(item => item.sport === sport)
        },
        get: (state) => (uid) => {
            if(!uid) return null
            if (state.items === null) return null
            return state.items.find(item => item.uid === uid)
        },
        getValues: (state) => (uid) => {
            if (state.items === null) return null
            let item = state.items.find(item => item.uid === uid)
            if (!item) return null
            return item.values
        }
    }
})
<script>
import TeamLogo from "@/components/common/TeamLogo.vue";
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";
import axiosService from "@/services/axios.service";
import TeamName from "@/components/common/TeamName.vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import {statStore} from "@/stores/Statistic.store";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import LeagueName from "@/components/common/LeagueName.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import FrequencyStatsWidgetRow from "@/components/routes/stats/widgets/FrequencyStats.Widget.Row.vue";
import SerieStatsWidgetRow from "@/components/routes/stats/widgets/SerieStats.Widget.Row.vue";
import WidgetNbEventsParamButton from "@/components/common/widgets/parameter/WidgetNbEventsParamButton.vue";

export default {
  name: "RawStatsWidget",
  components: {
    WidgetNbEventsParamButton,
    SerieStatsWidgetRow,
    FrequencyStatsWidgetRow,
    AreaFlag,
    ArjelLogo,
    WidgetLoadingContent,
    LeagueLogo,
    FontAwesomeIcon,
    LeagueName,
    WidgetTeamParamButton,
    WidgetLocationParamButton,
    WidgetLeagueParamButton,
    WidgetPeriodParamButton,
    TeamName, TeamLogo
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: () => {
    return {
      refreshTimeout: null,
      data: null,

      // Gestion du click long sur une ligne
      rowClickTimer: null,
    }
  },

  mounted() {
    window.emitter.on('stats-route:widgets-need-to-refresh', () => {
      this._refresh()
    })
    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
    this._refresh()
  },

  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _widget() {
      if (!this._itemUID) return null
      return perspectiveStore().getWidgetByUID(this._itemUID)
    },
    _currentPerspectiveUID() {
      return statsRouteStore().getSelectedPerspectiveUID()
    },
    _sdef() {
      if (!this._statFixedUID) return null
      return statStore().getDefinitionByFixedUID(this._statFixedUID)
    },
    _statLabel() {
      if (!this._sdef) return '...'

      // remove "Série -" from the label
      return this._sdef.name.replace('Série - ', '')
    },
    _parameters() {
      return perspectiveStore().getParametersByWidgetUID(this.item.uid)
    },
    _statFixedUID() {
      return perspectiveStore().getParameterValue(this.item.uid, 'stat_fixed_uid')
    },
    _statType() {
      if (!this._sdef) return null
      return this._sdef.type
    },
    _sortedData() {
      if (!this.data) return []
      return this.data.sort((a, b) => {
        if (b.absolute - a.absolute) return 1
        if (a.absolute - b.absolute) return -1

        return a.gameDate - b.gameDate
      })
    },
    _offerChoiceFixedUID() {
      if (!this._statFixedUID) return null
      return statStore().getOfferChoiceFixedUIDBySdefFixedUID(this._statFixedUID);
    },
  },
  watch: {
    _statFixedUID() {
      this._refresh()
    },
    _widget() {
      this._refresh()
    }
  },

  methods: {

    _refresh() {

      if(!this._widget) {
        return
      }
      const isInCurrentPerspective = this._widget.perspectiveUID === this._currentPerspectiveUID
      if(!isInCurrentPerspective) {
        return
      }

      this.data = null // => loading
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {
      this.data = null // => loading
      let offerChoiceFixedUIDs = null
      if (this._offerChoiceFixedUID) {
        offerChoiceFixedUIDs = []
        offerChoiceFixedUIDs.push(this._offerChoiceFixedUID['HOME'])
        offerChoiceFixedUIDs.push(this._offerChoiceFixedUID['AWAY'])
      }

      const payload = statsRouteStore().buildBaseWidgetPayload()
      payload.statFixedUID = this._statFixedUID
      payload.location = perspectiveStore().getParameterValue(this.item.uid, 'location')
      payload.period = perspectiveStore().getParameterValue(this.item.uid, 'period')
      payload.league = perspectiveStore().getParameterValue(this.item.uid, 'league')
      payload.minEvent = perspectiveStore().getParameterValue(this.item.uid, 'min_event')
      payload.offerChoiceFixedUIDs = offerChoiceFixedUIDs
      payload.offerArgument = this._offerArgument
      payload.sport = contextStore().getSport
      // TODO payload.againstTeamStatFixedUID = [19]

      axiosService.put('/wid/stats/serie', payload).then(response => {
        this.data = response.data.results
      }).catch(error => {
        console.error(error)
      })
    },

    _updateOverContext(row, pin = false) {
      statsRouteStore().setOverContext({
        statUID: row.statUID,
        teamUID: row.teamUID,
        gameUID: row.gameUID,
        leagueUID: row.leagueUID
      }, pin)
    },

    _openStatSelectorModal() {
      modalStore().openModal({
        uid: "statSelectorModal",
        payload: {
          category: 'stat',
          statFixedUID: this._statFixedUID,
          type: this._statType,
          sport: 'FOOTBALL',
          callback: (categorie, sdef) => {
            // Si non authentifié, message d'erreur
            if (!contextStore().isAuthenticated()) {
              message.error('Tu dois être connecté pour personnalisé les statistiques');
              return;
            }
            const parameterDefinition = this._parameters.find(p => p.key === 'stat_fixed_uid')

            perspectiveStore().setWidgetParameterValue(this.item.uid, parameterDefinition, {'key': sdef.fixedUID})
            perspectiveStore().saveWidgetParameters(this.item.uid)
          }
        }

      });
    },

    _expend() {
      modalStore().openModal({
        uid: "serieStatsModal",
        payload: {
          widgetUID: this.item.uid,
          statFixedUID: this._statFixedUID
        }
      });
    },

    _startClickTimer(row) {
      if (!row.gameUID) {
        return
      }
      clearTimeout(this.rowClickTimer)
      this.rowClickTimer = null
      const self = this
      this.rowClickTimer = setTimeout(() => {
        self._updateOverContext(row, true)
        this.rowClickTimer = null
      }, 400)
    },

    _endClick(row) {
      if (this.rowClickTimer) {
        clearTimeout(this.rowClickTimer)
        this.rowClickTimer = null
      }
    }
  }
}
</script>

<template>
  <div class="widget team-event-stats">
    <div class="widget-header flex">
      Série
      <button class="small no-radius flex-grow text-left" @click="_openStatSelectorModal" style="height: 28px">
        {{ _statLabel }}
      </button>
      <div class="widget-header-params flex">
        <widget-nb-events-param-button :widget="item"/>
        <widget-league-param-button :widget="item"/>
        <widget-period-param-button :widget="item"/>
        <widget-location-param-button :widget="item"/>
        <button class="ml-1 info square no-radius" @click="_expend">
          <font-awesome-icon :icon="['fas', 'up-right-and-down-left-from-center']"/>
        </button>
      </div>
    </div>
    <div class="widget-content">
      <div v-if="data">
        <serie-stats-widget-row v-for="(r, index) in _sortedData"
                                :row="r"
                                :even="index % 2 === 0"
                                @mouseenter="_updateOverContext(r)"
                                @mousedown="_startClickTimer(r)" @mouseup="_endClick(r)"/>
        <a-alert v-if="_sortedData.length === 0" message="Aucune donnée disponible" type="warning" show-icon class="m-1"/>
      </div>
      <widget-loading-content v-else/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.team-event-stats {
  overflow: hidden;

  .widget-header {
    background: -webkit-linear-gradient(0deg, @selected-color, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00);
  }

  .widget-content {
    height: auto;
    overflow: hidden;
  }
}

table {
  .logo {
    width: 24px;
    text-align: center;
  }

  .name {
    text-align: left;
    max-width: 150px;
    padding: 0 !important;
  }

  .relative-value {
    width: 52px;
    text-align: right;
    color: @text-color-dark;
  }

  .absolute-value {
    width: 24px;
    text-align: right;

  }


}

</style>
<script>
import {contextStore} from "@/stores/Context.store";
import {preferencesStore} from "@/stores/Preferences.store";

export default {
  name: "InitRoute",
  data() {
    return {
      interval: null
    }
  },

  beforeMount() {
    // if access in http redirect to https
    if (window.location.href.startsWith('http://docto.bet')) {
      window.location.href = 'https://docto.bet';
    }
  },

  mounted() {
    // get window width
    contextStore().initialize()
    this.interval = setInterval(() => {
      let isInitialized = contextStore().isInitialized();
      if(isInitialized) {

        // get redirect in query
        let query = this.$route.query
        let redirect = query.redirect
        let redirectQuery = {}
        if(query.query){
          redirectQuery = {uid: query.query}
        }

        if(!redirect || redirect === 'main'){
          redirect = preferencesStore().getFirstValue('DEFAULT_ROUTE', 'INIT_ROUTE')
        }
        if(!redirect){
          redirect = 'main'
        }

        // rename "selections" to "games"
        if(redirect === 'selections'){
          redirect = 'games'
        }

        setTimeout(() => {
          this.$router.push({name: redirect, query: redirectQuery})
        }, 500)

        clearInterval(this.interval)
      }
    }, 500)

    // On met a jour le nom de la route courante
    contextStore().setRoute('init')
  },
  computed: {
    _initiationProgress() {
      return contextStore().getInitiationProgress()
    }
  }
}
</script>

<template>
  <div id="init">
    <div class="loading-div">
      <a-progress class="loading-item" active :percent="_initiationProgress" :show-info="false" strokeColor="#000"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#init {
  // center div in page
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .loading-div {
    display: block;
    width: 320px;
    height: 320px;
    background-image: url('https://docto.bet/cdn/welcome.gif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: @padding-regular;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .11);
    border: 1px solid @stroke-color;

    h1 {
      font-size: 48px;
      font-weight: 700;
      color: black !important;
      text-align: center;
      position: absolute;
      left: 64px;
      top: 64px;
    }

    h2 {
      font-size: 24px;
      font-weight: 400;
      color: black !important;
      text-align: center;
      position: absolute;
      left: 64px;
      top: 128px;
    }

    .loading-item {
      position: absolute;
      left: 64px;
      bottom: 32px;
      width: calc(100% - 128px)
    }
  }
}
</style>
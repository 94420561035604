// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";
import {preferencesStore} from "@/stores/Preferences.store";
import {contextStore} from "@/stores/Context.store";

export const bookmakerStore = defineStore('bookmakerStore', {
    state: () => {
        return {
            items: null
        }
    },
    actions: {
        // Permet de récupérer un match par son UID
        fetchAll(callback = null) {
            if (this.items !== null) {
                if (callback) callback()
                return
            }
            this.items = []
            axiosService.get('/dta/bookmakers').then(response => {
                this.items = this.items.concat(response.data.results)

                if (this.items.length === 0) return

                const ps = preferencesStore()
                let favBookUID = ps.getFirstValue('BETTING', 'FAVORITE_BOOKMAKER')
                if (!favBookUID) {
                    favBookUID = this.items[0].uid
                    ps.save('BETTING', 'FAVORITE_BOOKMAKER', [favBookUID])
                }
                const cs = contextStore()
                cs.setBookmakerUID(favBookUID)
            }).finally(() => {
                if (callback) callback()
            });
        }
    },
    getters: {

        get: (state) => (uid) => {

            if (state.items === null) {
                return null
            }

            let item = state.items.find(item => item.uid === uid);
            if (item) {
                return item
            }

            return null
        },

        getAll: (state) => {
            if (state.items === null) {
                console.error('bookmakerStore.getSelected', 'state.items === null')
                return []
            }

            return state.items
        }
    }
})
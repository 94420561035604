<script>
import TeamName from "@/components/common/TeamName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import axiosService from "@/services/axios.service";
import LeagueName from "@/components/common/LeagueName.vue";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import GameScore from "@/components/common/GameScore.vue";
import GameDate from "@/components/common/GameDate.vue";
import {gameStore} from "@/stores/Game.store";
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import TeamModalGameRow from "@/components/common/modal/team/TeamModalGameRow.vue";

export default {
  name: "TeamModal",
  components: {TeamModalGameRow, GameDate, GameScore, GameResultCircle, LeagueName, TeamLogo, TeamName},
  data() {
    return {
      seasons: [],
      selectedLeagueUID: null,
      selectedSeasonUID: null,
      games: [],

      // On va stocker les games dans le store pour éviter de les recharger à chaque fois
      cache : {}
    }
  },
  computed: {
    _gameStore() {
      return gameStore()
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'teamModal';
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.payload
    },
    _teamUID() {
      if (!this._payload) return null;
      return this._payload.uid
    },
    _seasonsGroupByLeagueUID() {
      return this.seasons.reduce((acc, season) => {
        if (!acc[season.leagueUID]) {
          acc[season.leagueUID] = [];
        }
        acc[season.leagueUID].push(season);
        return acc;
      }, {})
    },
    _gameUIDs() {
      if(!this.games){
        return []
      }

      // sort games by date
      this.games.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      }).reverse();

      return this.games.map(g => g.uid)
    },

    _isMobile(){
      return contextStore().isMobileDevice()
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    isLeagueSelected(uid) {
      if (!this.selectedLeagueUID) return false;
      return parseInt(this.selectedLeagueUID) === parseInt(uid);
    },
    isSeasonSelected(uid) {
      if (!this.selectedSeasonUID) return false;
      return parseInt(this.selectedSeasonUID) === parseInt(uid);
    },
    _setLeagueUID(uid) {
      this.selectedLeagueUID = uid;
      this.games = [];

      const selectedSeason = this.seasons.find(season => season.uid === this.selectedSeasonUID)
      if(!selectedSeason){
        console.warn('No selected season')
        return
      }
      const selectedSeasonYear = selectedSeason.year;
      const newLeagueSeasons = this.seasons.filter(season => parseInt(season.leagueUID) === parseInt(uid));
      if(!newLeagueSeasons){
        console.warn('No seasons for this league')
        return
      }
      const newSelectedSeason = newLeagueSeasons.find(season => season.year === selectedSeasonYear)
      if(!newSelectedSeason){
        console.warn('No season for this year')
        return
      }
      this.selectedSeasonUID = newSelectedSeason.uid;
    },
    _setSeasonUID(uid) {
      this.selectedSeasonUID = uid;
    },
    _leagueSeasons(uid){
      let seasons = this._seasonsGroupByLeagueUID[uid]

      if(!seasons){
        return []
      }

      // sort seasons by year
      seasons.sort((a, b) => {
        return a.year - b.year;
      }).reverse();
      return seasons
    }
  },
  watch: {
    _teamUID(uid) {
      if (!uid) return;
      // On va chercher les seasons auxquelles l'équipe participe
      axiosService.get(`/dta/seasons?teamUID=` + uid).then(response => {
        this.seasons = response.data.results;

        // On sélectionne la saison en cours
        this.seasons.forEach(season => {
          if (season.current) {
            this.selectedSeasonUID = season.uid;
            this.selectedLeagueUID = season.leagueUID;
          }
        })
      })
    },
    selectedSeasonUID(uid) {
      // On vérifie si on a déjà les games en cache
      if(this.cache[uid]){
        this.games = this.cache[uid]
        return
      }

      axiosService.get(`/dta/games?teamUID=` + this._teamUID + `&seasonUID=` + uid).then(response => {
        this._gameStore.addGames(response.data.results)
        this.games = response.data.results;

        // On stocke les games en cache
        this.cache[uid] = response.data.results
      })
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      <team-logo :uid="_teamUID" size="small" class="mr-2"/>
      <team-name :uid="_teamUID"/>
    </header>

    <div class="content">
      <fieldset class="m-1 p-1">
        <legend>Compétitions</legend>
        <button v-for="leagueUID in Object.keys(_seasonsGroupByLeagueUID)" @click="_setLeagueUID(leagueUID)"
                :class="{'small info mr-1': true, 'primary selected' : isLeagueSelected(leagueUID)}">
          <league-name :uid="leagueUID"/>
        </button>
      </fieldset>

      <fieldset class="m-1 p-1">
        <legend>Saisons</legend>
        <button v-for="season in _leagueSeasons(selectedLeagueUID)" @click="_setSeasonUID(season.uid)"
                :class="{'small info mr-1': true, 'primary selected' : isSeasonSelected(season.uid)}">
          {{ season.year }}
        </button>
      </fieldset>

      <table style="width: 100%" class="m-1">
        <team-modal-game-row v-for="g in _gameUIDs" :game-u-i-d="g" :team-u-i-d="_teamUID"/>
      </table>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="" @click="_onCancel">Fermer</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
</style>